import React, { useContext, useEffect } from 'react';
import { FormGroup } from 'reactstrap';
import { ShepherdTourContext } from 'react-shepherd';
import PropTypes from 'prop-types';

export function FormFields({
    fields,
    handleNewUserRole,
    companyOptions,
    diaryOptions,
    showTrialsConfigurations,
    roleIdOptions,
    currentUser,
    handleChangeCompany,
    langOptions,
}) {
    const tour = useContext(ShepherdTourContext);

    const sleepDiarySubscription = currentUser.subscription.plans.filter(
        (subscriptionPlan) => subscriptionPlan.type === 'sleep_diary'
    );

    useEffect(() => {
        (function () {
            if (currentUser.permissions === 'manager') {
                const showAddPatientTour = JSON.parse(
                    localStorage.getItem('showAddPatientTour')
                );

                if (
                    showAddPatientTour === null ||
                    showAddPatientTour === true
                ) {
                    localStorage.setItem('showAddPatientTour', 'true');
                    tour.start();
                }
            }
        })();
    }, []);

    return fields.map((field) => {
        switch (field.props.name) {
            case 'company_id':
                return (
                    <FormGroup
                        key={field.props.name}
                        className={field.className}
                    >
                        <field.component
                            {...field.props}
                            options={companyOptions}
                            onChange={(e) => handleChangeCompany(e?.value)}
                        />
                    </FormGroup>
                );
            case 'diary_id':
                return (
                    <FormGroup
                        key={field.props.name}
                        className={field.className}
                    >
                        <field.component
                            {...field.props}
                            options={diaryOptions}
                            isDisabled={
                                !(
                                    sleepDiarySubscription.length > 0 &&
                                    sleepDiarySubscription[0].active
                                )
                            }
                        />
                    </FormGroup>
                );
            case 'role_id':
                return currentUser.permissions === 'condor_admin' ? (
                    <FormGroup
                        key={field.props.name}
                        className={field.className}
                    >
                        <field.component
                            name={field.props.name}
                            label={field.props.label}
                            options={roleIdOptions}
                            onChange={(e) => handleNewUserRole(e?.value)}
                        />
                    </FormGroup>
                ) : null;
            case 'trialStart':
                return (
                    <FormGroup
                        key={field.props.name}
                        className={
                            showTrialsConfigurations
                                ? field.className
                                : 'hidden'
                        }
                    >
                        <field.component
                            {...field.props}
                            disabled={
                                !(
                                    sleepDiarySubscription.length > 0 &&
                                    sleepDiarySubscription[0].active
                                )
                            }
                        />
                    </FormGroup>
                );
            case 'trialEnd':
                return (
                    <FormGroup
                        key={field.props.name}
                        className={
                            showTrialsConfigurations
                                ? field.className
                                : 'hidden'
                        }
                    >
                        <field.component
                            {...field.props}
                            disabled={
                                !(
                                    sleepDiarySubscription.length > 0 &&
                                    sleepDiarySubscription[0].active
                                )
                            }
                        />
                    </FormGroup>
                );
            case 'lang':
                return (
                    <FormGroup
                        key={field.props.name}
                        className={field.className}
                    >
                        <field.component
                            {...field.props}
                            options={langOptions}
                        />
                    </FormGroup>
                );
            case 'active':
                return (
                    <FormGroup
                        key={field.props.name}
                        className={field.className}
                    >
                        <field.component
                            {...field.props}
                            isDisabled={
                                !(
                                    sleepDiarySubscription.length > 0 &&
                                    sleepDiarySubscription[0].active
                                )
                            }
                        />
                    </FormGroup>
                );
            default:
                return (
                    <FormGroup
                        key={field.props.name}
                        className={field.className}
                    >
                        <field.component {...field.props} />
                    </FormGroup>
                );
        }
    });
}

FormFields.propTypes = {
    fields: PropTypes.array.isRequired,
    handleNewUserRole: PropTypes.func.isRequired,
    companyOptions: PropTypes.array,
    diaryOptions: PropTypes.array,
    showTrialsConfigurations: PropTypes.bool,
    roleIdOptions: PropTypes.array,
    currentUser: PropTypes.object.isRequired,
    handleChangeCompany: PropTypes.func,
    langOptions: PropTypes.array,
    showShareOptions: PropTypes.bool,
};
