import Input from '../../../components/inputForm/inputText';

export default {
    pt: {
        customer_admin: null,
        manager: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'ID Secundário',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'active',
                        label: 'Ativo',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'share',
                        label: 'Compartilhar pacientes?',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            deleteSuccessText: 'Gestor excluído com sucesso!',
            deleteButtonText: 'Excluir',
            cancelButtonText: 'Cancelar',
            activeText: 'Sim',
            inactiveText: 'Não',
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'Secondary ID',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'active',
                        label: 'Active',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'share',
                        label: 'Share patients?',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            deleteSuccessText: 'Manager successfully deleted!',
            deleteButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            activeText: 'Yes',
            inactiveText: 'No',
        },
        condor_admin: null,
    },
};
