import React, { useState } from 'react';
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarWeek } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

export function SelectDaysButton({
    texts,
    disabled,
    handleUpdateDailyEvolution,
    selectedDays,
    setSelectedDays,
}) {
    function handleClick(days) {
        if (days !== selectedDays) {
            setSelectedDays(days);
        }

        handleUpdateDailyEvolution(days);
    }

    return (
        <UncontrolledButtonDropdown disabled={disabled}>
            <DropdownToggle caret color="info">
                <FontAwesomeIcon icon={faCalendarWeek} /> {texts.periodText}
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem
                    disabled={selectedDays === 7}
                    onClick={() => handleClick(7)}
                >
                    {texts.sevenDaysText}
                </DropdownItem>
                <DropdownItem
                    disabled={selectedDays === 14}
                    onClick={() => handleClick(14)}
                >
                    {texts.fourteenDaysText}
                </DropdownItem>
                <DropdownItem
                    disabled={selectedDays === 21}
                    onClick={() => handleClick(21)}
                >
                    {texts.twentyOneDaysText}
                </DropdownItem>
                <DropdownItem
                    disabled={selectedDays === 28}
                    onClick={() => handleClick(28)}
                >
                    {texts.twentyEightDaysText}
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
}

SelectDaysButton.propTypes = {
    texts: PropTypes.object,
    disabled: PropTypes.bool,
    handleUpdateDailyEvolution: PropTypes.func,
    selectedDays: PropTypes.number,
    setSelectedDays: PropTypes.func,
};
