import React from 'react';
import PropTypes from 'prop-types';

import { handleActiveProperty } from '../../../helpers/handleActiveProperty';
import Td from '../../../components/table/td';
import ActionsContainer from '../../../components/table/actionsContainer';

export function TableTds({ tds, manager, actions, activeText, inactiveText }) {
    let { className, text } = handleActiveProperty(
        manager.active,
        activeText,
        inactiveText
    );

    return tds.map((td, index) => {
        switch (td.property) {
            case 'active':
                return (
                    <Td key={index} title={td.th} className={className}>
                        {text}
                    </Td>
                );
            case 'actions':
                return (
                    <Td key={index} title={td.th}>
                        <ActionsContainer actions={actions} entity={manager} />
                    </Td>
                );
            default:
                return (
                    <Td key={index} title={td.th}>
                        {manager[td.property] === null
                            ? ''
                            : manager[td.property]}
                    </Td>
                );
        }
    });
}

TableTds.propTypes = {
    tds: PropTypes.array.isRequired,
    manager: PropTypes.object.isRequired,
    actions: PropTypes.array,
    activeText: PropTypes.string.isRequired,
    inactiveText: PropTypes.string.isRequired,
};
