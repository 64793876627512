import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { BsSmartwatch } from 'react-icons/bs';
import { FaInfinity } from 'react-icons/fa';
import PropTypes from 'prop-types';

import '../../styles/pages/devices/devices.css';

import pageConfig from './page.config';
import { setLoading } from '../../store/actions/loadingActions';
import { fetchDevices } from '../../services/api/devices';
import { useOrderByProperty } from '../../hooks/useOrderByProperty';
import { SearchAndAddContainer } from './components/searchAndAddContainer';
import { TableContainer } from './components/tableContainer';
import { InfoCard } from '../../components/infoCard';

Devices.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function Devices({ currentUser }) {
    const [devices, setDevices] = useState([]);
    const { activeFilter, filteredData, setFilteredData, orderByProperty } =
        useOrderByProperty(devices, 'id', 'asc');

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const isLoading = useSelector((state) => state.loading.isLoading);

    const pageData = pageConfig[lang][currentUser.permissions];

    const active_devices = devices.reduce((acc, device) => {
        if (device.active) {
            return acc + 1;
        }

        return acc;
    }, 0);

    const lumusSubscription = currentUser.subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'lumus'
    );

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            const data = await fetchDevices();

            if (data?.devices) {
                if (data.devices.length === 0) {
                    toast.info(pageData.noDevicesText);
                }

                setDevices(data?.devices);
            }

            dispatch(setLoading(false));
        })();
    }, []);

    function updateDevice(deviceId, active) {
        setDevices((prevState) => {
            const newDevices = [...prevState];

            return newDevices.map((device) => ({
                ...device,
                active: device.id === deviceId ? active : device.active,
                body_part: device.id === deviceId ? null : device.body_part,
                last_server_connection:
                    device.id === deviceId
                        ? null
                        : device.last_server_connection,
                received_at: device.id === deviceId ? null : device.received_at,
                user_email: device.id === deviceId ? null : device.user_email,
                user_id: device.id === deviceId ? null : device.user_id,
                user_name: device.id === deviceId ? null : device.user_name,
            }));
        });

        setFilteredData((prevState) => {
            const newDevices = [...prevState];

            return newDevices.map((device) => ({
                ...device,
                active: device.id === deviceId ? active : device.active,
                body_part: device.id === deviceId ? null : device.body_part,
                last_server_connection:
                    device.id === deviceId
                        ? null
                        : device.last_server_connection,
                received_at: device.id === deviceId ? null : device.received_at,
                user_email: device.id === deviceId ? null : device.user_email,
                user_id: device.id === deviceId ? null : device.user_id,
                user_name: device.id === deviceId ? null : device.user_name,
            }));
        });
    }

    if (isLoading || pageData === null) {
        return <></>;
    }

    return (
        <>
            <Row>
                <Col xs="12" md="3" lg="3" xl="3">
                    <InfoCard
                        value={active_devices}
                        title={pageData.activeDevicesText}
                        icon={BsSmartwatch}
                        className="cardDashboard blueBackground"
                    />
                </Col>
                <Col xs="12" md="3" lg="3" xl="3">
                    <InfoCard
                        value={
                            lumusSubscription
                                ? lumusSubscription?.plan?.name !==
                                  'Lumus Unlimited'
                                    ? lumusSubscription?.max_devices -
                                      active_devices
                                    : FaInfinity
                                : 0
                        }
                        title={pageData.remainingDevicesQuotaText}
                        icon={BsSmartwatch}
                        className="cardDashboard greenBackground"
                    />
                </Col>
            </Row>

            <SearchAndAddContainer
                currentUser={currentUser}
                devices={devices}
                setFilteredDevices={setFilteredData}
                texts={{
                    searchBySerialNumberText: pageData.searchBySerialNumberText,
                    addText: pageData.addText,
                    addDeviceText: pageData.addDeviceText,
                    importDevicesText: pageData.importDevicesText,
                    batteryDischargeCurveText:
                        pageData.batteryDischargeCurveText,
                }}
            />

            <TableContainer
                devices={filteredData}
                activeFilter={activeFilter}
                orderByProperty={orderByProperty}
                texts={{
                    successText: pageData.successText,
                    activateDeviceText: pageData.activateDeviceText,
                    deactivateDeviceText: pageData.deactivateDeviceText,
                    ths: pageData.ths,
                    tds: pageData.tds,
                    actions: pageData.actions,
                    warningDeactivateDeviceText:
                        pageData.warningDeactivateDeviceText,
                    attentionText: pageData.attentionText,
                    deactivateText: pageData.deactivateText,
                    cancelText: pageData.cancelText,
                }}
                updateDevice={updateDevice}
            />
        </>
    );
}
