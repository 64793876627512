import React from 'react';
import PropTypes from 'prop-types';

import { handleActiveProperty } from '../../../../helpers/handleActiveProperty';
import { currentWeekType } from '../../../../helpers/handleType';
import { localeDateFormatter } from '../../../../helpers/formatters';
import Td from '../../../../components/table/td';

export function UserTableTds({
    tds,
    user,
    currentWeek,
    activeText,
    inactiveText,
    noDataText,
    locale,
}) {
    const { weekNumber, weekReference } = currentWeek;

    let { className, text } = handleActiveProperty(
        user.active,
        activeText,
        inactiveText
    );

    return tds.map((td, index) => {
        switch (td.property) {
            case 'active':
                return (
                    <Td key={index} title={td.th} className={className}>
                        {text}
                    </Td>
                );
            case 'trialStart':
                return (
                    <Td key={index} title={td.th}>
                        {user.trials_configurations[td.property]
                            ? localeDateFormatter(
                                  user.trials_configurations[td.property],
                                  locale
                              )
                                ? localeDateFormatter(
                                      user.trials_configurations[td.property],
                                      locale
                                  )
                                : noDataText
                            : noDataText}
                    </Td>
                );
            case 'weekNumber':
                return (
                    <Td key={index} title={td.th}>
                        {currentWeekType(weekNumber, noDataText)}
                    </Td>
                );
            case 'weekReference':
                return (
                    <Td key={index} title={td.th}>
                        {weekReference?.start && weekReference?.end
                            ? `${
                                  localeDateFormatter(
                                      weekReference.start,
                                      locale
                                  )
                                      ? localeDateFormatter(
                                            weekReference.start,
                                            locale
                                        )
                                      : '--'
                              } -
                              ${
                                  localeDateFormatter(weekReference.end, locale)
                                      ? localeDateFormatter(
                                            weekReference.end,
                                            locale
                                        )
                                      : '--'
                              }`
                            : noDataText}
                    </Td>
                );
            default:
                return (
                    <Td key={index} title={td.th}>
                        {user[td.property]}
                    </Td>
                );
        }
    });
}

UserTableTds.propTypes = {
    tds: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    currentWeek: PropTypes.object.isRequired,
    activeText: PropTypes.string.isRequired,
    inactiveText: PropTypes.string.isRequired,
    noDataText: PropTypes.string.isRequired,
    locale: PropTypes.object.isRequired,
};
