import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';

export default {
    pt: {
        customer_admin: null,
        manager: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'ID Secundário',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Ativo',
                        options: [
                            { value: false, label: 'Não' },
                            { value: true, label: 'Sim' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'share',
                        label: 'Compartilhar pacientes?',
                        options: [
                            { value: false, label: 'Não' },
                            { value: true, label: 'Sim' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            successText: 'Gestor editado com sucesso!',
            saveText: 'Salvar',
            cancelText: 'Cancelar',
            yesText: 'Sim',
            noText: 'Não',
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'Secondary ID',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Active',
                        options: [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'share',
                        label: 'Share patients?',
                        options: [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            successText: 'Manager successfully edited!',
            saveText: 'Save',
            cancelText: 'Cancel',
            yesText: 'Yes',
            noText: 'No',
        },
        condor_admin: null,
    },
};
