import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';

function CustomModal({
    className,
    modalButtonLabel,
    modalButtonTitle,
    modalTitle,
    modalText,
    hasPrimaryButton,
    primaryButtonHasLink,
    primaryButtonPathName,
    primaryButtonText,
    primaryButtonState,
    hasSecondaryButton,
    secondaryButtonHasLink,
    secondaryButtonPathName,
    secondaryButtonText,
    secondaryButtonState,
}) {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <>
            <Button
                color="primary"
                onClick={toggle}
                className={className}
                title={modalButtonTitle}
            >
                {modalButtonLabel}
            </Button>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
                <ModalBody>{modalText}</ModalBody>
                <ModalFooter>
                    {hasPrimaryButton &&
                        (primaryButtonHasLink ? (
                            <Link
                                to={{
                                    pathname: primaryButtonPathName,
                                    state: primaryButtonState,
                                }}
                                className="btn btn-primary"
                                onClick={toggle}
                            >
                                {primaryButtonText}
                            </Link>
                        ) : (
                            <Button
                                className="btn btn-primary"
                                onClick={toggle}
                            >
                                {primaryButtonText}
                            </Button>
                        ))}

                    {hasSecondaryButton &&
                        (secondaryButtonHasLink ? (
                            <Link
                                to={{
                                    pathname: secondaryButtonPathName,
                                    state: secondaryButtonState,
                                }}
                                className="btn btn-secondary"
                                onClick={toggle}
                            >
                                {secondaryButtonText}
                            </Link>
                        ) : (
                            <Button color="secondary" onClick={toggle}>
                                {secondaryButtonText}
                            </Button>
                        ))}
                </ModalFooter>
            </Modal>
        </>
    );
}

CustomModal.propTypes = {
    className: PropTypes.string,
    modalButtonLabel: PropTypes.string.isRequired,
    modalButtonTitle: PropTypes.string.isRequired,
    modalTitle: PropTypes.string.isRequired,
    modalText: PropTypes.string.isRequired,
    hasPrimaryButton: PropTypes.bool.isRequired,
    primaryButtonHasLink: PropTypes.bool.isRequired,
    primaryButtonPathName: PropTypes.string,
    primaryButtonText: PropTypes.string,
    primaryButtonState: PropTypes.object,
    hasSecondaryButton: PropTypes.bool.isRequired,
    secondaryButtonHasLink: PropTypes.bool.isRequired,
    secondaryButtonPathName: PropTypes.string,
    secondaryButtonText: PropTypes.string.isRequired,
    secondaryButtonState: PropTypes.object,
};

export default CustomModal;
