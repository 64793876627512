import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';

import Card from '../../../../components/card/card';
import Table from '../../../../components/table/table';
import TableHeaders from '../../../../components/table/tableHeaders';
import { TablesRows } from './tablesRows';

export function Tables({ data, textData }) {
    const { ths, tds, sleepStatisticsTitle, napStatisticsTitle } = textData;

    if (data === null || data.length === 0) {
        return <></>;
    }

    return (
        <>
            <Col xs="12" className="reportTable">
                <Card
                    title={sleepStatisticsTitle}
                    isTable={true}
                    textSize="lg"
                    textCenter
                >
                    <Table>
                        <thead>
                            <tr>
                                <TableHeaders ths={ths.sleepAndMedicine} />
                            </tr>
                        </thead>
                        <tbody>
                            <TablesRows
                                tableData={data}
                                tds={tds.sleepAndMedicine}
                            />
                        </tbody>
                    </Table>
                </Card>
            </Col>
            <Col xs="12" className="reportTable">
                <Card
                    title={napStatisticsTitle}
                    isTable={true}
                    textSize="lg"
                    textCenter
                >
                    <Table>
                        <thead>
                            <tr>
                                <TableHeaders ths={ths.naps} />
                            </tr>
                        </thead>
                        <tbody>
                            <TablesRows tableData={data} tds={tds.naps} />
                        </tbody>
                    </Table>
                </Card>
            </Col>
        </>
    );
}

Tables.propTypes = {
    data: PropTypes.array.isRequired,
    textData: PropTypes.object.isRequired,
};
