import React from 'react';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import PropTypes from 'prop-types';

export function ThWithOrdering({
    children,
    property,
    activeFilterProperty,
    activeOrder,
    orderByProperty,
    activeClassName,
    clickableIconClassName,
}) {
    return (
        <th>
            <div className="d-flex justify-content-center align-items-center">
                {children}
                <div className="ml-2 d-flex flex-column">
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        className={classnames({
                            [activeClassName]:
                                activeFilterProperty === property &&
                                activeOrder === 'asc',
                            [clickableIconClassName]: true,
                        })}
                        onClick={() => orderByProperty(property, 'asc')}
                    />
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        className={classnames({
                            [activeClassName]:
                                activeFilterProperty === property &&
                                activeOrder === 'desc',
                            [clickableIconClassName]: true,
                        })}
                        onClick={() => orderByProperty(property, 'desc')}
                    />
                </div>
            </div>
        </th>
    );
}

ThWithOrdering.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        .isRequired,
    property: PropTypes.string.isRequired,
    activeFilterProperty: PropTypes.string.isRequired,
    activeOrder: PropTypes.string.isRequired,
    orderByProperty: PropTypes.func.isRequired,
    activeClassName: PropTypes.string.isRequired,
    clickableIconClassName: PropTypes.string.isRequired,
};
