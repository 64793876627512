import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import imageExtensions from '../../config/imageExtensions';
import fileExtensions from '../../config/fileExtensions';

export default function FileInput({
    name,
    label,
    label2,
    noselectedfiletext,
    ...rest
}) {
    const inputRef = useRef(null);

    const { fieldName, registerField, defaultValue, error } = useField(name);
    const [preview, setPreview] = useState(defaultValue);
    const [previewImage, setPreviewImage] = useState(defaultValue);
    const [previewFileName, setPreviewFileName] = useState(defaultValue);

    const handleImagePreview = useCallback((file) => {
        const previewURL = URL.createObjectURL(file);

        setPreview(previewURL);
        setPreviewImage(previewURL);
        setPreviewFileName(file.name);

        return;
    }, []);

    const handleFileNamePreview = useCallback((file) => {
        setPreview(file.name);
        setPreviewFileName(file.name);
        setPreviewImage(null);

        return;
    }, []);

    const handlePreview = useCallback((e) => {
        const file = e.target.files?.[0];

        if (!file) {
            setPreview(null);
            setPreviewImage(null);
            setPreviewFileName(null);
            return;
        }

        if (
            imageExtensions.find(
                (extension) => extension === file.name.split('.')[1]
            )
        ) {
            handleImagePreview(file);
            return;
        }

        if (
            fileExtensions.find(
                (extension) => extension === file.name.split('.')[1]
            )
        ) {
            handleFileNamePreview(file);
            return;
        }

        setPreview(null);
        setPreviewImage(null);
        setPreviewFileName(null);

        return;
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'files[0]',
            clearValue(ref) {
                ref.value = '';
                setPreview(null);
            },
            setValue(_, value) {
                setPreview(value);
            },
        });
    }, [fieldName, registerField]);

    return (
        <div className="d-flex flex-column">
            {label && (
                <label
                    dangerouslySetInnerHTML={{
                        __html: label,
                    }}
                ></label>
            )}

            <div>
                {label2 && (
                    <label
                        htmlFor={fieldName}
                        dangerouslySetInnerHTML={{
                            __html: label2,
                        }}
                        className="btn btn-primary"
                    ></label>
                )}

                {!preview && (
                    <p className="fileNamePreview">{noselectedfiletext}</p>
                )}

                {preview && previewImage && (
                    <img src={preview} alt="Preview" width="100" />
                )}

                {preview && previewFileName && (
                    <div className="fileNamePreview">
                        {preview && previewFileName
                            ? previewFileName
                            : noselectedfiletext}
                    </div>
                )}

                <input
                    ref={inputRef}
                    id={fieldName}
                    name={fieldName}
                    type="file"
                    onChange={handlePreview}
                    style={{ opacity: 0 }}
                    {...rest}
                    className="hidden"
                />
            </div>

            {error && <span className="text-danger">{error}</span>}
        </div>
    );
}

FileInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    label2: PropTypes.string,
    noselectedfiletext: PropTypes.string,
};
