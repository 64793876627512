import React from 'react';
import PropTypes from 'prop-types';

import { TableTds } from './tableTds';

export function TableRows({
    managers,
    tds,
    actions,
    activeText,
    inactiveText,
}) {
    return managers.map((manager) => {
        return (
            <tr key={manager.id}>
                <TableTds
                    tds={tds}
                    manager={manager}
                    actions={actions}
                    activeText={activeText}
                    inactiveText={inactiveText}
                />
            </tr>
        );
    });
}

TableRows.propTypes = {
    managers: PropTypes.array.isRequired,
    tds: PropTypes.array.isRequired,
    actions: PropTypes.array,
    activeText: PropTypes.string.isRequired,
    inactiveText: PropTypes.string.isRequired,
};
