import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import { PlanCard } from '../../../../components/planCard';
import FormFields from '../../../../components/form/formFields';
import {
    currencyFormatter,
    localeDateFormatter,
} from '../../../../helpers/formatters';
import { setLoading } from '../../../../store/actions/loadingActions';
import { cancelSubscription } from '../../../../services/api/account';
import history from '../../../../services/history';

MyAccountForm.propTypes = {
    pageData: PropTypes.object.isRequired,
    subscriptionFields: PropTypes.array.isRequired,
    currentUser: PropTypes.object.isRequired,
    paymentMethod: PropTypes.object,
    currency: PropTypes.object,
};

export function MyAccountForm({
    pageData,
    subscriptionFields,
    currentUser,
    paymentMethod,
    currency,
}) {
    const formRef = useRef(null);

    const dispatch = useDispatch();

    const { dateLocale } = useSelector((state) => state.locale);
    const lang = useSelector((state) => state.lang.lang);

    const sleepDiarySubscription = currentUser.subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'sleep_diary'
    );

    const lumusSubscription = currentUser.subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'lumus'
    );

    const handleClick = async (planId, subscriptionType) => {
        dispatch(setLoading(true));

        const response = await cancelSubscription({
            subscription_id: currentUser.sub_id ?? currentUser.subscription.id,
            plan_id: planId,
        });

        dispatch(setLoading(false));

        if (response) {
            let expiresAt =
                subscriptionType === 'sleep_diary'
                    ? sleepDiarySubscription?.expires_at
                    : lumusSubscription?.expires_at;

            toast.success(
                `${pageData.cancelSuccessText} ${
                    localeDateFormatter(expiresAt, dateLocale)
                        ? localeDateFormatter(expiresAt, dateLocale)
                        : pageData.wrongFormatText
                }`,
                {
                    autoClose: 10000,
                }
            );

            history.replace('/account/my_account');
        }
    };

    useEffect(() => {
        formRef.current.reset();

        const initialData = {};

        pageData.fields.user.map((field) => {
            switch (field.props.name) {
                default:
                    initialData[field.props.name] =
                        currentUser[field.props.name];
                    break;
            }
        });

        subscriptionFields.map((field) => {
            switch (field.props.name) {
                case 'payment_method':
                    if (currency.locale === 'pt-BR') {
                        initialData[field.props.name] =
                            paymentMethod?.data?.display_number;
                    } else {
                        initialData[field.props.name] =
                            '•••• •••• •••• ' + paymentMethod?.card?.last4;
                    }
                    break;
                case 'price':
                    initialData[field.props.name] = currencyFormatter(
                        currentUser?.subscription[field.props.name],
                        currency.locale,
                        currency.format
                    );
                    break;
                case 'expires_at':
                case 'billing_date':
                    initialData[field.props.name] = currentUser?.subscription[
                        field.props.name
                    ]
                        ? localeDateFormatter(
                              currentUser?.subscription[field.props.name],
                              dateLocale
                          )
                            ? localeDateFormatter(
                                  currentUser?.subscription[field.props.name],
                                  dateLocale
                              )
                            : pageData.wrongFormatText
                        : '';
                    break;
                default:
                    initialData[field.props.name] =
                        currentUser?.subscription[field.props.name];
                    break;
            }
        });

        formRef.current.setData(initialData);
    }, [lang]);

    return (
        <Form ref={formRef}>
            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{pageData.subscriptionSpanText}</span>
                </FormGroup>
            </FormGroup>
            <FormGroup row>
                <FormFields fields={pageData.fields.user} />
            </FormGroup>
            <FormGroup row>
                <FormFields fields={subscriptionFields} />
            </FormGroup>
            {currentUser?.subscription?.active && (
                <>
                    <hr />
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span>{pageData.plansText}</span>
                        </FormGroup>
                    </FormGroup>
                    <FormGroup row className={styles.planCardsContainer}>
                        {currentUser?.subscription?.plans?.map(
                            (subscriptionPlan) => (
                                <PlanCard
                                    key={subscriptionPlan.type}
                                    planName={subscriptionPlan?.plan?.name}
                                    price={currencyFormatter(
                                        subscriptionPlan.price,
                                        currency.locale,
                                        currency.format
                                    )}
                                    paymentFrequency={
                                        pageData.monthlyPaymentFrequencyText
                                    }
                                    fidelity={
                                        pageData.fidelityInputText[
                                            subscriptionPlan.fidelity
                                        ]
                                    }
                                    numberOfEntities={
                                        subscriptionPlan?.type === 'sleep_diary'
                                            ? subscriptionPlan?.plan?.name ===
                                              'Unlimited'
                                                ? pageData.unlimitedActivePatientsText
                                                : `${subscriptionPlan?.plan?.max_patients} ${pageData.activePatientsText}`
                                            : subscriptionPlan?.plan?.name ===
                                              'Lumus Unlimited'
                                            ? pageData.unlimitedActiveDevicesText
                                            : `${subscriptionPlan?.max_devices} ${pageData.activeDevicesText}`
                                    }
                                    active={subscriptionPlan.active}
                                    suspended={subscriptionPlan.suspended}
                                    trialSubscriptionExpiresIn={
                                        subscriptionPlan.plan.name
                                            .toLowerCase()
                                            .includes('trial')
                                            ? localeDateFormatter(
                                                  subscriptionPlan?.expires_at,
                                                  dateLocale
                                              )
                                                ? `${
                                                      pageData.expiresAtText
                                                  } ${localeDateFormatter(
                                                      subscriptionPlan?.expires_at,
                                                      dateLocale
                                                  )}`
                                                : null
                                            : null
                                    }
                                    cancelDate={
                                        subscriptionPlan.active &&
                                        subscriptionPlan.suspended
                                            ? localeDateFormatter(
                                                  subscriptionPlan?.expires_at,
                                                  dateLocale
                                              )
                                                ? `${
                                                      pageData.expiresAtText
                                                  } ${localeDateFormatter(
                                                      subscriptionPlan?.expires_at,
                                                      dateLocale
                                                  )}`
                                                : null
                                            : null
                                    }
                                    texts={{
                                        activeText: pageData.activeText,
                                        suspendedText: pageData.suspendedText,
                                        expiredText: pageData.expiredText,
                                        cancelText: pageData.cancelText,
                                        attentionText: pageData.attentionText,
                                        warningCancelText:
                                            pageData[
                                                `${subscriptionPlan.fidelity}WarningCancelText`
                                            ],
                                        closeText: pageData.closeText,
                                        unsubscribeText:
                                            pageData.unsubscribeText,
                                    }}
                                    subscriptionType={
                                        pageData.subscriptionTypeText[
                                            subscriptionPlan.type
                                        ]
                                    }
                                    showButton
                                    onClick={() =>
                                        handleClick(
                                            subscriptionPlan?.plan?.id,
                                            subscriptionPlan?.type
                                        )
                                    }
                                />
                            )
                        )}
                    </FormGroup>
                </>
            )}
            <hr />
            <FormGroup row className="my-0">
                <div className="col-xs-12 col-md-12 text-left">
                    {(!sleepDiarySubscription || !lumusSubscription) && (
                        <Link
                            to={{
                                pathname:
                                    '/account/my_account/sign_trial_subscription',
                                state: {
                                    planType: !sleepDiarySubscription
                                        ? 'sleep_diary'
                                        : !lumusSubscription
                                        ? 'lumus'
                                        : null,
                                    currency,
                                },
                            }}
                            className="btn btn-primary mr-2"
                        >
                            {!sleepDiarySubscription
                                ? pageData.diaryPlanText
                                : !lumusSubscription
                                ? pageData.lumusPlanText
                                : ''}
                        </Link>
                    )}
                    {sleepDiarySubscription && (
                        <Link
                            to={{
                                pathname: '/account/my_account/change_plan',
                                state: {
                                    currency,
                                    paymentMethod,
                                    type: 'sleep_diary',
                                },
                            }}
                            className="btn btn-primary mr-2"
                        >
                            {!sleepDiarySubscription?.active
                                ? pageData.signDiaryPlanText
                                : pageData.changeDiaryPlanText}
                            {/* {!sleepDiarySubscription?.active && sleepDiarySubscription?.suspended
                                ? pageData.signDiaryPlanText
                                : pageData.changeDiaryPlanText} */}
                        </Link>
                    )}
                    {lumusSubscription && (
                        <Link
                            to={{
                                pathname: '/account/my_account/change_plan',
                                state: {
                                    currency,
                                    paymentMethod,
                                    type: 'lumus',
                                },
                            }}
                            className="btn btn-primary mr-2"
                        >
                            {!lumusSubscription?.active
                                ? pageData.signLumusPlanText
                                : pageData.changeLumusPlanText}
                            {/* {!lumusSubscription?.active && lumusSubscription?.suspended
                                ? pageData.signLumusPlanText
                                : pageData.changeLumusPlanText} */}
                        </Link>
                    )}
                </div>
            </FormGroup>
        </Form>
    );
}
