import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import history from '../../services/history';

function ActionsContainer({ actions, entity }) {
    return actions.map((action) => {
        return (
            <Link
                key={crypto.randomUUID()}
                to={{
                    pathname: action.to,
                    state: { entity, to: history.location.pathname },
                }}
                className={action.className}
                title={action.title}
            >
                <FontAwesomeIcon icon={action.icon} />
            </Link>
        );
    });
}

ActionsContainer.propTypes = {
    actions: PropTypes.array.isRequired,
    entity: PropTypes.object.isRequired,
};

export default ActionsContainer;
