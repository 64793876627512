import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

export function Td({ children, ...rest }) {
    const { className, title } = rest;

    return (
        <td className={className}>
            <Row>
                <Col xs="12" className="mobileTh">
                    {title}
                </Col>
                <Col xs="12">{children}</Col>
            </Row>
        </td>
    );
}

Td.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
        PropTypes.object.isRequired,
        PropTypes.element.isRequired,
        PropTypes.node.isRequired,
    ]),
};

export default Td;
