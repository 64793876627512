import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: {
            schema: Yup.object().shape({
                name: Yup.string().required('Nome é obrigatório'),
                email: Yup.string()
                    .email('Formato inválido')
                    .required('E-mail é obrigatório'),
                lang: Yup.string().required('Idioma é obrigatório'),
            }),
        },
        manager: {
            schema: Yup.object().shape({
                name: Yup.string().required('Nome é obrigatório'),
                email: Yup.string()
                    .email('Formato inválido')
                    .required('E-mail é obrigatório'),
                lang: Yup.string().required('Idioma é obrigatório'),
            }),
        },
        condor_admin: {
            schema: Yup.object().shape({
                name: Yup.string().required('Nome é obrigatório'),
                email: Yup.string()
                    .email('Formato inválido')
                    .required('E-mail é obrigatório'),
                lang: Yup.string().required('Idioma é obrigatório'),
            }),
        },
    },
    en: {
        customer_admin: {
            schema: Yup.object().shape({
                name: Yup.string().required('Name is required'),
                email: Yup.string()
                    .email('Invalid format')
                    .required('E-mail is required'),
                lang: Yup.string().required('Language is required'),
            }),
        },
        manager: {
            schema: Yup.object().shape({
                name: Yup.string().required('Name is required'),
                email: Yup.string()
                    .email('Invalid format')
                    .required('E-mail is required'),
                lang: Yup.string().required('Language is required'),
            }),
        },
        condor_admin: {
            schema: Yup.object().shape({
                name: Yup.string().required('Name is required'),
                email: Yup.string()
                    .email('Invalid format')
                    .required('E-mail is required'),
                lang: Yup.string().required('Language is required'),
            }),
        },
    },
};
