import React, { useContext, useEffect } from 'react';
import { ShepherdTourContext } from 'react-shepherd';
import PropTypes from 'prop-types';

import Table from '../../../../components/table/table';
import TableHeaders from '../../../../components/table/tableHeaders';
import { UserTableTds as TableTds } from './userTableTds';

export function UserTable({
    user,
    textData,
    locale,
    currentWeek,
    currentUser,
    steps,
}) {
    const tour = useContext(ShepherdTourContext);

    const { ths, tds, activeText, inactiveText, noDataText } = textData;

    useEffect(() => {
        (function () {
            if (currentUser.permissions === 'manager') {
                if (currentWeek?.weekNumber !== null) {
                    const showReportTour = JSON.parse(
                        localStorage.getItem('showReportTour')
                    );

                    if (showReportTour) {
                        tour.start();
                    }
                }
            }
        })();
    }, [steps]);

    return (
        <Table>
            <thead>
                <tr>
                    <TableHeaders ths={ths} />
                </tr>
            </thead>
            <tbody>
                <tr>
                    <TableTds
                        tds={tds}
                        user={user}
                        currentWeek={currentWeek}
                        activeText={activeText}
                        inactiveText={inactiveText}
                        noDataText={noDataText}
                        locale={locale}
                    />
                </tr>
            </tbody>
        </Table>
    );
}

UserTable.propTypes = {
    user: PropTypes.object.isRequired,
    textData: PropTypes.object.isRequired,
    locale: PropTypes.object.isRequired,
    currentWeek: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    steps: PropTypes.array.isRequired,
};
