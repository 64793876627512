import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { setLoading } from '../../../../store/actions/loadingActions';
import { validateForm } from '../../../../helpers/formValidator';
import { sendUserCredentials } from '../../../../services/api/users';
import history from '../../../../services/history';
import FormFields from '../../../../components/form/formFields';

export function MyForm({ manager, fields, schema, texts, languageOptions }) {
    const formRef = useRef(null);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const { successText, sendText, changeEmailText, cancelText } = texts;

    const handleSubmit = async (data) => {
        const { isValid, errors } = await validateForm(data, schema);

        if (isValid) {
            formRef.current.setErrors(errors);

            dispatch(setLoading(true));

            const response = await sendUserCredentials(manager.id, data);

            dispatch(setLoading(false));

            if (response) {
                toast.success(successText);
                history.push('/managers');
            }
        } else {
            formRef.current.setErrors(errors);
        }
    };

    useEffect(() => {
        formRef.current.reset();

        const initialData = {};

        fields.map((field) => {
            switch (field.props.name) {
                default:
                    initialData[field.props.name] = manager[field.props.name];
                    break;
            }
        });

        formRef.current.setData(initialData);
    }, []);

    useEffect(() => {
        if (formRef.current.getFieldValue('lang')) {
            let selectedLanguage = languageOptions.find(
                (lang) => lang.value === formRef.current.getFieldValue('lang')
            );

            formRef.current.setFieldValue('lang', {
                label: selectedLanguage?.label,
                value: selectedLanguage?.value,
            });
        }
    }, [lang]);

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <FormGroup row>
                <FormFields fields={fields} />
            </FormGroup>
            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button color="primary" className="mr-2" type="submit">
                        {sendText}
                    </Button>
                    <Link
                        to={{
                            pathname: '/managers/edit',
                            state: { entity: manager },
                        }}
                        className="btn btn-secondary mr-2"
                    >
                        {changeEmailText}
                    </Link>
                    <Link to={'/managers'} className="btn btn-secondary">
                        {cancelText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}

MyForm.propTypes = {
    manager: PropTypes.object.isRequired,
    fields: PropTypes.array.isRequired,
    schema: PropTypes.object.isRequired,
    texts: PropTypes.object.isRequired,
    languageOptions: PropTypes.array.isRequired,
};
