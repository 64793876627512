import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Button, Card, CardBody, CardFooter, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { setLoading } from '../../../store/actions/loadingActions';
import { validateForm } from '../../../helpers/formValidator';
import { addDevice } from '../../../services/api/devices';
import { fetchCompanies } from '../../../services/api/companies';
import history from '../../../services/history';

export default function AddDevice({ currentUser }) {
    const formRef = useRef(null);

    const [companies, setCompanies] = useState([]);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];
    const schemaData = schemaConfig[lang][currentUser.permissions];

    const handleSubmit = async (data) => {
        const { parsedData, isValid, errors } = await validateForm(
            data,
            schemaData.schema
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(setLoading(true));

            const response = await addDevice(parsedData);

            dispatch(setLoading(false));

            if (response) {
                toast.success(pageData.successText);
                history.push('/devices');
            }
        }
    };

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            const data = await fetchCompanies();

            setCompanies(data?.companies);

            dispatch(setLoading(false));
        })();
    }, []);

    if (pageData === null) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <FormGroup row>
                        {pageData.fields.map((field) => {
                            switch (field.props.name) {
                                case 'company_id':
                                    return (
                                        <FormGroup
                                            key={field.props.name}
                                            className={field.className}
                                        >
                                            <field.component
                                                {...field.props}
                                                options={companies.map(
                                                    (company) => {
                                                        return {
                                                            value: company.id,
                                                            label: company.name,
                                                        };
                                                    }
                                                )}
                                            />
                                        </FormGroup>
                                    );
                                default:
                                    return (
                                        <FormGroup
                                            key={field.props.name}
                                            className={field.className}
                                        >
                                            <field.component {...field.props} />
                                        </FormGroup>
                                    );
                            }
                        })}
                    </FormGroup>
                    <hr />
                    <FormGroup row>
                        <div className="col-xs-12 col-md-12 text-left">
                            <Button color="primary mr-2" type="submit">
                                {pageData.addText}
                            </Button>
                            <Link to="/devices" className="btn btn-secondary">
                                {pageData.cancelText}
                            </Link>
                        </div>
                    </FormGroup>
                </Form>
            </CardBody>
            <CardFooter>
                <p
                    dangerouslySetInnerHTML={{
                        __html: pageData.requiredFieldText,
                    }}
                ></p>
            </CardFooter>
        </Card>
    );
}

AddDevice.propTypes = {
    currentUser: PropTypes.object.isRequired,
};
