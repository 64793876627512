import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Button, Card, CardBody, CardFooter, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { setLoading } from '../../../store/actions/loadingActions';
import { validateForm } from '../../../helpers/formValidator';
import { fetchCompany } from '../../../services/api/account';
import { addUser } from '../../../services/api/users';
import history from '../../../services/history';
import FormFields from '../../../components/form/formFields';

export default function AddManager({ currentUser }) {
    const formRef = useRef(null);
    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const {
        fields,
        addSuccessText,
        addButtonText,
        cancelButtonText,
        requiredFieldPText,
        yesText,
        noText,
        languageOptions,
    } = pageConfig[lang][currentUser.permissions];
    const { schema } = schemaConfig[lang][currentUser.permissions];

    async function handleSubmit(data) {
        const { isValid, errors } = await validateForm(data, schema);

        if (isValid) {
            formRef.current.setErrors(errors);

            dispatch(setLoading(true));

            data.role_id = 2;

            const response = await addUser(data);

            dispatch(setLoading(false));

            if (response) {
                toast.success(addSuccessText);
                history.push('/managers');
            }
        } else {
            formRef.current.setErrors(errors);
        }
    }

    useEffect(() => {
        formRef.current.reset();

        (async function () {
            dispatch(setLoading(true));

            const data = await fetchCompany();

            if (data) {
                if (
                    languageOptions[data?.company?.descriptor?.defaultLanguage]
                        ?.label
                ) {
                    formRef.current.setFieldValue('lang', {
                        label: languageOptions[
                            data?.company?.descriptor?.defaultLanguage
                        ]?.label,
                        value: languageOptions[
                            data?.company?.descriptor?.defaultLanguage
                        ]?.value,
                    });
                }

                formRef.current.setFieldValue('share', {
                    label: data?.company?.share ? yesText : noText,
                    value: data?.company?.share,
                });
            }

            dispatch(setLoading(false));

            formRef.current.setFieldValue('active', {
                label: yesText,
                value: true,
            });
        })();
    }, []);

    useEffect(() => {
        formRef.current.reset();

        formRef.current.setFieldValue('lang', {
            label: languageOptions[formRef.current.getFieldValue('lang')]
                ?.label,
            value: languageOptions[formRef.current.getFieldValue('lang')]
                ?.value,
        });

        formRef.current.setFieldValue('active', {
            label: formRef.current.getFieldValue('active') ? yesText : noText,
            value: formRef.current.getFieldValue('active'),
        });

        formRef.current.setFieldValue('share', {
            label: formRef.current.getFieldValue('share') ? yesText : noText,
            value: formRef.current.getFieldValue('share'),
        });
    }, [lang]);

    return (
        <>
            <Card className="cardShadow">
                <CardBody>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <FormGroup row>
                            <FormFields fields={fields} />
                        </FormGroup>
                        <FormGroup row>
                            <div className="col-xs-12 col-md-12 text-left">
                                <Button color="primary mr-2" type="submit">
                                    {addButtonText}
                                </Button>
                                <Link
                                    to={'/managers'}
                                    className="btn btn-secondary"
                                >
                                    {cancelButtonText}
                                </Link>
                            </div>
                        </FormGroup>
                    </Form>
                </CardBody>
                <CardFooter>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: requiredFieldPText,
                        }}
                    ></p>
                </CardFooter>
            </Card>
        </>
    );
}

AddManager.propTypes = {
    currentUser: PropTypes.object.isRequired,
};
