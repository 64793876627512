import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import Table from '../../../components/table/table';
import { ThWithOrdering } from '../../../components/table/thWithOrdering';
import { TableTds } from './tableTds';

TableContainer.propTypes = {
    devices: PropTypes.array.isRequired,
    activeFilter: PropTypes.object.isRequired,
    orderByProperty: PropTypes.func.isRequired,
    texts: PropTypes.object.isRequired,
    updateDevice: PropTypes.func.isRequired,
};

export function TableContainer({
    devices,
    activeFilter,
    orderByProperty,
    texts,
    updateDevice,
}) {
    const [loading, setLoading] = useState(false);

    return (
        <Row>
            <Col xs="12">
                <Table className="companiesTable">
                    <thead>
                        <tr>
                            {texts.ths.map((th) =>
                                [
                                    'serial_no',
                                    'received_at',
                                    'last_server_connection',
                                ].includes(th.property) ? (
                                    <ThWithOrdering
                                        key={th.property}
                                        property={th.property}
                                        activeFilterProperty={
                                            activeFilter.property
                                        }
                                        activeOrder={activeFilter.order}
                                        orderByProperty={orderByProperty}
                                        activeClassName="active"
                                        clickableIconClassName="clickableIcon"
                                    >
                                        {th.name}
                                    </ThWithOrdering>
                                ) : (
                                    <th key={th.property}>{th.name}</th>
                                )
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {devices.map((device) => {
                            return (
                                <tr key={device.id}>
                                    <TableTds
                                        texts={{
                                            successText: texts.successText,
                                            activateDeviceText:
                                                texts.activateDeviceText,
                                            deactivateDeviceText:
                                                texts.deactivateDeviceText,
                                            warningDeactivateDeviceText:
                                                texts.warningDeactivateDeviceText,
                                            attentionText: texts.attentionText,
                                            deactivateText:
                                                texts.deactivateText,
                                            cancelText: texts.cancelText,
                                        }}
                                        tds={texts.tds}
                                        device={device}
                                        actions={texts.actions}
                                        loading={loading}
                                        setLoading={setLoading}
                                        updateDevice={updateDevice}
                                    />
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}
