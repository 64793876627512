import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export function SearchAndAddContainer({
    currentUser,
    devices,
    setFilteredDevices,
    texts,
}) {
    const [nameFilter, setNameFilter] = useState('');

    const {
        searchBySerialNumberText,
        addText,
        addDeviceText,
        importDevicesText,
        batteryDischargeCurveText,
    } = texts;

    function handleNameInputChange(e) {
        setNameFilter(e.target.value);
    }

    useEffect(() => {
        let tempFilteredDevices = [];

        if (nameFilter) {
            tempFilteredDevices = devices.filter((device) =>
                String(device.serial_no)
                    .toUpperCase()
                    .includes(nameFilter.toUpperCase())
            );
        } else {
            tempFilteredDevices = devices;
        }

        setFilteredDevices(tempFilteredDevices);
    }, [nameFilter]);

    return (
        <Row>
            <Col xs="12">
                <div className="searchAndAddContainer d-flex justify-content-end">
                    <input
                        name="searchSerialNumber"
                        placeholder={searchBySerialNumberText}
                        title={searchBySerialNumberText}
                        value={nameFilter}
                        onChange={handleNameInputChange}
                    />
                    {currentUser.permissions === 'condor_admin' && (
                        <>
                            <Link
                                to={{
                                    pathname: '/devices/add',
                                }}
                                className="btn btn-primary ml-2"
                                title={addDeviceText}
                            >
                                {addText}
                            </Link>
                            <Link
                                to={{
                                    pathname: 'devices/import',
                                }}
                                className="btn btn-primary ml-2"
                                title={importDevicesText}
                            >
                                {importDevicesText}
                            </Link>
                        </>
                    )}
                    <Link
                        to={{
                            pathname: 'devices/battery_discharge',
                            state: {
                                devices,
                            },
                        }}
                        className="btn btn-primary ml-2"
                        title={batteryDischargeCurveText}
                    >
                        {batteryDischargeCurveText}
                    </Link>
                </div>
            </Col>
        </Row>
    );
}

SearchAndAddContainer.propTypes = {
    currentUser: PropTypes.object.isRequired,
    devices: PropTypes.array.isRequired,
    setFilteredDevices: PropTypes.func.isRequired,
    texts: PropTypes.object.isRequired,
};
