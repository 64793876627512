import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Button, Card, CardBody, CardFooter, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { setLoading } from '../../../store/actions/loadingActions';
import { validateForm } from '../../../helpers/formValidator';
import { importUser } from '../../../services/api/users';
import { fetchAddUserTemplateCsv } from '../../../services/api/files';
import history from '../../../services/history';

export default function ImportUser({ currentUser }) {
    const formRef = useRef(null);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const {
        fields,
        successText,
        addText,
        cancelText,
        requiredFieldText,
        downloadText,
        downloadTemplateText,
        requiredFieldInfoText,
        activeInfoText,
        dateFormatInfoText,
        languageFormatText,
    } = pageConfig[lang][currentUser.permissions];
    const { schema } = schemaConfig[lang][currentUser.permissions];

    async function handleSubmit(data) {
        const { isValid, errors } = await validateForm(data, schema);

        if (isValid) {
            formRef.current.setErrors(errors);

            dispatch(setLoading(true));

            const response = await importUser(data);

            dispatch(setLoading(false));

            if (response) {
                if ('message' in response) {
                    toast.warning(response['message'], {
                        autoClose: 10000,
                    });
                } else {
                    toast.success(successText);
                    history.push(history.location.state?.to || '/users');
                }
            }
        } else {
            formRef.current.setErrors(errors);
        }
    }

    async function handleClick() {
        dispatch(setLoading(true));

        await fetchAddUserTemplateCsv();

        dispatch(setLoading(false));
    }

    return (
        <>
            <Card className="cardShadow">
                <CardBody>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <FormGroup row>
                            <FormGroup className="col-xs-12 col-md-12">
                                <span>{downloadTemplateText}</span>
                            </FormGroup>
                            <FormGroup className="col-xs-12 col-md-12">
                                <ul>
                                    <li
                                        dangerouslySetInnerHTML={{
                                            __html: requiredFieldInfoText,
                                        }}
                                    ></li>
                                    <li
                                        dangerouslySetInnerHTML={{
                                            __html: activeInfoText,
                                        }}
                                    ></li>
                                    <li
                                        dangerouslySetInnerHTML={{
                                            __html: dateFormatInfoText,
                                        }}
                                    ></li>
                                    <li
                                        dangerouslySetInnerHTML={{
                                            __html: languageFormatText,
                                        }}
                                    ></li>
                                </ul>
                            </FormGroup>
                            <FormGroup className="col-xs-12 col-md-12">
                                <Button
                                    type="button"
                                    color="primary"
                                    onClick={() => handleClick()}
                                >
                                    {downloadText}
                                </Button>
                            </FormGroup>
                        </FormGroup>
                        <hr />
                        <FormGroup row>
                            {fields.map((field) => (
                                <FormGroup
                                    key={field.props.name}
                                    className={field.className}
                                >
                                    <field.component {...field.props} />
                                </FormGroup>
                            ))}
                        </FormGroup>
                        <FormGroup row>
                            <div className="col-xs-12 col-md-12 text-left">
                                <Button color="primary mr-2" type="submit">
                                    {addText}
                                </Button>
                                <Link
                                    to={history.location.state?.to || '/users'}
                                    className="btn btn-secondary"
                                >
                                    {cancelText}
                                </Link>
                            </div>
                        </FormGroup>
                    </Form>
                </CardBody>
                <CardFooter>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: requiredFieldText,
                        }}
                    ></p>
                </CardFooter>
            </Card>
        </>
    );
}

ImportUser.propTypes = {
    currentUser: PropTypes.object.isRequired,
};
