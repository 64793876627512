import React, { useState, forwardRef, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { localeDateFormatter } from '../../../../helpers/formatters';
import Card from '../../../../components/card/card';
import Table from '../../../../components/table/table';
import TableHeaders from '../../../../components/table/tableHeaders';
import { CommentsTableRows } from './commentsTableRows';
import { Plot } from '../../../../components/plot';

export const CurrentWeek = forwardRef(
    ({ data, textData, visible, locale, completeWeekDays }, ref) => {
        const { cardTitles, ths, nextIcon, previousIcon } = textData;

        const { weekNumbers, weekReference, charts, commentsTable, napCharts } =
            data;

        const [weekIndex, setWeekIndex] = useState(0);

        const handlePrevNextWeek = (value) => {
            let nextIndex;

            if (value === 'previous') {
                if (weekIndex === 0) {
                    nextIndex = Object.keys(data.charts).length - 1;
                } else {
                    nextIndex = weekIndex - 1;
                }
            } else {
                if (weekIndex === Object.keys(data.charts).length - 1) {
                    nextIndex = 0;
                } else {
                    nextIndex = weekIndex + 1;
                }
            }

            setWeekIndex(nextIndex);
        };

        useEffect(() => {
            setWeekIndex(weekNumbers.length === 0 ? 0 : weekNumbers.length - 1);
        }, [weekNumbers]);

        if (weekNumbers.length === 0 || weekIndex > weekNumbers.length - 1) {
            return <></>;
        }

        return (
            <div className="row currentWeek" ref={ref}>
                <Col xs="12">
                    <Card
                        title={`${cardTitles.chart} ${weekNumbers[weekIndex]}`}
                        isTable={false}
                        textSize="lg"
                        textCenter
                    >
                        <Row>
                            <Col xs="3" className="text-left">
                                <button
                                    className="editDataButton"
                                    type="button"
                                    value="previous"
                                    onClick={(e) =>
                                        handlePrevNextWeek(
                                            e.currentTarget.value
                                        )
                                    }
                                >
                                    <FontAwesomeIcon icon={previousIcon} />
                                </button>
                            </Col>
                            <Col xs="6" className="text-center">
                                <h5>{`${
                                    localeDateFormatter(
                                        weekReference[weekIndex][0],
                                        locale
                                    )
                                        ? localeDateFormatter(
                                              weekReference[weekIndex][0],
                                              locale
                                          )
                                        : '--'
                                } - ${
                                    localeDateFormatter(
                                        weekReference[weekIndex][1],
                                        locale
                                    )
                                        ? localeDateFormatter(
                                              weekReference[weekIndex][1],
                                              locale
                                          )
                                        : '--'
                                }`}</h5>
                            </Col>
                            <Col xs="3" className="text-right">
                                <button
                                    className="editDataButton"
                                    type="button"
                                    value="next"
                                    onClick={(e) =>
                                        handlePrevNextWeek(
                                            e.currentTarget.value
                                        )
                                    }
                                >
                                    <FontAwesomeIcon icon={nextIcon} />
                                </button>
                            </Col>
                            <Col
                                xs="12"
                                className="text-center chartContainer"
                                style={{
                                    height: `${charts[weekIndex].layout.height}px`,
                                }}
                            >
                                {charts[weekIndex] && visible && (
                                    <Plot
                                        key={crypto.randomUUID()}
                                        data={charts[weekIndex].data}
                                        layout={charts[weekIndex].layout}
                                        config={charts[weekIndex].config}
                                        useResizeHandler={true}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col xs="12" md="7">
                    <Card title={cardTitles.table} isTable={true}>
                        <Table>
                            <thead>
                                <tr>
                                    <TableHeaders ths={ths} />
                                </tr>
                            </thead>
                            <tbody>
                                <CommentsTableRows
                                    tds={commentsTable[weekIndex]}
                                    ths={ths}
                                    locale={locale}
                                    completeWeekDays={
                                        completeWeekDays[weekNumbers[weekIndex]]
                                    }
                                />
                            </tbody>
                        </Table>
                    </Card>
                </Col>

                <Col xs="12" md="5">
                    <Card title={cardTitles.napsChart} isTable={false}>
                        <Col
                            xs="12"
                            className="text-center chartContainer"
                            style={{
                                height: `${
                                    napCharts[weekIndex].layout.height / 18
                                }rem`,
                            }}
                        >
                            {napCharts[weekIndex] && visible && (
                                <Plot
                                    key={crypto.randomUUID()}
                                    data={napCharts[weekIndex].data}
                                    layout={napCharts[weekIndex].layout}
                                    config={napCharts[weekIndex].config}
                                    useResizeHandler={true}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                            )}
                        </Col>
                    </Card>
                </Col>
            </div>
        );
    }
);

CurrentWeek.displayName = 'CurrentWeek';

CurrentWeek.propTypes = {
    data: PropTypes.shape({
        charts: PropTypes.arrayOf(
            PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            })
        ),
        napCharts: PropTypes.arrayOf(
            PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            })
        ),
        commentsTable: PropTypes.arrayOf(
            PropTypes.shape({
                weekDays: PropTypes.arrayOf(PropTypes.string).isRequired,
                comments: PropTypes.arrayOf(PropTypes.string).isRequired,
                medicineDescription: PropTypes.arrayOf(PropTypes.string)
                    .isRequired,
            })
        ),
        weekNumbers: PropTypes.arrayOf(PropTypes.number).isRequired,
        weekReference: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
            .isRequired,
    }).isRequired,
    textData: PropTypes.shape({
        cardTitles: PropTypes.shape({
            chart: PropTypes.string.isRequired,
            table: PropTypes.string.isRequired,
            napsChart: PropTypes.string.isRequired,
        }).isRequired,
        ths: PropTypes.arrayOf(PropTypes.string).isRequired,
        nextIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.object])
            .isRequired,
        previousIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.object])
            .isRequired,
    }).isRequired,
    visible: PropTypes.bool.isRequired,
    locale: PropTypes.object.isRequired,
    completeWeekDays: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.string).isRequired
    ),
};
