import Input from '../../../../../../components/inputForm/inputText';

export default {
    pt: {
        customer_admin: null,
        manager: {
            fields: {
                plan: [
                    {
                        component: Input,
                        props: {
                            name: 'name',
                            type: 'text',
                            label: 'Nome',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-4',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'max_patients',
                            type: 'text',
                            label: 'Número máximo de pacientes',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'number_of_devices',
                            type: 'text',
                            label: 'Quantidade de dispositivos',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                subscription: [
                    {
                        component: Input,
                        props: {
                            name: 'fidelity',
                            type: 'text',
                            label: 'Fidelidade',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'payable_with',
                            type: 'text',
                            label: 'Tipo de pagamento',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'price',
                            type: 'text',
                            label: 'Preço',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'pro_rata_price',
                            type: 'text',
                            label: 'Valor cobrado agora',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                card: [
                    {
                        component: Input,
                        props: {
                            name: 'description',
                            type: 'text',
                            label: 'Descrição do cartão',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'display_number',
                            type: 'text',
                            label: 'Número do cartão',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'expiration_date',
                            type: 'text',
                            label: 'Data de validade',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                new_card_description: [
                    {
                        component: Input,
                        props: {
                            name: 'description',
                            type: 'text',
                            label: 'Descrição do cartão <b style="color: darkred">*</b>',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                new_card_info: {
                    number: {
                        component: Input,
                        props: {
                            name: 'number',
                            type: 'text',
                            'data-iugu': 'number',
                            placeholder: 'Número do cartão',
                            className: 'credit_card_number',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    verification_value: {
                        component: Input,
                        props: {
                            name: 'verification_value',
                            type: 'text',
                            'data-iugu': 'verification_value',
                            placeholder: 'CVV',
                            className: 'credit_card_cvv',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    full_name: {
                        component: Input,
                        props: {
                            name: 'full_name',
                            type: 'text',
                            'data-iugu': 'full_name',
                            placeholder: 'Titular do cartão',
                            className: 'credit_card_name',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    expiration: {
                        component: Input,
                        props: {
                            name: 'expiration',
                            type: 'text',
                            'data-iugu': 'expiration',
                            placeholder: 'MM/AA',
                            className: 'credit_card_expiration',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                },
            },
            imgAltTexts: {
                cardTypes: 'Visa, Master, Diners. Amex',
                paymentByIugu: 'Pagamentos por Iugu',
            },
            payableWithText: {
                credit_card: 'Cartão de crédito',
            },
            fidelityText: {
                monthly: 'Mensal',
                biannual: 'Semestral',
                annual: 'Anual',
            },
            planSpanText: 'Plano',
            subscriptionSpanText: 'Assinatura',
            creditCardSpanText: 'Dados do cartão',
            newCreditCardLabelText: 'Cartão <b style="color: darkred">*</b>',
            confirmButtonText: 'Confirmar',
            goBackButtonText: 'Voltar',
            requiredFieldPText:
                '<b style="color: darkred">*</b> Campos obrigatórios!',
            editSuccessText:
                'Seu pagamento está sendo processado. Assim que for confirmado, sua assinatura será atualizada!',
            paymentFailed: 'Não foi possível processar o pagamento!',
            iuguErrorsText: {
                numberErrorText: 'O número do cartão é inválido.',
                cvvErrorText: 'O código de segurança do cartão está incorreto.',
                fullNameErrorText: 'O nome do titular do cartão é inválido.',
                expirationErrorText: 'A data de validade é inválida.',
                defaultErrorText: 'Erro no cartão.',
            },
            stripeErrors: {
                expired_card: 'O cartão expirou.',
                incorrect_cvc:
                    'O código de segurança do cartão está incorreto.',
                processing_error:
                    'Ocorreu um erro ao processar o cartão. Tente novamente em instantes.',
                incorrect_number: 'O número do cartão é inválido.',
                card_declined: 'O cartão foi recusado.',
                card_decline_codes: {
                    approve_with_id: 'Não é possível autorizar o pagamento.',
                    call_issuer:
                        'O cartão foi recusado por um motivo desconhecido.',
                    card_not_supported:
                        'O cartão não aceita este tipo de compra.',
                    card_velocity_exceeded:
                        'O cliente excedeu o saldo ou o limite de crédito disponível no cartão.',
                    currency_not_supported:
                        'O cartão não aceita a moeda especificada.',
                    do_not_honor:
                        'O cartão foi recusado por um motivo desconhecido.',
                    duplicate_transaction:
                        'Uma transação com valor e dados de cartão de crédito idênticos foi executada recentemente.',
                    expired_card: 'O cartão expirou.',
                    insufficient_funds:
                        'O cartão não tem saldo suficiente para concluir a compra.',
                    invalid_amount:
                        'O valor do pagamento é inválido ou excede o valor permitido.',
                    invalid_cvc:
                        'O código de segurança do cartão está incorreto.',
                    invalid_expiry_month: 'O mês de validade é inválido.',
                    invalid_expiry_year: 'O ano de validade é inválido.',
                    invalid_number: 'O número do cartão é inválido.',
                    not_permitted: 'O pagamento não é permitido.',
                    processing_error:
                        'Ocorreu um erro ao processar o cartão. Tente novamente em instantes.',
                },
            },
            nameText: 'Nome',
            maxPatientsText: 'Número máximo de pacientes',
            numberOfDevicesText: 'Quantidade de dispositivos',
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            fields: {
                plan: [
                    {
                        component: Input,
                        props: {
                            name: 'name',
                            type: 'text',
                            label: 'Name',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-4',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'max_patients',
                            type: 'text',
                            label: 'Maximum number of patients',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'number_of_devices',
                            type: 'text',
                            label: 'Amount of devices',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                subscription: [
                    {
                        component: Input,
                        props: {
                            name: 'fidelity',
                            type: 'text',
                            label: 'Fidelity',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'payable_with',
                            type: 'text',
                            label: 'Payment type',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'price',
                            type: 'text',
                            label: 'Price',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'pro_rata_price',
                            type: 'text',
                            label: 'Amount charged now',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                card: [
                    {
                        component: Input,
                        props: {
                            name: 'description',
                            type: 'text',
                            label: 'Card description',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'display_number',
                            type: 'text',
                            label: 'Card number',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'expiration_date',
                            type: 'text',
                            label: 'Expiration date',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                new_card_description: [
                    {
                        component: Input,
                        props: {
                            name: 'description',
                            type: 'text',
                            label: 'Card description <b style="color: darkred">*</b>',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                new_card_info: {
                    number: {
                        component: Input,
                        props: {
                            name: 'number',
                            type: 'text',
                            'data-iugu': 'number',
                            placeholder: 'Card number',
                            className: 'credit_card_number',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    verification_value: {
                        component: Input,
                        props: {
                            name: 'verification_value',
                            type: 'text',
                            'data-iugu': 'verification_value',
                            placeholder: 'CVV',
                            className: 'credit_card_cvv',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    full_name: {
                        component: Input,
                        props: {
                            name: 'full_name',
                            type: 'text',
                            'data-iugu': 'full_name',
                            placeholder: 'Cardholder',
                            className: 'credit_card_name',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    expiration: {
                        component: Input,
                        props: {
                            name: 'expiration',
                            type: 'text',
                            'data-iugu': 'expiration',
                            placeholder: 'MM/YY',
                            className: 'credit_card_expiration',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                },
            },
            imgAltTexts: {
                cardTypes: 'Visa, Master, Diners. Amex',
                paymentByIugu: 'Payments by Iugu',
            },
            payableWithText: {
                credit_card: 'Credit card',
            },
            fidelityText: {
                monthly: 'Monthly',
                biannual: 'Semestral',
                annual: 'Annual',
            },
            planSpanText: 'Plan',
            subscriptionSpanText: 'Subscription',
            creditCardSpanText: 'Card data',
            newCreditCardLabelText: 'Card <b style="color: darkred">*</b>',
            confirmButtonText: 'Confirm',
            goBackButtonText: 'Return',
            requiredFieldPText:
                '<b style="color: darkred">*</b> Required fields!',
            editSuccessText:
                'Your payment is being processed. Once confirmed, your subscription will be updated!',
            paymentFailed: 'It was not possible to process your payment!',
            iuguErrorsText: {
                numberErrorText: 'Card number is invalid!',
                cvvErrorText: 'Security code is incorrect!',
                fullNameErrorText: 'Cardholder is incorrect!',
                expirationErrorText: 'Invalid expiry date!',
                defaultErrorText: 'Card error!',
            },
            stripeErrors: {
                expired_card: 'The card has expired.',
                incorrect_cvc: 'The card verification code is incorrect.',
                processing_error:
                    'An error occurred while processing the card. Please try again in a moment.',
                incorrect_number: 'The card number is invalid.',
                card_declined: 'The card has been declined.',
                card_decline_codes: {
                    approve_with_id: 'Cannot approve payment.',
                    call_issuer: 'The card was declined for an unknown reason.',
                    card_not_supported:
                        'The card does not accept this type of purchase.',
                    card_velocity_exceeded:
                        'The customer has exceeded the balance or credit limit available on the card.',
                    currency_not_supported:
                        'The card does not accept the specified currency.',
                    do_not_honor:
                        'The card was declined for an unknown reason.',
                    duplicate_transaction:
                        'A transaction with identical amount and credit card data was performed recently.',
                    expired_card: 'The card has expired.',
                    insufficient_funds:
                        'Card does not have sufficient balance to complete purchase.',
                    invalid_amount:
                        'The payment amount is invalid or exceeds the allowed amount.',
                    invalid_cvc: 'The card verification code is incorrect.',
                    invalid_expiry_month: 'The expiration month is invalid.',
                    invalid_expiry_year: 'The expiration year is invalid.',
                    invalid_number: 'The card number is invalid.',
                    not_permitted: 'Payment is not allowed.',
                    processing_error:
                        'An error occurred while processing the card. Please try again in a moment.',
                },
            },
            nameText: 'Name',
            maxPatientsText: 'Maximum number of patients',
            numberOfDevicesText: 'Amount of devices',
        },
        condor_admin: null,
    },
};
