import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Nav, NavItem, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import '../../styles/components/layout/sidebar.css';

export function Sidebar({ pageData, toggled }) {
    const lang = useSelector((state) => state.lang.lang);

    const { sidebarItems, diariesText, customText } = pageData;

    return (
        <aside className="sidebar">
            <div className="menu">
                <Nav>
                    {sidebarItems.map((item) => (
                        <NavItem key={item.label}>
                            <NavLink
                                to={item.link}
                                title={item.label}
                                className="nav-link"
                                activeClassName="selected"
                            >
                                <div className={`${toggled ? 'toggled' : ''}`}>
                                    <item.icon />
                                    {item.link === '/custom-diaries' ? (
                                        <span>
                                            {lang === 'pt'
                                                ? diariesText
                                                : customText}{' '}
                                            <sup>
                                                <small>
                                                    <Badge
                                                        color="warning"
                                                        tag="p"
                                                        pill
                                                    >
                                                        BETA
                                                    </Badge>
                                                </small>
                                            </sup>{' '}
                                            {lang === 'pt'
                                                ? customText
                                                : diariesText}
                                        </span>
                                    ) : (
                                        <span>{item.label}</span>
                                    )}
                                </div>
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </div>
        </aside>
    );
}

Sidebar.propTypes = {
    pageData: PropTypes.object.isRequired,
    toggled: PropTypes.bool.isRequired,
};
