import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'serial_no',
                        type: 'number',
                        label: 'Número de série <b style="color: darkred">*</b>',
                        placeholder:
                            'Digite o número de série do dispositivo...',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'brand',
                        type: 'text',
                        label: 'Marca <b style="color: darkred">*</b>',
                        placeholder: 'Digite a marca do dispositivo...',
                        readOnly: true,
                        defaultValue: 'Condor Instruments',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Select,
                    props: {
                        name: 'model',
                        label: 'Modelo <b style="color: darkred">*</b>',
                        options: [{ value: 'ActLumus', label: 'ActLumus' }],
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Select,
                    props: {
                        name: 'company_id',
                        label: 'Empresa',
                        options: [],
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'purchased_at',
                        type: 'date',
                        label: 'Data de compra',
                        placeholder:
                            'Digite a data de compra do dispositivo...',
                    },
                    className: 'col-xs-12 col-md-5',
                },
            ],
            deviceText: 'Dispositivo',
            companyText: 'Empresa',
            successText: 'Dispositivo adicionado com sucesso!',
            addText: 'Adicionar',
            cancelText: 'Cancelar',
            requiredFieldText:
                '<b style="color: darkred">*</b> Campos obrigatórios!',
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'serial_no',
                        type: 'number',
                        label: 'Serial number <b style="color: darkred">*</b>',
                        placeholder: `Enter the device's serial number...`,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'brand',
                        type: 'text',
                        label: 'Brand <b style="color: darkred">*</b>',
                        placeholder: 'Enter the device brand..',
                        readOnly: true,
                        defaultValue: 'Condor Instruments',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Select,
                    props: {
                        name: 'model',
                        label: 'Model <b style="color: darkred">*</b>',
                        options: [{ value: 'ActLumus', label: 'ActLumus' }],
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Select,
                    props: {
                        name: 'company_id',
                        label: 'Company',
                        options: [],
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'purchased_at',
                        type: 'date',
                        label: 'Purchase date',
                        placeholder: 'Enter the device purchase date...',
                    },
                    className: 'col-xs-12 col-md-5',
                },
            ],
            deviceText: 'Device',
            companyText: 'Company',
            successText: 'Device successfully added!',
            addText: 'Add',
            cancelText: 'Cancel',
            requiredFieldText:
                '<b style="color: darkred">*</b> Required fields!',
        },
    },
};
