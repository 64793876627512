import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Spinner } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPrint,
    faFileCsv,
    faCalendarAlt,
    faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import styles from './actionsContainer.module.css';

import { handleDate } from '../../../../helpers/dateFormat';

export function ActionsContainer({
    texts,
    weekNumber,
    loadingCsvFile,
    trialStart,
    highlightDates,
    handleExport,
    handlePrint,
    handleUpdateReport,
    actigraphyData,
}) {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [isOpen, setIsOpen] = useState(false);
    const [oldDateRange, setOldDateRange] = useState([startDate, endDate]);
    const [oldStartDate, oldEndDate] = oldDateRange;
    const [disabled, setDisabled] = useState(true);
    const [lastClickedButton, setLastClickedButton] = useState(null);

    const { dateLocale } = useSelector((state) => state.locale);

    function handleChange(update) {
        setDateRange(update);
    }

    function handleClick() {
        setIsOpen(!isOpen);
    }

    function handleApplyClick() {
        setIsOpen(false);

        let minOldDate = oldDateRange[0]
            ? format(oldDateRange[0], "yyyy-MM-dd' 'HH:mm:ss")
            : null;
        let maxOldDate = oldDateRange[1]
            ? format(oldDateRange[1], "yyyy-MM-dd' 'HH:mm:ss")
            : null;
        let minDate = dateRange[0]
            ? format(dateRange[0], "yyyy-MM-dd' 'HH:mm:ss")
            : null;
        let maxDate = dateRange[1]
            ? format(dateRange[1], "yyyy-MM-dd' 'HH:mm:ss")
            : null;

        if (
            (minOldDate !== minDate && minDate !== null) ||
            (maxOldDate !== maxDate && maxDate !== null)
        ) {
            setOldDateRange([startDate, endDate]);
            handleUpdateReport(startDate, endDate);
        }
    }

    function handleCancelClick() {
        handleChange([oldStartDate, oldEndDate]);
        setIsOpen(false);
    }

    function handleCsvClick(name) {
        let minDate =
            name === 'actigraphyData'
                ? null
                : format(dateRange[0], "yyyy-MM-dd' 'HH:mm:ss");
        let maxDate =
            name === 'actigraphyData'
                ? null
                : format(dateRange[1], "yyyy-MM-dd' 'HH:mm:ss");

        setLastClickedButton(name);

        handleExport(name, minDate, maxDate);
    }

    useEffect(() => {
        handleChange([
            trialStart
                ? handleDate(trialStart)
                    ? handleDate(trialStart)
                    : null
                : null,
            trialStart ? new Date() : null,
        ]);
        setOldDateRange([
            trialStart
                ? handleDate(trialStart)
                    ? handleDate(trialStart)
                    : null
                : null,
            trialStart ? new Date() : null,
        ]);
    }, []);

    useEffect(() => {
        if (dateRange[0] === null || dateRange[1] === null) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [dateRange]);

    return (
        <>
            <div className={styles.marginRight}>
                <Button
                    className="ml-0"
                    color="info"
                    onClick={handleClick}
                    name="periodSelection"
                    disabled={trialStart === null || loadingCsvFile}
                >
                    <FontAwesomeIcon icon={faCalendarAlt} />{' '}
                    {oldStartDate ? oldStartDate.toLocaleDateString() : ''}
                    {' - '}
                    {oldEndDate ? oldEndDate.toLocaleDateString() : ''}
                </Button>
                {isOpen && (
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        todayButton={texts.todayText}
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={handleChange}
                        showMonthDropdown
                        showYearDropdown
                        minDate={
                            trialStart
                                ? handleDate(trialStart)
                                    ? handleDate(trialStart)
                                    : null
                                : null
                        }
                        maxDate={trialStart ? new Date() : null}
                        dropdownMode="select"
                        highlightDates={highlightDates}
                        shouldCloseOnSelect={false}
                        showPopperArrow={false}
                        locale={dateLocale}
                        open={open}
                        disabledKeyboardNavigation
                        popperPlacement="bottom-end"
                        popperModifiers={[
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 10],
                                },
                            },
                            {
                                name: 'preventOverflow',
                                options: {
                                    rootBoundary: 'viewport',
                                    tether: false,
                                    altAxis: false,
                                },
                            },
                        ]}
                    >
                        <div className={styles.datePickerCustomArea}>
                            <div className="text-center">
                                {texts.selectedIntervalText}: <br />
                                {startDate
                                    ? startDate.toLocaleDateString()
                                    : ''}
                                {' - '}
                                {endDate ? endDate.toLocaleDateString() : ''}
                            </div>
                            <div className="text-center mt-1 mb-1">
                                <Button
                                    color="primary"
                                    onClick={handleApplyClick}
                                    disabled={disabled}
                                >
                                    {texts.applyText}
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={handleCancelClick}
                                >
                                    {texts.cancelText}
                                </Button>
                            </div>
                        </div>
                    </DatePicker>
                )}
            </div>
            <Button
                type="button"
                color="info"
                onClick={handlePrint}
                name="print"
                disabled={weekNumber === null || loadingCsvFile}
            >
                <FontAwesomeIcon icon={faPrint} /> {texts.print}
            </Button>
            <Button
                type="button"
                color="info"
                onClick={(e) => handleCsvClick(e.target.name)}
                name="rawData"
                disabled={weekNumber === null || loadingCsvFile}
            >
                <FontAwesomeIcon icon={faFileCsv} /> {texts.rawData}{' '}
                {loadingCsvFile && lastClickedButton === 'rawData' && (
                    <Spinner size="sm" />
                )}
            </Button>
            <Button
                type="button"
                color="info"
                onClick={(e) => handleCsvClick(e.target.name)}
                name="tablesData"
                disabled={weekNumber === null || loadingCsvFile}
            >
                <FontAwesomeIcon icon={faFileCsv} /> {texts.tableData}{' '}
                {loadingCsvFile && lastClickedButton === 'tablesData' && (
                    <Spinner size="sm" />
                )}
            </Button>
            <Button
                type="button"
                color="info"
                onClick={(e) => handleCsvClick(e.target.name)}
                name="decimalsTablesData"
                disabled={weekNumber === null || loadingCsvFile}
            >
                <FontAwesomeIcon icon={faFileCsv} /> {texts.decimalsTableData}{' '}
                {loadingCsvFile &&
                    lastClickedButton === 'decimalsTablesData' && (
                        <Spinner size="sm" />
                    )}
            </Button>
            <Button
                type="button"
                color="info"
                onClick={(e) => handleCsvClick(e.target.name)}
                name="customQuestionsData"
                disabled={weekNumber === null || loadingCsvFile}
            >
                <FontAwesomeIcon icon={faFileCsv} /> {texts.customQuestionsData}{' '}
                {loadingCsvFile &&
                    lastClickedButton === 'customQuestionsData' && (
                        <Spinner size="sm" />
                    )}
            </Button>
            <Button
                type="button"
                color="info"
                onClick={(e) => handleCsvClick(e.target.name)}
                name="actigraphyData"
                disabled={actigraphyData === null || loadingCsvFile}
            >
                <FontAwesomeIcon icon={faFileAlt} /> {texts.actigraphyData}{' '}
                {loadingCsvFile && lastClickedButton === 'actigraphyData' && (
                    <Spinner size="sm" />
                )}
            </Button>
        </>
    );
}

ActionsContainer.propTypes = {
    texts: PropTypes.object.isRequired,
    weekNumber: PropTypes.number,
    loadingCsvFile: PropTypes.bool.isRequired,
    trialStart: PropTypes.string,
    highlightDates: PropTypes.array,
    handleExport: PropTypes.func.isRequired,
    handlePrint: PropTypes.func.isRequired,
    handleUpdateReport: PropTypes.func.isRequired,
    actigraphyData: PropTypes.object,
};
