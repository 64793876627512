import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';

export default {
    pt: {
        customer_admin: null,
        manager: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome <b style="color: darkred">*</b>',
                        placeholder: 'Digite o nome do gestor...',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'ID Secundário',
                        placeholder: 'Digite o id secundário do gestor...',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail <b style="color: darkred">*</b>',
                        placeholder: 'Digite o e-Mail do gestor...',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Ativo <b style="color: darkred">*</b>',
                        options: [
                            { value: false, label: 'Não' },
                            { value: true, label: 'Sim' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'share',
                        label: 'Compartilhar pacientes? <b style="color: darkred">*</b>',
                        options: [
                            { value: false, label: 'Não' },
                            { value: true, label: 'Sim' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'lang',
                        label: 'Idioma <b style="color: darkred">*</b>',
                        options: [
                            { value: 'pt', label: 'Português' },
                            { value: 'en', label: 'Inglês' },
                            { value: 'ge', label: 'Alemão' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            addSuccessText: 'Gestor adicionado com sucesso!',
            addButtonText: 'Adicionar',
            cancelButtonText: 'Cancelar',
            requiredFieldPText:
                '<b style="color: darkred">*</b> Campos obrigatórios!',
            yesText: 'Sim',
            noText: 'Não',
            languageOptions: {
                pt: { label: 'Português', value: 'pt' },
                en: { label: 'Inglês', value: 'en' },
                ge: { label: 'Alemão', value: 'ge' },
            },
            shareText: 'Compartilhar pacientes?',
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name <b style="color: darkred">*</b>',
                        placeholder: `Enter the manager's name...`,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'Secondary ID',
                        placeholder: `Enter the secondary manager id...`,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail <b style="color: darkred">*</b>',
                        placeholder: `Enter the manager's e-Mail...`,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Active <b style="color: darkred">*</b>',
                        options: [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'share',
                        label: 'Share patients? <b style="color: darkred">*</b>',
                        options: [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'lang',
                        label: 'Language <b style="color: darkred">*</b>',
                        options: [
                            { value: 'pt', label: 'Portuguese' },
                            { value: 'en', label: 'English' },
                            { value: 'ge', label: 'German' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            addSuccessText: 'Manager successfully added!',
            addButtonText: 'Add',
            cancelButtonText: 'Cancel',
            requiredFieldPText:
                '<b style="color: darkred">*</b> Required fields!',
            yesText: 'Yes',
            noText: 'No',
            languageOptions: {
                pt: { label: 'Portuguese', value: 'pt' },
                en: { label: 'English', value: 'en' },
                ge: { label: 'German', value: 'ge' },
            },
            shareText: 'Share patients?',
        },
        condor_admin: null,
    },
};
