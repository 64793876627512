import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardTitle,
    CardSubtitle,
    CardBody,
    Button,
} from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import history from '../../../services/history';
import { fetchDeviceBatteryDischarge } from '../../../services/api/devices';
import Plot from '../../../components/plot';
import useWindowSize from '../../../hooks/useWindowSize';
import { getMinValue } from '../../../helpers/getMinValue';
import { getMaxValue } from '../../../helpers/getMaxValue';
import { setLoading } from '../../../store/actions/loadingActions';

BatteryDischarge.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function BatteryDischarge({ currentUser }) {
    const { width } = useWindowSize();

    const [devices, setDevices] = useState([]);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [batteryDischargeData, setBatteryDischargeData] = useState([]);
    const [plotData, setPlotData] = useState([]);

    const lang = useSelector((state) => state.lang.lang);
    const { currencyLocale } = useSelector((state) => state.locale);

    const dispatch = useDispatch();

    const pageData = pageConfig[lang][currentUser.permissions];

    function handleChange(newValue, action) {
        setSelectedDevices(newValue.map((value) => value.value));
    }

    function createCharts() {
        const tempPlotData = [];

        for (let i = 0; i < batteryDischargeData.length; i++) {
            let deviceId = batteryDischargeData[i].device_id;
            let serialNumber = batteryDischargeData[i].serial_no;
            let battery = batteryDischargeData[i].battery;
            let connection = batteryDischargeData[i].connection;
            let disconnection = batteryDischargeData[i].disconnection;
            let referenceCurve = batteryDischargeData[i].reference_curve;

            let data = [
                {
                    x: battery.log_time,
                    y: battery.voltage,
                    type: 'scatter',
                    mode: 'lines',
                    name: pageData.batteryText,
                    line: { shape: 'linear', color: '#1A33F4', width: 2 },
                    hovertemplate:
                        currencyLocale === 'pt-BR'
                            ? '%{y:.2f}V - %{x|%d/%m/%Y %H:%M:%S}<extra></extra>'
                            : '%{y:.2f}V - %{x|%m/%d/%Y %H:%M:%S}<extra></extra>',
                },
                {
                    x: connection.log_time,
                    y: connection.voltage,
                    type: 'scatter',
                    mode: 'markers',
                    name: pageData.connectionText,
                    marker: {
                        color: '#5fbf3f',
                    },
                    hovertemplate:
                        currencyLocale === 'pt-BR'
                            ? '%{y:.2f}V - %{x|%d/%m/%Y %H:%M:%S}<extra></extra>'
                            : '%{y:.2f}V - %{x|%m/%d/%Y %H:%M:%S}<extra></extra>',
                },
                {
                    x: disconnection.log_time,
                    y: disconnection.voltage,
                    type: 'scatter',
                    mode: 'markers',
                    name: pageData.disconnectionText,
                    marker: {
                        color: '#bf3f3f',
                    },
                    hovertemplate:
                        currencyLocale === 'pt-BR'
                            ? '%{y:.2f}V - %{x|%d/%m/%Y %H:%M:%S}<extra></extra>'
                            : '%{y:.2f}V - %{x|%m/%d/%Y %H:%M:%S}<extra></extra>',
                },
                {
                    x: referenceCurve.log_time,
                    y: referenceCurve.upper_voltage,
                    type: 'scatter',
                    mode: 'lines',
                    name: pageData.referenceCurveText,
                    showlegend: false,
                    marker: { color: '#444' },
                    line: { width: 0 },
                    hovertemplate:
                        currencyLocale === 'pt-BR'
                            ? '%{y:.2f}V - %{x|%d/%m/%Y %H:%M:%S}<extra></extra>'
                            : '%{y:.2f}V - %{x|%m/%d/%Y %H:%M:%S}<extra></extra>',
                },
                {
                    x: referenceCurve.log_time,
                    y: referenceCurve.lower_voltage,
                    type: 'scatter',
                    mode: 'lines',
                    name: pageData.referenceCurveText,
                    showlegend: true,
                    marker: { color: '#444' },
                    line: { width: 0 },
                    fillcolor: 'rgba(68, 68, 68, 0.3)',
                    fill: 'tonexty',
                    hovertemplate:
                        currencyLocale === 'pt-BR'
                            ? '%{y:.2f}V - %{x|%d/%m/%Y %H:%M:%S}<extra></extra>'
                            : '%{y:.2f}V - %{x|%m/%d/%Y %H:%M:%S}<extra></extra>',
                },
            ];

            const minRange = battery?.log_time
                ? getMinValue([
                      getMinValue(battery?.log_time),
                      getMinValue(connection.log_time),
                      getMinValue(disconnection.log_time),
                  ])
                : null;

            const maxRange = battery?.log_time
                ? getMaxValue([
                      getMaxValue(battery?.log_time),
                      getMaxValue(connection.log_time),
                      getMaxValue(disconnection.log_time),
                  ])
                : null;

            let layout = {
                height: 250,
                width: width - (20 * width) / 100,
                margin: {
                    t: 0,
                    b: 50,
                    l: 100,
                    r: 10,
                },
                font: { family: 'Raleway, sans-serif' },
                showlegend: true,
                xaxis: {
                    type: 'date',
                    autorange: true,
                    range: [minRange, maxRange],
                    fixedrange: true,
                    rangeselector: {
                        buttons: [
                            {
                                count: 7,
                                label: pageData.oneWeekText,
                                step: 'day',
                                stepmode: 'backward',
                            },
                            {
                                count: 1,
                                label: pageData.oneMonthText,
                                step: 'month',
                                stepmode: 'backward',
                            },
                            { step: 'all' },
                        ],
                    },
                    rangeslider: { range: [minRange, maxRange] },
                    visible: true,
                    showgrid: false,
                },
                yaxis: {
                    tickmode: 'auto',
                    type: 'linear',
                    autorange: true,
                    fixedrange: true,
                    visible: true,
                    showgrid: true,
                    gridcolor: '#cccccc',
                    gridwidth: 1,
                    zerolinecolor: '#cccccc',
                    title: {
                        text: `
                            <br />
                                ${pageData.deviceText}
                            <br />
                                ${serialNumber}
                            <br />
                                ${pageData.voltageText}
                            <br />
                        `,
                        font: {
                            size: 16,
                        },
                    },
                },
            };

            let config = {
                displayModeBar: false,
                staticPlot: false,
                responsive: true,
                edits: {
                    axisTitleText: false,
                    shapePosition: false,
                    titleText: false,
                },
                scrollZoom: false,
                showAxisDragHandles: false,
            };

            tempPlotData.push({
                deviceId,
                data,
                layout,
                config,
            });
        }

        setPlotData(tempPlotData);
    }

    async function getBatteryDischarge() {
        if (selectedDevices.length > 0) {
            dispatch(setLoading(true));

            const data = await fetchDeviceBatteryDischarge(selectedDevices);

            setBatteryDischargeData(data.data);

            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        if (history.location.state?.devices) {
            setDevices(history.location.state.devices);
        } else {
            history.push('/devices');
        }
    }, []);

    useEffect(() => {
        if (batteryDischargeData.length > 0) {
            createCharts();
        }
    }, [batteryDischargeData, width, lang]);

    return (
        <>
            <Row>
                <Col xs="12">
                    <Card className="cardShadow">
                        <CardBody>
                            <h5>{pageData.chooseDevicesText}</h5>

                            <Col xs="3" className="px-0">
                                <Select
                                    isMulti
                                    name="devices"
                                    options={devices.map((device) => ({
                                        value: device.id,
                                        label: device.serial_no,
                                    }))}
                                    onChange={handleChange}
                                />
                            </Col>

                            <Button
                                type="button"
                                color="primary"
                                className="mt-2"
                                onClick={() => getBatteryDischarge()}
                                disabled={selectedDevices.length === 0}
                            >
                                Visualizar
                            </Button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xs="12" className="mt-2">
                    <Card className="cardShadow">
                        <CardHeader>
                            <CardTitle>
                                <h5>{pageData.batteryDischargeChartText}</h5>
                            </CardTitle>
                            <CardSubtitle>
                                {pageData.batteryVoltageChartText}
                            </CardSubtitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                {plotData.map((plot) => (
                                    <Col
                                        key={plot.deviceId}
                                        xs="12"
                                        className="pb-3 mt-3 d-flex justify-content-center"
                                    >
                                        <Plot
                                            data={plot.data}
                                            layout={plot.layout}
                                            config={plot.config}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
