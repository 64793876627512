import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { BsSmartwatch } from 'react-icons/bs';
import { FaUsers, FaInfinity } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { ShepherdTourContext } from 'react-shepherd';
import PropTypes from 'prop-types';

import 'shepherd.js/dist/css/shepherd.css';
import '../../../styles/pages/dashboard/components/table.css';

import CardWrapper from '../../../components/card/card';
import { InfoCard } from '../../../components/infoCard';
import Table from '../../../components/table/table';
import TableTds from './tableTds';
import { handleDate } from '../../../helpers/dateFormat';

CustomerDashboard.propTypes = {
    dashboardData: PropTypes.shape({
        last_ten_days: PropTypes.shape({
            date: PropTypes.arrayOf(PropTypes.string),
            timestamp: PropTypes.arrayOf(PropTypes.number),
        }),
        subordinates: PropTypes.arrayOf(PropTypes.object),
        active_company_devices: PropTypes.number,
        active_company_patients: PropTypes.number,
        subscription: PropTypes.object,
    }),
    pageData: PropTypes.shape({
        noPatientsText: PropTypes.string.isRequired,
        activePatientsCardTitle: PropTypes.string.isRequired,
        activePatientsToBeAddedCardTitle: PropTypes.string.isRequired,
        activeDevicesText: PropTypes.string.isRequired,
        remainingDevicesQuotaText: PropTypes.string.isRequired,
        tableTitle: PropTypes.string.isRequired,
        ths: PropTypes.array.isRequired,
        tds: PropTypes.array.isRequired,
    }),
    currentUser: PropTypes.object.isRequired,
};

export function CustomerDashboard({ dashboardData, pageData, currentUser }) {
    const tour = useContext(ShepherdTourContext);

    const { dateLocale } = useSelector((state) => state.locale);

    const [subordinates, setSubordinates] = useState([]);

    const sleepDiarySubscription = dashboardData.subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'sleep_diary'
    );

    const lumusSubscription = dashboardData.subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'lumus'
    );

    useEffect(() => {
        if (dashboardData) {
            if (dashboardData.subordinates.length === 0) {
                toast.info(pageData.noPatientsText);
            }

            if (currentUser.permissions === 'manager') {
                const showWelcomeTour = JSON.parse(
                    localStorage.getItem('showWelcomeTour')
                );
                const showFirstPatientTour = JSON.parse(
                    localStorage.getItem('showFirstPatientTour')
                );
                const showNotFilledYetTour = JSON.parse(
                    localStorage.getItem('showNotFilledYetTour')
                );

                if (
                    showWelcomeTour ||
                    showFirstPatientTour ||
                    showNotFilledYetTour
                ) {
                    tour.start();
                }
            }

            const tempSubordinates = dashboardData.subordinates.map(
                (subordinate) => {
                    if (subordinate.trials_configurations.trialStart !== null) {
                        let trialStart = handleDate(
                            subordinate.trials_configurations.trialStart
                        );

                        if (trialStart) {
                            subordinate.trials_configurations.timestampTrialStart =
                                trialStart.getTime() / 1000;
                        }
                    }

                    return subordinate;
                }
            );

            setSubordinates(tempSubordinates);
        }
    }, []);

    if (!dashboardData) {
        return null;
    }

    return (
        <>
            <Row>
                <Col xs="12" md="3" lg="3" xl="3">
                    <InfoCard
                        value={
                            sleepDiarySubscription
                                ? dashboardData.active_company_patients
                                : 0
                        }
                        title={pageData.activePatientsCardTitle}
                        icon={FaUsers}
                        className="cardDashboard blueBackground"
                    />
                </Col>
                <Col xs="12" md="3" lg="3" xl="3">
                    <InfoCard
                        value={
                            sleepDiarySubscription
                                ? sleepDiarySubscription?.plan?.name !==
                                  'Unlimited'
                                    ? sleepDiarySubscription?.plan
                                          .max_patients -
                                      dashboardData.active_company_patients
                                    : FaInfinity
                                : 0
                        }
                        title={pageData.activePatientsToBeAddedCardTitle}
                        icon={FaUsers}
                        className="cardDashboard purpleBackground"
                    />
                </Col>
                <Col xs="12" md="3" lg="3" xl="3">
                    <InfoCard
                        value={
                            lumusSubscription
                                ? dashboardData.active_company_devices
                                : 0
                        }
                        title={pageData.activeDevicesText}
                        icon={BsSmartwatch}
                        className="cardDashboard greenBackground"
                    />
                </Col>
                <Col xs="12" md="3" lg="3" xl="3">
                    <InfoCard
                        value={
                            lumusSubscription
                                ? lumusSubscription?.plan?.name !==
                                  'Lumus Unlimited'
                                    ? lumusSubscription?.max_devices -
                                      dashboardData.active_company_devices
                                    : FaInfinity
                                : 0
                        }
                        title={pageData.remainingDevicesQuotaText}
                        icon={BsSmartwatch}
                        className="cardDashboard graphiteBackground"
                    />
                </Col>
            </Row>

            <Row>
                <Col xs="12" md="12" lg="12" xl="12">
                    <CardWrapper
                        title={pageData.tableTitle}
                        isTable={true}
                        className="dashboardTableRow"
                    >
                        <Table className="dashboardTable table-striped">
                            <thead>
                                <tr>
                                    {pageData.ths.map((th) => {
                                        return <th key={th}>{th}</th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {subordinates.map((sub) => (
                                    <tr key={sub.id}>
                                        <TableTds
                                            tds={pageData.tds}
                                            sub={sub}
                                            lastNDays={
                                                dashboardData.last_ten_days
                                            }
                                            locale={dateLocale}
                                        />
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </CardWrapper>
                </Col>
            </Row>
        </>
    );
}
