import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { setLoading } from '../../../store/actions/loadingActions';
import history from '../../../services/history';
import { editUser } from '../../../services/api/users';
import { validateForm } from '../../../helpers/formValidator';
import { MyForm as Form } from './components/form';

export default function EditManager({ currentUser }) {
    const formRef = useRef(null);

    const [manager, setManager] = useState({});

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const { fields, successText, saveText, cancelText, yesText, noText } =
        pageConfig[lang][currentUser.permissions];
    const { schema } = schemaConfig[lang][currentUser.permissions];

    async function handleSubmit(data) {
        const { isValid, errors } = await validateForm(data, schema);

        if (isValid) {
            formRef.current.setErrors(errors);

            dispatch(setLoading(true));

            const response = await editUser(manager.id, data);

            dispatch(setLoading(false));

            if (response) {
                toast.success(successText);
                history.push('/managers');
            }
        } else {
            formRef.current.setErrors(errors);
        }
    }

    useEffect(() => {
        if (history.location.state?.entity) {
            setManager(history.location.state.entity);
        } else {
            history.push('/managers');
        }
    }, []);

    if (Object.entries(manager).length === 0) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form
                    formRef={formRef}
                    manager={manager}
                    fields={fields}
                    texts={{
                        saveText,
                        cancelText,
                        yesText,
                        noText,
                    }}
                    handleSubmit={handleSubmit}
                />
            </CardBody>
        </Card>
    );
}

EditManager.propTypes = {
    currentUser: PropTypes.object.isRequired,
};
