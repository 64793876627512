import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { setLoading } from '../../../store/actions/loadingActions';
import { validateForm } from '../../../helpers/formValidator';
import history from '../../../services/history';
import { deleteUser } from '../../../services/api/users';
import { MyForm as Form } from './components/form';

export default function DeleteManager({ currentUser }) {
    const formRef = useRef(null);

    const [manager, setManager] = useState({});

    const dispatch = useDispatch();
    const lang = useSelector((state) => state.lang.lang);

    const {
        fields,
        deleteSuccessText,
        deleteButtonText,
        cancelButtonText,
        activeText,
        inactiveText,
    } = pageConfig[lang][currentUser.permissions];
    const { schema } = schemaConfig[lang][currentUser.permissions];

    async function handleSubmit(data) {
        const { isValid, errors } = await validateForm(data, schema);

        if (isValid) {
            formRef.current.setErrors(errors);

            dispatch(setLoading(true));

            const response = await deleteUser(manager.id);

            dispatch(setLoading(false));

            if (response) {
                toast.success(deleteSuccessText);
                history.push('/managers');
            }
        } else {
            formRef.current.setErrors(errors);
        }
    }

    useEffect(() => {
        if (history.location.state?.entity) {
            setManager(history.location.state.entity);
        } else {
            history.push('/managers');
        }
    }, []);

    if (Object.entries(manager).length === 0) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form
                    formRef={formRef}
                    manager={manager}
                    fields={fields}
                    handleSubmit={handleSubmit}
                    deleteButtonText={deleteButtonText}
                    cancelButtonText={cancelButtonText}
                    activeText={activeText}
                    inactiveText={inactiveText}
                />
            </CardBody>
        </Card>
    );
}

DeleteManager.propTypes = {
    currentUser: PropTypes.object.isRequired,
};
