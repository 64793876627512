import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

import FormFields from '../../../../components/form/formFields';

export function MyForm({
    formRef,
    manager,
    fields,
    handleSubmit,
    deleteButtonText,
    cancelButtonText,
    activeText,
    inactiveText,
}) {
    useEffect(() => {
        formRef.current.reset();

        const initialData = {};

        fields.map((field) => {
            switch (field.props.name) {
                case 'share':
                case 'active':
                    initialData[field.props.name] = manager[field.props.name]
                        ? activeText
                        : inactiveText;
                    break;
                default:
                    initialData[field.props.name] = manager[field.props.name];
                    break;
            }
        });

        formRef.current.setData(initialData);
    }, []);

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <FormGroup row>
                <FormFields fields={fields} />
            </FormGroup>
            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button color="primary mr-2" type="submit">
                        {deleteButtonText}
                    </Button>
                    <Link to={'/managers'} className="btn btn-secondary">
                        {cancelButtonText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}

MyForm.propTypes = {
    formRef: PropTypes.object.isRequired,
    manager: PropTypes.object.isRequired,
    fields: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    deleteButtonText: PropTypes.string.isRequired,
    cancelButtonText: PropTypes.string.isRequired,
    activeText: PropTypes.string.isRequired,
    inactiveText: PropTypes.string.isRequired,
};
