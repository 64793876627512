import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Td from '../../../components/table/td';
import { handleActiveProperty } from '../../../helpers/handleActiveProperty';
import ActionsContainer from '../../../components/table/actionsContainer';

function TableTds({
    tds,
    user,
    actions,
    activeText,
    inactiveText,
    roleDescription,
}) {
    let { className, text } = handleActiveProperty(
        user.active,
        activeText,
        inactiveText
    );

    return tds.map((td, index) => {
        switch (td.property) {
            case 'role_id':
                return (
                    <Td key={index} title={td.th}>
                        {roleDescription[user[td.property]]}
                    </Td>
                );
            case 'active':
                return (
                    <Td key={index} title={td.th} className={className}>
                        {text}
                    </Td>
                );
            case 'actions':
                return (
                    <Td key={index} title={td.th}>
                        <ActionsContainer actions={actions} entity={user} />
                    </Td>
                );
            default:
                if (td.hasLink)
                    return (
                        <Td key={index} title={td.th}>
                            <Link
                                to={{
                                    pathname: td.to,
                                    state: { entity: user },
                                }}
                            >
                                {user[td.property] === null
                                    ? ''
                                    : user[td.property]}
                            </Link>
                        </Td>
                    );
                return (
                    <Td key={index} title={td.th}>
                        {user[td.property] === null ? '' : user[td.property]}
                    </Td>
                );
        }
    });
}

TableTds.propTypes = {
    tds: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    actions: PropTypes.array,
    activeText: PropTypes.string.isRequired,
    inactiveText: PropTypes.string.isRequired,
    roleDescription: PropTypes.object,
};

export default TableTds;
