import FileInput from '../../../components/inputForm/inputFile';

export default {
    pt: {
        customer_admin: {
            fields: [
                {
                    component: FileInput,
                    props: {
                        name: 'file',
                        label: 'Arquivo <b style="color: darkred">*</b>',
                        label2: 'Escolher arquivo...',
                        noselectedfiletext: 'Nenhum ficheiro selecionado',
                        accept: '.csv',
                        placeholder: 'Arquivo',
                    },
                    className: 'col-xs-12 col-md-5',
                },
            ],
            successText: 'Pacientes adicionados com sucesso!',
            addText: 'Adicionar',
            cancelText: 'Cancelar',
            requiredFieldText:
                '<b style="color: darkred">*</b> Campos obrigatórios!',
            downloadText: 'Download',
            downloadTemplateText:
                'Faça o download do arquivo com o formato para adicionar pacientes!',
            requiredFieldInfoText: `Dentro do arquivo há colunas <b>obrigatórias (*)</b> e não obrigatórias a serem preenchidas.
                        As colunas não obrigatórias podem ser deixadas em branco.`,
            activeInfoText: `Para usuários ativos, digitar <b>1</b>. Para usuários inativos, digitar <b>0</b>.`,
            dateFormatInfoText: `Para as datas, utilizar o formato <b>aaaa-mm-dd</b>.`,
            languageFormatText: `Para o idioma do e-mail enviado ao paciente, há três opções:
                    <b>en</b> (Inglês), <b>pt</b> (Português) e <b>ge</b> (Alemão).`,
        },
        manager: {
            fields: [
                {
                    component: FileInput,
                    props: {
                        name: 'file',
                        label: 'Arquivo <b style="color: darkred">*</b>',
                        label2: 'Escolher arquivo...',
                        noselectedfiletext: 'Nenhum ficheiro selecionado',
                        accept: '.csv',
                        placeholder: 'Arquivo',
                    },
                    className: 'col-xs-12 col-md-5',
                },
            ],
            successText: 'Usuário adicionado com sucesso!',
            addText: 'Adicionar',
            cancelText: 'Cancelar',
            requiredFieldText:
                '<b style="color: darkred">*</b> Campos obrigatórios!',
            downloadText: 'Download',
            downloadTemplateText:
                'Faça o download do arquivo com o formato para adicionar pacientes!',
            requiredFieldInfoText: `Dentro do arquivo há colunas <b>obrigatórias (*)</b> e não obrigatórias a serem preenchidas.
                    As colunas não obrigatórias podem ser deixadas em branco.`,
            activeInfoText: `Para usuários ativos, digitar <b>1</b>. Para usuários inativos, digitar <b>0</b>.`,
            dateFormatInfoText: `Para as datas, utilizar o formato <b>aaaa-mm-dd</b>.`,
            languageFormatText: `Para o idioma do e-mail enviado ao paciente, há três opções:
                <b>en</b> (Inglês), <b>pt</b> (Português) e <b>ge</b> (Alemão).`,
        },
        condor_admin: null,
    },
    en: {
        customer_admin: {
            fields: [
                {
                    component: FileInput,
                    props: {
                        name: 'file',
                        label: 'File <b style="color: darkred">*</b>',
                        label2: 'Choose file...',
                        noselectedfiletext:
                            'No files currently selected for upload',
                        accept: '.csv',
                        placeholder: 'File',
                    },
                    className: 'col-xs-12 col-md-5',
                },
            ],
            successText: 'Patient successfully added!',
            addText: 'Add',
            cancelText: 'Cancel',
            requiredFieldText:
                '<b style="color: darkred">*</b> Required fields!',
            downloadText: 'Download',
            downloadTemplateText:
                'Download the file with the format to add patients!',
            requiredFieldInfoText: `Inside the file there are <b>required (*)</b> and not required columns to be filled.
                    The not required columns can be left blank.`,
            activeInfoText: `For active users, enter <b>1</b>. For inactive users, enter <b>0</b>.`,
            dateFormatInfoText: `For dates, use the format <b>yyyy-mm-dd</b>.`,
            languageFormatText: `For the language of the email sent to the patient, there are three options:
            <b>en</b> (English), <b>pt</b> (Portuguese) and <b>ge</b> (German).`,
        },
        manager: {
            fields: [
                {
                    component: FileInput,
                    props: {
                        name: 'file',
                        label: 'File <b style="color: darkred">*</b>',
                        label2: 'Choose file...',
                        noselectedfiletext:
                            'No files currently selected for upload',
                        accept: '.csv',
                        placeholder: 'File',
                    },
                    className: 'col-xs-12 col-md-5',
                },
            ],
            successText: 'User successfully added!',
            addText: 'Add',
            cancelText: 'Cancel',
            requiredFieldText:
                '<b style="color: darkred">*</b> Required fields!',
            downloadText: 'Download',
            downloadTemplateText:
                'Download the file with the format to add patients!',
            requiredFieldInfoText: `Inside the file there are <b>required (*)</b> and not required columns to be filled.
                        The not required columns can be left blank.`,
            activeInfoText: `For active users, enter <b>1</b>. For inactive users, enter <b>0</b>.`,
            dateFormatInfoText: `For dates, use the format <b>yyyy-mm-dd</b>.`,
            languageFormatText: `For the language of the email sent to the patient, there are three options:
                <b>en</b> (English), <b>pt</b> (Portuguese) and <b>ge</b> (German).`,
        },
        condor_admin: null,
    },
};
