import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, Card, CardBody, CardFooter, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';

import '../../../styles/pages/account/passwordReset/passwordReset.css';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import FormFields from '../../../components/form/formFields';
import { setLoading } from '../../../store/actions/loadingActions';
import { validateForm } from '../../../helpers/formValidator';
import { sendPasswordResetRequest } from '../../../services/api/account';
import history from '../../../services/history';

export default function PasswordResetRequest() {
    const formRef = useRef(null);

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.loading.isLoading);
    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang];
    const { schema } = schemaConfig[lang];

    const handleSubmit = async (data) => {
        const { isValid, errors } = await validateForm(data, schema);

        if (isValid) {
            formRef.current.setErrors(errors);

            dispatch(setLoading(true));

            const response = await sendPasswordResetRequest(data);

            dispatch(setLoading(false));

            if (response) {
                toast.info(pageData.verifyEmailText);
                history.push('/login');
            }
        } else {
            formRef.current.setErrors(errors);
        }
    };

    return (
        <div className="passwordResetContainer">
            <div className="shadow rounded passwordResetBox">
                <Card>
                    <CardBody>
                        <Form ref={formRef} onSubmit={handleSubmit}>
                            <FormGroup row>
                                <FormGroup className="col-md-12 text-center">
                                    <h2>
                                        <b>{pageData.pageTitle}</b>
                                    </h2>
                                </FormGroup>
                            </FormGroup>
                            <FormGroup row>
                                <FormFields fields={pageData.fields} />
                            </FormGroup>
                            <FormGroup row>
                                <div className="col-md-12">
                                    <Button
                                        color="primary"
                                        type="submit"
                                        disabled={loading}
                                        className="mr-2"
                                    >
                                        {loading
                                            ? pageData.requestButtonLoadingText
                                            : pageData.requestButtonText}
                                    </Button>
                                    <Link
                                        to="/login"
                                        className="btn btn-secondary"
                                    >
                                        {pageData.goBackButtonText}
                                    </Link>
                                </div>
                            </FormGroup>
                        </Form>
                    </CardBody>
                    <CardFooter className="text-center">
                        <p className="textRed">
                            {pageData.doesNotRememberCredentials}
                        </p>
                    </CardFooter>
                </Card>
            </div>
        </div>
    );
}
