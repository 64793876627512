import React, { useMemo } from 'react';
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from '@stripe/react-stripe-js';
import { FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

import useWindowSize from '../../../../../../../hooks/useWindowSize';

const useOptions = () => {
    const options = useMemo(
        () => ({
            style: {
                base: {
                    color: '#495057',
                    letterSpacing: '0.05rem',
                    fontFamily: 'Poppins, sans-serif',
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        }),
        []
    );

    return options;
};

export function StripeForm({
    creditCardLabelText,
    setCardNumberComplete,
    setCardExpiryComplete,
    setCardCvcComplete,
}) {
    const windowSize = useWindowSize();
    const options = useOptions();

    return (
        <>
            <FormGroup
                row
                className={`${windowSize.width > 768 ? 'mb-0' : ''}`}
            >
                <FormGroup
                    className={`col-xs-12 col-md-5 col-lg-5 mb-0 ${
                        windowSize.width > 768 ? 'mb-0' : ''
                    }`}
                >
                    <label
                        dangerouslySetInnerHTML={{
                            __html: creditCardLabelText,
                        }}
                    ></label>
                    <CardNumberElement
                        className="StripeElement maxWidth100"
                        options={options}
                        onChange={(event) => {
                            setCardNumberComplete(event.complete);
                        }}
                    />
                </FormGroup>
            </FormGroup>
            <FormGroup row>
                <FormGroup
                    className={`col-xs-12 col-md-3 col-lg-3 ${
                        windowSize.width > 768 ? 'pr-0' : ''
                    }`}
                >
                    <CardExpiryElement
                        className="StripeElement maxWidth100"
                        options={options}
                        onChange={(event) => {
                            setCardExpiryComplete(event.complete);
                        }}
                    />
                </FormGroup>
                <FormGroup
                    className={`col-xs-12 col-md-2 col-lg-2 ${
                        windowSize.width > 768 ? 'pl-0' : ''
                    }`}
                >
                    <CardCvcElement
                        className="StripeElement maxWidth100"
                        options={options}
                        onChange={(event) => {
                            setCardCvcComplete(event.complete);
                        }}
                    />
                </FormGroup>
            </FormGroup>
        </>
    );
}

StripeForm.propTypes = {
    creditCardLabelText: PropTypes.string.isRequired,
    setCardNumberComplete: PropTypes.func.isRequired,
    setCardExpiryComplete: PropTypes.func.isRequired,
    setCardCvcComplete: PropTypes.func.isRequired,
};
