import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: null,
        manager: {
            schema: Yup.object().shape({
                name: Yup.string(),
                secondary_id: Yup.string(),
                email: Yup.string(),
                active: Yup.string(),
            }),
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            schema: Yup.object().shape({
                name: Yup.string(),
                secondary_id: Yup.string(),
                email: Yup.string(),
                active: Yup.string(),
            }),
        },
        condor_admin: null,
    },
};
