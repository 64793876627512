import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

const InputText = ({ name, label, className, ...rest }) => {
    const inputRef = useRef(null);

    const { fieldName, defaultValue, registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <>
            {label && (
                <label
                    htmlFor={fieldName}
                    dangerouslySetInnerHTML={{
                        __html: label,
                    }}
                ></label>
            )}

            <input
                id={fieldName}
                ref={inputRef}
                defaultValue={defaultValue}
                {...rest}
                className={`form-control ${className}`}
            />

            {error && <span className="text-danger">{error}</span>}
        </>
    );
};

InputText.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
};

export default InputText;
