import React from 'react';
import PropTypes from 'prop-types';

import { TablesTds } from './tablesTds';

export function TablesRows({ tableData, tds }) {
    return tableData.map((data) => {
        return (
            <tr key={crypto.randomUUID()}>
                <TablesTds data={data} tds={tds} />
            </tr>
        );
    });
}

TablesRows.propTypes = {
    tableData: PropTypes.array.isRequired,
    tds: PropTypes.array.isRequired,
};
