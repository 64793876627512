import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import {
    CardNumberElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import { setLoading } from '../../../../../../../store/actions/loadingActions';
import {
    getNewSubscriptionProRataValue,
    getPlanUpgradeProRataValue,
} from '../../../../../../../helpers/subscriptionHelpers';
import { validateForm } from '../../../../../../../helpers/formValidator';
import { getLumusPrice } from '../../../../../../../helpers/getLumusPrice';
import { currencyFormatter } from '../../../../../../../helpers/formatters';
import history from '../../../../../../../services/history';
import {
    directCharge,
    createNationalSubscription,
    createInternationalSubscription,
    updateInternationalSubscription,
} from '../../../../../../../services/api/account';
import useIugu from '../../../../../../../hooks/useIugu';
import FormFields from '../../../../../../../components/form/formFields';
import { IuguCreditCardForm } from '../../../../../../../components/form/iuguCreditCardForm';
import { StripeForm } from './stripeForm';
import Input from '../../../../../../../components/inputForm/inputText';

ReviewAndPaymentForm.propTypes = {
    pageData: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired,
    plans: PropTypes.array.isRequired,
    paymentMethod: PropTypes.object,
    currency: PropTypes.object.isRequired,
    plansGroups: PropTypes.array,
    numberOfDevices: PropTypes.number,
    prorationDate: PropTypes.number,
    amountDue: PropTypes.number,
};

export function ReviewAndPaymentForm({
    pageData,
    schema,
    plan,
    plans,
    paymentMethod,
    currency,
    plansGroups,
    numberOfDevices,
    prorationDate,
    amountDue,
}) {
    const iugu = useIugu();
    const stripe = useStripe();
    const elements = useElements();

    const [clientSecret, setClientSecret] = useState(null);

    const [cardNumberComplete, setCardNumberComplete] = useState(false);
    const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
    const [cardCvcComplete, setCardCvcComplete] = useState(false);

    const [filledInForm, setFilledInForm] = useState(
        currency.locale !== 'pt-BR'
    );
    const [formReady, setFormReady] = useState(false);

    const formRef = useRef(null);

    const dispatch = useDispatch();

    const currentUser = useSelector((state) => state.auth.currentUser);

    const sleepDiarySubscription = currentUser.subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'sleep_diary'
    );

    const lumusSubscription = currentUser.subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'lumus'
    );

    const handleKeyUpForm = () => {
        const formData = formRef.current.getData();

        let empty = 0;

        for (let key in formData) {
            if (formData[key] === '') {
                empty += 1;
            }
        }

        setFilledInForm(empty ? false : true);
    };

    const handleSubmit = async (data, { reset }, event) => {
        event.preventDefault();

        const { isValid, errors } = await validateForm(data, schema);

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(setLoading(true));

            const sendData = {};

            sendData.plan_id = plan.id;

            if (currency.locale === 'pt-BR') {
                sendData.description = data.description;

                // avoid sending the form if iugu script has not yet been loaded
                if (!iugu.impl()) {
                    dispatch(setLoading(false));
                    return;
                }

                // check if user already has a payment method, if not, create a payment token
                if (!paymentMethod) {
                    const createTokenResponse = await iugu.createPaymentToken(
                        event.target
                    );

                    if (createTokenResponse?.errors) {
                        dispatch(setLoading(false));

                        if ('incorrect_number' in createTokenResponse.errors) {
                            toast.error(
                                pageData.iuguErrorsText.numberErrorText
                            );
                        } else if (
                            'incorrect_cvv' in createTokenResponse.errors
                        ) {
                            toast.error(pageData.iuguErrorsText.cvvErrorText);
                        } else if (
                            'incorrect_fullName' in createTokenResponse.errors
                        ) {
                            toast.error(
                                pageData.iuguErrorsText.fullNameErrorText
                            );
                        } else if (
                            'incorrect_expiration' in createTokenResponse.errors
                        ) {
                            toast.error(
                                pageData.iuguErrorsText.expirationErrorText
                            );
                        } else {
                            toast.error(
                                pageData.iuguErrorsText.defaultErrorText
                            );
                        }

                        return;
                    }

                    sendData.token = createTokenResponse.id;
                }

                // if user has no paid subscriptions
                // that means, if user does not have a iugu_id in both subscriptions
                // request to create subscription endpoint
                if (
                    !!(
                        sleepDiarySubscription &&
                        !sleepDiarySubscription?.iugu_id &&
                        lumusSubscription &&
                        !lumusSubscription?.iugu_id
                    ) ||
                    !!(
                        sleepDiarySubscription &&
                        !sleepDiarySubscription?.iugu_id &&
                        !lumusSubscription
                    ) ||
                    !!(
                        lumusSubscription &&
                        !lumusSubscription?.iugu_id &&
                        !sleepDiarySubscription
                    )
                ) {
                    sendData.type = plan.type;
                    sendData.fidelity = plan.fidelity;

                    if (plan.type === 'lumus') {
                        sendData.number_of_devices = numberOfDevices;
                    }

                    const response = await createNationalSubscription(sendData);

                    if (response) {
                        toast.success(pageData.editSuccessText);

                        history.push('/account/my_account');

                        return;
                    }
                }

                // if user has at least one paid subscription
                // that means, if user has at least one iugu_id in either subscription
                // request to direct charge endpoint
                if (
                    (sleepDiarySubscription?.active &&
                        sleepDiarySubscription?.iugu_id !== null) ||
                    (lumusSubscription?.active &&
                        lumusSubscription?.iugu_id !== null)
                ) {
                    if (plan.type === 'lumus') {
                        sendData.number_of_devices = numberOfDevices;
                    }

                    // if user is updating an existing subscription
                    // direct_charge_reason = 'update_subscription';

                    if (
                        (plan.type === sleepDiarySubscription?.type &&
                            sleepDiarySubscription?.iugu_id !== null) ||
                        (plan.type === lumusSubscription?.type &&
                            lumusSubscription?.iugu_id !== null)
                    ) {
                        sendData.direct_charge_reason = 'update_subscription';
                    }

                    // if user is creating a new subscription from a different type
                    // direct_charge_reason = 'create_new_subscription';

                    if (
                        (plan.type === sleepDiarySubscription?.type &&
                            sleepDiarySubscription?.iugu_id === null) ||
                        (plan.type === lumusSubscription?.type &&
                            lumusSubscription?.iugu_id === null)
                    ) {
                        sendData.direct_charge_reason =
                            'create_new_subscription';
                    }

                    const response = await directCharge(sendData);

                    if (response) {
                        toast.success(pageData.editSuccessText);

                        dispatch(setLoading(false));

                        history.push('/account/my_account');

                        return;
                    }
                }
            } else {
                sendData.type = plan.type;
                sendData.fidelity = plan.fidelity;

                if (plan.type === 'lumus') {
                    sendData.number_of_devices = numberOfDevices;
                }

                // avoid sending the form if stripe promise has not yet been fulfilled

                if (!stripe || !elements) {
                    dispatch(setLoading(false));
                    return;
                }

                // if user has no paid subscription of the same type as the new plan
                // that means, if user does not have a stripe_id in the subscription of the same type of the plan
                // request to create subscription endpoint
                if (
                    (!sleepDiarySubscription?.stripe_id &&
                        plan.type === 'sleep_diary') ||
                    (!lumusSubscription?.stripe_id && plan.type === 'lumus')
                ) {
                    let tempClientSecret;

                    if (!clientSecret) {
                        const response = await createInternationalSubscription({
                            ...sendData,
                            proration_date: prorationDate,
                        });

                        if (response) {
                            tempClientSecret = response?.clientSecret;
                            setClientSecret(response?.clientSecret);
                        }
                    }

                    if (!(tempClientSecret ?? clientSecret)) {
                        dispatch(setLoading(false));
                        return;
                    }

                    let result;

                    if (paymentMethod?.id) {
                        result = await stripe.confirmCardPayment(
                            tempClientSecret,
                            {
                                payment_method: paymentMethod?.id,
                            }
                        );
                    } else {
                        result = await stripe.confirmCardPayment(
                            tempClientSecret ?? clientSecret,
                            {
                                payment_method: {
                                    card: elements.getElement(
                                        CardNumberElement
                                    ),
                                    billing_details: {
                                        name: currentUser.company_name,
                                    },
                                },
                            }
                        );
                    }

                    dispatch(setLoading(false));

                    if (result.error) {
                        if (result.error.code in pageData?.stripeErrors) {
                            if (
                                result.error.code === 'card_declined' &&
                                result.error.decline_code in
                                    pageData?.stripeErrors.card_decline_codes
                            ) {
                                toast.error(
                                    pageData?.stripeErrors?.card_decline_codes[
                                        result.error.decline_code
                                    ]
                                );
                            } else {
                                toast.error(
                                    pageData?.stripeErrors[result.error.code]
                                );
                            }
                        } else {
                            toast.error(pageData?.paymentFailed);
                        }

                        return;
                    }

                    if (result.paymentIntent.status === 'succeeded') {
                        toast.success(pageData.editSuccessText);

                        history.push('/account/my_account');

                        return;
                    }
                }

                // if user has at least one paid subscription
                // that means, if user has at least one stripe_id in either subscription
                // request to update subscription endpoint
                if (
                    (sleepDiarySubscription?.active &&
                        sleepDiarySubscription?.stripe_id !== null) ||
                    (lumusSubscription?.active &&
                        lumusSubscription?.stripe_id !== null)
                ) {
                    let tempClientSecret;

                    if (!clientSecret) {
                        const response = await updateInternationalSubscription({
                            ...sendData,
                            proration_date: prorationDate,
                        });

                        if (response) {
                            if (!response?.clientSecret) {
                                dispatch(setLoading(false));

                                toast.success(pageData.editSuccessText);

                                history.push('/account/my_account');

                                return;
                            } else {
                                tempClientSecret = response?.clientSecret;
                                setClientSecret(response?.clientSecret);
                            }
                        }
                    }

                    if (!(tempClientSecret ?? clientSecret)) {
                        dispatch(setLoading(false));
                        return;
                    }

                    const result = await stripe.confirmCardPayment(
                        tempClientSecret,
                        {
                            payment_method: paymentMethod?.id,
                        }
                    );

                    dispatch(setLoading(false));

                    if (result.error) {
                        if (result.error.code in pageData?.stripeErrors) {
                            if (
                                result.error.code === 'card_declined' &&
                                result.error.decline_code in
                                    pageData?.stripeErrors.card_decline_codes
                            ) {
                                toast.error(
                                    pageData?.stripeErrors?.card_decline_codes[
                                        result.error.decline_code
                                    ]
                                );
                            } else {
                                toast.error(
                                    pageData?.stripeErrors[result.error.code]
                                );
                            }
                        } else {
                            toast.error(pageData?.paymentFailed);
                        }

                        return;
                    } else {
                        if (result.paymentIntent.status === 'succeeded') {
                            toast.success(pageData.editSuccessText);

                            history.push('/account/my_account');

                            return;
                        }
                    }
                }
            }

            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        formRef.current.reset();

        const initialData = pageData.fields.plan.reduce(
            (acc, { props: { name } }) => ({ ...acc, [name]: plan[name] }),
            {}
        );

        let price = 0;
        let pro_rata_price = undefined;

        if (plan.type === 'sleep_diary') {
            initialData['max_patients'] = plan.max_patients;

            price = currency.format === 'BRL' ? plan.price_brl : plan.price_usd;

            if (sleepDiarySubscription) {
                if (sleepDiarySubscription.plan.name.includes('Trial')) {
                    // user wants to create a new paid sleep diary subscription

                    if (
                        lumusSubscription &&
                        lumusSubscription.active &&
                        !lumusSubscription.plan.name.includes('Trial') &&
                        !lumusSubscription.plan.name.includes('Unlimited')
                    ) {
                        if (currency.format === 'BRL') {
                            pro_rata_price = currentUser?.subscription
                                ?.last_billing_date
                                ? getNewSubscriptionProRataValue(
                                      price,
                                      currentUser?.subscription?.billing_date,
                                      currentUser?.subscription
                                          ?.last_billing_date
                                  )
                                : undefined;
                        } else {
                            pro_rata_price = amountDue;
                        }
                    }
                } else {
                    // user wants to upgrade a paid subscription
                    if (currency.format === 'BRL') {
                        pro_rata_price = currentUser?.subscription
                            ?.last_billing_date
                            ? getPlanUpgradeProRataValue(
                                  price,
                                  sleepDiarySubscription.price,
                                  currentUser?.subscription?.billing_date,
                                  currentUser?.subscription?.last_billing_date
                              )
                            : undefined;
                    } else {
                        pro_rata_price = amountDue;
                    }
                }
            }
        } else {
            initialData['number_of_devices'] = numberOfDevices;

            price = getLumusPrice(
                plan.prices,
                numberOfDevices,
                currency.format
            );

            if (lumusSubscription) {
                if (lumusSubscription.plan.name.includes('Trial')) {
                    // user wants to create a new paid lumus subscription

                    if (
                        sleepDiarySubscription &&
                        sleepDiarySubscription.active &&
                        !sleepDiarySubscription.plan.name.includes('Trial') &&
                        !sleepDiarySubscription.plan.name.includes('Unlimited')
                    ) {
                        if (currency.format === 'BRL') {
                            pro_rata_price = currentUser?.subscription
                                ?.last_billing_date
                                ? getNewSubscriptionProRataValue(
                                      price,
                                      currentUser?.subscription?.billing_date,
                                      currentUser?.subscription
                                          ?.last_billing_date
                                  )
                                : undefined;
                        } else {
                            pro_rata_price = amountDue;
                        }
                    }
                } else {
                    // user wants to upgrade a paid subscription
                    if (currency.format === 'BRL') {
                        pro_rata_price = currentUser?.subscription
                            ?.last_billing_date
                            ? getPlanUpgradeProRataValue(
                                  price,
                                  lumusSubscription.price,
                                  currentUser?.subscription?.billing_date,
                                  currentUser?.subscription?.last_billing_date
                              )
                            : undefined;
                    } else {
                        pro_rata_price = amountDue;
                    }
                }
            }
        }

        pageData.fields.subscription.forEach((field) => {
            if (field.props.name === 'payable_with') {
                initialData[field.props.name] =
                    pageData.payableWithText[plan[field.props.name]];
            } else if (field.props.name === 'fidelity') {
                initialData[field.props.name] =
                    pageData.fidelityText[plan[field.props.name]];
            } else if (field.props.name === 'price') {
                initialData[field.props.name] = currencyFormatter(
                    price,
                    currency.locale,
                    currency.format
                );
            } else if (field.props.name === 'pro_rata_price') {
                if (pro_rata_price !== undefined) {
                    initialData[field.props.name] = currencyFormatter(
                        pro_rata_price,
                        currency.locale,
                        currency.format
                    );
                } else {
                    initialData[field.props.name] = currencyFormatter(
                        price,
                        currency.locale,
                        currency.format
                    );
                }
            } else {
                initialData[field.props.name] = plan[field.props.name];
            }
        });

        if (paymentMethod !== null) {
            if (currency.format === 'BRL') {
                pageData.fields.card.forEach((field) => {
                    if (field.props.name === 'expiration_date') {
                        initialData[
                            field.props.name
                        ] = `${paymentMethod['data']['month']}/${paymentMethod['data']['year']}`;
                    } else if (field.props.name === 'display_number') {
                        initialData[field.props.name] =
                            paymentMethod['data'][field.props.name];
                    } else {
                        initialData[field.props.name] =
                            paymentMethod[field.props.name];
                    }
                });
            } else {
                pageData.fields.card.forEach((field) => {
                    if (field.props.name === 'expiration_date') {
                        initialData[
                            field.props.name
                        ] = `${paymentMethod['card']['exp_month']}/${paymentMethod['card']['exp_year']}`;
                    } else if (field.props.name === 'display_number') {
                        initialData[field.props.name] =
                            '•••• •••• •••• ' + paymentMethod['card']['last4'];
                    } else {
                        initialData[field.props.name] =
                            paymentMethod[field.props.name];
                    }
                });
            }
        }

        formRef.current.setData(initialData);
    }, []);

    useEffect(() => {
        if (
            (cardNumberComplete &&
                cardExpiryComplete &&
                cardCvcComplete &&
                filledInForm) ||
            (currency.locale === 'pt-BR' &&
                (paymentMethod !== null || filledInForm)) ||
            (currency.locale !== 'pt-BR' && paymentMethod !== null)
        ) {
            setCardNumberComplete(true);
            setCardCvcComplete(true);
            setCardExpiryComplete(true);
            setFormReady(true);
        } else {
            setFormReady(false);
        }
    }, [cardNumberComplete, cardExpiryComplete, cardCvcComplete, filledInForm]);

    return (
        <Form ref={formRef} onSubmit={handleSubmit} onKeyUp={handleKeyUpForm}>
            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{pageData.planSpanText}</span>
                </FormGroup>
            </FormGroup>
            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-4">
                    <Input
                        name="name"
                        type="text"
                        label={pageData.nameText}
                        readOnly={true}
                    />
                </FormGroup>

                {plan.type === 'sleep_diary' && (
                    <FormGroup className="col-xs-12 col-md-3">
                        <Input
                            name="max_patients"
                            type="text"
                            label={pageData.maxPatientsText}
                            readOnly={true}
                        />
                    </FormGroup>
                )}

                {plan.type === 'lumus' && (
                    <FormGroup className="col-xs-12 col-md-3">
                        <Input
                            name="number_of_devices"
                            type="text"
                            label={pageData.numberOfDevicesText}
                            readOnly={true}
                        />
                    </FormGroup>
                )}
            </FormGroup>
            <hr />
            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{pageData.subscriptionSpanText}</span>
                </FormGroup>
            </FormGroup>
            <FormGroup row>
                <FormFields fields={pageData.fields.subscription} />
            </FormGroup>
            <hr />
            {currency.format === 'BRL' && paymentMethod && (
                <>
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span>{pageData.creditCardSpanText}</span>
                        </FormGroup>
                    </FormGroup>
                    <FormGroup row>
                        <FormFields fields={pageData.fields.card} />
                    </FormGroup>
                </>
            )}
            {currency.format === 'BRL' && !paymentMethod && (
                <>
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span>{pageData.creditCardSpanText}</span>
                        </FormGroup>
                    </FormGroup>
                    <FormGroup row>
                        <FormFields
                            fields={pageData.fields.new_card_description}
                        />
                        <FormGroup className="col-xs-12 col-md-5">
                            <IuguCreditCardForm
                                fields={pageData.fields.new_card_info}
                                imgAltTexts={pageData.imgAltTexts}
                                creditCardLabelText={
                                    pageData.newCreditCardLabelText
                                }
                                iugu={iugu}
                            />
                        </FormGroup>
                    </FormGroup>
                </>
            )}
            {currency.format !== 'BRL' && paymentMethod && (
                <>
                    <FormGroup row>
                        <FormFields fields={pageData.fields.card.slice(1)} />
                    </FormGroup>
                </>
            )}
            {currency.format !== 'BRL' && !paymentMethod && (
                <>
                    <StripeForm
                        creditCardLabelText={pageData.creditCardSpanText}
                        setCardNumberComplete={setCardNumberComplete}
                        setCardExpiryComplete={setCardExpiryComplete}
                        setCardCvcComplete={setCardCvcComplete}
                    />
                </>
            )}
            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button
                        color="primary mr-2"
                        type="submit"
                        disabled={!formReady}
                    >
                        {pageData.confirmButtonText}
                    </Button>
                    <Link
                        to={{
                            pathname:
                                '/account/my_account/change_plan/choose_subscription',
                            state: {
                                plans,
                                currency,
                                paymentMethod,
                                plansGroups,
                                planType: plan.type,
                            },
                        }}
                        className="btn btn-secondary"
                    >
                        {pageData.goBackButtonText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}
