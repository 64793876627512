import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

import FormFields from '../../../../components/form/formFields';

export function MyForm({ formRef, manager, fields, texts, handleSubmit }) {
    const lang = useSelector((state) => state.lang.lang);

    useEffect(() => {
        formRef.current.reset();

        const initialData = {};

        fields.map((field) => {
            switch (field.props.name) {
                default:
                    initialData[field.props.name] = manager[field.props.name];
                    break;
            }
        });

        formRef.current.setData(initialData);

        formRef.current.setFieldValue('active', {
            label: manager.active ? texts.yesText : texts.noText,
            value: manager.active,
        });

        if (manager?.share !== undefined) {
            formRef.current.setFieldValue('share', {
                label: manager.share ? texts.yesText : texts.noText,
                value: manager.share,
            });
        }
    }, []);

    useEffect(() => {
        if (
            formRef.current.getFieldValue('share') !== undefined &&
            formRef.current.getFieldValue('share') !== ''
        ) {
            formRef.current.setFieldValue('share', {
                label: formRef.current.getFieldValue('share')
                    ? texts.yesText
                    : texts.noText,
                value: formRef.current.getFieldValue('share'),
            });
        }

        if (
            formRef.current.getFieldValue('active') !== undefined &&
            formRef.current.getFieldValue('active') !== ''
        ) {
            formRef.current.setFieldValue('active', {
                label: formRef.current.getFieldValue('active')
                    ? texts.yesText
                    : texts.noText,
                value: formRef.current.getFieldValue('active'),
            });
        }
    }, [lang]);

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <FormGroup row>
                <FormFields fields={fields} />
            </FormGroup>
            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button color="primary mr-2" type="submit">
                        {texts.saveText}
                    </Button>
                    <Link to={'/managers'} className="btn btn-secondary">
                        {texts.cancelText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}

MyForm.propTypes = {
    formRef: PropTypes.object.isRequired,
    manager: PropTypes.object.isRequired,
    fields: PropTypes.array.isRequired,
    texts: PropTypes.shape({
        saveText: PropTypes.string.isRequired,
        cancelText: PropTypes.string.isRequired,
        yesText: PropTypes.string.isRequired,
        noText: PropTypes.string.isRequired,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
};
