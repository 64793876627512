import React from 'react';
import PropTypes from 'prop-types';

import Td from '../../../../components/table/td';

export function TablesTds({ data, tds }) {
    return tds.map((td) => {
        return (
            <Td key={crypto.randomUUID()} title={td.th}>
                {data[td.property]}
            </Td>
        );
    });
}

TablesTds.propTypes = {
    data: PropTypes.object.isRequired,
    tds: PropTypes.array.isRequired,
};
