import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import { Button, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

import history from '../../../../services/history';
import FormFields from '../../../../components/form/formFields';

export function MyForm({
    formRef,
    pageData,
    user,
    diary,
    fields,
    devicesFields,
    handleSubmit,
}) {
    useEffect(() => {
        formRef.current.reset();

        const initialData = {};

        fields.map((field) => {
            switch (field.props.name) {
                case 'role_id':
                    initialData[field.props.name] =
                        field.userTypes[user.role_id];
                    break;
                case 'active':
                    initialData[field.props.name] = user.active
                        ? pageData.activeText
                        : pageData.inactiveText;
                    break;
                case 'trialStart':
                    initialData[field.props.name] =
                        user['trials_configurations'][field.props.name];
                    break;
                case 'trialEnd':
                    initialData[field.props.name] =
                        user['trials_configurations'][field.props.name];
                    break;
                case 'diary_id':
                    initialData[field.props.name] = diary?.name;
                    break;
                default:
                    initialData[field.props.name] = user[field.props.name];
                    break;
            }
        });

        formRef.current.setData(initialData);

        if (user?.devices?.length > 0) {
            for (let i = 0; i < user.devices.length; i++) {
                formRef.current.setFieldValue(
                    `devices[${i}].device_id`,
                    user.devices[i].device.serial_no
                );
                formRef.current.setFieldValue(
                    `devices[${i}].body_part`,
                    pageData.bodyPartTexts[user.devices[i].body_part]
                );
                formRef.current.setFieldValue(
                    `devices[${i}].received_at`,
                    user.devices[i].received_at
                );
            }
        }
    }, []);

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <FormGroup row>
                <FormFields fields={fields} />
            </FormGroup>
            {user?.devices?.length > 0 && (
                <>
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span>{pageData.devicesText}</span>
                        </FormGroup>
                    </FormGroup>
                    {devicesFields.map((deviceFields, index) => (
                        <React.Fragment key={`${deviceFields[0].key}`}>
                            <FormGroup row>
                                <Scope path={`devices[${index}]`}>
                                    {deviceFields.map((field) => (
                                        <FormGroup
                                            key={`${field.props.name}${field.key}`}
                                            className={field.className}
                                        >
                                            <field.component {...field.props} />
                                        </FormGroup>
                                    ))}
                                </Scope>
                            </FormGroup>
                            <hr />
                        </React.Fragment>
                    ))}
                </>
            )}
            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button color="primary mr-2" type="submit">
                        {pageData.deleteText}
                    </Button>
                    <Link to={'/users'} className="btn btn-secondary">
                        {pageData.cancelText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}

MyForm.propTypes = {
    formRef: PropTypes.object.isRequired,
    pageData: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    diary: PropTypes.object,
    fields: PropTypes.array.isRequired,
    devicesFields: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};
