import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Row, Button, Card, CardHeader, CardBody, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import schemaConfig from './schema.config';
import pageConfig from './page.config';
import { setLoading } from '../../../store/actions/loadingActions';
import { validateForm } from '../../../helpers/formValidator';
import {
    fetchCompany,
    updateSettings,
    disable2fa,
} from '../../../services/api/account';
import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';
import { Disable2faModal } from './components/disable2faModal';

Settings.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function Settings({ currentUser }) {
    const formRef = useRef(null);

    const [company, setCompany] = useState(null);

    const [editModeForm, setEditModeForm] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const { schema } = schemaConfig[lang];

    const pageData = pageConfig[lang][currentUser.permissions];

    async function handleSubmit(data) {
        const { parsedData, isValid, errors } = await validateForm(
            data,
            schema
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(setLoading(true));

            const response = await updateSettings(parsedData);

            dispatch(setLoading(false));

            if (response) {
                toast.success(pageData.successText);

                setCompany((prevState) => ({
                    ...prevState,
                    ...parsedData,
                    descriptor: {
                        ...prevState.descriptor,
                        ...parsedData.descriptor,
                    },
                }));
            }
        }
    }

    function cancelEditModeForm() {
        setEditModeForm(false);

        formRef.current.reset();

        formRef.current.setData({
            name: company.name,
            share: {
                label: company?.share ? pageData.yesText : pageData.noText,
                value: company?.share ? true : false,
            },
            descriptor: {
                saveEmail: {
                    label: company?.descriptor?.saveEmail
                        ? pageData.yesText
                        : pageData.noText,
                    value: company?.descriptor?.saveEmail ? true : false,
                },
                defaultLanguage: {
                    label: pageData.defaultLanguageOptions[
                        company?.descriptor?.defaultLanguage
                    ]?.label,
                    value: pageData.defaultLanguageOptions[
                        company?.descriptor?.defaultLanguage
                    ]?.value,
                },
                minPasswordLength: company.descriptor.minPasswordLength,
                hasSpecialCharacterInPassword: {
                    label: company?.descriptor?.hasSpecialCharacterInPassword
                        ? pageData.yesText
                        : pageData.noText,
                    value: company?.descriptor?.hasSpecialCharacterInPassword
                        ? true
                        : false,
                },
            },
        });
    }

    function toggle() {
        setIsModalOpen(!isModalOpen);
    }

    async function handleDisable2fa() {
        dispatch(setLoading(true));

        const data = await disable2fa();

        dispatch(setLoading(false));

        if (data) {
            toast.success(pageData.enable2faSuccessText);

            setCompany((prevState) => ({
                ...prevState,
                descriptor: {
                    ...prevState.descriptor,
                    is2faEnabled: false,
                },
            }));
        }
    }

    useEffect(() => {
        (async function () {
            formRef.current.reset();

            dispatch(setLoading(true));

            const data = await fetchCompany();

            dispatch(setLoading(false));

            if (Object.entries(data).length > 0) {
                formRef.current.setData({
                    name: data?.company.name,
                    share: {
                        label: data?.company?.share
                            ? pageData.yesText
                            : pageData.noText,
                        value: data?.company?.share ? true : false,
                    },
                    descriptor: {
                        saveEmail: {
                            label: data?.company?.descriptor?.saveEmail
                                ? pageData.yesText
                                : pageData.noText,
                            value: data?.company?.descriptor?.saveEmail
                                ? true
                                : false,
                        },
                        defaultLanguage: {
                            label: pageData.defaultLanguageOptions[
                                data?.company?.descriptor?.defaultLanguage
                            ]?.label,
                            value: pageData.defaultLanguageOptions[
                                data?.company?.descriptor?.defaultLanguage
                            ]?.value,
                        },
                        minPasswordLength:
                            data?.company.descriptor.minPasswordLength,
                        hasSpecialCharacterInPassword: {
                            label: data?.company?.descriptor
                                ?.hasSpecialCharacterInPassword
                                ? pageData.yesText
                                : pageData.noText,
                            value: data?.company?.descriptor
                                ?.hasSpecialCharacterInPassword
                                ? true
                                : false,
                        },
                    },
                });

                setCompany(data?.company);
            }
        })();
    }, []);

    useEffect(() => {
        if (company && pageData) {
            formRef.current.setData({
                name: company.name,
                share: {
                    label: formRef.current.getFieldValue('share')
                        ? pageData.yesText
                        : pageData.noText,
                    value: formRef.current.getFieldValue('share')
                        ? true
                        : false,
                },
                descriptor: {
                    saveEmail: {
                        label: formRef.current.getFieldValue(
                            'descriptor.saveEmail'
                        )
                            ? pageData.yesText
                            : pageData.noText,
                        value: formRef.current.getFieldValue(
                            'descriptor.saveEmail'
                        ),
                    },
                    defaultLanguage: {
                        label: pageData.defaultLanguageOptions[
                            formRef.current.getFieldValue(
                                'descriptor.defaultLanguage'
                            )
                        ]?.label,
                        value: pageData.defaultLanguageOptions[
                            formRef.current.getFieldValue(
                                'descriptor.defaultLanguage'
                            )
                        ]?.value,
                    },
                    minPasswordLength: formRef.current.getFieldValue(
                        'descriptor.minPasswordLength'
                    ),
                    hasSpecialCharacterInPassword: {
                        label: formRef.current.getFieldValue(
                            'descriptor.hasSpecialCharacterInPassword'
                        )
                            ? pageData.yesText
                            : pageData.noText,
                        value: formRef.current.getFieldValue(
                            'descriptor.hasSpecialCharacterInPassword'
                        ),
                    },
                },
            });
        }
    }, [lang]);

    return (
        <div className={styles.pageContainer}>
            <Card className={styles.cardShadow}>
                <CardHeader className={styles.header}>
                    <p>{pageData.companySettingsText}</p>

                    <div>
                        <Button
                            type="button"
                            onClick={() => setEditModeForm(true)}
                            disabled={editModeForm}
                        >
                            {pageData.editText}
                        </Button>
                    </div>
                </CardHeader>

                <CardBody>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <Row>
                            <FormGroup className="col-xs-12 col-md-5">
                                <Input
                                    name="name"
                                    type="text"
                                    label={pageData.companyNameText}
                                    readOnly={!editModeForm}
                                />
                            </FormGroup>

                            <FormGroup
                                className={`col-xs-12 col-md-3 ${styles.settingsRadioContainer}`}
                            >
                                <Select
                                    name="share"
                                    label={pageData.sharePatientsText}
                                    options={[
                                        {
                                            id: 'yes',
                                            value: true,
                                            label: pageData.yesText,
                                        },
                                        {
                                            id: 'no',
                                            value: false,
                                            label: pageData.noText,
                                        },
                                    ]}
                                    isDisabled={!editModeForm}
                                />
                            </FormGroup>

                            <FormGroup
                                className={`col-xs-12 col-md-2 ${styles.settingsRadioContainer}`}
                            >
                                <Select
                                    name="descriptor.saveEmail"
                                    label={pageData.saveEmailText}
                                    options={[
                                        {
                                            id: 'yes',
                                            value: true,
                                            label: pageData.yesText,
                                        },
                                        {
                                            id: 'no',
                                            value: false,
                                            label: pageData.noText,
                                        },
                                    ]}
                                    isDisabled={!editModeForm}
                                />
                            </FormGroup>

                            <FormGroup
                                className={`col-xs-12 col-md-2 ${styles.settingsRadioContainer}`}
                            >
                                <Select
                                    name="descriptor.defaultLanguage"
                                    label={pageData.defaultLanguageText}
                                    options={[
                                        {
                                            id: 'pt',
                                            value: 'pt',
                                            label: pageData.portugueseText,
                                        },
                                        {
                                            id: 'en',
                                            value: 'en',
                                            label: pageData.englishText,
                                        },
                                    ]}
                                    isDisabled={!editModeForm}
                                />
                            </FormGroup>

                            <FormGroup className="col-xs-12 col-md-3">
                                <Input
                                    name="descriptor.minPasswordLength"
                                    type="number"
                                    label={pageData.minimumPasswordLengthText}
                                    min={8}
                                    readOnly={!editModeForm}
                                />
                            </FormGroup>

                            <FormGroup
                                className={`col-xs-12 col-md-3 ${styles.settingsRadioContainer}`}
                            >
                                <Select
                                    name="descriptor.hasSpecialCharacterInPassword"
                                    label={
                                        pageData.hasSpecialCharactersInPasswordText
                                    }
                                    options={[
                                        {
                                            id: 'yes',
                                            value: true,
                                            label: pageData.yesText,
                                        },
                                        {
                                            id: 'no',
                                            value: false,
                                            label: pageData.noText,
                                        },
                                    ]}
                                    isDisabled={!editModeForm}
                                />
                            </FormGroup>
                        </Row>

                        {editModeForm && (
                            <Row>
                                <div className="col-xs-12 col-md-12 text-left">
                                    <Button
                                        color="primary"
                                        className="mr-2"
                                        type="submit"
                                    >
                                        {pageData.saveText}
                                    </Button>

                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={() => cancelEditModeForm()}
                                    >
                                        {pageData.cancelText}
                                    </Button>
                                </div>
                            </Row>
                        )}
                    </Form>
                </CardBody>
            </Card>

            <Card className={`mt-4 ${styles.cardShadow}`}>
                <CardHeader className={styles.header}>
                    <p>{pageData.twoFactorAuthenticationText}</p>

                    <div>
                        <Button
                            type="button"
                            onClick={() => setEditMode(true)}
                            disabled={editMode}
                        >
                            {pageData.editText}
                        </Button>
                    </div>
                </CardHeader>

                <CardBody>
                    <div>
                        <p>
                            {pageData.statusText}:{' '}
                            <b>
                                {company?.descriptor?.is2faEnabled
                                    ? pageData.enabledText
                                    : pageData.disabledText}
                            </b>
                        </p>
                    </div>

                    <div className="mt-4">
                        <p>{pageData.twoFactorAuthenticationInfoText}</p>
                    </div>

                    {editMode && (
                        <div className="mt-4 text-left">
                            {company?.descriptor?.is2faEnabled ? (
                                <Button
                                    type="button"
                                    color="primary"
                                    className="mr-2"
                                    onClick={() => toggle()}
                                >
                                    {pageData.disableText}
                                </Button>
                            ) : (
                                <Link
                                    to="/account/settings/setup_2fa"
                                    className="btn btn-primary mr-2"
                                >
                                    {pageData.enableText}
                                </Link>
                            )}

                            <Button
                                type="button"
                                color="secondary"
                                onClick={() => setEditMode(false)}
                            >
                                {pageData.cancelText}
                            </Button>
                        </div>
                    )}
                </CardBody>
            </Card>

            <Disable2faModal
                onDisable2fa={handleDisable2fa}
                toggle={toggle}
                isModalOpen={isModalOpen}
                texts={{
                    disableTwoFactorAuthenticationText:
                        pageData.disableTwoFactorAuthenticationText,
                    sureToDisableTwoFactorAuthenticationText:
                        pageData.sureToDisableTwoFactorAuthenticationText,
                    disableText: pageData.disableText,
                    cancelText: pageData.cancelText,
                }}
            />
        </div>
    );
}
