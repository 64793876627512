import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap';

import '../../styles/components/card/card.css';

export default function CardWrapper({
    title,
    children,
    isTable,
    className,
    textCenter = false,
    textSize = 'md',
}) {
    const classNameTable = isTable ? 'cardWrapperTableBody' : '';

    return (
        <Card className={`${className ?? ''} cardShadow`}>
            <CardHeader
                className={`cardHeader ${textCenter ? 'text-center' : ''}`}
            >
                <CardTitle className="cardTitle">
                    <h6
                        className={`${
                            textSize === 'lg' ? 'cardHeaderSizeLarge' : ''
                        }`}
                    >
                        {title}
                    </h6>
                </CardTitle>
            </CardHeader>
            <CardBody className={`${classNameTable}`}>{children}</CardBody>
        </Card>
    );
}

CardWrapper.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
        PropTypes.node,
    ]).isRequired,
    isTable: PropTypes.bool.isRequired,
    className: PropTypes.string,
    textCenter: PropTypes.bool,
    textSize: PropTypes.string,
};
