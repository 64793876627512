import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ShepherdTour } from 'react-shepherd';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import { stepsOptions, tourOptions } from './tour.config';
import { setLoading } from '../../store/actions/loadingActions';
import { fetchUsers, fetchSubordinates } from '../../services/api/users';
import { useOrderByProperty } from '../../hooks/useOrderByProperty';
import { SearchAndAddContainer } from './components/searchAndAddContainer';
import { TableContainer } from './components/tableContainer';

Users.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function Users({ currentUser }) {
    const [canAddUsers, setCanAddUsers] = useState(null);
    const [users, setUsers] = useState([]);
    const { activeFilter, filteredData, setFilteredData, orderByProperty } =
        useOrderByProperty(users, 'name', 'asc');
    const [steps, setSteps] = useState([]);
    const [ready, setReady] = useState(false);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const isLoading = useSelector((state) => state.loading.isLoading);

    const pageData = pageConfig[lang][currentUser.permissions];

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            let data;

            if (currentUser.permissions === 'condor_admin') {
                data = await fetchUsers();
            } else {
                data = await fetchSubordinates();
                setCanAddUsers(data?.canAddUsers);
            }

            if (currentUser.permissions === 'manager') {
                const showAddPatientButtonTour = JSON.parse(
                    localStorage.getItem('showAddPatientButtonTour')
                );
                const showAppDownloadTour = JSON.parse(
                    localStorage.getItem('showAppDownloadTour')
                );

                if (
                    (showAddPatientButtonTour === null ||
                        showAddPatientButtonTour === true) &&
                    data?.users.length === 0
                ) {
                    localStorage.setItem('showAddPatientButtonTour', 'true');
                    setSteps(stepsOptions[lang].addPatientButton);
                } else {
                    localStorage.setItem('showAddPatientButtonTour', 'false');
                    if (
                        (showAppDownloadTour === null ||
                            showAppDownloadTour === true) &&
                        data?.users.length !== 0
                    ) {
                        localStorage.setItem('showAppDownloadTour', 'true');
                        setSteps(stepsOptions[lang].appDownload);
                    }
                }
            }

            if (data?.users.length === 0) {
                toast.info(pageData.noUsersText);
            }

            setUsers(data?.users);

            setReady(true);

            dispatch(setLoading(false));
        })();
    }, []);

    if (isLoading || !ready) {
        return <></>;
    }

    return (
        <>
            <ShepherdTour steps={steps} tourOptions={tourOptions}>
                <SearchAndAddContainer
                    users={users}
                    setFilteredUsers={setFilteredData}
                    canAddUsers={canAddUsers}
                    modalProps={pageData.modalProps}
                    currentUser={currentUser}
                    texts={{
                        searchByNameText: pageData.searchByNameText,
                        searchBySecondaryIdText:
                            pageData.searchBySecondaryIdText,
                        addText: pageData.addText,
                        addUserText: pageData.addUserText,
                        importUserText: pageData.importUserText,
                    }}
                />
            </ShepherdTour>
            <TableContainer
                ths={pageData.ths}
                tds={pageData.tds}
                filteredUsers={filteredData}
                actions={pageData.actions}
                activeText={pageData.activeText}
                inactiveText={pageData.inactiveText}
                roleDescription={pageData?.roleDescription}
                activeFilter={activeFilter}
                orderByProperty={orderByProperty}
            />
        </>
    );
}
