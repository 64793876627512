export default {
    pt: {
        customer_admin: {
            pageNameText: 'Página de Impressão',
            printButtonText: 'Imprimir',
            userInfo: {
                sleepDiaryText: 'Diário de Sono',
                reportNameText: 'Relatório de Diário de Sono',
                titleSectionText: 'Informações do paciente',
                patientText: 'Paciente: ',
                startText: 'Início: ',
                currentWeekText: 'Semana atual: ',
                weekText: 'Semana',
                weeksText: 'Semanas',
            },
            weeklyEvolution: {
                titleSectionText: 'Evolução semanal',
            },
            currentWeek: {
                titleSectionText: 'Evolução diária',
                medicineDescriptionTable: {
                    titleText: 'Medicamentos',
                    ths: ['Dia da semana', 'Medicamentos'],
                },
                commentsTableTexts: {
                    titleText: 'Comentários',
                    ths: ['Dia da semana', 'Comentários'],
                },
            },
            tables: {
                titleSectionText: 'Tabelas',
                sleepStatistics: {
                    titleText: 'Estatísticas de sono - Médias semanais',
                    ths: [
                        'Semana',
                        'Hora que deitou',
                        'Hora que tentou dormir',
                        'Hora que levantou',
                        'Tempo na cama',
                        'Tempo de sono',
                        'Eficiência',
                        'Latência',
                        'Número de despertares',
                        'WASO',
                        'Inércia',
                        'Satisfação',
                        'Uso de medicamento',
                    ],
                    tds: [
                        {
                            th: 'Semana',
                            property: 'week',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Hora que deitou',
                            property: 'bedTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Hora que tentou dormir',
                            property: 'trySleepStart',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Hora que levantou',
                            property: 'getUpTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Tempo na cama',
                            property: 'timeInBed',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Tempo de sono',
                            property: 'sleepDuration',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Eficiência',
                            property: 'sleepEfficiency',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Latência',
                            property: 'latencyDuration',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Número de despertares',
                            property: 'nOfAwk',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'WASO',
                            property: 'WASO',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Inércia',
                            property: 'inertia',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Satisfação',
                            property: 'sleepSatisfaction',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Uso de medicamento',
                            property: 'usesMedicine',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                napsStatistics: {
                    titleText: 'Estatísticas de cochilo - Médias semanais',
                    ths: [
                        'Semana',
                        'Número de cochilos',
                        'Tempo total de cochilos',
                    ],
                    tds: [
                        {
                            th: 'Semana',
                            property: 'week',
                            hasLink: false,
                        },
                        {
                            th: 'Número de cochilos',
                            property: 'nOfNaps',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Tempo total de cochilos',
                            property: 'NBBT',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
            },
        },
        manager: {
            pageNameText: 'Página de Impressão',
            printButtonText: 'Imprimir',
            userInfo: {
                sleepDiaryText: 'Diário de Sono',
                reportNameText: 'Relatório de Diário de Sono',
                titleSectionText: 'Informações do paciente',
                patientText: 'Paciente: ',
                startText: 'Início: ',
                currentWeekText: 'Semana atual: ',
                weekText: 'Semana',
                weeksText: 'Semanas',
            },
            weeklyEvolution: {
                titleSectionText: 'Evolução semanal',
            },
            currentWeek: {
                titleSectionText: 'Evolução diária',
                medicineDescriptionTable: {
                    titleText: 'Medicamentos',
                    ths: ['Dia da semana', 'Medicamentos'],
                },
                commentsTableTexts: {
                    titleText: 'Comentários',
                    ths: ['Dia da semana', 'Comentários'],
                },
            },
            tables: {
                titleSectionText: 'Tabelas',
                sleepStatistics: {
                    titleText: 'Estatísticas de sono - Médias semanais',
                    ths: [
                        'Semana',
                        'Hora que deitou',
                        'Hora que tentou dormir',
                        'Hora que levantou',
                        'Tempo na cama',
                        'Tempo de sono',
                        'Eficiência',
                        'Latência',
                        'Número de despertares',
                        'WASO',
                        'Inércia',
                        'Satisfação',
                        'Uso de medicamento',
                    ],
                    tds: [
                        {
                            th: 'Semana',
                            property: 'week',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Hora que deitou',
                            property: 'bedTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Hora que tentou dormir',
                            property: 'trySleepStart',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Hora que levantou',
                            property: 'getUpTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Tempo na cama',
                            property: 'timeInBed',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Tempo de sono',
                            property: 'sleepDuration',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Eficiência',
                            property: 'sleepEfficiency',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Latência',
                            property: 'latencyDuration',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Número de despertares',
                            property: 'nOfAwk',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'WASO',
                            property: 'WASO',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Inércia',
                            property: 'inertia',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Satisfação',
                            property: 'sleepSatisfaction',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Uso de medicamento',
                            property: 'usesMedicine',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                napsStatistics: {
                    titleText: 'Estatísticas de cochilo - Médias semanais',
                    ths: [
                        'Semana',
                        'Número de cochilos',
                        'Tempo total de cochilos',
                    ],
                    tds: [
                        {
                            th: 'Semana',
                            property: 'week',
                            hasLink: false,
                        },
                        {
                            th: 'Número de cochilos',
                            property: 'nOfNaps',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Tempo total de cochilos',
                            property: 'NBBT',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
            },
        },
        condor_admin: null,
    },
    en: {
        customer_admin: {
            pageNameText: 'Print page',
            printButtonText: 'Print',
            userInfo: {
                sleepDiaryText: 'Sleep Diary',
                reportNameText: 'Sleep Diary Report',
                titleSectionText: 'Patient information',
                patientText: 'Patient: ',
                startText: 'Start: ',
                currentWeekText: 'Current week: ',
                weekText: 'Week',
                weeksText: 'Weeks',
            },
            weeklyEvolution: {
                titleSectionText: 'Weekly evolution',
            },
            currentWeek: {
                titleSectionText: 'Diary evolution',
                medicineDescriptionTable: {
                    titleText: 'Medications',
                    ths: ['Week days', 'Medicine'],
                },
                commentsTableTexts: {
                    titleText: 'Comments',
                    ths: ['Week days', 'Comments'],
                },
            },
            tables: {
                titleSectionText: 'Tables',
                sleepStatistics: {
                    titleText: 'Sleep statistics - Weekly averages',
                    ths: [
                        'Week',
                        'Bed time',
                        'Time patient tried to sleep',
                        'Get up time',
                        'Time in bed',
                        'Sleep time',
                        'Efficiency',
                        'Latency',
                        'Number of awakenings',
                        'WASO',
                        'Inertia',
                        'Sleep satisfaction',
                        'Use of medication',
                    ],
                    tds: [
                        {
                            th: 'Week',
                            property: 'week',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Bed time',
                            property: 'bedTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Time patient tried to sleep',
                            property: 'trySleepStart',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Get up time',
                            property: 'getUpTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Time in bed',
                            property: 'timeInBed',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Sleep time',
                            property: 'sleepDuration',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Efficiency',
                            property: 'sleepEfficiency',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Latency',
                            property: 'latencyDuration',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Number of awakenings',
                            property: 'nOfAwk',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'WASO',
                            property: 'WASO',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Inertia',
                            property: 'inertia',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Sleep satisfaction',
                            property: 'sleepSatisfaction',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Use of medication',
                            property: 'usesMedicine',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                napsStatistics: {
                    titleText: 'Nap statistics - Weekly averages',
                    ths: ['Week', 'Number of naps', 'Total naps time'],
                    tds: [
                        {
                            th: 'Week',
                            property: 'week',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Number of naps',
                            property: 'nOfNaps',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Total naps time',
                            property: 'NBBT',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
            },
        },
        manager: {
            pageNameText: 'Print page',
            printButtonText: 'Print',
            userInfo: {
                sleepDiaryText: 'Sleep Diary',
                reportNameText: 'Sleep Diary Report',
                titleSectionText: 'Patient information',
                patientText: 'Patient: ',
                startText: 'Start: ',
                currentWeekText: 'Current week: ',
                weekText: 'Week',
                weeksText: 'Weeks',
            },
            weeklyEvolution: {
                titleSectionText: 'Weekly evolution',
            },
            currentWeek: {
                titleSectionText: 'Diary evolution',
                medicineDescriptionTable: {
                    titleText: 'Medications',
                    ths: ['Week days', 'Medicine'],
                },
                commentsTableTexts: {
                    titleText: 'Comments',
                    ths: ['Week days', 'Comments'],
                },
            },
            tables: {
                titleSectionText: 'Tables',
                sleepStatistics: {
                    titleText: 'Sleep statistics - Weekly averages',
                    ths: [
                        'Week',
                        'Bed time',
                        'Time patient tried to sleep',
                        'Get up time',
                        'Time in bed',
                        'Sleep time',
                        'Efficiency',
                        'Latency',
                        'Number of awakenings',
                        'WASO',
                        'Inertia',
                        'Sleep satisfaction',
                        'Use of medication',
                    ],
                    tds: [
                        {
                            th: 'Week',
                            property: 'week',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Bed time',
                            property: 'bedTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Time patient tried to sleep',
                            property: 'trySleepStart',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Get up time',
                            property: 'getUpTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Time in bed',
                            property: 'timeInBed',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Sleep time',
                            property: 'sleepDuration',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Efficiency',
                            property: 'sleepEfficiency',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Latency',
                            property: 'latencyDuration',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Number of awakenings',
                            property: 'nOfAwk',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'WASO',
                            property: 'WASO',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Inertia',
                            property: 'inertia',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Sleep satisfaction',
                            property: 'sleepSatisfaction',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Use of medication',
                            property: 'usesMedicine',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                napsStatistics: {
                    titleText: 'Nap statistics - Weekly averages',
                    ths: ['Week', 'Number of naps', 'Total naps time'],
                    tds: [
                        {
                            th: 'Week',
                            property: 'week',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Number of naps',
                            property: 'nOfNaps',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Total naps time',
                            property: 'NBBT',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
            },
        },
        condor_admin: null,
    },
};
