import { faBook, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

export default {
    pt: {
        customer_admin: {
            searchBySerialNumberText:
                'Procurar dispositivo por número de série',
            addText: 'Adicionar',
            addDeviceText: 'Adicionar dispositivo',
            noDevicesText: 'Você não possui dispositivos.',
            activeDevicesText: 'Dispositivos ativos',
            remainingDevicesQuotaText:
                'Dispositivos ativos a serem adicionados',
            successText: 'Dispositivo atualizado com sucesso!',
            activateDeviceText: 'Ativar dispositivo',
            deactivateDeviceText: 'Desativar dispositivo',
            warningDeactivateDeviceText:
                'Ao desativar um dispositivo, o dispositivo também é desvinculado do paciente.',
            attentionText: 'Atenção!',
            deactivateText: 'Desativar',
            cancelText: 'Cancelar',
            ths: [
                {
                    name: 'Número de série',
                    property: 'serial_no',
                },
                // {
                //     name: 'Marca',
                //     property: 'brand',
                // },
                {
                    name: 'Modelo',
                    property: 'model',
                },
                {
                    name: 'Usuário',
                    property: 'user_name',
                },
                {
                    name: 'Recebido em',
                    property: 'received_at',
                },
                {
                    name: 'Última atualização',
                    property: 'last_server_connection',
                },
                {
                    name: 'Ativo',
                    property: 'active',
                },
                {
                    name: 'Ações',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'Número de série',
                    property: 'serial_no',
                    hasLink: false,
                    to: '',
                },
                // {
                //     th: 'Marca',
                //     property: 'brand',
                //     hasLink: false,
                //     to: '',
                // },
                {
                    th: 'Modelo',
                    property: 'model',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Usuário',
                    property: 'user_name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Recebido em',
                    property: 'received_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Última atualização',
                    property: 'last_server_connection',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Ativo',
                    property: 'active',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Ações',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Relatório',
                    className: 'btn btn-success',
                    to: 'devices/report',
                    icon: faBook,
                },
                {
                    title: 'Editar',
                    className: 'btn btn-secondary ml-2',
                    to: 'devices/edit',
                    icon: faEdit,
                },
            ],
            batteryDischargeCurveText: 'Curva de descarga de bateria',
        },
        manager: {
            searchBySerialNumberText:
                'Procurar dispositivo por número de série',
            addText: 'Adicionar',
            addDeviceText: 'Adicionar dispositivo',
            noDevicesText: 'Você não possui dispositivos.',
            activeDevicesText: 'Dispositivos ativos',
            remainingDevicesQuotaText:
                'Dispositivos ativos a serem adicionados',
            successText: 'Dispositivo atualizado com sucesso!',
            activateDeviceText: 'Ativar dispositivo',
            deactivateDeviceText: 'Desativar dispositivo',
            warningDeactivateDeviceText:
                'Ao desativar um dispositivo, o dispositivo também é desvinculado do paciente.',
            attentionText: 'Atenção!',
            deactivateText: 'Desativar',
            cancelText: 'Cancelar',
            ths: [
                {
                    name: 'Número de série',
                    property: 'serial_no',
                },
                // {
                //     name: 'Marca',
                //     property: 'brand',
                // },
                {
                    name: 'Modelo',
                    property: 'model',
                },
                {
                    name: 'Usuário',
                    property: 'user_name',
                },
                {
                    name: 'Recebido em',
                    property: 'received_at',
                },
                {
                    name: 'Última atualização',
                    property: 'last_server_connection',
                },
                {
                    name: 'Ativo',
                    property: 'active',
                },
                {
                    name: 'Ações',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'Número de série',
                    property: 'serial_no',
                    hasLink: false,
                    to: '',
                },
                // {
                //     th: 'Marca',
                //     property: 'brand',
                //     hasLink: false,
                //     to: '',
                // },
                {
                    th: 'Modelo',
                    property: 'model',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Usuário',
                    property: 'user_name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Recebido em',
                    property: 'received_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Última atualização',
                    property: 'last_server_connection',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Ativo',
                    property: 'active',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Ações',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Relatório',
                    className: 'btn btn-success',
                    to: 'devices/report',
                    icon: faBook,
                },
                {
                    title: 'Editar',
                    className: 'btn btn-secondary ml-2',
                    to: 'devices/edit',
                    icon: faEdit,
                },
            ],
            batteryDischargeCurveText: 'Curva de descarga de bateria',
        },
        condor_admin: {
            searchBySerialNumberText:
                'Procurar dispositivo por número de série',
            addText: 'Adicionar',
            addDeviceText: 'Adicionar dispositivo',
            noDevicesText: 'Você não possui dispositivos.',
            activeDevicesText: 'Dispositivos ativos',
            remainingDevicesQuotaText:
                'Dispositivos ativos a serem adicionados',
            successText: 'Dispositivo atualizado com sucesso!',
            activateDeviceText: 'Ativar dispositivo',
            deactivateDeviceText: 'Desativar dispositivo',
            warningDeactivateDeviceText:
                'Ao desativar um dispositivo, o dispositivo também é desvinculado do paciente.',
            attentionText: 'Atenção!',
            deactivateText: 'Desativar',
            cancelText: 'Cancelar',
            ths: [
                {
                    name: 'Empresa',
                    property: 'company_name',
                },
                {
                    name: 'Número de série',
                    property: 'serial_no',
                },
                // {
                //     name: 'Marca',
                //     property: 'brand',
                // },
                {
                    name: 'Modelo',
                    property: 'model',
                },
                {
                    name: 'Usuário',
                    property: 'user_name',
                },
                {
                    name: 'Recebido em',
                    property: 'received_at',
                },
                {
                    name: 'Última atualização',
                    property: 'last_server_connection',
                },
                {
                    name: 'Ativo',
                    property: 'active',
                },
                {
                    name: 'Ações',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'Empresa',
                    property: 'company_name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Número de série',
                    property: 'serial_no',
                    hasLink: false,
                    to: '',
                },
                // {
                //     th: 'Marca',
                //     property: 'brand',
                //     hasLink: false,
                //     to: '',
                // },
                {
                    th: 'Modelo',
                    property: 'model',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Usuário',
                    property: 'user_name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Recebido em',
                    property: 'received_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Última atualização',
                    property: 'last_server_connection',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Ativo',
                    property: 'active',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Ações',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Relatório',
                    className: 'btn btn-success',
                    to: 'devices/report',
                    icon: faBook,
                },
                {
                    title: 'Editar',
                    className: 'btn btn-secondary ml-2',
                    to: 'devices/edit',
                    icon: faEdit,
                },
                {
                    title: 'Excluir',
                    className: 'btn btn-danger ml-2',
                    to: 'devices/delete',
                    icon: faTrash,
                },
            ],
            importDevicesText: 'Importar dispositivos',
            batteryDischargeCurveText: 'Curva de descarga de bateria',
        },
    },
    en: {
        customer_admin: {
            searchBySerialNumberText: 'Search device by serial number',
            addText: 'Add',
            addDeviceText: 'Add device',
            noDevicesText: 'You do not have devices.',
            activeDevicesText: 'Active devices',
            remainingDevicesQuotaText: 'Remaining active devices quota',
            successText: 'Device successfully updated!',
            activateDeviceText: 'Activate device',
            deactivateDeviceText: 'Deactivate device',
            warningDeactivateDeviceText:
                'When deactivating a device, the device is also unbind from the patient.',
            attentionText: 'Attention!',
            deactivateText: 'Deactivate',
            cancelText: 'Cancel',
            ths: [
                {
                    name: 'Serial number',
                    property: 'serial_no',
                },
                // {
                //     name: 'Brand',
                //     property: 'brand',
                // },
                {
                    name: 'Model',
                    property: 'model',
                },
                {
                    name: 'User',
                    property: 'user_name',
                },
                {
                    name: 'Received at',
                    property: 'received_at',
                },
                {
                    name: 'Last update',
                    property: 'last_server_connection',
                },
                {
                    name: 'Active',
                    property: 'active',
                },
                {
                    name: 'Actions',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'Serial number',
                    property: 'serial_no',
                    hasLink: false,
                    to: '',
                },
                // {
                //     th: 'Brand',
                //     property: 'brand',
                //     hasLink: false,
                //     to: '',
                // },
                {
                    th: 'Model',
                    property: 'model',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'User',
                    property: 'user_name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Received at',
                    property: 'received_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Last update',
                    property: 'last_server_connection',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Active',
                    property: 'active',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Actions',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Report',
                    className: 'btn btn-success',
                    to: 'devices/report',
                    icon: faBook,
                },
                {
                    title: 'Edit',
                    className: 'btn btn-secondary ml-2',
                    to: 'devices/edit',
                    icon: faEdit,
                },
            ],
            batteryDischargeCurveText: 'Battery discharge curve',
        },
        manager: {
            searchBySerialNumberText: 'Search device by serial number',
            addText: 'Add',
            addDeviceText: 'Add device',
            noDevicesText: 'You do not have devices.',
            activeDevicesText: 'Active devices',
            remainingDevicesQuotaText: 'Remaining active devices quota',
            successText: 'Device successfully updated!',
            activateDeviceText: 'Activate device',
            deactivateDeviceText: 'Deactivate device',
            warningDeactivateDeviceText:
                'When deactivating a device, the device is also unbind from the patient.',
            attentionText: 'Attention!',
            deactivateText: 'Deactivate',
            cancelText: 'Cancel',
            ths: [
                {
                    name: 'Serial number',
                    property: 'serial_no',
                },
                // {
                //     name: 'Brand',
                //     property: 'brand',
                // },
                {
                    name: 'Model',
                    property: 'model',
                },
                {
                    name: 'User',
                    property: 'user_name',
                },
                {
                    name: 'Received at',
                    property: 'received_at',
                },
                {
                    name: 'Last update',
                    property: 'last_server_connection',
                },
                {
                    name: 'Active',
                    property: 'active',
                },
                {
                    name: 'Actions',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'Serial number',
                    property: 'serial_no',
                    hasLink: false,
                    to: '',
                },
                // {
                //     th: 'Brand',
                //     property: 'brand',
                //     hasLink: false,
                //     to: '',
                // },
                {
                    th: 'Model',
                    property: 'model',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'User',
                    property: 'user_name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Received at',
                    property: 'received_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Last update',
                    property: 'last_server_connection',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Active',
                    property: 'active',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Actions',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Report',
                    className: 'btn btn-success',
                    to: 'devices/report',
                    icon: faBook,
                },
                {
                    title: 'Edit',
                    className: 'btn btn-secondary ml-2',
                    to: 'devices/edit',
                    icon: faEdit,
                },
            ],
            batteryDischargeCurveText: 'Battery discharge curve',
        },
        condor_admin: {
            searchBySerialNumberText: 'Search device by serial number',
            addText: 'Add',
            addDeviceText: 'Add device',
            noDevicesText: 'You do not have devices.',
            activeDevicesText: 'Active devices',
            remainingDevicesQuotaText: 'Remaining active devices quota',
            successText: 'Device successfully updated!',
            activateDeviceText: 'Activate device',
            deactivateDeviceText: 'Deactivate device',
            warningDeactivateDeviceText:
                'When deactivating a device, the device is also unbind from the patient.',
            attentionText: 'Attention!',
            deactivateText: 'Deactivate',
            cancelText: 'Cancel',
            ths: [
                {
                    name: 'Company',
                    property: 'company_name',
                },
                {
                    name: 'Serial number',
                    property: 'serial_no',
                },
                // {
                //     name: 'Brand',
                //     property: 'brand',
                // },
                {
                    name: 'Model',
                    property: 'model',
                },
                {
                    name: 'User',
                    property: 'user_name',
                },
                {
                    name: 'Received at',
                    property: 'received_at',
                },
                {
                    name: 'Last update',
                    property: 'last_server_connection',
                },
                {
                    name: 'Active',
                    property: 'active',
                },
                {
                    name: 'Actions',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'Company',
                    property: 'company_name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Serial number',
                    property: 'serial_no',
                    hasLink: false,
                    to: '',
                },
                // {
                //     th: 'Brand',
                //     property: 'brand',
                //     hasLink: false,
                //     to: '',
                // },
                {
                    th: 'Model',
                    property: 'model',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'User',
                    property: 'user_name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Received at',
                    property: 'received_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Last update',
                    property: 'last_server_connection',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Active',
                    property: 'active',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Actions',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Report',
                    className: 'btn btn-success',
                    to: 'devices/report',
                    icon: faBook,
                },
                {
                    title: 'Edit',
                    className: 'btn btn-secondary ml-2',
                    to: 'devices/edit',
                    icon: faEdit,
                },
                {
                    title: 'Delete',
                    className: 'btn btn-danger ml-2',
                    to: 'devices/delete',
                    icon: faTrash,
                },
            ],
            importDevicesText: 'Import devices',
            batteryDischargeCurveText: 'Battery discharge curve',
        },
    },
};
