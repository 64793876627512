import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';

export default {
    pt: {
        customer_admin: null,
        manager: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'lang',
                        label: 'Idioma <b style="color: darkred">*</b>',
                        options: [
                            { value: 'pt', label: 'Português' },
                            { value: 'en', label: 'Inglês' },
                            { value: 'ge', label: 'Alemão' },
                        ],
                        className: 'lang-sheperd',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            successText: 'Credenciais enviadas com sucesso!',
            sendText: 'Enviar',
            changeEmailText: 'Modificar e-mail',
            cancelText: 'Cancelar',
            langOptions: [
                { value: 'pt', label: 'Português' },
                { value: 'en', label: 'Inglês' },
                { value: 'ge', label: 'Alemão' },
            ],
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'lang',
                        label: 'Idioma <b style="color: darkred">*</b>',
                        options: [
                            { value: 'pt', label: 'Portuguese' },
                            { value: 'en', label: 'English' },
                            { value: 'ge', label: 'German' },
                        ],
                        className: 'lang-sheperd',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            successText: 'Credentials successfully submitted!',
            sendText: 'Submit',
            changeEmailText: 'Change e-mail',
            cancelText: 'Cancel',
            langOptions: [
                { value: 'pt', label: 'Portuguese' },
                { value: 'en', label: 'English' },
                { value: 'ge', label: 'German' },
            ],
        },
        condor_admin: null,
    },
};
