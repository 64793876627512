export default {
    pt: {
        customer_admin: null,
        manager: {
            successText: 'Configurações atualizadas com sucesso!',
            saveText: 'Salvar',
            cancelText: 'Cancelar',
            yesText: 'Sim',
            noText: 'Não',
            defaultLanguageOptions: {
                pt: { label: 'Português', value: 'pt' },
                en: { label: 'Inglês', value: 'en' },
            },
            companySettingsText: 'Configurações da empresa',
            editText: 'Editar',
            companyNameText: 'Nome da empresa',
            sharePatientsText: 'Compartilha pacientes?',
            saveEmailText: 'Salvar email?',
            defaultLanguageText: 'Idioma padrão',
            portugueseText: 'Português',
            englishText: 'Inglês',
            minimumPasswordLengthText: 'Tamanho mínimo de senha',
            hasSpecialCharactersInPasswordText:
                'É obrigatório ter ao menos um caractere especial na senha?',
            isTwoFactorAuthenticationEnabledText:
                'Autenticação de dois fatores habilitada?',
            twoFactorAuthenticationInfoText:
                'A autenticação de dois fatores consiste em enviar um código por email para completar o login.',
            twoFactorAuthenticationText: 'Autenticação de dois fatores',
            statusText: 'Status',
            enabledText: 'Habilitado',
            disabledText: 'Desabilitado',
            enableText: 'Habilitar',
            disableText: 'Desabilitar',
            disableTwoFactorAuthenticationText:
                'Desabilitar autenticação de 2 fatores',
            sureToDisableTwoFactorAuthenticationText: `Tem certeza que deseja desabilitar a autenticação de 2
                fatores para todos os usuários?`,
            enable2faSuccessText:
                'Autenticação de 2 fatores desabilitada com sucesso!',
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            successText: 'Settings successfully updated!',
            saveText: 'Save',
            cancelText: 'Cancel',
            yesText: 'Yes',
            noText: 'No',
            defaultLanguageOptions: {
                pt: { label: 'Portuguese', value: 'pt' },
                en: { label: 'English', value: 'en' },
            },
            companySettingsText: 'Company settings',
            editText: 'Edit',
            companyNameText: 'Company name',
            sharePatientsText: 'Share patients?',
            saveEmailText: 'Save email?',
            defaultLanguageText: 'Default language',
            portugueseText: 'Portuguese',
            englishText: 'English',
            minimumPasswordLengthText: 'Minimum password length',
            hasSpecialCharactersInPasswordText:
                'Is it mandatory to have at least one special character in the password?',
            isTwoFactorAuthenticationEnabledText:
                'Two-factor authentication enabled?',
            twoFactorAuthenticationInfoText:
                'Two-factor authentication consists of sending a code by email to complete the login.',
            twoFactorAuthenticationText: 'Two-factor authentication',
            statusText: 'Status',
            enabledText: 'Enabled',
            disabledText: 'Disabled',
            enableText: 'Enable',
            disableText: 'Disable',
            disableTwoFactorAuthenticationText:
                'Disable 2-factor authentication',
            sureToDisableTwoFactorAuthenticationText: `Are you sure you want to disable 2-way authentication?
                factors for all users?`,
            enable2faSuccessText:
                '2-factor authentication successfully disabled!',
        },
        condor_admin: null,
    },
};
