import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: {
            schemaWithTrials: Yup.object().shape({
                name: Yup.string().required('O nome é obrigatório'),
                secondary_id: Yup.string(),
                email: Yup.string()
                    .email('Formato inválido')
                    .required('O e-Mail é obrigatório'),
                active: Yup.bool().required(
                    'O estado do usuário é obrigatório'
                ),
                trialStart: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                trialEnd: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                diary_id: Yup.string(),
                lang: Yup.string().required('O idioma é obrigatório'),
                devices: Yup.array().of(
                    Yup.object().shape({
                        device_id: Yup.string().required(
                            'O número de série é obrigatório'
                        ),
                        body_part: Yup.string().required(
                            'A parte do corpo é obrigatória'
                        ),
                        received_at: Yup.date()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('A data de recebimento é obrigatória'),
                    })
                ),
            }),
        },
        manager: {
            schemaWithTrials: Yup.object().shape({
                name: Yup.string().required('O nome é obrigatório'),
                secondary_id: Yup.string(),
                email: Yup.string()
                    .email('Formato inválido')
                    .required('O e-Mail é obrigatório'),
                active: Yup.bool().required(
                    'O estado do usuário é obrigatório'
                ),
                trialStart: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                trialEnd: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                diary_id: Yup.string(),
                lang: Yup.string().required('O idioma é obrigatório'),
                devices: Yup.array().of(
                    Yup.object().shape({
                        device_id: Yup.string().required(
                            'O número de série é obrigatório'
                        ),
                        body_part: Yup.string().required(
                            'A parte do corpo é obrigatória'
                        ),
                        received_at: Yup.date()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('A data de recebimento é obrigatória'),
                    })
                ),
            }),
            schema: Yup.object().shape({
                name: Yup.string().required('O nome é obrigatório'),
                secondary_id: Yup.string(),
                email: Yup.string()
                    .email('Formato inválido')
                    .required('O e-Mail é obrigatório'),
                active: Yup.bool().required(
                    'O estado do usuário é obrigatório'
                ),
                diary_id: Yup.string(),
                lang: Yup.string().required('O idioma é obrigatório'),
            }),
        },
        condor_admin: {
            schema: Yup.object().shape({
                name: Yup.string().required('O nome é obrigatório'),
                company_id: Yup.string()
                    .ensure()
                    .required('É obrigatório selecionar uma empresa'),
                secondary_id: Yup.string(),
                email: Yup.string()
                    .email('Formato inválido')
                    .required('O e-Mail é obrigatório'),
                role_id: Yup.string().required(
                    'O tipo de usuário é obrigatório'
                ),
                active: Yup.bool().required(
                    'O estado do usuário é obrigatório'
                ),
                lang: Yup.string().required('O idioma é obrigatório'),
            }),
        },
    },
    en: {
        customer_admin: {
            schemaWithTrials: Yup.object().shape({
                name: Yup.string().required('Name is required'),
                secondary_id: Yup.string(),
                email: Yup.string()
                    .email('Invalid format')
                    .required('e-Mail is required'),
                active: Yup.bool().required('User state is required'),
                trialStart: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                trialEnd: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                diary_id: Yup.string(),
                lang: Yup.string().required('Language is required'),
                devices: Yup.array().of(
                    Yup.object().shape({
                        device_id: Yup.string().required(
                            'Serial number is required'
                        ),
                        body_part: Yup.string().required(
                            'Body part is required'
                        ),
                        received_at: Yup.date()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('The receipt date is'),
                    })
                ),
            }),
        },
        manager: {
            schemaWithTrials: Yup.object().shape({
                name: Yup.string().required('Name is required'),
                secondary_id: Yup.string(),
                email: Yup.string()
                    .email('Invalid format')
                    .required('e-Mail is required'),
                active: Yup.bool().required('User state is required'),
                trialStart: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                trialEnd: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                diary_id: Yup.string(),
                lang: Yup.string().required('Language is required'),
                devices: Yup.array().of(
                    Yup.object().shape({
                        device_id: Yup.string().required(
                            'Serial number is required'
                        ),
                        body_part: Yup.string().required(
                            'Body part is required'
                        ),
                        received_at: Yup.date()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('The receipt date is'),
                    })
                ),
            }),
            schema: Yup.object().shape({
                name: Yup.string().required('Name is required'),
                secondary_id: Yup.string(),
                email: Yup.string()
                    .email('Invalid format')
                    .required('e-Mail is required'),
                active: Yup.bool().required('User state is required'),
                diary_id: Yup.string(),
                lang: Yup.string().required('Language is required'),
            }),
        },
        condor_admin: {
            schema: Yup.object().shape({
                name: Yup.string().required('Name is required'),
                company_id: Yup.string()
                    .ensure()
                    .required('It is required to select a company'),
                secondary_id: Yup.string(),
                email: Yup.string()
                    .email('Invalid format')
                    .required('e-Mail is required'),
                role_id: Yup.string().required('User type is required'),
                active: Yup.bool().required('User state is required'),
                lang: Yup.string().required('Language is required'),
            }),
        },
    },
};
