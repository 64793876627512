import {
    FaUser,
    FaChartLine,
    FaUserTie,
    FaIndustry,
    FaClipboard,
    FaUserCircle,
    FaBook,
    FaGift,
} from 'react-icons/fa';
import { BsSmartwatch } from 'react-icons/bs';

export default {
    pt: {
        customer_admin: {
            header: {
                welcomeText: 'Bem vindo(a)',
                imgAlt: 'Ícone de usuário',
                dropDownMenu: [
                    {
                        label: 'Meu Perfil',
                        link: '/account/my_profile',
                    },
                    {
                        label: 'Sair',
                        link: '',
                    },
                ],
                sleepDiaryTrialsubscriptionExpiresInText:
                    'Assinatura Trial do Diário de sono expira em',
                lumusTrialSubscriptionExpiresInText:
                    'Assinatura Trial do Lumus expira em',
                daysText: 'dias',
            },
            sidebar: {
                sidebarItems: [
                    {
                        label: 'Dashboard',
                        link: '/dashboard',
                        icon: FaChartLine,
                    },
                    {
                        label: 'Pacientes',
                        link: '/users',
                        icon: FaUser,
                    },
                    {
                        label: 'Dispositivos',
                        link: '/devices',
                        icon: BsSmartwatch,
                    },
                    {
                        label: 'Diários Customizados',
                        link: '/custom-diaries',
                        icon: FaBook,
                    },
                ],
                diariesText: 'Diários',
                customText: 'Customizados',
            },
            content: {
                crumbs: {
                    exception: [],
                    dashboard: [{ name: 'Dashboard', link: '' }],
                    myProfile: [{ name: 'Meu Perfil', link: '' }],
                    users: [{ name: 'Pacientes', link: '/users' }],
                    addUser: [
                        { name: 'Pacientes', link: '/users' },
                        { name: 'Adicionar', link: '/users/add' },
                    ],
                    editUser: [
                        { name: 'Pacientes', link: '/users' },
                        { name: 'Editar', link: '/users/edit' },
                    ],
                    deleteUser: [
                        { name: 'Pacientes', link: '/users' },
                        { name: 'Excluir', link: '/users/delete' },
                    ],
                    managers: [],
                    userCredentials: [
                        { name: 'Pacientes', link: '/users' },
                        { name: 'Credenciais', link: '/users/credentials' },
                    ],
                    userReport: [
                        { name: 'Pacientes', link: '/users' },
                        { name: 'Relatório', link: '/users/report' },
                    ],
                    print: [
                        { name: 'Pacientes', link: '/users' },
                        { name: 'Relatório', link: '/users/report' },
                        { name: 'Impressão', link: '/users/report/print' },
                    ],
                    importUser: [
                        { name: 'Pacientes', link: '/users' },
                        { name: 'Importar pacientes', link: '/users/import' },
                    ],
                    companies: [{ name: 'Empresas', link: '/companies' }],
                    addCompany: [
                        { name: 'Empresas', link: '/companies' },
                        { name: 'Adicionar', link: '/companies/add' },
                    ],
                    editCompany: [
                        { name: 'Empresas', link: '/companies' },
                        { name: 'Editar', link: '/companies/edit' },
                    ],
                    deleteCompany: [
                        { name: 'Empresas', link: '/companies' },
                        { name: 'Excluir', link: '/companies/delete' },
                    ],
                    plans: [{ name: 'Planos', link: '/plans' }],
                    addPlan: [
                        { name: 'Planos', link: '/plans' },
                        { name: 'Adicionar', link: '/plans/add' },
                    ],
                    editPlan: [
                        { name: 'Planos', link: '/plans' },
                        { name: 'Editar', link: '/plans/edit' },
                    ],
                    deletePlan: [
                        { name: 'Planos', link: '/plans' },
                        { name: 'Excluir', link: '/plans/delete' },
                    ],
                    myAccount: [{ name: 'Assinatura', link: '' }],
                    updatePaymentMethod: [
                        { name: 'Assinatura', link: '/account/my_account' },
                        {
                            name: 'Alterar cartão',
                            link: '/account/my_account/update_payment_method',
                        },
                    ],
                    changePlan: [
                        { name: 'Assinatura', link: '/account/my_account' },
                        {
                            name: 'Mudar plano',
                            link: '/account/my_account/change_plan',
                        },
                    ],
                    chooseSubscription: [
                        { name: 'Assinatura', link: '/account/my_account' },
                        {
                            name: 'Mudar plano',
                            link: '/account/my_account/change_plan',
                        },
                        {
                            name: 'Escolher assinatura',
                            link: '/account/my_account/change_plan/choose_subscription',
                        },
                    ],
                    reviewAndPayment: [
                        { name: 'Assinatura', link: '/account/my_account' },
                        {
                            name: 'Mudar plano',
                            link: '/account/my_account/change_plan',
                        },
                        {
                            name: 'Escolher assinatura',
                            link: '/account/my_account/change_plan/choose_subscription',
                        },
                        {
                            name: 'Revisão e pagamento',
                            link: '/account/my_account/change_plan/choose_subscription/review_and_payment',
                        },
                    ],
                    settings: [{ name: 'Configurações', link: '' }],
                    diaries: [{ name: 'Diários Customizados', link: '' }],
                    addDiary: [
                        {
                            name: 'Diários Customizados',
                            link: '/custom-diaries',
                        },
                        { name: 'Adicionar', link: '/custom-diaries/add' },
                    ],
                    editDiary: [
                        {
                            name: 'Diários Customizados',
                            link: '/custom-diaries',
                        },
                        { name: 'Editar', link: '/custom-diaries/edit' },
                    ],
                    deleteDiary: [
                        {
                            name: 'Diários Customizados',
                            link: '/custom-diaries',
                        },
                        { name: 'Excluir', link: '/custom-diaries/delete' },
                    ],
                    devices: [{ name: 'Dispositivos', link: '/devices' }],
                    addDevice: [
                        { name: 'Dispositivos', link: '/devices' },
                        { name: 'Adicionar', link: '/devices/add' },
                    ],
                    editDevice: [
                        { name: 'Dispositivos', link: '/devices' },
                        { name: 'Editar', link: '/devices/edit' },
                    ],
                    deleteDevice: [
                        { name: 'Dispositivos', link: '/devices' },
                        { name: 'Excluir', link: '/devices/delete' },
                    ],
                    deviceReport: [
                        { name: 'Dispositivos', link: '/devices' },
                        { name: 'Relatório', link: '/devices/report' },
                    ],
                    batteryDischarge: [
                        { name: 'Dispositivos', link: '/devices' },
                        {
                            name: 'Descarga de bateria',
                            link: '/devices/battery_discharge',
                        },
                    ],
                },
            },
        },
        manager: {
            header: {
                welcomeText: 'Bem vindo(a)',
                imgAlt: 'Ícone de usuário',
                dropDownMenu: [
                    {
                        label: 'Meu Perfil',
                        link: '/account/my_profile',
                    },
                    {
                        label: 'Assinatura',
                        link: '/account/my_account',
                    },
                    {
                        label: 'Configurações',
                        link: '/account/settings',
                    },
                    {
                        label: 'Sair',
                        link: '',
                    },
                ],
                sleepDiaryTrialsubscriptionExpiresInText:
                    'Assinatura Trial do Diário de sono expira em',
                lumusTrialSubscriptionExpiresInText:
                    'Assinatura Trial do Lumus expira em',
                daysText: 'dias',
            },
            sidebar: {
                sidebarItems: [
                    {
                        label: 'Dashboard',
                        link: '/dashboard',
                        icon: FaChartLine,
                    },
                    {
                        label: 'Pacientes',
                        link: '/users',
                        icon: FaUser,
                    },
                    {
                        label: 'Gestores',
                        link: '/managers',
                        icon: FaUserTie,
                    },
                    {
                        label: 'Assinatura',
                        link: '/account/my_account',
                        icon: FaUserCircle,
                    },
                    {
                        label: 'Dispositivos',
                        link: '/devices',
                        icon: BsSmartwatch,
                    },
                    {
                        label: 'Diários Customizados',
                        link: '/custom-diaries',
                        icon: FaBook,
                    },
                ],
                diariesText: 'Diários',
                customText: 'Customizados',
            },
            content: {
                crumbs: {
                    exception: [],
                    dashboard: [{ name: 'Dashboard', link: '' }],
                    myProfile: [{ name: 'Meu Perfil', link: '' }],
                    myAccount: [{ name: 'Assinatura', link: '' }],
                    updatePaymentMethod: [
                        { name: 'Assinatura', link: '/account/my_account' },
                        {
                            name: 'Alterar cartão',
                            link: '/account/my_account/update_payment_method',
                        },
                    ],
                    signTrialSubscription: [
                        { name: 'Assinatura', link: '/account/my_account' },
                        {
                            name: 'Assinar plano Trial',
                            link: '/account/my_account/sign_trial_subscription',
                        },
                    ],
                    changePlan: [
                        { name: 'Assinatura', link: '/account/my_account' },
                        {
                            name: 'Mudar plano',
                            link: '/account/my_account/change_plan',
                        },
                    ],
                    chooseSubscription: [
                        { name: 'Assinatura', link: '/account/my_account' },
                        {
                            name: 'Mudar plano',
                            link: '/account/my_account/change_plan',
                        },
                        {
                            name: 'Escolher assinatura',
                            link: '/account/my_account/change_plan/choose_subscription',
                        },
                    ],
                    reviewAndPayment: [
                        { name: 'Assinatura', link: '/account/my_account' },
                        {
                            name: 'Mudar plano',
                            link: '/account/my_account/change_plan',
                        },
                        {
                            name: 'Escolher assinatura',
                            link: '/account/my_account/change_plan/choose_subscription',
                        },
                        {
                            name: 'Revisão e pagamento',
                            link: '/account/my_account/change_plan/choose_subscription/review_and_payment',
                        },
                    ],
                    settings: [{ name: 'Configurações', link: '' }],
                    setupTwoFactorAuthentication: [
                        { name: 'Configurações', link: '/account/settings' },
                        {
                            name: 'Configurar autenticação de 2 fatores',
                            link: '/account/settings/setup_2fa',
                        },
                    ],
                    users: [{ name: 'Pacientes', link: '/users' }],
                    addUser: [
                        { name: 'Pacientes', link: '/users' },
                        { name: 'Adicionar', link: '/users/add' },
                    ],
                    editUser: [
                        { name: 'Pacientes', link: '/users' },
                        { name: 'Editar', link: '/users/edit' },
                    ],
                    deleteUser: [
                        { name: 'Pacientes', link: '/users' },
                        { name: 'Excluir', link: '/users/delete' },
                    ],
                    userCredentials: [
                        { name: 'Pacientes', link: '/users' },
                        { name: 'Credenciais', link: '/users/credentials' },
                    ],
                    userReport: [
                        { name: 'Pacientes', link: '/users' },
                        { name: 'Relatório', link: '/users/report' },
                    ],
                    importUser: [
                        { name: 'Pacientes', link: '/users' },
                        { name: 'Importar pacientes', link: '/users/import' },
                    ],
                    managers: [{ name: 'Gestores', link: '/managers' }],
                    addManager: [
                        { name: 'Gestores', link: '/managers' },
                        { name: 'Adicionar', link: '/managers/add' },
                    ],
                    editManager: [
                        { name: 'Gestores', link: '/managers' },
                        { name: 'Editar', link: '/managers/edit' },
                    ],
                    deleteManager: [
                        { name: 'Gestores', link: '/managers' },
                        { name: 'Excluir', link: '/managers/delete' },
                    ],
                    managerCredentials: [
                        { name: 'Gestores', link: '/managers' },
                        { name: 'Credenciais', link: '/managers/credentials' },
                    ],
                    diaries: [{ name: 'Diários Customizados', link: '' }],
                    addDiary: [
                        {
                            name: 'Diários Customizados',
                            link: '/custom-diaries',
                        },
                        { name: 'Adicionar', link: '/custom-diaries/add' },
                    ],
                    editDiary: [
                        {
                            name: 'Diários Customizados',
                            link: '/custom-diaries',
                        },
                        { name: 'Editar', link: '/custom-diaries/edit' },
                    ],
                    deleteDiary: [
                        {
                            name: 'Diários Customizados',
                            link: '/custom-diaries',
                        },
                        { name: 'Excluir', link: '/custom-diaries/delete' },
                    ],
                    devices: [{ name: 'Dispositivos', link: '/devices' }],
                    addDevice: [
                        { name: 'Dispositivos', link: '/devices' },
                        { name: 'Adicionar', link: '/devices/add' },
                    ],
                    editDevice: [
                        { name: 'Dispositivos', link: '/devices' },
                        { name: 'Editar', link: '/devices/edit' },
                    ],
                    deleteDevice: [
                        { name: 'Dispositivos', link: '/devices' },
                        { name: 'Excluir', link: '/devices/delete' },
                    ],
                    deviceReport: [
                        { name: 'Dispositivos', link: '/devices' },
                        { name: 'Relatório', link: '/devices/report' },
                    ],
                    batteryDischarge: [
                        { name: 'Dispositivos', link: '/devices' },
                        {
                            name: 'Descarga de bateria',
                            link: '/devices/battery_discharge',
                        },
                    ],
                },
            },
        },
        condor_admin: {
            header: {
                welcomeText: 'Bem vindo(a)',
                imgAlt: 'Ícone de usuário',
                dropDownMenu: [
                    {
                        label: 'Meu Perfil',
                        link: '/account/my_profile',
                    },
                    {
                        label: 'Sair',
                        link: '',
                    },
                ],
                sleepDiaryTrialsubscriptionExpiresInText:
                    'Assinatura Trial do Diário de sono expira em',
                lumusTrialSubscriptionExpiresInText:
                    'Assinatura Trial do Lumus expira em',
                daysText: 'dias',
            },
            sidebar: {
                sidebarItems: [
                    {
                        label: 'Dashboard',
                        link: '/dashboard',
                        icon: FaChartLine,
                    },
                    {
                        label: 'Usuários',
                        link: '/users',
                        icon: FaUser,
                    },
                    {
                        label: 'Empresas',
                        link: '/companies',
                        icon: FaIndustry,
                    },
                    {
                        label: 'Planos',
                        link: '/plans',
                        icon: FaClipboard,
                    },
                    {
                        label: 'Dispositivos',
                        link: '/devices',
                        icon: BsSmartwatch,
                    },
                    {
                        label: 'Cupons',
                        link: '/coupons',
                        icon: FaGift,
                    },
                ],
            },
            content: {
                crumbs: {
                    exception: [],
                    dashboard: [{ name: 'Dashboard', link: '' }],
                    myProfile: [{ name: 'Meu Perfil', link: '' }],
                    users: [{ name: 'Usuários', link: '/users' }],
                    addUser: [
                        { name: 'Usuários', link: '/users' },
                        { name: 'Adicionar', link: '/users/add' },
                    ],
                    editUser: [
                        { name: 'Usuários', link: '/users' },
                        { name: 'Editar', link: '/users/edit' },
                    ],
                    deleteUser: [
                        { name: 'Usuários', link: '/users' },
                        { name: 'Excluir', link: '/users/delete' },
                    ],
                    managers: [],
                    userCredentials: [
                        { name: 'Usuários', link: '/users' },
                        { name: 'Credenciais', link: '/users/credentials' },
                    ],
                    companies: [{ name: 'Empresas', link: '/companies' }],
                    addCompany: [
                        { name: 'Empresas', link: '/companies' },
                        { name: 'Adicionar', link: '/companies/add' },
                    ],
                    editCompany: [
                        { name: 'Empresas', link: '/companies' },
                        { name: 'Editar', link: '/companies/edit' },
                    ],
                    deleteCompany: [
                        { name: 'Empresas', link: '/companies' },
                        { name: 'Excluir', link: '/companies/delete' },
                    ],
                    companySubscription: [
                        { name: 'Empresas', link: '/companies' },
                        { name: 'Assinatura', link: '/companies/subscription' },
                    ],
                    plans: [{ name: 'Planos', link: '/plans' }],
                    addPlan: [
                        { name: 'Planos', link: '/plans' },
                        { name: 'Adicionar', link: '/plans/add' },
                    ],
                    editPlan: [
                        { name: 'Planos', link: '/plans' },
                        { name: 'Editar', link: '/plans/edit' },
                    ],
                    deletePlan: [
                        { name: 'Planos', link: '/plans' },
                        { name: 'Excluir', link: '/plans/delete' },
                    ],
                    coupons: [{ name: 'Cupons', link: '/coupons' }],
                    addCoupon: [
                        { name: 'Cupons', link: '/coupons' },
                        { name: 'Adicionar', link: '/coupons/add' },
                    ],
                    editCoupon: [
                        { name: 'Cupons', link: '/coupons' },
                        { name: 'Editar', link: '/coupons/edit' },
                    ],
                    deleteCoupon: [
                        { name: 'Cupons', link: '/coupons' },
                        { name: 'Editar', link: '/coupons/delete' },
                    ],
                    settings: [{ name: 'Configurações', link: '' }],
                    devices: [{ name: 'Dispositivos', link: '/devices' }],
                    addDevice: [
                        { name: 'Dispositivos', link: '/devices' },
                        { name: 'Adicionar', link: '/devices/add' },
                    ],
                    editDevice: [
                        { name: 'Dispositivos', link: '/devices' },
                        { name: 'Editar', link: '/devices/edit' },
                    ],
                    deleteDevice: [
                        { name: 'Dispositivos', link: '/devices' },
                        { name: 'Excluir', link: '/devices/delete' },
                    ],
                    deviceReport: [
                        { name: 'Dispositivos', link: '/devices' },
                        { name: 'Relatório', link: '/devices/report' },
                    ],
                    importDevices: [
                        { name: 'Dispositivos', link: '/devices' },
                        {
                            name: 'Importar dispositivos',
                            link: '/devices/import',
                        },
                    ],
                    batteryDischarge: [
                        { name: 'Dispositivos', link: '/devices' },
                        {
                            name: 'Descarga de bateria',
                            link: '/devices/battery_discharge',
                        },
                    ],
                },
            },
        },
    },
    en: {
        customer_admin: {
            header: {
                welcomeText: 'Welcome',
                imgAlt: 'User icon',
                dropDownMenu: [
                    {
                        label: 'My Profile',
                        link: '/account/my_profile',
                    },
                    {
                        label: 'Logout',
                        link: '',
                    },
                ],
                sleepDiaryTrialsubscriptionExpiresInText:
                    'Sleep Diary Trial subscription expires in',
                lumusTrialSubscriptionExpiresInText:
                    'Lumus Trial subscription expires in',
                daysText: 'days',
            },
            sidebar: {
                sidebarItems: [
                    {
                        label: 'Dashboard',
                        link: '/dashboard',
                        icon: FaChartLine,
                    },
                    {
                        label: 'Patients',
                        link: '/users',
                        icon: FaUser,
                    },
                    {
                        label: 'Devices',
                        link: '/devices',
                        icon: BsSmartwatch,
                    },
                    {
                        label: 'Custom Diaries',
                        link: '/custom-diaries',
                        icon: FaBook,
                    },
                ],
                diariesText: 'Diaries',
                customText: 'Custom',
            },
            content: {
                crumbs: {
                    exception: [],
                    dashboard: [{ name: 'Dashboard', link: '' }],
                    myProfile: [{ name: 'My Profile', link: '' }],
                    users: [{ name: 'Patients', link: '/users' }],
                    addUser: [
                        { name: 'Patients', link: '/users' },
                        { name: 'Add', link: '/users/add' },
                    ],
                    editUser: [
                        { name: 'Patients', link: '/users' },
                        { name: 'Edit', link: '/users/edit' },
                    ],
                    deleteUser: [
                        { name: 'Patients', link: '/users' },
                        { name: 'Delete', link: '/users/delete' },
                    ],
                    managers: [],
                    companies: [{ name: 'Companies', link: '/companies' }],
                    deleteCompany: [
                        { name: 'Companies', link: '/companies' },
                        { name: 'Delete', link: '/companies/delete' },
                    ],
                    editCompany: [
                        { name: 'Companies', link: '/companies' },
                        { name: 'Edit', link: '/companies/edit' },
                    ],
                    addCompany: [
                        { name: 'Companies', link: '/companies' },
                        { name: 'Add', link: '/companies/add' },
                    ],
                    userCredentials: [
                        { name: 'Users', link: '/users' },
                        { name: 'Credentials', link: '/users/credentials' },
                    ],
                    userReport: [
                        { name: 'Patients', link: '/users' },
                        { name: 'Report', link: '/users/report' },
                    ],
                    print: [
                        { name: 'Patients', link: '/users' },
                        { name: 'Report', link: '/users/report' },
                        { name: 'Print', link: '/users/report/print' },
                    ],
                    settings: [{ name: 'Settings', link: '' }],
                    devices: [{ name: 'Devices', link: '/devices' }],
                    addDevice: [
                        { name: 'Devices', link: '/devices' },
                        { name: 'Add', link: '/devices/add' },
                    ],
                    editDevice: [
                        { name: 'Devices', link: '/devices' },
                        { name: 'Edit', link: '/devices/edit' },
                    ],
                    deleteDevice: [
                        { name: 'Devices', link: '/devices' },
                        { name: 'Delete', link: '/devices/delete' },
                    ],
                    deviceReport: [
                        { name: 'Devices', link: '/devices' },
                        { name: 'Report', link: '/devices/report' },
                    ],
                    batteryDischarge: [
                        { name: 'Devices', link: '/devices' },
                        {
                            name: 'Battery discharge',
                            link: '/devices/battery_discharge',
                        },
                    ],
                    importUser: [
                        { name: 'Patients', link: '/users' },
                        { name: 'Import patients', link: '/users/import' },
                    ],
                    diaries: [{ name: 'Custom Diaries', link: '' }],
                    addDiary: [
                        { name: 'Custom Diaries', link: '/custom-diaries' },
                        { name: 'Add', link: '/custom-diaries/add' },
                    ],
                    editDiary: [
                        { name: 'Custom Diaries', link: '/custom-diaries' },
                        { name: 'Edit', link: '/custom-diaries/edit' },
                    ],
                    deleteDiary: [
                        { name: 'Custom Diaries', link: '/custom-diaries' },
                        { name: 'Delete', link: '/custom-diaries/delete' },
                    ],
                },
            },
        },
        manager: {
            header: {
                welcomeText: 'Welcome',
                imgAlt: 'User icon',
                dropDownMenu: [
                    {
                        label: 'My Profile',
                        link: '/account/my_profile',
                    },
                    {
                        label: 'Subscription',
                        link: '/account/my_account',
                    },
                    {
                        label: 'Settings',
                        link: '/account/settings',
                    },
                    {
                        label: 'Logout',
                        link: '',
                    },
                ],
                sleepDiaryTrialsubscriptionExpiresInText:
                    'Sleep Diary Trial subscription expires in',
                lumusTrialSubscriptionExpiresInText:
                    'Lumus Trial subscription expires in',
                daysText: 'days',
            },
            sidebar: {
                sidebarItems: [
                    {
                        label: 'Dashboard',
                        link: '/dashboard',
                        icon: FaChartLine,
                    },
                    {
                        label: 'Patients',
                        link: '/users',
                        icon: FaUser,
                    },
                    {
                        label: 'Managers',
                        link: '/managers',
                        icon: FaUserTie,
                    },
                    {
                        label: 'Subscription',
                        link: '/account/my_account',
                        icon: FaUserCircle,
                    },
                    {
                        label: 'Devices',
                        link: '/devices',
                        icon: BsSmartwatch,
                    },
                    {
                        label: 'Custom Diaries',
                        link: '/custom-diaries',
                        icon: FaBook,
                    },
                ],
                diariesText: 'Diaries',
                customText: 'Custom',
            },
            content: {
                crumbs: {
                    exception: [],
                    dashboard: [{ name: 'Dashboard', link: '' }],
                    myProfile: [{ name: 'My Profile', link: '' }],
                    myAccount: [{ name: 'Subscription', link: '' }],
                    updatePaymentMethod: [
                        { name: 'Subscription', link: '/account/my_account' },
                        {
                            name: 'Change credit card',
                            link: '/account/my_account/update_payment_method',
                        },
                    ],
                    signTrialSubscription: [
                        { name: 'Subscription', link: '/account/my_account' },
                        {
                            name: 'Sign Trial subscription',
                            link: '/account/my_account/sign_trial_subscription',
                        },
                    ],
                    changePlan: [
                        { name: 'Subscription', link: '/account/my_account' },
                        {
                            name: 'Change plan',
                            link: '/account/my_account/change_plan',
                        },
                    ],
                    chooseSubscription: [
                        { name: 'Subscription', link: '/account/my_account' },
                        {
                            name: 'Change plan',
                            link: '/account/my_account/change_plan',
                        },
                        {
                            name: 'Choose subscription',
                            link: '/account/my_account/change_plan/choose_subscription',
                        },
                    ],
                    reviewAndPayment: [
                        { name: 'Subscription', link: '/account/my_account' },
                        {
                            name: 'Change plan',
                            link: '/account/my_account/change_plan',
                        },
                        {
                            name: 'Choose subscription',
                            link: '/account/my_account/change_plan/choose_subscription',
                        },
                        {
                            name: 'Review and payment',
                            link: '/account/my_account/change_plan/choose_subscription/review_and_payment',
                        },
                    ],
                    settings: [{ name: 'Settings', link: '' }],
                    setupTwoFactorAuthentication: [
                        { name: 'Settings', link: '/account/settings' },
                        {
                            name: 'Configure 2-factor authentication',
                            link: '/account/settings/setup_2fa',
                        },
                    ],
                    users: [{ name: 'Patients', link: '/users' }],
                    addUser: [
                        { name: 'Patients', link: '/users' },
                        { name: 'Add', link: '/users/add' },
                    ],
                    editUser: [
                        { name: 'Patients', link: '/users' },
                        { name: 'Edit', link: '/users/edit' },
                    ],
                    deleteUser: [
                        { name: 'Patients', link: '/users' },
                        { name: 'Delete', link: '/users/delete' },
                    ],
                    userCredentials: [
                        { name: 'Users', link: '/users' },
                        { name: 'Credentials', link: '/users/credentials' },
                    ],
                    userReport: [
                        { name: 'Patients', link: '/users' },
                        { name: 'Report', link: '/users/report' },
                    ],
                    importUser: [
                        { name: 'Patients', link: '/users' },
                        { name: 'Import patients', link: '/users/import' },
                    ],
                    managers: [{ name: 'Managers', link: '/managers' }],
                    addManager: [
                        { name: 'Managers', link: '/managers' },
                        { name: 'Add', link: '/managers/add' },
                    ],
                    editManager: [
                        { name: 'Managers', link: '/managers' },
                        { name: 'Edit', link: '/managers/edit' },
                    ],
                    deleteManager: [
                        { name: 'Managers', link: '/managers' },
                        { name: 'Delete', link: '/managers/delete' },
                    ],
                    managerCredentials: [
                        { name: 'Managers', link: '/managers' },
                        { name: 'Credentials', link: '/managers/credentials' },
                    ],
                    diaries: [{ name: 'Custom Diaries', link: '' }],
                    addDiary: [
                        { name: 'Custom Diaries', link: '/custom-diaries' },
                        { name: 'Add', link: '/custom-diaries/add' },
                    ],
                    editDiary: [
                        { name: 'Custom Diaries', link: '/custom-diaries' },
                        { name: 'Edit', link: '/custom-diaries/edit' },
                    ],
                    deleteDiary: [
                        { name: 'Custom Diaries', link: '/custom-diaries' },
                        { name: 'Delete', link: '/custom-diaries/delete' },
                    ],
                    devices: [{ name: 'Devices', link: '/devices' }],
                    addDevice: [
                        { name: 'Devices', link: '/devices' },
                        { name: 'Add', link: '/devices/add' },
                    ],
                    editDevice: [
                        { name: 'Devices', link: '/devices' },
                        { name: 'Edit', link: '/devices/edit' },
                    ],
                    deleteDevice: [
                        { name: 'Devices', link: '/devices' },
                        { name: 'Delete', link: '/devices/delete' },
                    ],
                    deviceReport: [
                        { name: 'Devices', link: '/devices' },
                        { name: 'Report', link: '/devices/report' },
                    ],
                    batteryDischarge: [
                        { name: 'Devices', link: '/devices' },
                        {
                            name: 'Battery discharge',
                            link: '/devices/battery_discharge',
                        },
                    ],
                },
            },
        },
        condor_admin: {
            header: {
                welcomeText: 'Welcome',
                imgAlt: 'User icon',
                dropDownMenu: [
                    {
                        label: 'My Profile',
                        link: '/account/my_profile',
                    },
                    {
                        label: 'Logout',
                        link: '',
                    },
                ],
                sleepDiaryTrialsubscriptionExpiresInText:
                    'Sleep Diary Trial subscription expires in',
                lumusTrialSubscriptionExpiresInText:
                    'Lumus Trial subscription expires in',
                daysText: 'days',
            },
            sidebar: {
                sidebarItems: [
                    {
                        label: 'Dashboard',
                        link: '/dashboard',
                        icon: FaChartLine,
                    },
                    {
                        label: 'Users',
                        link: '/users',
                        icon: FaUser,
                    },
                    {
                        label: 'Companies',
                        link: '/companies',
                        icon: FaIndustry,
                    },
                    {
                        label: 'Plans',
                        link: '/plans',
                        icon: FaClipboard,
                    },
                    {
                        label: 'Devices',
                        link: '/devices',
                        icon: BsSmartwatch,
                    },
                    {
                        label: 'Coupons',
                        link: '/coupons',
                        icon: FaGift,
                    },
                ],
            },
            content: {
                crumbs: {
                    exception: [],
                    dashboard: [{ name: 'Dashboard', link: '' }],
                    myProfile: [{ name: 'My Profile', link: '' }],
                    users: [{ name: 'Users', link: '/users' }],
                    addUser: [
                        { name: 'Users', link: '/users' },
                        { name: 'Add', link: '/users/add' },
                    ],
                    editUser: [
                        { name: 'Users', link: '/users' },
                        { name: 'Edit', link: '/users/edit' },
                    ],
                    deleteUser: [
                        { name: 'Users', link: '/users' },
                        { name: 'Delete', link: '/users/delete' },
                    ],
                    managers: [],
                    userCredentials: [
                        { name: 'Users', link: '/users' },
                        { name: 'Credentials', link: '/users/credentials' },
                    ],
                    companies: [{ name: 'Companies', link: '/companies' }],
                    addCompany: [
                        { name: 'Companies', link: '/companies' },
                        { name: 'Add', link: '/companies/add' },
                    ],
                    editCompany: [
                        { name: 'Companies', link: '/companies' },
                        { name: 'Edit', link: '/companies/edit' },
                    ],
                    deleteCompany: [
                        { name: 'Companies', link: '/companies' },
                        { name: 'Delete', link: '/companies/delete' },
                    ],
                    companySubscription: [
                        { name: 'Companies', link: '/companies' },
                        {
                            name: 'Subscription',
                            link: '/companies/subscription',
                        },
                    ],
                    plans: [{ name: 'Plans', link: '/plans' }],
                    addPlan: [
                        { name: 'Plans', link: '/plans' },
                        { name: 'Add', link: '/plans/add' },
                    ],
                    editPlan: [
                        { name: 'Plans', link: '/plans' },
                        { name: 'Edit', link: '/plans/edit' },
                    ],
                    deletePlan: [
                        { name: 'Plans', link: '/plans' },
                        { name: 'Delete', link: '/plans/delete' },
                    ],
                    coupons: [{ name: 'Coupons', link: '/coupons' }],
                    addCoupon: [
                        { name: 'Coupons', link: '/coupons' },
                        { name: 'Add', link: '/coupons/add' },
                    ],
                    editCoupon: [
                        { name: 'Coupons', link: '/coupons' },
                        { name: 'Edit', link: '/coupons/edit' },
                    ],
                    deleteCoupon: [
                        { name: 'Coupons', link: '/coupons' },
                        { name: 'Delete', link: '/coupons/delete' },
                    ],
                    chooseSubscription: [
                        { name: 'Subscription', link: '/account/my_account' },
                        {
                            name: 'Change plan',
                            link: '/account/my_account/change_plan',
                        },
                        {
                            name: 'Choose subscription',
                            link: '/account/my_account/change_plan/choose_subscription',
                        },
                    ],
                    reviewAndPayment: [
                        { name: 'Subscription', link: '/account/my_account' },
                        {
                            name: 'Change plan',
                            link: '/account/my_account/change_plan',
                        },
                        {
                            name: 'Choose subscription',
                            link: '/account/my_account/change_plan/choose_subscription',
                        },
                        {
                            name: 'Review and payment',
                            link: '/account/my_account/change_plan/choose_subscription/review_and_payment',
                        },
                    ],
                    userReport: [
                        { name: 'Patients', link: '/users' },
                        { name: 'Report', link: '/users/report' },
                    ],
                    print: [
                        { name: 'Patients', link: '/users' },
                        { name: 'Report', link: '/users/report' },
                        { name: 'Print', link: '/users/report/print' },
                    ],
                    settings: [{ name: 'Settings', link: '' }],
                    devices: [{ name: 'Devices', link: '/devices' }],
                    addDevice: [
                        { name: 'Devices', link: '/devices' },
                        { name: 'Add', link: '/devices/add' },
                    ],
                    editDevice: [
                        { name: 'Devices', link: '/devices' },
                        { name: 'Edit', link: '/devices/edit' },
                    ],
                    deleteDevice: [
                        { name: 'Devices', link: '/devices' },
                        { name: 'Delete', link: '/devices/delete' },
                    ],
                    deviceReport: [
                        { name: 'Devices', link: '/devices' },
                        { name: 'Report', link: '/devices/report' },
                    ],
                    importDevices: [
                        { name: 'Devices', link: '/devices' },
                        { name: 'Import devices', link: '/devices/import' },
                    ],
                    batteryDischarge: [
                        { name: 'Devices', link: '/devices' },
                        {
                            name: 'Battery discharge',
                            link: '/devices/battery_discharge',
                        },
                    ],
                },
            },
        },
    },
};
