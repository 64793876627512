import React from 'react';
import PropTypes from 'prop-types';

import TableTds from './tableTds';

function TableRows({
    users,
    tds,
    actions,
    activeText,
    inactiveText,
    roleDescription,
}) {
    return users.map((user) => {
        return (
            <tr key={user.id}>
                <TableTds
                    tds={tds}
                    user={user}
                    actions={actions}
                    activeText={activeText}
                    inactiveText={inactiveText}
                    roleDescription={roleDescription}
                />
            </tr>
        );
    });
}

TableRows.propTypes = {
    users: PropTypes.array.isRequired,
    tds: PropTypes.array.isRequired,
    actions: PropTypes.array,
    activeText: PropTypes.string.isRequired,
    inactiveText: PropTypes.string.isRequired,
    roleDescription: PropTypes.object,
};

export default TableRows;
