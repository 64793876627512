import React from 'react';
import { Card, CardHeader, CardBody, Badge } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import { CancelSubscriptionModal } from '../cancelSubscriptionModal';

PlanCard.propTypes = {
    planName: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    paymentFrequency: PropTypes.string.isRequired,
    fidelity: PropTypes.string.isRequired,
    numberOfEntities: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    suspended: PropTypes.bool.isRequired,
    trialSubscriptionExpiresIn: PropTypes.string,
    cancelDate: PropTypes.string,
    texts: PropTypes.shape({
        activeText: PropTypes.string,
        suspendedText: PropTypes.string,
        expiredText: PropTypes.string,
        cancelText: PropTypes.string,
        attentionText: PropTypes.string,
        warningCancelText: PropTypes.string,
        closeText: PropTypes.string,
        unsubscribeText: PropTypes.string,
    }),
    subscriptionType: PropTypes.string.isRequired,
    showButton: PropTypes.bool,
    onClick: PropTypes.func,
};

export function PlanCard({
    planName,
    price,
    paymentFrequency,
    fidelity,
    numberOfEntities,
    active,
    suspended,
    trialSubscriptionExpiresIn,
    cancelDate,
    texts,
    subscriptionType,
    showButton = false,
    onClick,
}) {
    return (
        <Card className={`${styles.planCardContainer}`}>
            <CardHeader className={styles.planCardHeader}>
                <p>{planName}</p>
            </CardHeader>
            <CardBody className={styles.planItemCardBody}>
                <div className={styles.planItemCardPrice}>
                    <div className={styles.info}>
                        <h4>{price}</h4>
                        <small>
                            <b>{paymentFrequency}</b>
                        </small>
                    </div>

                    <div className={styles.info}>
                        <p>{fidelity}</p>
                    </div>

                    <div className={styles.info}>
                        <p>{numberOfEntities}</p>
                    </div>

                    {active && !suspended && (
                        <div className={styles.info}>
                            <p className="textGreen">{texts?.activeText}</p>
                        </div>
                    )}

                    {active && suspended && (
                        <div className={styles.info}>
                            <p className="textOrange">{texts?.suspendedText}</p>
                        </div>
                    )}

                    {!active && suspended && (
                        <div className={styles.info}>
                            <p className="textRed">{texts?.expiredText}</p>
                        </div>
                    )}

                    {trialSubscriptionExpiresIn && (
                        <div className={styles.info}>
                            <p>{trialSubscriptionExpiresIn}</p>
                        </div>
                    )}

                    {!trialSubscriptionExpiresIn && cancelDate && (
                        <div className={styles.info}>
                            <p>{cancelDate}</p>
                        </div>
                    )}
                </div>
                <div className={styles.planItemCardInfo}>
                    <Badge color="dark">{subscriptionType}</Badge>
                </div>
            </CardBody>
            <div className={styles.planItemCardAction}>
                <CancelSubscriptionModal
                    cancelText={texts?.cancelText}
                    attentionText={texts?.attentionText}
                    warningCancelText={texts?.warningCancelText}
                    closeText={texts?.closeText}
                    unsubscribeText={texts?.unsubscribeText}
                    onCancel={onClick}
                    disabled={!(showButton && !suspended)}
                />
            </div>
        </Card>
    );
}
