import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import Route from './routes';
import Login from '../pages/login';
import VerifyTwoFactorAuthenticationPage from '../pages/twoFactorAuthentication/verify';
import PrivacyPolicy from '../pages/privacyPolicy';
import PasswordResetRequest from '../pages/account/passwordResetRequest';
import PasswordReset from '../pages/account/passwordReset';
import Register from '../pages/register';
import RegisterByPlan from '../pages/register/plan';
import RegisterEvent from '../pages/register/event';
import Exception from '../pages/exceptions';
import Dashboard from '../pages/dashboard';
import MyProfile from '../pages/account/myProfile';
import MyAccount from '../pages/account/myAccount';
import UpdatePaymentMethod from '../pages/account/myAccount/updatePaymentMethod';
import CreateTrialSubscription from '../pages/account/myAccount/createTrialSubscription';
import ChangePlan from '../pages/account/myAccount/changePlan';
import ChooseSubscription from '../pages/account/myAccount/changePlan/chooseSubscription';
import ReviewAndPayment from '../pages/account/myAccount/changePlan/chooseSubscription/reviewAndPayment';
import Settings from '../pages/account/settings';
import TwoFactorAuthenticationSetupPage from '../pages/account/settings/twoFactorAuthenticationSetup';
import Users from '../pages/users';
import AddUser from '../pages/users/addUser';
import EditUser from '../pages/users/editUser';
import DeleteUser from '../pages/users/deleteUser';
import UserCredentials from '../pages/users/userCredentials';
import UserReport from '../pages/users/userReport';
import UserPrintReport from '../pages/users/userReport/print';
import ImportUser from '../pages/users/importUser';
import Managers from '../pages/managers';
import AddManager from '../pages/managers/add';
import EditManager from '../pages/managers/edit';
import DeleteManager from '../pages/managers/delete';
import ManagerCredentials from '../pages/managers/credentials';
import Companies from '../pages/companies';
import AddCompany from '../pages/companies/addCompany';
import EditCompany from '../pages/companies/editCompany';
import DeleteCompany from '../pages/companies/deleteCompany';
import CompanySubscription from '../pages/companies/companySubscription';
import Plans from '../pages/plans';
import AddPlan from '../pages/plans/addPlan';
import EditPlan from '../pages/plans/editPlan';
import DeletePlan from '../pages/plans/deletePlan';
import Coupons from '../pages/coupons';
import AddCoupon from '../pages/coupons/addCoupon';
import EditCoupon from '../pages/coupons/editCoupon';
import DeleteCoupon from '../pages/coupons/deleteCoupon';
import Diaries from '../pages/diaries';
import AddDiary from '../pages/diaries/addDiary';
import EditDiary from '../pages/diaries/editDiary';
import DeleteDiary from '../pages/diaries/deleteDiary';
import Devices from '../pages/devices';
import AddDevice from '../pages/devices/addDevice';
import EditDevice from '../pages/devices/editDevice';
import DeleteDevice from '../pages/devices/deleteDevice';
import DeviceReport from '../pages/devices/deviceReport';
import ImportDevices from '../pages/devices/importDevices';
import BatteryDischarge from '../pages/devices/batteryDischarge';

const routes = [
    {
        path: '/privacy-policy',
        component: PrivacyPolicy,
        isPrivate: false,
        pageName: 'privacyPolicy',
        roles: [],
    },
    {
        path: '/login',
        component: Login,
        isPrivate: false,
        pageName: 'login',
        roles: [],
    },
    {
        path: '/two_factor_authentication/verify',
        component: VerifyTwoFactorAuthenticationPage,
        isPrivate: true,
        pageName: 'verifyTwoFactorAuthentication',
        roles: [],
    },
    {
        path: '/password_reset_request',
        component: PasswordResetRequest,
        isPrivate: false,
        pageName: 'passwordResetRequest',
        roles: [],
    },
    {
        path: '/password_reset/:token',
        component: PasswordReset,
        isPrivate: false,
        pageName: 'passwordReset',
        roles: [],
    },
    {
        path: '/register/:lang',
        component: Register,
        isPrivate: false,
        exact: true,
        pageName: 'register',
        roles: [],
    },
    {
        path: '/register/:lang/:planName',
        component: RegisterByPlan,
        isPrivate: false,
        exact: true,
        pageName: 'register',
        roles: [],
    },
    {
        path: '/register/event/:lang/:uuid',
        component: RegisterEvent,
        isPrivate: false,
        exact: true,
        pageName: 'registerEvent',
        roles: [],
    },
    {
        path: '/exception',
        component: Exception,
        isPrivate: true,
        pageName: 'exception',
        roles: [],
    },
    {
        path: '/dashboard',
        component: Dashboard,
        isPrivate: true,
        pageName: 'dashboard',
        roles: ['customer_admin', 'manager', 'condor_admin'],
    },
    {
        path: '/account/my_profile',
        component: MyProfile,
        isPrivate: true,
        pageName: 'myProfile',
        roles: ['customer_admin', 'manager', 'condor_admin'],
    },
    {
        path: '/account/my_account',
        component: MyAccount,
        isPrivate: true,
        exact: true,
        pageName: 'myAccount',
        roles: ['manager'],
    },
    {
        path: '/account/my_account/update_payment_method',
        component: UpdatePaymentMethod,
        isPrivate: true,
        pageName: 'updatePaymentMethod',
        roles: ['manager'],
    },
    {
        path: '/account/my_account/change_plan',
        component: ChangePlan,
        isPrivate: true,
        exact: true,
        pageName: 'changePlan',
        roles: ['manager'],
    },
    {
        path: '/account/my_account/sign_trial_subscription',
        component: CreateTrialSubscription,
        isPrivate: true,
        exact: true,
        pageName: 'signTrialSubscription',
        roles: ['manager'],
    },
    {
        path: '/account/my_account/change_plan/choose_subscription',
        component: ChooseSubscription,
        isPrivate: true,
        exact: true,
        pageName: 'chooseSubscription',
        roles: ['manager'],
    },
    {
        path: '/account/my_account/change_plan/choose_subscription/review_and_payment',
        component: ReviewAndPayment,
        isPrivate: true,
        pageName: 'reviewAndPayment',
        roles: ['manager'],
    },
    {
        path: '/account/settings',
        component: Settings,
        isPrivate: true,
        exact: true,
        pageName: 'settings',
        roles: ['manager'],
    },
    {
        path: '/account/settings/setup_2fa',
        component: TwoFactorAuthenticationSetupPage,
        isPrivate: true,
        pageName: 'setupTwoFactorAuthentication',
        roles: ['manager'],
    },
    {
        path: '/users',
        component: Users,
        isPrivate: true,
        exact: true,
        pageName: 'users',
        roles: ['customer_admin', 'manager', 'condor_admin'],
    },
    {
        path: '/users/add',
        component: AddUser,
        isPrivate: true,
        pageName: 'addUser',
        roles: ['customer_admin', 'manager', 'condor_admin'],
    },
    {
        path: '/users/edit',
        component: EditUser,
        isPrivate: true,
        pageName: 'editUser',
        roles: ['customer_admin', 'manager', 'condor_admin'],
    },
    {
        path: '/users/delete',
        component: DeleteUser,
        isPrivate: true,
        pageName: 'deleteUser',
        roles: ['customer_admin', 'manager', 'condor_admin'],
    },
    {
        path: '/users/credentials',
        component: UserCredentials,
        isPrivate: true,
        pageName: 'userCredentials',
        roles: ['customer_admin', 'manager', 'condor_admin'],
    },
    {
        path: '/users/report',
        component: UserReport,
        isPrivate: true,
        exact: true,
        pageName: 'userReport',
        roles: ['customer_admin', 'manager'],
    },
    {
        path: '/users/report/print',
        component: UserPrintReport,
        isPrivate: true,
        pageName: 'print',
        roles: ['customer_admin', 'manager'],
    },
    {
        path: '/users/import',
        component: ImportUser,
        isPrivate: true,
        pageName: 'importUser',
        roles: ['customer_admin', 'manager'],
    },
    {
        path: '/managers',
        component: Managers,
        isPrivate: true,
        exact: true,
        pageName: 'managers',
        roles: ['manager'],
    },
    {
        path: '/managers/add',
        component: AddManager,
        isPrivate: true,
        pageName: 'addManager',
        roles: ['manager'],
    },
    {
        path: '/managers/edit',
        component: EditManager,
        isPrivate: true,
        pageName: 'editManager',
        roles: ['manager'],
    },
    {
        path: '/managers/delete',
        component: DeleteManager,
        isPrivate: true,
        pageName: 'deleteManager',
        roles: ['manager'],
    },
    {
        path: '/managers/credentials',
        component: ManagerCredentials,
        isPrivate: true,
        pageName: 'managerCredentials',
        roles: ['manager'],
    },
    {
        path: '/companies',
        component: Companies,
        isPrivate: true,
        exact: true,
        pageName: 'companies',
        roles: ['condor_admin'],
    },
    {
        path: '/companies/add',
        component: AddCompany,
        isPrivate: true,
        pageName: 'addCompany',
        roles: ['condor_admin'],
    },
    {
        path: '/companies/edit',
        component: EditCompany,
        isPrivate: true,
        pageName: 'editCompany',
        roles: ['condor_admin'],
    },
    {
        path: '/companies/delete',
        component: DeleteCompany,
        isPrivate: true,
        pageName: 'deleteCompany',
        roles: ['condor_admin'],
    },
    {
        path: '/companies/subscription',
        component: CompanySubscription,
        isPrivate: true,
        pageName: 'companySubscription',
        roles: ['condor_admin'],
    },
    {
        path: '/plans',
        component: Plans,
        isPrivate: true,
        exact: true,
        pageName: 'plans',
        roles: ['condor_admin'],
    },
    {
        path: '/plans/add',
        component: AddPlan,
        isPrivate: true,
        pageName: 'addPlan',
        roles: ['condor_admin'],
    },
    {
        path: '/plans/edit',
        component: EditPlan,
        isPrivate: true,
        pageName: 'editPlan',
        roles: ['condor_admin'],
    },
    {
        path: '/plans/delete',
        component: DeletePlan,
        isPrivate: true,
        pageName: 'deletePlan',
        roles: ['condor_admin'],
    },
    {
        path: '/coupons',
        component: Coupons,
        isPrivate: true,
        exact: true,
        pageName: 'coupons',
        roles: ['condor_admin'],
    },
    {
        path: '/coupons/add',
        component: AddCoupon,
        isPrivate: true,
        pageName: 'addCoupon',
        roles: ['condor_admin'],
    },
    {
        path: '/coupons/edit',
        component: EditCoupon,
        isPrivate: true,
        pageName: 'editCoupon',
        roles: ['condor_admin'],
    },
    {
        path: '/coupons/delete',
        component: DeleteCoupon,
        isPrivate: true,
        pageName: 'deleteCoupon',
        roles: ['condor_admin'],
    },
    {
        path: '/custom-diaries',
        component: Diaries,
        isPrivate: true,
        exact: true,
        pageName: 'diaries',
        roles: ['customer_admin', 'manager'],
    },
    {
        path: '/custom-diaries/add',
        component: AddDiary,
        isPrivate: true,
        pageName: 'addDiary',
        roles: ['customer_admin', 'manager'],
    },
    {
        path: '/custom-diaries/edit',
        component: EditDiary,
        isPrivate: true,
        pageName: 'editDiary',
        roles: ['customer_admin', 'manager'],
    },
    {
        path: '/custom-diaries/delete',
        component: DeleteDiary,
        isPrivate: true,
        pageName: 'deleteDiary',
        roles: ['customer_admin', 'manager'],
    },
    {
        path: '/devices',
        component: Devices,
        isPrivate: true,
        exact: true,
        pageName: 'devices',
    },
    {
        path: '/devices/add',
        component: AddDevice,
        isPrivate: true,
        pageName: 'addDevice',
    },
    {
        path: '/devices/edit',
        component: EditDevice,
        isPrivate: true,
        pageName: 'editDevice',
    },
    {
        path: '/devices/delete',
        component: DeleteDevice,
        isPrivate: true,
        pageName: 'deleteDevice',
    },
    {
        path: '/devices/report',
        component: DeviceReport,
        isPrivate: true,
        pageName: 'deviceReport',
    },
    {
        path: '/devices/import',
        component: ImportDevices,
        isPrivate: true,
        pageName: 'importDevices',
        roles: ['condor_admin'],
    },
    {
        path: '/devices/battery_discharge',
        component: BatteryDischarge,
        isPrivate: true,
        pageName: 'batteryDischarge',
    },
];

export default function Routes() {
    return (
        <Switch>
            {routes.map((props, key) => (
                <Route key={key} {...props} />
            ))}
            <Redirect from="/" to="/login" />
        </Switch>
    );
}
