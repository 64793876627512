import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import {
    CardNumberElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import useIugu from '../../../../../hooks/useIugu';
import history from '../../../../../services/history';
import {
    createStripeSetupIntent,
    updatePaymentMethod,
} from '../../../../../services/api/account';
import { validateForm } from '../../../../../helpers/formValidator';
import { setLoading } from '../../../../../store/actions/loadingActions';
import FormFields from '../../../../../components/form/formFields';
import { IuguCreditCardForm } from '../../../../../components/form/iuguCreditCardForm';
import { StripeForm } from './stripeForm';

export function UpdatePaymentMethodForm({
    schema,
    fields,
    texts,
    paymentMethod,
    currentUser,
}) {
    const iugu = useIugu();

    const stripe = useStripe();
    const elements = useElements();

    const [setupIntentClientSecret, setSetupIntentClientSecret] =
        useState(null);
    const [paymentIntentClientSecret, setPaymentIntentClientSecret] =
        useState(null);
    const [requiresPaymentMethod, setRequiresPaymentMethod] = useState(null);

    const [setupIntentConfirmed, setSetupIntentConfirmed] = useState(false);
    const [paymentIntentConfirmed, setPaymentIntentConfirmed] = useState(false);
    const [stripePaymentMethodId, setStripePaymentMethodId] = useState(null);

    const [cardNumberComplete, setCardNumberComplete] = useState(false);
    const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
    const [cardCvcComplete, setCardCvcComplete] = useState(false);
    const [filledInForm, setFilledInForm] = useState(
        currentUser?.subscription?.locale !== 'pt-BR'
    );
    const [formReady, setFormReady] = useState(false);

    const formRef = useRef(null);
    const dispatch = useDispatch();

    const { locale } = currentUser?.subscription;

    const handleKeyUpForm = () => {
        const formData = formRef.current.getData();

        let empty = 0;

        for (let key in formData) {
            if (formData[key] === '') {
                empty += 1;
            }
        }

        setFilledInForm(empty ? false : true);
    };

    const handleSetupIntent = async () => {
        let tempSetupIntentClientSecret;
        let tempPaymentIntentClientSecret;
        let tempRequiresPaymentMethod;

        let tempStripePaymentMethodId;

        if (!setupIntentClientSecret) {
            const response = await createStripeSetupIntent();

            if (response) {
                tempSetupIntentClientSecret = response?.setupIntentClientSecret;
                tempPaymentIntentClientSecret =
                    response?.paymentIntentClientSecret;
                tempRequiresPaymentMethod = response?.requiresPaymentMethod;
                setSetupIntentClientSecret(response?.setupIntentClientSecret);
                setPaymentIntentClientSecret(
                    response?.paymentIntentClientSecret
                );
                setRequiresPaymentMethod(response?.requiresPaymentMethod);
            } else {
                toast.error(texts?.editFailedText);

                dispatch(setLoading(false));

                return {};
            }
        }

        if (!(tempSetupIntentClientSecret ?? setupIntentClientSecret)) {
            dispatch(setLoading(false));
            return {};
        }

        // confirm stripe setup intent to attach new payment method to customer
        if (!setupIntentConfirmed) {
            const result = await stripe.confirmCardSetup(
                tempSetupIntentClientSecret ?? setupIntentClientSecret,
                {
                    payment_method: {
                        card: elements.getElement(CardNumberElement),
                        billing_details: {
                            name: currentUser.company_name,
                        },
                    },
                }
            );

            if (result.error) {
                dispatch(setLoading(false));

                toast.error(
                    result.error.code in texts?.stripeTexts
                        ? texts?.stripeTexts[result.error.code]
                        : texts?.editFailedText
                );

                tempSetupIntentClientSecret = null;
                tempPaymentIntentClientSecret = null;
                tempRequiresPaymentMethod = null;
                setSetupIntentClientSecret(null);
                setPaymentIntentClientSecret(null);
                setRequiresPaymentMethod(null);

                return {
                    tempStripePaymentMethodId,
                    tempPaymentIntentClientSecret,
                    tempRequiresPaymentMethod,
                };
            } else {
                if (result.setupIntent.status === 'succeeded') {
                    tempStripePaymentMethodId =
                        result.setupIntent.payment_method;
                    setStripePaymentMethodId(result.setupIntent.payment_method);
                    setSetupIntentConfirmed(true);
                }
            }
        }

        return {
            tempStripePaymentMethodId,
            tempPaymentIntentClientSecret,
            tempRequiresPaymentMethod,
        };
    };

    const handlePaymentIntent = async (
        tempPaymentIntentClientSecret,
        tempStripePaymentMethodId
    ) => {
        if (!(tempPaymentIntentClientSecret ?? paymentIntentClientSecret)) {
            dispatch(setLoading(false));
            return false;
        }

        if (!paymentIntentConfirmed) {
            const result = await stripe.confirmCardPayment(
                tempPaymentIntentClientSecret ?? paymentIntentClientSecret,
                {
                    payment_method:
                        tempStripePaymentMethodId ?? stripePaymentMethodId,
                }
            );

            if (result.error) {
                dispatch(setLoading(false));

                toast.error(
                    result.error.code in texts?.stripeTexts
                        ? texts?.stripeTexts[result.error.code]
                        : texts?.editFailedText
                );

                return false;
            } else {
                if (result.paymentIntent.status === 'succeeded') {
                    setPaymentIntentConfirmed(true);
                    return true;
                }
            }
        }

        return true;
    };

    const handleSubmit = async (data, { reset }, event) => {
        event.preventDefault();

        const { isValid, errors } = await validateForm(data, schema);

        if (isValid) {
            formRef.current.setErrors(errors);

            dispatch(setLoading(true));

            const sendData = {};

            if (locale === 'pt-BR') {
                // avoid sending the form if iugu script has not yet been loaded
                if (!iugu.impl()) {
                    dispatch(setLoading(false));
                    return;
                }

                // create iugu credit card token
                const createTokenResponse = await iugu.createPaymentToken(
                    event.target
                );

                if (createTokenResponse?.errors) {
                    dispatch(setLoading(false));

                    switch (true) {
                        case 'incorrect_number' in createTokenResponse.errors:
                            toast.error(texts.iuguTexts.numberErrorText);
                            break;
                        case 'incorrect_cvv' in createTokenResponse.errors:
                            toast.error(texts.iuguTexts.cvvErrorText);
                            break;
                        case 'incorrect_fullName' in createTokenResponse.errors:
                            toast.error(texts.iuguTexts.fullNameErrorText);
                            break;
                        case 'incorrect_expiration' in
                            createTokenResponse.errors:
                            toast.error(texts.iuguTexts.expirationErrorText);
                            break;
                        default:
                            toast.error(texts.iuguTexts.defaultErrorText);
                            break;
                    }

                    return;
                }

                sendData.token = createTokenResponse.id;
                sendData.new_description = data.new_description;

                // update payment method
                const response = await updatePaymentMethod(sendData);

                dispatch(setLoading(false));

                if (response) {
                    toast.success(texts.editSuccessText);
                    history.push('/account/my_account');

                    return;
                }
            } else {
                // avoid sending the form if stripe promise has not yet been fulfilled
                if (!stripe || !elements) {
                    dispatch(setLoading(false));
                    return;
                }

                let tempUpdatePaymentMethod;

                let {
                    tempStripePaymentMethodId,
                    tempPaymentIntentClientSecret,
                    tempRequiresPaymentMethod,
                } = await handleSetupIntent();

                sendData.payment_method_id =
                    tempStripePaymentMethodId ?? stripePaymentMethodId;

                switch (tempRequiresPaymentMethod ?? requiresPaymentMethod) {
                    case true:
                        tempUpdatePaymentMethod = await handlePaymentIntent(
                            tempPaymentIntentClientSecret,
                            tempStripePaymentMethodId
                        );
                        break;
                    case false:
                        tempUpdatePaymentMethod = true;
                        break;
                    case undefined:
                    case null:
                        tempUpdatePaymentMethod = false;
                        break;
                }

                if (tempUpdatePaymentMethod) {
                    const response = await updatePaymentMethod(sendData);

                    dispatch(setLoading(false));

                    if (response) {
                        toast.success(texts.editSuccessText);
                        history.push('/account/my_account');

                        return;
                    }
                }
            }
        } else {
            formRef.current.setErrors(errors);
        }
    };

    useEffect(() => {
        formRef.current.reset();

        const initialData = {};

        if (locale === 'pt-BR') {
            if (currentUser?.iugu_id !== null) {
                fields.card.map((field) => {
                    switch (field.props.name) {
                        case 'expiration_date':
                            initialData[
                                field.props.name
                            ] = `${paymentMethod['data']['month']}/${paymentMethod['data']['year']}`;
                            break;
                        case 'display_number':
                            initialData[field.props.name] =
                                paymentMethod['data'][field.props.name];
                            break;
                        default:
                            initialData[field.props.name] =
                                paymentMethod[field.props.name];
                            break;
                    }
                });
            }
        } else {
            if (currentUser?.stripe_id !== null) {
                fields.card.map((field) => {
                    switch (field.props.name) {
                        case 'expiration_date':
                            initialData[
                                field.props.name
                            ] = `${paymentMethod['card']['exp_month']}/${paymentMethod['card']['exp_year']}`;
                            break;
                        case 'display_number':
                            initialData[field.props.name] =
                                '•••• •••• •••• ' +
                                paymentMethod['card']['last4'];
                            break;
                        default:
                            initialData[field.props.name] =
                                paymentMethod[field.props.name];
                            break;
                    }
                });
            }
        }

        formRef.current.setData(initialData);
    }, []);

    useEffect(() => {
        if (
            filledInForm &&
            ((cardNumberComplete && cardExpiryComplete && cardCvcComplete) ||
                currentUser?.subscription?.locale === 'pt-BR')
        ) {
            setFormReady(true);
        } else {
            setFormReady(false);
        }
    }, [cardNumberComplete, cardExpiryComplete, cardCvcComplete, filledInForm]);

    return (
        <Form ref={formRef} onSubmit={handleSubmit} onKeyUp={handleKeyUpForm}>
            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{texts.currentCreditCardSpanText}</span>
                </FormGroup>
            </FormGroup>
            <FormGroup row>
                <FormFields
                    fields={
                        locale === 'pt-BR' ? fields.card : fields.card.slice(1)
                    }
                />
            </FormGroup>
            <hr />
            {locale === 'pt-BR' ? (
                <FormGroup row>
                    <FormGroup className="col-xs-12 col-md-12">
                        <span>{texts.newCreditCardSpanText}</span>
                    </FormGroup>
                    <FormFields fields={fields.new_card_description} />
                    <FormGroup className="col-xs-12 col-md-5">
                        <IuguCreditCardForm
                            fields={fields.new_card_info}
                            imgAltTexts={texts.iuguTexts.imgAltTexts}
                            creditCardLabelText={texts.newCreditCardLabelText}
                            iugu={iugu}
                        />
                    </FormGroup>
                </FormGroup>
            ) : (
                <StripeForm
                    creditCardLabelText={texts.newCreditCardSpanText}
                    setCardNumberComplete={setCardNumberComplete}
                    setCardExpiryComplete={setCardExpiryComplete}
                    setCardCvcComplete={setCardCvcComplete}
                />
            )}
            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button
                        color="primary mr-2"
                        type="submit"
                        disabled={!formReady}
                    >
                        {texts.editButtonText}
                    </Button>
                    <Link
                        to="/account/my_account"
                        className="btn btn-secondary"
                    >
                        {texts.cancelButtonText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}

UpdatePaymentMethodForm.propTypes = {
    schema: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    texts: PropTypes.object.isRequired,
    paymentMethod: PropTypes.object.isRequired,
    currentUser: PropTypes.object,
};
