import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import { setLoading } from '../../store/actions/loadingActions';
import { fetchManagers } from '../../services/api/users';
import { useOrderByProperty } from '../../hooks/useOrderByProperty';
import { SearchAndAddContainer } from './components/searchAndAddContainer';
import { TableContainer } from './components/tableContainer';

export default function Managers({ currentUser }) {
    const [users, setUsers] = useState([]);
    const { activeFilter, filteredData, setFilteredData, orderByProperty } =
        useOrderByProperty(users, 'name', 'asc');

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const isLoading = useSelector((state) => state.loading.isLoading);

    const pageData = pageConfig[lang][currentUser.permissions];

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            const data = await fetchManagers();

            if (data?.managers.length === 0) {
                setUsers(data?.managers);
                toast.info(pageData.noUsersText);
            } else {
                setUsers(data?.managers);
            }

            dispatch(setLoading(false));
        })();
    }, []);

    if (isLoading) {
        return <></>;
    }

    return (
        <>
            <SearchAndAddContainer
                users={users}
                setFilteredUsers={setFilteredData}
                texts={{
                    searchByNameText: pageData.searchByNameText,
                    searchBySecondaryIdText: pageData.searchBySecondaryIdText,
                    addText: pageData.addText,
                    addUserText: pageData.addUserText,
                }}
            />
            <TableContainer
                ths={pageData.ths}
                tds={pageData.tds}
                filteredUsers={filteredData}
                actions={pageData.actions}
                activeText={pageData.activeText}
                inactiveText={pageData.inactiveText}
                activeFilter={activeFilter}
                orderByProperty={orderByProperty}
            />
        </>
    );
}

Managers.propTypes = {
    currentUser: PropTypes.object.isRequired,
};
