import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import lodash from 'lodash';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import { setLoading } from '../../../store/actions/loadingActions';
import { setLocale } from '../../../store/actions/localeActions';
import { updateCurrentUserSubscriptionRequest } from '../../../store/actions/authActions';
import { fetchSubscriptionData } from '../../../services/api/account';
import { MyAccountForm } from './components/form';
import history from '../../../services/history';

MyAccount.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function MyAccount({ currentUser }) {
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [subscriptionFields, setSubscriptionFields] = useState(null);
    const [currency, setCurrency] = useState(null);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const isLoading = useSelector((state) => state.loading.isLoading);
    const { dateLocale, currencyLocale, currencyFormat } = useSelector(
        (state) => state.locale
    );

    const pageData = pageConfig[lang][currentUser.permissions];

    const handleFieldProperty = (subFields, subscription, paymentMethod) => {
        if (paymentMethod !== null) {
            subFields.unshift({
                ...pageData.fields.optionalSubscriptionFields.hasPaymentMethod,
                to: {
                    pathname: '/account/my_account/update_payment_method',
                    state: {
                        paymentMethod,
                    },
                },
            });
        }

        if (subscription?.suspended) {
            subFields.push(
                pageData.fields.optionalSubscriptionFields.subscriptionSuspended
            );
        }

        if (subscription?.billing_date) {
            subFields.push(
                pageData.fields.optionalSubscriptionFields
                    .subscriptionBillingDate
            );
        }
    };

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            const data = await fetchSubscriptionData();

            const subFields = [...pageData.fields.subscription];

            handleFieldProperty(
                subFields,
                data?.current_user?.subscription,
                data?.payment_method
            );

            let tempCurrentUser = data?.current_user
                ? {
                      ...data?.current_user,
                  }
                : { ...currentUser };

            if (!lodash.isEqual(tempCurrentUser, currentUser)) {
                dispatch(
                    updateCurrentUserSubscriptionRequest({
                        currentUser: tempCurrentUser,
                    })
                );
            }

            // if user is in trial, show currency formats from global state
            // if user already has a subscription, show currency formats from the subscription
            let iuguId;
            let stripeId;

            tempCurrentUser?.subscription?.plans?.forEach(
                (subscriptionPlan) => {
                    if (subscriptionPlan.iugu_id !== null) {
                        iuguId = subscriptionPlan.iugu_id;
                    }
                    if (subscriptionPlan.stripe_id !== null) {
                        stripeId = subscriptionPlan.stripe_id;
                    }
                }
            );

            if (iuguId === null && stripeId === null) {
                setCurrency({
                    locale: currencyLocale,
                    format: currencyFormat,
                });
            } else {
                setCurrency({
                    locale: tempCurrentUser?.subscription?.locale,
                    format:
                        tempCurrentUser?.subscription?.locale === 'pt-BR'
                            ? 'BRL'
                            : 'USD',
                });

                dispatch(
                    setLocale({
                        dateLocale: dateLocale,
                        currencyLocale: tempCurrentUser?.subscription?.locale,
                        currencyFormat:
                            tempCurrentUser?.subscription?.locale === 'pt-BR'
                                ? 'BRL'
                                : 'USD',
                    })
                );
            }

            setSubscriptionFields([...subFields]);

            setPaymentMethod(data?.payment_method);

            dispatch(setLoading(false));
        })();
    }, [history?.location?.key]);

    useEffect(() => {
        if (pageData) {
            const subFields = [...pageData.fields.subscription];

            handleFieldProperty(
                subFields,
                currentUser?.subscription,
                paymentMethod
            );

            setSubscriptionFields([...subFields]);
        }
    }, [lang]);

    if (isLoading || subscriptionFields === null || pageData === null) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <MyAccountForm
                    pageData={pageData}
                    subscriptionFields={subscriptionFields}
                    currentUser={currentUser}
                    paymentMethod={paymentMethod}
                    currency={currency}
                />
            </CardBody>
        </Card>
    );
}
