import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import lodash from 'lodash';

import styles from './editData.module.css';

import { updateEditableTableWeeksRequest } from '../../../../store/actions/reportActions';
import { editDataInput } from '../../../../services/api/dataInput';
import {
    localeDateFormatter,
    timeFormatter,
} from '../../../../helpers/formatters';
import { validateForm } from '../../../../helpers/formValidator';
import { createYupSchema } from '../../../../helpers/yupSchemaCreator';
import Table from '../../../../components/table/table';
import TableHeaders from '../../../../components/table/tableHeaders';
import { EditTableTds as TableTds } from './editTableTds';

export function EditData({
    userId,
    data,
    textData,
    locale,
    dispatchReportAction,
    lang,
}) {
    const { weeks, weekNumbers, weekReference } = data;

    const {
        cardTitle,
        ths,
        tds,
        editButtonText,
        saveButtonText,
        loadingButtonText,
        cancelButtonText,
        incorrectValueSpanText,
        automaticFixedValueSpanText,
        nextIcon,
        previousIcon,
        editSuccessText,
        incorretFormatText,
    } = textData;

    const [weekIndex, setWeekIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [inputsAndButtonsProps, setInputsAndButtonsProps] = useState(
        getInputsAndButtonsProps(weeks, weekIndex)
    );

    const formRef = useRef(null);

    const dispatch = useDispatch();

    function getInputsAndButtonsProps(weeks, weekIndex) {
        let disabledArray = [];
        let initialButtonsTexts = [];

        if (weeks.length !== 0) {
            for (let i = 0; i < weeks[weekIndex].dataInput.length; i++) {
                disabledArray.push(true);
                initialButtonsTexts.push(editButtonText);
            }
        }

        return { disabledArray, buttonsTexts: initialButtonsTexts };
    }

    const handlePrevNextWeek = (value) => {
        let nextIndex;

        if (value === 'previous') {
            if (weekIndex === 0) {
                nextIndex = Object.keys(weeks).length - 1;
            } else {
                nextIndex = weekIndex - 1;
            }
        } else {
            if (weekIndex === Object.keys(weeks).length - 1) {
                nextIndex = 0;
            } else {
                nextIndex = weekIndex + 1;
            }
        }

        setWeekIndex(nextIndex);
    };

    const updateRow = async (currentDate) => {
        const allData = formRef.current.getData();

        let params = {};
        let formData = [];
        let rowToValidate = {};

        for (const item in allData) {
            let [key, date] = item.split('/');

            if (date === currentDate) {
                params[key] = allData[item];
                formData.push({
                    id: item,
                    validationType: 'string',
                    validations: [
                        {
                            type: 'matches',
                            params: [
                                /(^([0-9][0-9]):[0-5][0-9]$)/,
                                incorretFormatText,
                            ],
                        },
                    ],
                });
                rowToValidate[item] = allData[item];
            }
        }

        const schema = formData.reduce(createYupSchema, {});

        const { isValid, errors } = await validateForm(
            rowToValidate,
            Yup.object().shape(schema)
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            const response = await editDataInput(userId, currentDate, params);

            if (response) {
                if ('dataInput' in response) {
                    let indexUpdate = 0;

                    for (
                        let i = 0;
                        i < weeks[weekIndex].dataInput.length;
                        i++
                    ) {
                        if (
                            weeks[weekIndex].dataInput[i].date ===
                            response.dataInput.date
                        ) {
                            indexUpdate = i;
                        }
                    }

                    let newWeeksInput = lodash.cloneDeep(weeks);

                    newWeeksInput[weekIndex].dataInput[indexUpdate] =
                        response.dataInput;

                    dispatchReportAction({
                        type: 'updateEditableTableWeeks',
                        payload: newWeeksInput,
                    });

                    dispatch(
                        updateEditableTableWeeksRequest({
                            data: newWeeksInput,
                        })
                    );
                }

                toast.success(editSuccessText);
            }
        }
    };

    const handleClickEditButton = (index) => {
        let newDisabledArray = [...inputsAndButtonsProps.disabledArray];
        let newButtonsTexts = [...inputsAndButtonsProps.buttonsTexts];

        for (let i = 0; i < newDisabledArray.length; i++) {
            if (i === index) {
                newDisabledArray[i] = false;
                newButtonsTexts[i] = saveButtonText;
            } else {
                newDisabledArray[i] = true;
                newButtonsTexts[i] = editButtonText;
            }
        }

        setInputsAndButtonsProps({
            disabledArray: newDisabledArray,
            buttonsTexts: newButtonsTexts,
        });
    };

    const handleClickSaveButton = async (index, dayInput) => {
        let newDisabledArray = [...inputsAndButtonsProps.disabledArray];
        let newButtonsTexts = [...inputsAndButtonsProps.buttonsTexts];

        newButtonsTexts[index] = loadingButtonText;
        newDisabledArray[index] = true;

        setLoading(true);

        setInputsAndButtonsProps({
            disabledArray: newDisabledArray,
            buttonsTexts: newButtonsTexts,
        });

        await updateRow(dayInput.date.slice(0, 10));

        newButtonsTexts[index] = editButtonText;

        setInputsAndButtonsProps({
            disabledArray: newDisabledArray,
            buttonsTexts: newButtonsTexts,
        });

        setLoading(false);
    };

    const handleClickCancelButton = () => {
        let newDisabledArray = [...inputsAndButtonsProps.disabledArray];
        let newButtonsTexts = [...inputsAndButtonsProps.buttonsTexts];

        for (let i = 0; i < newDisabledArray.length; i++) {
            newDisabledArray[i] = true;
            newButtonsTexts[i] = editButtonText;
        }

        setInputsAndButtonsProps({
            disabledArray: newDisabledArray,
            buttonsTexts: newButtonsTexts,
        });
    };

    const handleClick = (e, index, dayInput) => {
        let element = e.currentTarget;
        let id = element.attributes['id'].value;

        if (id === editButtonText) {
            handleClickEditButton(index);
        } else if (id === saveButtonText) {
            handleClickSaveButton(index, dayInput);
        } else if (id === cancelButtonText) {
            handleClickCancelButton();
        }
    };

    useEffect(() => {
        const initialData = {};

        if (weeks.length !== 0) {
            let week = weeks[weekIndex];
            for (let i = 0; i < week.dataInput.length; i++) {
                let dayInput = week.dataInput[i];

                let durationOfNaps = dayInput.durationOfNaps;
                let durationOfAwk = dayInput.durationOfAwk;

                if (durationOfNaps !== null && durationOfNaps.length > 0) {
                    durationOfNaps.forEach(function (currentValue, index) {
                        initialData[
                            `durationOfNaps${index}/${dayInput.date.slice(
                                0,
                                10
                            )}`
                        ] = timeFormatter(currentValue);
                    });
                }

                if (durationOfAwk !== null && durationOfAwk.length > 0) {
                    durationOfAwk.forEach(function (currentValue, index) {
                        initialData[
                            `durationOfAwk${index}/${dayInput.date.slice(
                                0,
                                10
                            )}`
                        ] = timeFormatter(currentValue);
                    });
                }

                tds.map((td) => {
                    if (
                        'diaryVersion' in dayInput &&
                        dayInput?.diaryVersion >= 2
                    ) {
                        switch (td.property) {
                            case 'bedTime':
                            case 'trySleepStart':
                            case 'wakeTime':
                            case 'getUpTime': {
                                if (dayInput[td.property] !== null) {
                                    initialData[
                                        `${td.property}/${dayInput.date.slice(
                                            0,
                                            10
                                        )}`
                                    ] = dayInput[td.property].slice(11, 16);
                                }

                                break;
                            }
                            case 'totalDurationOfAwk':
                            case 'sleepDuration':
                            case 'latencyDuration': {
                                if (dayInput[td.property] !== null) {
                                    initialData[
                                        `${td.property}/${dayInput.date.slice(
                                            0,
                                            10
                                        )}`
                                    ] = timeFormatter(dayInput[td.property]);
                                }
                                break;
                            }
                            case 'durationOfNaps':
                                break;
                        }
                    } else {
                        switch (td.property) {
                            case 'bedTime':
                            case 'wakeTime':
                            case 'getUpTime': {
                                if (dayInput[td.property] !== null) {
                                    initialData[
                                        `${td.property}/${dayInput.date.slice(
                                            0,
                                            10
                                        )}`
                                    ] = dayInput[td.property].slice(11, 16);
                                }

                                break;
                            }
                            case 'sleepDuration':
                            case 'latencyDuration': {
                                if (dayInput[td.property] !== null) {
                                    initialData[
                                        `${td.property}/${dayInput.date.slice(
                                            0,
                                            10
                                        )}`
                                    ] = timeFormatter(dayInput[td.property]);
                                }
                                break;
                            }
                            case 'durationOfAwk':
                            case 'durationOfNaps':
                                break;
                        }
                    }
                });
            }

            formRef.current.setData(initialData);
        }
    }, [weekIndex]);

    useEffect(() => {
        if (weeks.length !== 0) {
            setInputsAndButtonsProps(
                getInputsAndButtonsProps(weeks, weekIndex)
            );
        }
    }, [weekIndex, lang]);

    if (weeks.length === 0) {
        return <></>;
    }

    return (
        <>
            <Col xs="12">
                <Card className="cardShadow">
                    <CardHeader className="cardHeader">
                        <CardTitle className="cardTitle text-center">
                            <h5 className="cardHeaderSizeLarge">{`${cardTitle} ${weekNumbers[weekIndex]}`}</h5>
                        </CardTitle>
                    </CardHeader>
                    <CardBody className={styles.editDataCardBodyBg}>
                        <Row>
                            <Col xs="4" className="text-left">
                                <button
                                    className={styles.editDataButton}
                                    type="button"
                                    value="previous"
                                    onClick={(e) =>
                                        handlePrevNextWeek(
                                            e.currentTarget.value
                                        )
                                    }
                                >
                                    <FontAwesomeIcon icon={previousIcon} />
                                </button>
                            </Col>
                            <Col xs="4" className="text-center">
                                <h5>{`${
                                    localeDateFormatter(
                                        weekReference[weekIndex][0],
                                        locale
                                    )
                                        ? localeDateFormatter(
                                              weekReference[weekIndex][0],
                                              locale
                                          )
                                        : '--'
                                } - ${
                                    localeDateFormatter(
                                        weekReference[weekIndex][1],
                                        locale
                                    )
                                        ? localeDateFormatter(
                                              weekReference[weekIndex][1],
                                              locale
                                          )
                                        : '--'
                                }`}</h5>
                            </Col>
                            <Col xs="4" className="text-right">
                                <button
                                    className={styles.editDataButton}
                                    type="button"
                                    value="next"
                                    onClick={(e) =>
                                        handlePrevNextWeek(
                                            e.currentTarget.value
                                        )
                                    }
                                >
                                    <FontAwesomeIcon icon={nextIcon} />
                                </button>
                            </Col>
                            <Col
                                xs="12"
                                className={styles.editDataTableContainer}
                            >
                                <Form ref={formRef}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <TableHeaders ths={ths} />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {weeks[weekIndex].dataInput.map(
                                                (dayInput, index) => (
                                                    <tr
                                                        key={dayInput.date.slice(
                                                            0,
                                                            10
                                                        )}
                                                        id={dayInput.date.slice(
                                                            0,
                                                            10
                                                        )}
                                                    >
                                                        <TableTds
                                                            tds={tds}
                                                            texts={{
                                                                editButtonText,
                                                                loadingButtonText,
                                                                saveButtonText,
                                                                cancelButtonText,
                                                            }}
                                                            dayInput={dayInput}
                                                            dayInputDecimals={
                                                                weeks[weekIndex]
                                                                    .dataInputDecimals[
                                                                    index
                                                                ]
                                                            }
                                                            dayInputStatistics={
                                                                weeks[weekIndex]
                                                                    .dataInputStatistics
                                                            }
                                                            buttonText={
                                                                inputsAndButtonsProps
                                                                    .buttonsTexts[
                                                                    index
                                                                ]
                                                            }
                                                            disabled={
                                                                inputsAndButtonsProps
                                                                    .disabledArray[
                                                                    index
                                                                ]
                                                            }
                                                            loading={loading}
                                                            locale={locale}
                                                            index={index}
                                                            handleClick={
                                                                handleClick
                                                            }
                                                        />
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </Table>
                                </Form>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: incorrectValueSpanText,
                            }}
                        ></p>
                        <hr />
                        <p
                            dangerouslySetInnerHTML={{
                                __html: automaticFixedValueSpanText,
                            }}
                        ></p>
                    </CardFooter>
                </Card>
            </Col>
        </>
    );
}

EditData.propTypes = {
    userId: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    textData: PropTypes.object.isRequired,
    locale: PropTypes.object.isRequired,
    dispatchReportAction: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
};
