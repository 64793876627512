import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import history from '../../../services/history';
import { MyForm as Form } from './components/form';

export default function ManagerCredentials({ currentUser }) {
    const [manager, setManager] = useState({});

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];
    const schemaData = schemaConfig[lang][currentUser.permissions];

    useEffect(() => {
        if (history.location.state?.entity) {
            setManager(history.location.state.entity);
        } else {
            history.push('/managers');
        }
    }, []);

    if (Object.entries(manager).length === 0) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form
                    manager={manager}
                    fields={pageData.fields}
                    schema={schemaData.schema}
                    texts={{
                        successText: pageData.successText,
                        sendText: pageData.sendText,
                        changeEmailText: pageData.changeEmailText,
                        cancelText: pageData.cancelText,
                    }}
                    languageOptions={pageData.langOptions}
                />
            </CardBody>
        </Card>
    );
}

ManagerCredentials.propTypes = {
    currentUser: PropTypes.object.isRequired,
};
