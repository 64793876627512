import Input from '../../../components/inputForm/inputText';

export default {
    pt: {
        customer_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'ID Secundário',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'role_id',
                        label: 'Tipo de usuário',
                        readOnly: true,
                    },
                    userTypes: {
                        1: 'Paciente',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'active',
                        label: 'Ativo',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialStart',
                        label: 'Início',
                        type: 'date',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialEnd',
                        label: 'Fim',
                        type: 'date',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'diary_id',
                        label: 'Diário',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
            ],
            deviceFields: [
                {
                    component: Input,
                    props: {
                        name: 'device_id',
                        label: 'Número de série',
                        type: 'text',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'body_part',
                        type: 'text',
                        label: 'Parte do corpo',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'received_at',
                        type: 'date',
                        label: 'Data de recebimento',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
            ],
            devicesText: 'Dispositivos',
            serialNumberText: 'Numéro de série',
            receivedAtText: 'Data de recebimento',
            bodyPartText: 'Parte do corpo',
            successText: 'Paciente excluído com sucesso!',
            deleteText: 'Excluir',
            cancelText: 'Cancelar',
            activeText: 'Sim',
            inactiveText: 'Não',
            bodyPartTexts: {
                wrist: 'Pulso',
                necklace: 'Colar',
            },
            defaultDiaryText: 'Diário padrão',
        },
        manager: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'ID Secundário',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'role_id',
                        label: 'Tipo de usuário',
                        readOnly: true,
                    },
                    userTypes: {
                        1: 'Paciente',
                        2: 'Gestor',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'active',
                        label: 'Ativo',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialStart',
                        label: 'Início',
                        type: 'date',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialEnd',
                        label: 'Fim',
                        type: 'date',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'diary_id',
                        label: 'Diário',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
            ],
            deviceFields: [
                {
                    component: Input,
                    props: {
                        name: 'device_id',
                        label: 'Número de série',
                        type: 'text',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'body_part',
                        type: 'text',
                        label: 'Parte do corpo',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'received_at',
                        type: 'date',
                        label: 'Data de recebimento',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
            ],
            devicesText: 'Dispositivos',
            serialNumberText: 'Numéro de série',
            receivedAtText: 'Data de recebimento',
            bodyPartText: 'Parte do corpo',
            successText: 'Usuário excluído com sucesso!',
            deleteText: 'Excluir',
            cancelText: 'Cancelar',
            activeText: 'Sim',
            inactiveText: 'Não',
            bodyPartTexts: {
                wrist: 'Pulso',
                necklace: 'Colar',
            },
            defaultDiaryText: 'Diário padrão',
        },
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'company_name',
                        label: 'Empresa',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'ID Secundário',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'role_id',
                        label: 'Tipo de usuário',
                        readOnly: true,
                    },
                    userTypes: {
                        2: 'Gestor',
                        3: 'Administrador Condor',
                        4: 'Gestor Administrador',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'active',
                        label: 'Ativo',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            successText: 'Usuário excluído com sucesso!',
            deleteText: 'Excluir',
            cancelText: 'Cancelar',
            activeText: 'Sim',
            inactiveText: 'Não',
        },
    },
    en: {
        customer_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'Secondary ID',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'role_id',
                        label: 'User type',
                        readOnly: true,
                    },
                    userTypes: {
                        1: 'Patient',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'active',
                        label: 'Active',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialStart',
                        label: 'Start',
                        type: 'date',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialEnd',
                        label: 'End',
                        type: 'date',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'diary_id',
                        label: 'Diary',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
            ],
            deviceFields: [
                {
                    component: Input,
                    props: {
                        name: 'device_id',
                        label: 'Serial number',
                        type: 'text',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'body_part',
                        type: 'text',
                        label: 'Body part',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'received_at',
                        type: 'date',
                        label: 'Received at',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
            ],
            devicesText: 'Devices',
            serialNumberText: 'Serial number',
            receivedAtText: 'Received at',
            bodyPartText: 'Body part',
            successText: 'Patient successfully deleted!',
            deleteText: 'Delete',
            cancelText: 'Cancel',
            activeText: 'Yes',
            inactiveText: 'No',
            bodyPartTexts: {
                wrist: 'Wrist',
                necklace: 'Necklace',
            },
            defaultDiaryText: 'Default diary',
        },
        manager: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'Secondary ID',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'role_id',
                        label: 'User type',
                        readOnly: true,
                    },
                    userTypes: {
                        1: 'Patient',
                        2: 'Manager',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'active',
                        label: 'Active',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialStart',
                        label: 'Start',
                        type: 'date',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialEnd',
                        label: 'End',
                        type: 'date',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'diary_id',
                        label: 'Diary',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
            ],
            deviceFields: [
                {
                    component: Input,
                    props: {
                        name: 'device_id',
                        label: 'Serial number',
                        type: 'text',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'body_part',
                        type: 'text',
                        label: 'Body part',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'received_at',
                        type: 'date',
                        label: 'Received at',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
            ],
            devicesText: 'Devices',
            serialNumberText: 'Serial number',
            receivedAtText: 'Received at',
            bodyPartText: 'Body part',
            successText: 'User successfully deleted!',
            deleteText: 'Delete',
            cancelText: 'Cancel',
            activeText: 'Yes',
            inactiveText: 'No',
            bodyPartTexts: {
                wrist: 'Wrist',
                necklace: 'Necklace',
            },
            defaultDiaryText: 'Default diary',
        },
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'company_name',
                        label: 'Company',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'Secondary ID',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'role_id',
                        label: 'User type',
                        readOnly: true,
                    },
                    userTypes: {
                        2: 'Manager',
                        3: 'Condor Admin',
                        4: 'Manager Admin',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'active',
                        label: 'Active',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            successText: 'User successfully deleted!',
            deleteText: 'Delete',
            cancelText: 'Cancel',
            activeText: 'Yes',
            inactiveText: 'No',
        },
    },
};
