import {
    faBook,
    faEdit,
    faEnvelope,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';

export default {
    pt: {
        customer_admin: {
            searchByNameText: 'Procurar paciente por nome',
            searchBySecondaryIdText: 'Procurar paciente por id secundário',
            addText: 'Adicionar',
            addUserText: 'Adicionar paciente',
            modalProps: {
                className: 'btn btn-primary ml-2',
                title: 'Aumente seu plano!',
                text: `Sua empresa atingiu o número máximo de pacientes ativos do plano!
                Para conseguir adicionar mais pacientes, inative pacientes ou
                faça o upgrade do plano!`,
                hasPrimaryButton: false,
                primaryButtonHasLink: false,
                primaryButtonPathName: '',
                primaryButtonText: 'Alterar plano',
                hasSecondaryButton: true,
                secondaryButtonHasLink: false,
                secondaryButtonPathName: '',
                secondaryButtonText: 'Fechar',
            },
            ths: [
                {
                    name: 'ID',
                    property: 'id',
                },
                {
                    name: 'Nome',
                    property: 'name',
                },
                {
                    name: 'ID Secundário',
                    property: 'secondary_id',
                },
                {
                    name: 'e-Mail',
                    property: 'email',
                },
                {
                    name: 'Ativo',
                    property: 'active',
                },
                {
                    name: 'Ações',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'ID',
                    property: 'id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Nome',
                    property: 'name',
                    hasLink: true,
                    to: 'users/report',
                },
                {
                    th: 'ID Secundário',
                    property: 'secondary_id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'e-Mail',
                    property: 'email',
                    hasLink: false,
                    to: '',
                },

                {
                    th: 'Ativo',
                    property: 'active',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Ações',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Relatório',
                    className: 'btn btn-success',
                    to: 'users/report',
                    icon: faBook,
                },
                {
                    title: 'Editar',
                    className: 'btn btn-secondary ml-2',
                    to: 'users/edit',
                    icon: faEdit,
                },
                {
                    title: 'Credenciais',
                    className: 'btn btn-info ml-2',
                    to: 'users/credentials',
                    icon: faEnvelope,
                },
                {
                    title: 'Excluir',
                    className: 'btn btn-danger ml-2',
                    to: 'users/delete',
                    icon: faTrash,
                },
            ],
            activeText: 'Sim',
            inactiveText: 'Não',
            noUsersText: 'Você não possui pacientes cadastrados!',
            importUserText: 'Importar pacientes',
        },
        manager: {
            searchByNameText: 'Procurar paciente por nome',
            searchBySecondaryIdText: 'Procurar paciente por id secundário',
            addText: 'Adicionar',
            addUserText: 'Adicionar paciente',
            modalProps: {
                className: 'btn btn-primary ml-2',
                title: 'Aumente seu plano!',
                text: `Sua empresa atingiu o número máximo de pacientes ativos do plano!
                Para conseguir adicionar mais pacientes, inative pacientes ou
                faça o upgrade do plano!`,
                hasPrimaryButton: true,
                primaryButtonHasLink: true,
                primaryButtonPathName: 'change-plan',
                primaryButtonText: 'Alterar plano',
                hasSecondaryButton: true,
                secondaryButtonHasLink: true,
                secondaryButtonPathName: 'users/add',
                secondaryButtonText: 'Adicionar Gestor',
            },
            ths: [
                {
                    name: 'ID',
                    property: 'id',
                },
                {
                    name: 'Nome',
                    property: 'name',
                },
                {
                    name: 'ID Secundário',
                    property: 'secondary_id',
                },
                {
                    name: 'e-Mail',
                    property: 'email',
                },
                {
                    name: 'Ativo',
                    property: 'active',
                },
                {
                    name: 'Ações',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'ID',
                    property: 'id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Nome',
                    property: 'name',
                    hasLink: true,
                    to: 'users/report',
                },
                {
                    th: 'ID Secundário',
                    property: 'secondary_id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'e-Mail',
                    property: 'email',
                    hasLink: false,
                    to: '',
                },

                {
                    th: 'Ativo',
                    property: 'active',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Ações',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Relatório',
                    className: 'btn btn-success',
                    to: 'users/report',
                    icon: faBook,
                },
                {
                    title: 'Editar',
                    className: 'btn btn-secondary ml-2',
                    to: 'users/edit',
                    icon: faEdit,
                },
                {
                    title: 'Credenciais',
                    className: 'btn btn-info ml-2',
                    to: 'users/credentials',
                    icon: faEnvelope,
                },
                {
                    title: 'Excluir',
                    className: 'btn btn-danger ml-2',
                    to: 'users/delete',
                    icon: faTrash,
                },
            ],
            activeText: 'Sim',
            inactiveText: 'Não',
            noUsersText: 'Você não possui pacientes cadastrados!',
            importUserText: 'Importar pacientes',
        },
        condor_admin: {
            searchByNameText: 'Procurar usuário por nome',
            searchBySecondaryIdText: 'Procurar usuário por id secundário',
            addText: 'Adicionar',
            addUserText: 'Adicionar usuário',
            modalProps: {},
            ths: [
                {
                    name: 'ID',
                    property: 'id',
                },
                {
                    name: 'Empresa',
                    property: 'company_name',
                },
                {
                    name: 'Nome',
                    property: 'name',
                },
                {
                    name: 'ID Secundário',
                    property: 'secondary_id',
                },
                {
                    name: 'e-Mail',
                    property: 'email',
                },
                {
                    name: 'Ativo',
                    property: 'active',
                },
                {
                    name: 'Tipo de Usuário',
                    property: 'role_id',
                },
                {
                    name: 'Ações',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'ID',
                    property: 'id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Empresa',
                    property: 'company_name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Nome',
                    property: 'name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'ID Secundário',
                    property: 'secondary_id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'e-Mail',
                    property: 'email',
                    hasLink: false,
                    to: '',
                },

                {
                    th: 'Ativo',
                    property: 'active',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Tipo de Usuário',
                    property: 'role_id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Ações',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Editar',
                    className: 'btn btn-secondary',
                    to: 'users/edit',
                    icon: faEdit,
                },
                {
                    title: 'Credenciais',
                    className: 'btn btn-info ml-2',
                    to: 'users/credentials',
                    icon: faEnvelope,
                },
                {
                    title: 'Excluir',
                    className: 'btn btn-danger ml-2',
                    to: 'users/delete',
                    icon: faTrash,
                },
            ],
            activeText: 'Sim',
            inactiveText: 'Não',
            roleDescription: {
                1: 'Paciente',
                2: 'Gestor',
                3: 'Condor Admin',
                4: 'Gestor Admin',
            },
            noUsersText: 'Você não possui clientes cadastrados!',
        },
    },
    en: {
        customer_admin: {
            searchByNameText: 'Search patient by name',
            searchBySecondaryIdText: 'Search patient by secondary ID',
            addText: 'Add',
            addUserText: 'Add patient',
            modalProps: {
                className: 'btn btn-primary ml-2',
                title: 'Upgrade your plan!',
                text: `Your company has reached the maximum number of active patients allowed in your plan!
                To be able to add more patients, inactivate patients or
                upgrade your plan!`,
                hasPrimaryButton: false,
                primaryButtonHasLink: false,
                primaryButtonPathName: '',
                primaryButtonText: 'Change Plan',
                hasSecondaryButton: true,
                secondaryButtonHasLink: false,
                secondaryButtonPathName: '',
                secondaryButtonText: 'Close',
            },
            ths: [
                {
                    name: 'ID',
                    property: 'id',
                },
                {
                    name: 'Name',
                    property: 'name',
                },
                {
                    name: 'Secondary ID',
                    property: 'secondary_id',
                },
                {
                    name: 'e-Mail',
                    property: 'email',
                },
                {
                    name: 'Active',
                    property: 'active',
                },
                {
                    name: 'Actions',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'ID',
                    property: 'id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Name',
                    property: 'name',
                    hasLink: true,
                    to: 'users/report',
                },
                {
                    th: 'Secondary ID',
                    property: 'secondary_id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'e-Mail',
                    property: 'email',
                    hasLink: false,
                    to: '',
                },

                {
                    th: 'Active',
                    property: 'active',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Actions',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Report',
                    className: 'btn btn-success',
                    to: 'users/report',
                    icon: faBook,
                },
                {
                    title: 'Edit',
                    className: 'btn btn-secondary ml-2',
                    to: 'users/edit',
                    icon: faEdit,
                },
                {
                    title: 'Credentials',
                    className: 'btn btn-info ml-2',
                    to: 'users/credentials',
                    icon: faEnvelope,
                },
                {
                    title: 'Delete',
                    className: 'btn btn-danger ml-2',
                    to: 'users/delete',
                    icon: faTrash,
                },
            ],
            activeText: 'Yes',
            inactiveText: 'No',
            noUsersText: `You dont't have registered patients!`,
            importUserText: 'Import patients',
        },
        manager: {
            searchByNameText: 'Search patient by name',
            searchBySecondaryIdText: 'Search patient by secondary ID',
            addText: 'Add',
            addUserText: 'Add patient',
            modalProps: {
                className: 'btn btn-primary ml-2',
                title: 'Upgrade your plan!',
                text: `Your company has reached the maximum number of active patients allowed in your plan!
                To be able to add more patients, inactivate patients or
                upgrade your plan!`,
                hasPrimaryButton: true,
                primaryButtonHasLink: true,
                primaryButtonPathName: 'change-plan',
                primaryButtonText: 'Change Plan',
                hasSecondaryButton: true,
                secondaryButtonHasLink: true,
                secondaryButtonPathName: 'users/add',
                secondaryButtonText: 'Add Manager',
            },
            ths: [
                {
                    name: 'ID',
                    property: 'id',
                },
                {
                    name: 'Name',
                    property: 'name',
                },
                {
                    name: 'Secondary ID',
                    property: 'secondary_id',
                },
                {
                    name: 'e-Mail',
                    property: 'email',
                },
                {
                    name: 'Active',
                    property: 'active',
                },
                {
                    name: 'Actions',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'ID',
                    property: 'id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Name',
                    property: 'name',
                    hasLink: true,
                    to: 'users/report',
                },
                {
                    th: 'Secondary ID',
                    property: 'secondary_id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'e-Mail',
                    property: 'email',
                    hasLink: false,
                    to: '',
                },

                {
                    th: 'Active',
                    property: 'active',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Actions',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Report',
                    className: 'btn btn-success',
                    to: 'users/report',
                    icon: faBook,
                },
                {
                    title: 'Edit',
                    className: 'btn btn-secondary ml-2',
                    to: 'users/edit',
                    icon: faEdit,
                },
                {
                    title: 'Credentials',
                    className: 'btn btn-info ml-2',
                    to: 'users/credentials',
                    icon: faEnvelope,
                },
                {
                    title: 'Delete',
                    className: 'btn btn-danger ml-2',
                    to: 'users/delete',
                    icon: faTrash,
                },
            ],
            activeText: 'Yes',
            inactiveText: 'No',
            noUsersText: `You dont't have registered patients!`,
            importUserText: 'Import patients',
        },
        condor_admin: {
            searchByNameText: 'Search user by name',
            searchBySecondaryIdText: 'Search user by secondary ID',
            addText: 'Add',
            addUserText: 'Add user',
            modalProps: {},
            ths: [
                {
                    name: 'ID',
                    property: 'id',
                },
                {
                    name: 'Company',
                    property: 'company_name',
                },
                {
                    name: 'Name',
                    property: 'name',
                },
                {
                    name: 'Secondary ID',
                    property: 'secondary_id',
                },
                {
                    name: 'e-Mail',
                    property: 'email',
                },
                {
                    name: 'Active',
                    property: 'active',
                },
                {
                    name: 'User Type',
                    property: 'role_id',
                },
                {
                    name: 'Actions',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'ID',
                    property: 'id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Company',
                    property: 'company_name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Name',
                    property: 'name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Secondary ID',
                    property: 'secondary_id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'e-Mail',
                    property: 'email',
                    hasLink: false,
                    to: '',
                },

                {
                    th: 'Active',
                    property: 'active',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'User Type',
                    property: 'role_id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Actions',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Edit',
                    className: 'btn btn-secondary',
                    to: 'users/edit',
                    icon: faEdit,
                },
                {
                    title: 'Credentials',
                    className: 'btn btn-info ml-2',
                    to: 'users/credentials',
                    icon: faEnvelope,
                },
                {
                    title: 'Delete',
                    className: 'btn btn-danger ml-2',
                    to: 'users/delete',
                    icon: faTrash,
                },
            ],
            activeText: 'Yes',
            inactiveText: 'No',
            roleDescription: {
                1: 'Patient',
                2: 'Manager',
                3: 'Condor Admin',
                4: 'Manager Admin',
            },
            noUsersText: `You dont't have registered customers!`,
        },
    },
};
