import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Switch } from '@headlessui/react';
import {
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import Td from '../../../components/table/td';
import ActionsContainer from '../../../components/table/actionsContainer';
import {
    localeDateFormatter,
    localeDateTimeFormatter,
} from '../../../helpers/formatters';
import { activateDevice } from '../../../services/api/devices';

TableTds.propTypes = {
    texts: PropTypes.shape({
        successText: PropTypes.string.isRequired,
        activateDeviceText: PropTypes.string.isRequired,
        deactivateDeviceText: PropTypes.string.isRequired,
        warningDeactivateDeviceText: PropTypes.string.isRequired,
        attentionText: PropTypes.string.isRequired,
        deactivateText: PropTypes.string.isRequired,
        cancelText: PropTypes.string.isRequired,
    }).isRequired,
    tds: PropTypes.array.isRequired,
    device: PropTypes.object.isRequired,
    actions: PropTypes.array,
    loading: PropTypes.bool.isRequired,
    setLoading: PropTypes.func.isRequired,
    updateDevice: PropTypes.func.isRequired,
};

export function TableTds({
    texts,
    tds,
    device,
    actions,
    loading,
    setLoading,
    updateDevice,
}) {
    const { dateLocale } = useSelector((state) => state.locale);

    const [enabled, setEnabled] = useState(device?.active);
    const [clicked, setClicked] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    async function handleChangeToogle(newToogleState) {
        setClicked(true);
        setLoading(true);

        const response = await activateDevice(device.id, newToogleState);

        setClicked(false);
        setLoading(false);

        if (response) {
            toast.success(texts.successText);

            updateDevice(device.id, newToogleState);

            setEnabled(newToogleState);
        }
    }

    function handleSwitchChange(newValue) {
        if (enabled && device.user_id) {
            toggleModal();
        } else {
            handleChangeToogle(newValue);
        }
    }

    return tds.map((td, index) => {
        switch (td.property) {
            case 'actions':
                return (
                    <Td key={index} title={td.th}>
                        <ActionsContainer actions={actions} entity={device} />
                    </Td>
                );
            case 'active':
                return (
                    <Td key={index} title={td.th}>
                        {loading && clicked && <Spinner size="sm" />}
                        <Switch
                            checked={enabled}
                            onChange={handleSwitchChange}
                            className={`toggleButton ${
                                enabled ? 'enabled' : ''
                            }`}
                            title={
                                enabled
                                    ? texts.deactivateDeviceText
                                    : texts.activateDeviceText
                            }
                            disabled={loading}
                        >
                            <span
                                className={`toggleIcon ${
                                    enabled ? 'enabled' : ''
                                }`}
                            />
                        </Switch>
                        <Modal isOpen={isOpen} toggle={toggleModal}>
                            <ModalHeader toggle={toggleModal}>
                                {texts.attentionText}
                            </ModalHeader>
                            <ModalBody>
                                {texts.warningDeactivateDeviceText}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    type="button"
                                    color="primary"
                                    onClick={() => {
                                        toggleModal();
                                        handleChangeToogle(false);
                                    }}
                                >
                                    {texts.deactivateText}
                                </Button>

                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={toggleModal}
                                >
                                    {texts.cancelText}
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Td>
                );
            case 'company_name':
            case 'user_name':
                return (
                    <Td key={index} title={td.th}>
                        {device[td.property] || '--'}
                    </Td>
                );
            case 'last_server_connection':
                return (
                    <Td key={index} title={td.th}>
                        {device[td.property]
                            ? localeDateTimeFormatter(
                                  device[td.property],
                                  dateLocale
                              )
                                ? localeDateTimeFormatter(
                                      device[td.property],
                                      dateLocale
                                  )
                                : '--'
                            : '--'}
                    </Td>
                );
            case 'received_at':
                return (
                    <Td key={index} title={td.th}>
                        {device[td.property]
                            ? localeDateFormatter(
                                  device[td.property],
                                  dateLocale
                              )
                                ? localeDateFormatter(
                                      device[td.property],
                                      dateLocale
                                  )
                                : '--'
                            : '--'}
                    </Td>
                );
            default:
                if (td.hasLink)
                    return (
                        <Td key={index} title={td.th}>
                            <Link
                                to={{
                                    pathname: td.to,
                                    state: { device, entity: device },
                                }}
                            >
                                {device[td.property]}
                            </Link>
                        </Td>
                    );
                return (
                    <Td key={index} title={td.th}>
                        {device[td.property]}
                    </Td>
                );
        }
    });
}
