import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import lodash from 'lodash';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import history from '../../../services/history';
import { deleteUser } from '../../../services/api/users';
import { validateForm } from '../../../helpers/formValidator';
import { setLoading } from '../../../store/actions/loadingActions';
import { fetchDiaries } from '../../../services/api/diaries';
import { MyForm as Form } from './components/form';

export default function DeleteUser({ currentUser }) {
    const formRef = useRef(null);

    const [devicesFields, setDevicesFields] = useState([]);
    const [diary, setDiary] = useState(null);
    const [user, setUser] = useState({});

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];
    const { schema } = schemaConfig[lang][currentUser.permissions];

    async function handleSubmit(data) {
        const { isValid, errors } = await validateForm(data, schema);

        if (isValid) {
            formRef.current.setErrors(errors);

            dispatch(setLoading(true));

            const response = await deleteUser(user.id);

            dispatch(setLoading(false));

            if (response) {
                toast.success(pageData.successText);
                history.push('/users');
            }
        } else {
            formRef.current.setErrors(errors);
        }
    }

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            if (history.location.state?.entity) {
                let tempUser = history.location.state.entity;

                if (currentUser.permissions !== 'condor_admin') {
                    if (tempUser?.devices?.length > 0) {
                        let tempDevicesFields = tempUser?.devices?.map((_) => {
                            let tempDeviceFields = lodash.cloneDeep(
                                pageData.deviceFields
                            );
                            let key = crypto.randomUUID();

                            tempDeviceFields[0].key = key;
                            tempDeviceFields[1].key = key;

                            return tempDeviceFields;
                        });
                        setDevicesFields(tempDevicesFields);
                    }

                    const diariesData = await fetchDiaries();

                    let foundDiary = diariesData?.diaries.find(
                        (diary) =>
                            diary.id === tempUser.trials_configurations.diaryId
                    );

                    if (!foundDiary) {
                        foundDiary = { id: 0, name: pageData.defaultDiaryText };
                    }

                    setDiary(foundDiary);
                }

                setUser(tempUser);
            } else {
                history.push('/users');
            }

            dispatch(setLoading(false));
        })();
    }, []);

    useEffect(() => {
        if (devicesFields.length > 0) {
            let tempDevicesFields = lodash.cloneDeep(devicesFields);

            tempDevicesFields = tempDevicesFields.map((deviceFields) => {
                deviceFields[0].props.label = pageData.serialNumberText;
                deviceFields[1].props.label = pageData.bodyPartText;
                deviceFields[2].props.label = pageData.receivedAtText;

                return deviceFields;
            });

            setDevicesFields(tempDevicesFields);
        }
    }, [lang]);

    if (Object.entries(user).length === 0) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form
                    formRef={formRef}
                    pageData={pageData}
                    user={user}
                    diary={diary}
                    fields={pageData.fields}
                    devicesFields={devicesFields}
                    handleSubmit={handleSubmit}
                />
            </CardBody>
        </Card>
    );
}

DeleteUser.propTypes = {
    currentUser: PropTypes.object.isRequired,
};
