import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './searchAndAddContainer.module.css';

export function SearchAndAddContainer({ users, setFilteredUsers, texts }) {
    const [nameFilter, setNameFilter] = useState('');
    const [secondaryIdFilter, setSecondaryIdFilter] = useState('');

    const { searchByNameText, searchBySecondaryIdText, addText, addUserText } =
        texts;

    function handleNameInputChange(e) {
        setNameFilter(e.target.value);
    }

    function handleSecondaryIdInputChange(e) {
        setSecondaryIdFilter(e.target.value);
    }

    useEffect(() => {
        let tempFilteredUsers = [];

        if (nameFilter && secondaryIdFilter) {
            tempFilteredUsers = users.filter((user) =>
                user.secondary_id === null
                    ? ''
                          .toUpperCase()
                          .includes(secondaryIdFilter.toUpperCase()) &&
                      user.name.toUpperCase().includes(nameFilter.toUpperCase())
                    : user.secondary_id
                          .toUpperCase()
                          .includes(secondaryIdFilter.toUpperCase()) &&
                      user.name.toUpperCase().includes(nameFilter.toUpperCase())
            );
        } else if (nameFilter) {
            tempFilteredUsers = users.filter((user) =>
                user.name.toUpperCase().includes(nameFilter.toUpperCase())
            );
        } else if (secondaryIdFilter) {
            tempFilteredUsers = users.filter((user) =>
                user.secondary_id === null
                    ? ''.toUpperCase().includes(secondaryIdFilter.toUpperCase())
                    : user.secondary_id
                          .toUpperCase()
                          .includes(secondaryIdFilter.toUpperCase())
            );
        } else {
            tempFilteredUsers = users;
        }

        setFilteredUsers(tempFilteredUsers);
    }, [nameFilter, secondaryIdFilter]);

    return (
        <Row>
            <Col xs="12">
                <div className={styles.searchAndAddContainer}>
                    <input
                        name="searchName"
                        placeholder={searchByNameText}
                        title={searchByNameText}
                        value={nameFilter}
                        onChange={handleNameInputChange}
                    />
                    <input
                        name="searchSecondaryId"
                        placeholder={searchBySecondaryIdText}
                        title={searchBySecondaryIdText}
                        value={secondaryIdFilter}
                        onChange={handleSecondaryIdInputChange}
                        className="ml-2"
                    />
                    <Link
                        to={{
                            pathname: 'managers/add',
                            state: { canAddManagers: true, to: '/managers' },
                        }}
                        className="btn btn-primary ml-2"
                        title={addUserText}
                    >
                        {addText}
                    </Link>
                </div>
            </Col>
        </Row>
    );
}

SearchAndAddContainer.propTypes = {
    users: PropTypes.array.isRequired,
    setFilteredUsers: PropTypes.func.isRequired,
    texts: PropTypes.object.isRequired,
};
