import React, { useState, useEffect, useRef } from 'react';
import { Col } from 'reactstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import PropTypes from 'prop-types';

import Card from '../../../../components/card/card';

export function Calendar({ data, textData, lang }) {
    const calendarRef = useRef();

    const [events, setEvents] = useState([]);

    const { filledDays, totalDays } = data;

    const {
        cardTitle,
        calendarLocale,
        filledText,
        notFilledText,
        filledColor,
        notFilledColor,
    } = textData;

    useEffect(() => {
        (function () {
            let e = [];

            for (let day of totalDays) {
                if (filledDays.includes(day)) {
                    e.push({
                        title: filledText,
                        start: day,
                        display: 'background',
                        backgroundColor: filledColor,
                    });
                } else {
                    e.push({
                        title: notFilledText,
                        start: day,
                        display: 'background',
                        backgroundColor: notFilledColor,
                    });
                }
            }

            setEvents(e);
        })();
    }, [lang]);

    if (filledDays.length === 0 && totalDays.length === 0) {
        return <></>;
    }

    return (
        <>
            <Col xs="12">
                <Card
                    title={cardTitle}
                    isTable={false}
                    textSize="lg"
                    textCenter
                >
                    <FullCalendar
                        ref={calendarRef}
                        plugins={[dayGridPlugin]}
                        initialView="dayGridMonth"
                        events={events}
                        headerToolbar={{
                            left: 'prev next today',
                            center: 'title',
                            right: 'dayGridMonth',
                        }}
                        locale={calendarLocale}
                    />
                </Card>
            </Col>
        </>
    );
}

Calendar.propTypes = {
    data: PropTypes.object.isRequired,
    textData: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};
