import React, { useEffect, useState, useRef, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col,
    Spinner,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { ShepherdTour } from 'react-shepherd';
import lodash from 'lodash';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import '../../../styles/pages/users/userReport/userReport.css';

import pageConfig from './page.config';
import { stepsOptions, tourOptions } from './tour.config';
import { setLoading } from '../../../store/actions/loadingActions';
import {
    updateUserRequest,
    updateDatesRequest,
    updateDaysInWeekRequest,
    updateWeekReferenceRequest,
    updateCalendarRequest,
    updateWeeklyEvolutionRequest,
    updateDailyEvolutionRequest,
    updateAverageTableRequest,
    updateEditableTableRequest,
} from '../../../store/actions/reportActions';
import {
    fetchCurrentWeekReferenceData,
    fetchCalendarData,
    fetchWeeklyEvolutionData,
    fetchDailyEvolutionData,
    fetchAverageTableData,
    fetchEditData,
    fetchActogramData,
    calculateSleepStates,
    fetchSleepScoringStatus,
    fetchSleepScoringData,
} from '../../../services/api/users';
import history from '../../../services/history';
import { UserTable } from './components/userTable';
import { ActionsContainer } from './components/actionsContainer';
import { Calendar } from './components/calendar';
import { WeeklyEvolution } from './components/weeklyEvolution';
import { CurrentWeek } from './components/currentWeek';
import { Tables } from './components/tables';
import { EditData } from './components/editData';
import { ExportButton } from './components/exportButton';
import { SelectDaysButton } from './components/selectDaysButton';
import { Actogram } from './components/actogram';
import { SleepScoring } from './components/sleepScoring';
import {
    fetchRawDataCsv,
    fetchTablesDataCsv,
    fetchDecimalsTablesDataCsv,
    fetchActigraphyData,
    fetchCustomQuestionsDataCsv,
} from '../../../services/api/files';
import { handleDate } from '../../../helpers/dateFormat';

UserReport.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

function reducer(state, action) {
    switch (action.type) {
        case 'updateWeekReference': {
            return {
                ...state,
                weekReference: action.payload,
            };
        }
        case 'updateCalendar': {
            return {
                ...state,
                calendar: action.payload,
            };
        }
        case 'updateWeeklyEvolution': {
            return {
                ...state,
                weeklyEvolution: action.payload,
            };
        }
        case 'updateDailyEvolution': {
            return {
                ...state,
                dailyEvolution: action.payload,
            };
        }
        case 'updateAverageTable': {
            return {
                ...state,
                averageTable: action.payload,
            };
        }
        case 'updateEditableTable': {
            return {
                ...state,
                editableTable: action.payload,
            };
        }
        case 'updateEditableTableWeeks': {
            return {
                ...state,
                editableTable: {
                    ...state.editableTable,
                    weeks: action.payload,
                },
            };
        }
        case 'updateActogram': {
            return {
                ...state,
                actogram: action.payload,
            };
        }
        case 'updateSleepScoring': {
            return {
                ...state,
                sleepScoring: action.payload,
            };
        }
    }
    throw Error('Unknown action: ' + action.type);
}

export default function UserReport({ currentUser }) {
    const [reportData, dispatchReportAction] = useReducer(reducer, {
        weekReference: null,
        calendar: null,
        weeklyEvolution: null,
        dailyEvolution: null,
        averageTable: null,
        editableTable: null,
        actogram: null,
        sleepScoring: null,
    });
    const [loadingCalendarData, setLoadingCalendarData] = useState(true);
    const [loadingWeeklyEvolutionData, setLoadingWeeklyEvolutionData] =
        useState(true);
    const [loadingCurrentWeekData, setLoadingCurrentWeekData] = useState(true);
    const [loadingTablesData, setLoadingTablesData] = useState(true);
    const [loadingEditData, setLoadingEditData] = useState(true);
    const [loadingActogramData, setLoadingActogramData] = useState(true);
    const [loadingSleepScoringData, setLoadingSleepScoringData] =
        useState(true);
    const [requestsStatus, setRequestsStatus] = useState({
        currentWeekReference: 'pending',
        calendar: 'pending',
        weeklyEvolution: 'pending',
        currentWeek: 'pending',
        averageTable: 'pending',
        editData: 'pending',
        actogram: 'pending',
        sleepScoring: 'pending',
    });
    const [weeklyEvolutionViewDays, setWeeklyEvolutionViewDays] = useState(7);

    const currentWeekRef = useRef();
    const weeklyEvolutionRef = useRef();
    const ref = useRef({
        firstRender: true,
        intervalId: null,
    });

    const [steps, setSteps] = useState([]);
    const [user, setUser] = useState(null);
    const [activeTab, setActiveTab] = useState('calendar');

    const [loadingCsvFile, setLoadingCsvFile] = useState(false);

    const [highlightDates, setHighlightDates] = useState([]);
    const [currentDates, setCurrentDates] = useState([null, null]);
    const [daysInWeek, setDaysInWeek] = useState(7);
    const [updateDailyEvolutionTab, setUpdateDailyEvolutionTab] =
        useState(false);
    const [loadingSleepStates, setLoadingSleepStates] = useState(false);
    const [sleepingStatesStatus, setSleepingStatesStatus] = useState(null);
    // sleepingStatesStatus = null | 'loading' | 'up_to_date' | 'states_to_calculate'
    // null = Não possui dados de actigrafia
    // loading = Calculando estados
    // up_to_date = Estados atualizados
    // states_to_calculate = Há estados a serem calculados
    const [hasStatesToCalculate, setHasStatesToCalculate] = useState(false);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const isLoading = useSelector((state) => state.loading.isLoading);
    const { dateLocale } = useSelector((state) => state.locale);

    const pageData = pageConfig[lang][currentUser.permissions];

    function toggle(tab) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    async function handleUpdateDailyEvolution(days_in_week) {
        setUpdateDailyEvolutionTab(true);

        let date = null;
        let min = null;
        let max = null;

        if (currentDates[0] === currentDates[1]) {
            date = currentDates[0];
        } else {
            min = currentDates[0];
            max = currentDates[1];
        }

        fetchDailyEvolutionData(user.id, date, min, max, days_in_week).then(
            (dailyEvolutionData) => {
                dispatchReportAction({
                    type: 'updateDailyEvolution',
                    payload: {
                        ...dailyEvolutionData,
                    },
                });

                let cloneData = lodash.cloneDeep(dailyEvolutionData);

                dispatch(
                    updateDailyEvolutionRequest({
                        dailyEvolution: cloneData,
                        // userId: tempUser.id,
                    })
                );

                setUpdateDailyEvolutionTab(false);
            }
        );

        setDaysInWeek(days_in_week);

        dispatch(
            updateDaysInWeekRequest({
                daysInWeek: days_in_week,
            })
        );
    }

    async function handleUpdateReport(minDate = null, maxDate = null) {
        let formattedMinDate = format(minDate, "yyyy-MM-dd' 'HH:mm:ss");
        let formattedMaxDate = format(maxDate, "yyyy-MM-dd' 'HH:mm:ss");

        setCurrentDates([formattedMinDate, formattedMaxDate]);

        dispatch(
            updateDatesRequest({
                minDate: format(minDate, 'yyyy-MM-dd'),
                maxDate: format(maxDate, 'yyyy-MM-dd'),
            })
        );

        let date = null;
        let min = null;
        let max = null;

        if (formattedMinDate === formattedMaxDate) {
            date = formattedMinDate;
        } else {
            min = formattedMinDate;
            max = formattedMaxDate;
        }

        setLoadingWeeklyEvolutionData(true);
        setLoadingCurrentWeekData(true);
        setLoadingTablesData(true);
        setLoadingEditData(true);

        fetchWeeklyEvolutionData(user.id, date, min, max, daysInWeek).then(
            (weeklyEvolutionData) => {
                dispatchReportAction({
                    type: 'updateWeeklyEvolution',
                    payload: weeklyEvolutionData?.weeklyEvolution,
                });

                let cloneData = lodash.cloneDeep(
                    weeklyEvolutionData?.weeklyEvolution
                );

                dispatch(
                    updateWeeklyEvolutionRequest({
                        weeklyEvolution: cloneData,
                        // userId: tempUser.id,
                    })
                );

                setLoadingWeeklyEvolutionData(false);
            }
        );

        fetchDailyEvolutionData(user.id, date, min, max, daysInWeek).then(
            (dailyEvolutionData) => {
                dispatchReportAction({
                    type: 'updateDailyEvolution',
                    payload: {
                        ...dailyEvolutionData,
                    },
                });

                let cloneData = lodash.cloneDeep({
                    ...dailyEvolutionData,
                });

                dispatch(
                    updateDailyEvolutionRequest({
                        dailyEvolution: cloneData,
                        // userId: tempUser.id,
                    })
                );

                setLoadingCurrentWeekData(false);
            }
        );

        fetchAverageTableData(user.id, date, min, max, daysInWeek).then(
            (averageTableData) => {
                dispatchReportAction({
                    type: 'updateAverageTable',
                    payload: averageTableData?.averageTable,
                });

                let cloneData = lodash.cloneDeep(
                    averageTableData?.averageTable
                );

                dispatch(
                    updateAverageTableRequest({
                        data: cloneData,
                        // userId: tempUser.id,
                    })
                );

                setLoadingTablesData(false);
            }
        );

        fetchEditData(user.id, date, min, max, daysInWeek).then((editData) => {
            dispatchReportAction({
                type: 'updateEditableTable',
                payload: editData?.editData,
            });

            let cloneData = lodash.cloneDeep(editData?.editData);

            dispatch(
                updateEditableTableRequest({
                    data: cloneData,
                    // userId: tempUser.id,
                })
            );

            setLoadingEditData(false);
        });
    }

    function handlePrint() {
        window.open('/users/report/print', '_blank');
    }

    async function handleExport(name, minDate, maxDate) {
        setLoadingCsvFile(true);

        if (name === 'rawData') {
            if (minDate === maxDate) {
                await fetchRawDataCsv(user.id, minDate);
            } else {
                await fetchRawDataCsv(user.id, null, minDate, maxDate);
            }
        } else if (name === 'tablesData') {
            if (minDate === maxDate) {
                await fetchTablesDataCsv(user.id, minDate);
            } else {
                await fetchTablesDataCsv(user.id, null, minDate, maxDate);
            }
        } else if (name === 'decimalsTablesData') {
            if (minDate === maxDate) {
                await fetchDecimalsTablesDataCsv(user.id, minDate);
            } else {
                await fetchDecimalsTablesDataCsv(
                    user.id,
                    null,
                    minDate,
                    maxDate
                );
            }
        } else if (name === 'customQuestionsData') {
            await fetchCustomQuestionsDataCsv(user.id);
        } else {
            await fetchActigraphyData(user.id, 'wrist');

            await fetchActigraphyData(user.id, 'necklace');
        }

        setLoadingCsvFile(false);
    }

    async function getSleepScoringStatus(intervalId) {
        fetchSleepScoringStatus(history.location.state?.entity?.id)
            .then((data) => {
                if (data.status === 'done') {
                    if (
                        ref.current.intervalId === undefined ||
                        ref.current.intervalId === null
                    ) {
                        clearInterval(intervalId);
                    } else {
                        clearInterval(ref.current.intervalId);

                        ref.current.intervalId = null;
                    }

                    getActigraphyData();
                }
            })
            .catch((error) => {
                console.log('getSleepScoringStatus error', error);
            });
    }

    async function getActogramData() {
        fetchActogramData(history.location.state?.entity.id)
            .then((data) => {
                dispatchReportAction({
                    type: 'updateActogram',
                    payload: data?.actogram,
                });

                setLoadingActogramData(false);

                setRequestsStatus((prevState) => ({
                    ...prevState,
                    actogram: 'done',
                }));
            })
            .catch((error) => {
                console.log('getActogramData error', error);
            });
    }

    async function getSleepScoringData() {
        fetchSleepScoringData(history.location.state?.entity.id)
            .then((data) => {
                dispatchReportAction({
                    type: 'updateSleepScoring',
                    payload: data?.sleep_scoring,
                });

                setHasStatesToCalculate(data?.has_states_to_calculate);

                if (data.has_states_to_calculate) {
                    setSleepingStatesStatus('states_to_calculate');
                } else {
                    setSleepingStatesStatus('up_to_date');
                }

                setLoadingSleepScoringData(false);

                setLoadingSleepStates(false);

                setRequestsStatus((prevState) => ({
                    ...prevState,
                    sleepScoring: 'done',
                }));
            })
            .catch((error) => {
                console.log('getSleepScoringData error', error);
            });
    }

    async function calculateSleepStatesData(
        userId,
        days,
        recalculate = false,
        fullPeriod = false
    ) {
        setLoadingSleepStates(true);

        setSleepingStatesStatus('loading');

        calculateSleepStates(userId, recalculate, days, fullPeriod).then(
            (sleepScoringStatus) => {
                if (sleepScoringStatus.status === null) {
                    toast.info(pageData.noActigraphyData);

                    setLoadingSleepStates(false);
                    setSleepingStatesStatus(null);
                } else if (sleepScoringStatus.status === 'done') {
                    getActigraphyData();
                } else {
                    const newIntervalId = setInterval(() => {
                        getSleepScoringStatus(ref.current.intervalId);
                    }, 6000);

                    ref.current.intervalId = newIntervalId;
                    ref.current.firstRender = false;
                }
            }
        );
    }

    async function getActigraphyData() {
        getActogramData();
        getSleepScoringData();
    }

    useEffect(() => {
        let tempUser = history.location.state?.entity;

        if (tempUser) {
            if (ref.current.firstRender) {
                ref.current.firstRender = false;

                dispatch(setLoading(true));

                dispatch(
                    updateDaysInWeekRequest({
                        daysInWeek: 7,
                    })
                );

                dispatch(
                    updateDatesRequest({
                        minDate: null,
                        maxDate: null,
                    })
                );

                fetchCurrentWeekReferenceData(tempUser.id).then((data) => {
                    if (data?.currentWeekReference?.weekNumber === null) {
                        toast.info(pageData.noDataInput);
                    }

                    dispatchReportAction({
                        type: 'updateWeekReference',
                        payload: data,
                    });

                    tempUser.trials_configurations.trialStart =
                        data?.trialStart;

                    setUser(tempUser);

                    let cloneUser = lodash.cloneDeep(tempUser);

                    dispatch(
                        updateUserRequest({
                            user: cloneUser,
                        })
                    );

                    let cloneData = lodash.cloneDeep(
                        data?.currentWeekReference
                    );

                    dispatch(
                        updateWeekReferenceRequest({
                            currentWeekReference: cloneData,
                        })
                    );

                    setRequestsStatus((prevState) => ({
                        ...prevState,
                        currentWeekReference: 'done',
                    }));

                    dispatch(setLoading(false));
                });

                fetchCalendarData(tempUser.id).then((data) => {
                    let filled = [];
                    let notFilled = [];
                    let filledCorrect = [];
                    let notFilledCorrect = [];

                    for (let day of data?.calendarDays.totalDays) {
                        if (data?.calendarDays.filledDays.includes(day)) {
                            filled.push(new Date(day));

                            if (handleDate(day)) {
                                filledCorrect.push(handleDate(day));
                            }
                        } else {
                            notFilled.push(new Date(day));

                            if (handleDate(day)) {
                                notFilledCorrect.push(handleDate(day));
                            }
                        }
                    }

                    setHighlightDates([
                        {
                            'react-datepicker__day--highlighted-trial-start':
                                data?.trialStart
                                    ? handleDate(data?.trialStart)
                                        ? [handleDate(data?.trialStart)]
                                        : []
                                    : [],
                        },
                        {
                            'react-datepicker__day--highlighted-filled':
                                filledCorrect,
                        },
                        {
                            'react-datepicker__day--highlighted-not-filled':
                                notFilledCorrect,
                        },
                    ]);

                    dispatchReportAction({
                        type: 'updateCalendar',
                        payload: data?.calendarDays,
                    });

                    let cloneData = lodash.cloneDeep(data?.calendarDays);

                    dispatch(
                        updateCalendarRequest({
                            calendar: cloneData,
                        })
                    );

                    setLoadingCalendarData(false);

                    setRequestsStatus((prevState) => ({
                        ...prevState,
                        calendar: 'done',
                    }));
                });

                fetchWeeklyEvolutionData(tempUser.id).then((data) => {
                    dispatchReportAction({
                        type: 'updateWeeklyEvolution',
                        payload: data?.weeklyEvolution,
                    });

                    let cloneData = lodash.cloneDeep(data?.weeklyEvolution);

                    dispatch(
                        updateWeeklyEvolutionRequest({
                            weeklyEvolution: cloneData,
                        })
                    );

                    setLoadingWeeklyEvolutionData(false);

                    setRequestsStatus((prevState) => ({
                        ...prevState,
                        weeklyEvolution: 'done',
                    }));
                });

                fetchDailyEvolutionData(tempUser.id).then((data) => {
                    dispatchReportAction({
                        type: 'updateDailyEvolution',
                        payload: data,
                    });

                    let cloneData = lodash.cloneDeep(data);

                    dispatch(
                        updateDailyEvolutionRequest({
                            dailyEvolution: cloneData,
                        })
                    );

                    setLoadingCurrentWeekData(false);

                    setRequestsStatus((prevState) => ({
                        ...prevState,
                        currentWeek: 'done',
                    }));
                });

                fetchAverageTableData(tempUser.id).then((data) => {
                    dispatchReportAction({
                        type: 'updateAverageTable',
                        payload: data?.averageTable,
                    });

                    let cloneData = lodash.cloneDeep(data?.averageTable);

                    dispatch(
                        updateAverageTableRequest({
                            data: cloneData,
                        })
                    );

                    setLoadingTablesData(false);

                    setRequestsStatus((prevState) => ({
                        ...prevState,
                        averageTable: 'done',
                    }));
                });

                fetchEditData(tempUser.id).then((data) => {
                    dispatchReportAction({
                        type: 'updateEditableTable',
                        payload: data?.editData,
                    });

                    let cloneData = lodash.cloneDeep(data?.editData);

                    dispatch(
                        updateEditableTableRequest({
                            data: cloneData,
                        })
                    );

                    setLoadingEditData(false);

                    setRequestsStatus((prevState) => ({
                        ...prevState,
                        editData: 'done',
                    }));
                });

                getActogramData();

                getSleepScoringData();
            }
        } else {
            history.push('/users');
        }

        return () => {
            if (ref.current.intervalId) {
                clearInterval(ref.current.intervalId);
            }
        };
    }, []);

    useEffect(() => {
        if (currentUser.permissions === 'manager') {
            if (user) {
                if (
                    Object.values(requestsStatus).every(
                        (value) => value === 'done'
                    )
                ) {
                    const showReportTour = JSON.parse(
                        localStorage.getItem('showReportTour')
                    );

                    if (showReportTour === null || showReportTour === true) {
                        if (
                            reportData?.weekReference?.currentWeekReference
                                ?.weekNumber !== null
                        ) {
                            let tempReportStep = [...stepsOptions[lang].report];

                            localStorage.setItem('showReportTour', 'true');

                            setSteps(tempReportStep);
                        }
                    }
                }
            }
        }
    }, [requestsStatus]);

    if (isLoading || user === null) {
        return <></>;
    }

    return (
        <>
            <ShepherdTour steps={steps} tourOptions={tourOptions}>
                <Row>
                    <Col xs="12">
                        <UserTable
                            user={user}
                            textData={pageData.userTable}
                            locale={dateLocale}
                            currentWeek={
                                reportData?.weekReference?.currentWeekReference
                            }
                            currentUser={currentUser}
                            steps={steps}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="exportContainer">
                        <ActionsContainer
                            texts={{
                                ...pageData.exportButtonsText,
                            }}
                            weekNumber={
                                reportData?.weekReference?.currentWeekReference
                                    ?.weekNumber
                            }
                            loadingCsvFile={loadingCsvFile}
                            trialStart={user.trials_configurations.trialStart}
                            highlightDates={highlightDates}
                            handleExport={handleExport}
                            handlePrint={handlePrint}
                            handleUpdateReport={handleUpdateReport}
                            actigraphyData={
                                reportData.actogram ?? reportData.sleepScoring
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={`${
                                        activeTab === 'calendar' ? 'active' : ''
                                    } calendarLink`}
                                    onClick={() => {
                                        toggle('calendar');
                                    }}
                                    disabled={loadingCalendarData}
                                >
                                    {pageData.tabsText.calendar}{' '}
                                    {loadingCalendarData && (
                                        <Spinner size="sm" />
                                    )}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={`${
                                        activeTab === 'weeklyEvolution'
                                            ? 'active'
                                            : ''
                                    } weeklyEvolutionLink`}
                                    onClick={() => {
                                        toggle('weeklyEvolution');
                                    }}
                                    disabled={loadingWeeklyEvolutionData}
                                >
                                    {pageData.tabsText.weeklyEvolution}{' '}
                                    {loadingWeeklyEvolutionData && (
                                        <Spinner size="sm" />
                                    )}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={`${
                                        activeTab === 'currentWeek'
                                            ? 'active'
                                            : ''
                                    } currentWeekLink`}
                                    onClick={() => {
                                        toggle('currentWeek');
                                    }}
                                    disabled={
                                        loadingCurrentWeekData ||
                                        updateDailyEvolutionTab
                                    }
                                >
                                    {pageData.tabsText.dailyEvolution}{' '}
                                    {(loadingCurrentWeekData ||
                                        updateDailyEvolutionTab) && (
                                        <Spinner size="sm" />
                                    )}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={`${
                                        activeTab === 'tables' ? 'active' : ''
                                    } tablesLink`}
                                    onClick={() => {
                                        toggle('tables');
                                    }}
                                    disabled={loadingTablesData}
                                >
                                    {pageData.tabsText.tables}{' '}
                                    {loadingTablesData && <Spinner size="sm" />}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={`${
                                        activeTab === 'editData' ? 'active' : ''
                                    } editDataLink`}
                                    onClick={() => {
                                        toggle('editData');
                                    }}
                                    disabled={loadingEditData}
                                >
                                    {pageData.tabsText.editData}{' '}
                                    {loadingEditData && <Spinner size="sm" />}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={`${
                                        activeTab === 'actogram' ? 'active' : ''
                                    } actogramLink`}
                                    onClick={() => {
                                        toggle('actogram');
                                    }}
                                    disabled={loadingActogramData}
                                >
                                    {pageData.tabsText.actogram}{' '}
                                    {loadingActogramData && (
                                        <Spinner size="sm" />
                                    )}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={`${
                                        activeTab === 'sleepScoring'
                                            ? 'active'
                                            : ''
                                    } sleepScoringLink`}
                                    onClick={() => {
                                        toggle('sleepScoring');
                                    }}
                                    disabled={loadingSleepScoringData}
                                >
                                    {pageData.tabsText.sleepScoring}{' '}
                                    {loadingSleepScoringData && (
                                        <Spinner size="sm" />
                                    )}
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane
                                tabId="calendar"
                                className="calendarTabPane"
                            >
                                {!loadingCalendarData && (
                                    <Row>
                                        <Calendar
                                            data={reportData?.calendar}
                                            textData={pageData.calendar}
                                            lang={lang}
                                        />
                                    </Row>
                                )}
                            </TabPane>
                            <TabPane
                                tabId="weeklyEvolution"
                                className="weeklyEvolutionTabPane"
                            >
                                {!loadingWeeklyEvolutionData && (
                                    <Row className="weeklyEvolution">
                                        <Col xs="12">
                                            {weeklyEvolutionRef.current &&
                                                reportData?.weeklyEvolution &&
                                                Object.entries(
                                                    reportData?.weeklyEvolution
                                                        ?.chart
                                                ).length !== 0 && (
                                                    <Row>
                                                        <Col
                                                            xs="12"
                                                            className="my-1"
                                                        >
                                                            <ExportButton
                                                                texts={{
                                                                    exportText:
                                                                        pageData.exportText,
                                                                    formatText:
                                                                        pageData.formatText,
                                                                    pngText:
                                                                        pageData.pngText,
                                                                    pdfText:
                                                                        pageData.pdfText,
                                                                }}
                                                                userName={
                                                                    user.name
                                                                }
                                                                sectionName="weekly_evolution"
                                                                componentRef={
                                                                    weeklyEvolutionRef.current
                                                                }
                                                                disabled={
                                                                    loadingCsvFile
                                                                }
                                                                numberOfWeeks={
                                                                    reportData
                                                                        ?.weekReference
                                                                        ?.currentWeekReference
                                                                        .weekNumber
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                            <WeeklyEvolution
                                                ref={weeklyEvolutionRef}
                                                data={
                                                    reportData.weeklyEvolution
                                                }
                                                textData={
                                                    pageData.weeklyEvolution
                                                }
                                                visible={
                                                    activeTab ===
                                                    'weeklyEvolution'
                                                }
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </TabPane>
                            <TabPane
                                tabId="currentWeek"
                                className="currentWeekTabPane"
                            >
                                {!loadingCurrentWeekData && (
                                    <Row className="currentWeek">
                                        <Col xs="12">
                                            {currentWeekRef.current &&
                                                reportData?.dailyEvolution
                                                    ?.currentWeek?.weekNumbers
                                                    .length !== 0 && (
                                                    <Row>
                                                        <Col
                                                            xs="12"
                                                            className="dailyEvolutionActions my-1"
                                                        >
                                                            <ExportButton
                                                                texts={{
                                                                    exportText:
                                                                        pageData.exportText,
                                                                    formatText:
                                                                        pageData.formatText,
                                                                    pngText:
                                                                        pageData.pngText,
                                                                    pdfText:
                                                                        pageData.pdfText,
                                                                }}
                                                                userName={
                                                                    user.name
                                                                }
                                                                sectionName="current_week"
                                                                componentRef={
                                                                    currentWeekRef.current
                                                                }
                                                                disabled={
                                                                    loadingCsvFile
                                                                }
                                                                numberOfWeeks={
                                                                    weeklyEvolutionViewDays
                                                                }
                                                            />
                                                            <SelectDaysButton
                                                                texts={{
                                                                    periodText:
                                                                        pageData.periodText,
                                                                    sevenDaysText:
                                                                        pageData.sevenDaysText,
                                                                    fourteenDaysText:
                                                                        pageData.fourteenDaysText,
                                                                    twentyOneDaysText:
                                                                        pageData.twentyOneDaysText,
                                                                    twentyEightDaysText:
                                                                        pageData.twentyEightDaysText,
                                                                }}
                                                                disabled={
                                                                    loadingCsvFile
                                                                }
                                                                handleUpdateDailyEvolution={
                                                                    handleUpdateDailyEvolution
                                                                }
                                                                selectedDays={
                                                                    weeklyEvolutionViewDays
                                                                }
                                                                setSelectedDays={
                                                                    setWeeklyEvolutionViewDays
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                            <CurrentWeek
                                                ref={currentWeekRef}
                                                data={
                                                    reportData?.dailyEvolution
                                                        .currentWeek
                                                }
                                                textData={pageData.currentWeek}
                                                visible={
                                                    activeTab === 'currentWeek'
                                                }
                                                locale={dateLocale}
                                                completeWeekDays={
                                                    reportData?.dailyEvolution
                                                        ?.completeWeekDays
                                                }
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </TabPane>
                            <TabPane tabId="tables" className="tablesTabPane">
                                {!loadingTablesData && (
                                    <Row>
                                        <Tables
                                            data={reportData?.averageTable}
                                            textData={pageData.tables}
                                        />
                                    </Row>
                                )}
                            </TabPane>
                            <TabPane
                                tabId="editData"
                                className="editDataTabPane"
                            >
                                {!loadingEditData && (
                                    <Row>
                                        <EditData
                                            userId={user.id}
                                            data={reportData?.editableTable}
                                            textData={pageData.editData}
                                            locale={dateLocale}
                                            dispatchReportAction={
                                                dispatchReportAction
                                            }
                                            lang={lang}
                                        />
                                    </Row>
                                )}
                            </TabPane>
                            <TabPane
                                tabId="actogram"
                                className="weeklyEvolutionTabPane"
                            >
                                {!loadingActogramData && (
                                    <Row className="actogram">
                                        {reportData?.actogram && (
                                            <Actogram
                                                data={reportData?.actogram}
                                                textData={pageData.actogram}
                                            />
                                        )}
                                    </Row>
                                )}
                            </TabPane>
                            <TabPane
                                tabId="sleepScoring"
                                className="weeklyEvolutionTabPane"
                            >
                                {!loadingSleepScoringData && (
                                    <Row className="sleepScoring">
                                        {reportData?.sleepScoring && (
                                            <SleepScoring
                                                data={reportData?.sleepScoring}
                                                textData={pageData.sleepScoring}
                                                userId={user.id}
                                                sleepingStatesStatus={
                                                    sleepingStatesStatus
                                                }
                                                calculateSleepStatesData={
                                                    calculateSleepStatesData
                                                }
                                                loadingSleepStates={
                                                    loadingSleepStates
                                                }
                                                hasStatesToCalculate={
                                                    hasStatesToCalculate
                                                }
                                            />
                                        )}
                                    </Row>
                                )}
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </ShepherdTour>
        </>
    );
}
