import { faEdit, faEnvelope, faTrash } from '@fortawesome/free-solid-svg-icons';

export default {
    pt: {
        customer_admin: null,
        manager: {
            searchByNameText: 'Procurar gestor por nome',
            searchBySecondaryIdText: 'Procurar gestor por id secundário',
            addText: 'Adicionar',
            addUserText: 'Adicionar gestor',
            ths: [
                {
                    name: 'ID',
                    property: 'id',
                },
                {
                    name: 'Nome',
                    property: 'name',
                },
                {
                    name: 'ID Secundário',
                    property: 'secondary_id',
                },
                {
                    name: 'e-Mail',
                    property: 'email',
                },
                {
                    name: 'Ativo',
                    property: 'active',
                },
                {
                    name: 'Ações',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'ID',
                    property: 'id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Nome',
                    property: 'name',
                    hasLink: false,
                },
                {
                    th: 'ID Secundário',
                    property: 'secondary_id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'e-Mail',
                    property: 'email',
                    hasLink: false,
                    to: '',
                },

                {
                    th: 'Ativo',
                    property: 'active',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Ações',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Editar',
                    className: 'btn btn-secondary ml-2',
                    to: 'managers/edit',
                    icon: faEdit,
                },
                {
                    title: 'Credenciais',
                    className: 'btn btn-info ml-2',
                    to: 'managers/credentials',
                    icon: faEnvelope,
                },
                {
                    title: 'Excluir',
                    className: 'btn btn-danger ml-2',
                    to: 'managers/delete',
                    icon: faTrash,
                },
            ],
            activeText: 'Sim',
            inactiveText: 'Não',
            noUsersText: 'Você não possui gestores cadastrados!',
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            searchByNameText: 'Search manager by name',
            searchBySecondaryIdText: 'Search manager by secondary ID',
            addText: 'Add',
            addUserText: 'Add manager',
            ths: [
                {
                    name: 'ID',
                    property: 'id',
                },
                {
                    name: 'Name',
                    property: 'name',
                },
                {
                    name: 'Secondary ID',
                    property: 'secondary_id',
                },
                {
                    name: 'e-Mail',
                    property: 'email',
                },
                {
                    name: 'Active',
                    property: 'active',
                },
                {
                    name: 'Actions',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'ID',
                    property: 'id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Name',
                    property: 'name',
                    hasLink: false,
                },
                {
                    th: 'Secondary ID',
                    property: 'secondary_id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'e-Mail',
                    property: 'email',
                    hasLink: false,
                    to: '',
                },

                {
                    th: 'Active',
                    property: 'active',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Actions',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Edit',
                    className: 'btn btn-secondary ml-2',
                    to: 'managers/edit',
                    icon: faEdit,
                },
                {
                    title: 'Credentials',
                    className: 'btn btn-info ml-2',
                    to: 'managers/credentials',
                    icon: faEnvelope,
                },
                {
                    title: 'Delete',
                    className: 'btn btn-danger ml-2',
                    to: 'managers/delete',
                    icon: faTrash,
                },
            ],
            activeText: 'Yes',
            inactiveText: 'No',
            noUsersText: `You dont't have registered managers!`,
        },
        condor_admin: null,
    },
};
