import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    Row,
    Col,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
    Button,
} from 'reactstrap';
import PropTypes from 'prop-types';

import Card from '../../../../components/card/card';
import { ChartLabel } from '../../../../components/chartLabel';
import {
    localeDateFormatter,
    localeWeekDayFormatter,
} from '../../../../helpers/formatters';
import {
    createActivityTrace,
    createTemperatureTrace,
    createLightTrace,
    createNoDataShapes,
    createEventShapes,
    createSleepDiaryShapes,
    createOffwristShapes,
    createSleepingShapes,
    createRestingShapes,
    connectLightValues,
    activityHigherScaleFactor,
    temperatureLowerScaleFactor,
    temperatureHigherScaleFactor,
} from '../../../../helpers/sleepScoringHelpers';
import useWindowSize from '../../../../hooks/useWindowSize';
import { SleepScoringChart } from './sleepScoringChart';
import { MatriceChart } from './matriceChart';

SleepScoring.propTypes = {
    data: PropTypes.shape({
        body_parts: PropTypes.arrayOf(PropTypes.string),
        charts_data: PropTypes.arrayOf(
            PropTypes.arrayOf(
                PropTypes.shape({
                    date: PropTypes.string.isRequired,
                    range: PropTypes.array.isRequired,
                    log_times: PropTypes.array.isRequired,
                    activity: PropTypes.array.isRequired,
                    temperature: PropTypes.array.isRequired,
                    f1: PropTypes.array.isRequired,
                    f2: PropTypes.array.isRequired,
                    f3: PropTypes.array.isRequired,
                    f4: PropTypes.array.isRequired,
                    f5: PropTypes.array.isRequired,
                    f6: PropTypes.array.isRequired,
                    f7: PropTypes.array.isRequired,
                    f8: PropTypes.array.isRequired,
                    clear: PropTypes.array.isRequired,
                    nir: PropTypes.array.isRequired,
                    lux_photopic: PropTypes.array.isRequired,
                    lux_melanopic: PropTypes.array.isRequired,
                    event: PropTypes.array.isRequired,
                    state: PropTypes.array.isRequired,
                    offwrist: PropTypes.array.isRequired,
                    sleep_diary_dates: PropTypes.array.isRequired,
                })
            )
        ).isRequired,
    }),
    textData: PropTypes.shape({
        cardTitle: PropTypes.string.isRequired,
        actigraphyDataText: PropTypes.string.isRequired,
        wristText: PropTypes.string.isRequired,
        necklaceText: PropTypes.string.isRequired,
        mixedText: PropTypes.string.isRequired,
        activityText: PropTypes.string.isRequired,
        temperatureText: PropTypes.string.isRequired,
        photopicText: PropTypes.string.isRequired,
        melanopicText: PropTypes.string.isRequired,
        photopicNecklaceText: PropTypes.string.isRequired,
        melanopicNecklaceText: PropTypes.string.isRequired,
        mixedChartsInfoText: PropTypes.string.isRequired,
        dataVisualizationText: PropTypes.string.isRequired,
        recalculateStatesText: PropTypes.string.isRequired,
        updateText: PropTypes.string.isRequired,
        numberOfDaysText: PropTypes.string.isRequired,
        changeChartLeftScaleText: PropTypes.string.isRequired,
        restingText: PropTypes.string.isRequired,
        sleepingText: PropTypes.string.isRequired,
        offwristText: PropTypes.string.isRequired,
        noDataText: PropTypes.string.isRequired,
        eventText: PropTypes.string.isRequired,
        sleepDiaryText: PropTypes.string.isRequired,
        entirePeriodText: PropTypes.string.isRequired,
        sleepingStatesText: PropTypes.string.isRequired,
        noActigraphyDataText: PropTypes.string.isRequired,
        calculatingStatesText: PropTypes.string.isRequired,
        thereAreStatesToCalculateText: PropTypes.string.isRequired,
        upToDateStatesText: PropTypes.string.isRequired,
        calculateStateText: PropTypes.string.isRequired,
    }).isRequired,
    userId: PropTypes.number.isRequired,
    calculateSleepStatesData: PropTypes.func.isRequired,
    loadingSleepStates: PropTypes.bool.isRequired,
    sleepingStatesStatus: PropTypes.string.isRequired,
    hasStatesToCalculate: PropTypes.bool,
};

export function SleepScoring({
    data,
    textData,
    userId,
    calculateSleepStatesData,
    loadingSleepStates,
    sleepingStatesStatus,
    hasStatesToCalculate,
}) {
    const { body_parts: bodyParts, charts_data: chartsData } = data;

    const { width } = useWindowSize();

    const lang = useSelector((state) => state.lang.lang);
    const { dateLocale } = useSelector((state) => state.locale);

    const [chartsMode, setChartsMode] = useState('wrist');
    const [chartsModesOptions, setChartsModesOptions] = useState([
        { text: textData.wristText, active: true, type: 'wrist' },
        { text: textData.necklaceText, active: false, type: 'necklace' },
        { text: textData.mixedText, active: false, type: 'mixed' },
    ]);
    const [charts, setCharts] = useState([]);
    const [layouts, setLayouts] = useState([]);
    const [dataLists, setDataLists] = useState({ wrist: [], necklace: [] });
    const [loadingCharts, setLoadingCharts] = useState(true);
    const [numberOfDays, setNumberOfDays] = useState(15);
    const [chartsLeftScale, setChartsLeftScale] = useState('temperature');
    const [chartsLeftScaleOptions, setChartsLeftScaleOptions] = useState([
        { text: textData.activityText, active: false, type: 'activity' },
        { text: textData.temperatureText, active: true, type: 'temperature' },
    ]);
    const [isFullPeriodToggle, setIsFullPeriodToggle] = useState(false);

    const [channelsChart, setChannelsChart] = useState({});

    const sleepScoringColors = {
        activity: '#00008b',
        temperature: '#ff641e',
        ext_temperature: '#c83200',
        f1: '#6A00FF',
        f2: '#003BFF',
        f3: '#00CDFF',
        f4: '#2DFF00',
        f5: '#B6FF00',
        f6: '#FFE600',
        f7: '#FF5F00',
        f8: '#FF0000',
        clear: '#A10000',
        nir: '#BF3F3F',
        photopic: '#ffff00',
        melanopic: '#00ffff',
        photopic2: '#ffbb00',
        melanopic2: '#00bfff',
        no_data: '#404040',
        event: '#00ff00',
        sleep_diary: '#a0a0a4',
        offwrist: '#663399',
        sleeping: '#46bece',
        resting: '#64ff64',
    };

    const dataLabels = [
        {
            name: textData.activityText,
            color: sleepScoringColors.activity,
            type: 'data',
        },
        {
            name: textData.temperatureText,
            color: sleepScoringColors.temperature,
            type: 'data',
        },
        {
            name: textData.photopicText,
            color: sleepScoringColors.photopic,
            type: 'data',
        },
        {
            name: textData.melanopicText,
            color: sleepScoringColors.melanopic,
            type: 'data',
        },
        {
            name: textData.photopicNecklaceText,
            color: sleepScoringColors.photopic2,
            type: 'data',
        },
        {
            name: textData.melanopicNecklaceText,
            color: sleepScoringColors.melanopic2,
            type: 'data',
        },
        {
            name: textData.restingText,
            color: sleepScoringColors.resting,
            type: 'state',
        },
        {
            name: textData.sleepingText,
            color: sleepScoringColors.sleeping,
            type: 'state',
        },
        {
            name: textData.offwristText,
            color: sleepScoringColors.offwrist,
            type: 'state',
        },
        {
            name: textData.noDataText,
            color: sleepScoringColors.no_data,
            type: 'state',
        },
        {
            name: textData.eventText,
            color: sleepScoringColors.event,
            type: 'line',
        },
        {
            name: textData.sleepDiaryText,
            color: sleepScoringColors.sleep_diary,
            type: 'line',
        },
    ];

    const sleepScoringChartWidth = useMemo(() => {
        return Object.keys(channelsChart).length > 0
            ? width - (50 * width) / 100
            : width - (20 * width) / 100;
    }, [width, channelsChart]);

    useEffect(() => {
        if (bodyParts.length === 2) {
            setChartsMode('mixed');

            setChartsModesOptions([
                { text: textData.wristText, active: false, type: 'wrist' },
                {
                    text: textData.necklaceText,
                    active: false,
                    type: 'necklace',
                },
                { text: textData.mixedText, active: true, type: 'mixed' },
            ]);

            createCharts('mixed');
        } else {
            if (bodyParts.find((part) => part === 'wrist')) {
                setChartsMode('wrist');

                setChartsModesOptions([
                    {
                        text: textData.wristText,
                        active: true,
                        type: 'wrist',
                    },
                ]);

                createCharts('wrist');
            } else if (bodyParts.find((part) => part === 'necklace')) {
                setChartsMode('necklace');

                setChartsModesOptions([
                    {
                        text: textData.necklaceText,
                        active: true,
                        type: 'necklace',
                    },
                ]);

                createCharts('necklace');
            } else {
                setChartsModesOptions([]);
            }
        }
    }, [chartsData]);

    useEffect(() => {
        let tempLayouts = [];

        if (layouts.length > 0) {
            tempLayouts = layouts.map((layout) => ({
                ...layout,
                width: sleepScoringChartWidth,
            }));
        } else {
            let wristIndex = -1;
            let necklaceIndex = -1;

            if (chartsMode === 'wrist') {
                wristIndex = bodyParts.findIndex((part) => part === 'wrist');
            } else if (chartsMode === 'necklace') {
                necklaceIndex = bodyParts.findIndex(
                    (part) => part === 'necklace'
                );
            } else {
                wristIndex = bodyParts.findIndex((part) => part === 'wrist');
                necklaceIndex = bodyParts.findIndex(
                    (part) => part === 'necklace'
                );
            }

            let indexToUse =
                wristIndex !== -1
                    ? wristIndex
                    : necklaceIndex !== -1
                    ? necklaceIndex
                    : -1;

            if (indexToUse !== -1) {
                tempLayouts = charts.map((chart, index) => {
                    let formattedDate = '';
                    let weekDay = '';

                    try {
                        formattedDate = localeDateFormatter(
                            chartsData[indexToUse][index].date.replace(
                                ' ',
                                'T'
                            ),
                            dateLocale
                        );

                        if (formattedDate === null) {
                            formattedDate = '';
                        }
                    } catch (e) {
                        formattedDate = '';
                    }

                    try {
                        weekDay = localeWeekDayFormatter(
                            chartsData[indexToUse][index].date.replace(
                                ' ',
                                'T'
                            ),
                            dateLocale
                        );

                        if (weekDay === null) {
                            weekDay = '';
                        }
                    } catch (e) {
                        weekDay = '';
                    }

                    return {
                        ...chart.layout,
                        yaxis3: {
                            ...chart.layout.yaxis3,
                            title: {
                                ...chart.layout.yaxis3.title,
                                text: `
                        <br />
                        ${formattedDate}
                        <br />
                        ${weekDay}
                        <br />`,
                            },
                        },
                        height: 160,
                        width: sleepScoringChartWidth,
                    };
                });
            }
        }

        setLayouts(tempLayouts);
    }, [sleepScoringChartWidth]);

    useEffect(() => {
        setChartsModesOptions((prevState) => {
            const newState = [...prevState];

            return newState.map((option) => ({
                ...option,
                text: textData[`${option.type}Text`],
            }));
        });

        setChartsLeftScaleOptions((prevState) => {
            const newState = [...prevState];

            return newState.map((option) => ({
                ...option,
                text: textData[`${option.type}Text`],
            }));
        });

        setLayouts((prevState) => {
            const newState = [...prevState];

            let index = -1;

            if (chartsMode === 'mixed') {
                index = bodyParts.findIndex((bodyPart) => bodyPart === 'wrist');
            } else {
                index = bodyParts.findIndex(
                    (bodyPart) => bodyPart === chartsMode
                );
            }

            return newState.map((layout, i) => {
                let formattedDate = '';
                let weekDay = '';

                try {
                    formattedDate = localeDateFormatter(
                        chartsData[index][i]['date'].replace(' ', 'T'),
                        dateLocale
                    );

                    if (formattedDate === null) {
                        formattedDate = '';
                    }
                } catch (e) {
                    formattedDate = '';
                }

                try {
                    weekDay = localeWeekDayFormatter(
                        chartsData[index][i]['date'].replace(' ', 'T'),
                        dateLocale
                    );

                    if (weekDay === null) {
                        weekDay = '';
                    }
                } catch (e) {
                    weekDay = '';
                }

                return {
                    ...layout,
                    yaxis3: {
                        ...layout.yaxis3,
                        title: {
                            ...layout.yaxis3.title,

                            text: `<br />${formattedDate}<br />${weekDay}<br />`,
                        },
                    },
                };
            });
        });
    }, [lang]);

    function onCloseChannelsChart() {
        setChannelsChart({});

        setLayouts((prevState) =>
            prevState.map((layout) => {
                const index = layout.shapes.findIndex(
                    (shape) => shape.name === 'clickLine'
                );

                const newShapes = [...layout.shapes];

                if (index !== -1) {
                    newShapes.splice(index, 1);
                }

                return {
                    ...layout,
                    shapes: newShapes,
                };
            })
        );
    }

    function handleClickActigraphyData(type) {
        if (type !== chartsMode) {
            setChartsMode(type);

            setChartsModesOptions((prevState) => {
                const newState = [...prevState];

                return newState.map((chartModeOption) => ({
                    ...chartModeOption,
                    active: chartModeOption.type === type,
                }));
            });

            createCharts(type, chartsLeftScale);
        }
    }

    function createCharts(mode, leftScale = 'temperature') {
        setLoadingCharts(true);

        if (mode === 'wrist') {
            createSingleBodyPartCharts('wrist', leftScale);
        } else if (mode === 'necklace') {
            createSingleBodyPartCharts('necklace', leftScale);
        } else {
            createMixedDeviceCharts(leftScale);
        }

        setLoadingCharts(false);
    }

    function createSingleBodyPartCharts(part, leftScale) {
        const index = bodyParts.findIndex((bodyPart) => bodyPart === part);

        if (index !== -1) {
            if (part === 'wrist') {
                setDataLists({ wrist: chartsData[index], necklace: [] });
            } else {
                setDataLists({ wrist: [], necklace: chartsData[index] });
            }

            const newcharts = [];
            const newLayouts = [];

            const maxActivityValue = chartsData[index].reduce(
                (accumulator, currentValue) => {
                    let tempMax = Math.max(...currentValue.activity);

                    if (tempMax > accumulator) {
                        return tempMax;
                    }

                    return accumulator;
                },
                0
            );

            for (let i = 0; i < chartsData[index].length; i++) {
                const minTemperatureValue = Math.min(
                    ...chartsData[index][i].temperature
                );

                const maxTemperatureValue = Math.max(
                    ...chartsData[index][i].temperature
                );

                let activityTrace = createActivityTrace(
                    chartsData[index][i].log_times,
                    chartsData[index][i].activity,
                    sleepScoringColors.activity
                );
                let temperatureTrace = createTemperatureTrace(
                    chartsData[index][i].log_times,
                    chartsData[index][i].temperature,
                    sleepScoringColors.temperature
                );
                let photopicTrace = createLightTrace(
                    chartsData[index][i].log_times,
                    chartsData[index][i].lux_photopic,
                    sleepScoringColors.photopic,
                    'photopic'
                );
                let melanopicTrace = createLightTrace(
                    chartsData[index][i].log_times,
                    chartsData[index][i].lux_melanopic,
                    sleepScoringColors.melanopic,
                    'melanopic'
                );

                let shapes = [];

                shapes = shapes.concat(
                    createNoDataShapes(
                        chartsData[index][i].log_times,
                        chartsData[index][i].range,
                        sleepScoringColors.no_data
                    )
                );
                shapes = shapes.concat(
                    createEventShapes(
                        chartsData[index][i].log_times,
                        chartsData[index][i].event,
                        sleepScoringColors.event
                    )
                );
                shapes = shapes.concat(
                    createSleepDiaryShapes(
                        chartsData[index][i].sleep_diary_dates,
                        sleepScoringColors.sleep_diary
                    )
                );
                shapes = shapes.concat(
                    createOffwristShapes(
                        chartsData[index][i].log_times,
                        part === 'wrist'
                            ? chartsData[index][i].state
                            : chartsData[index][i].offwrist,
                        part,
                        sleepScoringColors.offwrist
                    )
                );
                shapes = shapes.concat(
                    createSleepingShapes(
                        chartsData[index][i].log_times,
                        chartsData[index][i].state,
                        sleepScoringColors.sleeping
                    )
                );
                shapes = shapes.concat(
                    createRestingShapes(
                        chartsData[index][i].log_times,
                        chartsData[index][i].state,
                        sleepScoringColors.resting
                    )
                );

                let formattedDate = '';
                let weekDay = '';

                try {
                    formattedDate = localeDateFormatter(
                        chartsData[index][i]['date'].replace(' ', 'T'),
                        dateLocale
                    );

                    if (formattedDate === null) {
                        formattedDate = '';
                    }
                } catch (e) {
                    formattedDate = '';
                }

                try {
                    weekDay = localeWeekDayFormatter(
                        chartsData[index][i]['date'].replace(' ', 'T'),
                        dateLocale
                    );

                    if (weekDay === null) {
                        weekDay = '';
                    }
                } catch (e) {
                    weekDay = '';
                }

                const visibleYAxis = {
                    visible: true,
                    showgrid: true,
                    side: 'left',
                    tickmode: 'linear',
                    ticksuffix: ' ',
                    gridcolor: '#cccccc',
                    gridwidth: 1,
                    zerolinecolor: '#cccccc',
                    linecolor: 'black',
                    linewidth: 1,
                    mirror: true,
                    title: {
                        text: `<br />${formattedDate}<br />${weekDay}<br />`,
                        font: {
                            size: 18,
                        },
                    },
                };

                let activityYAxis = {
                    visible: false,
                    fixedrange: true,
                    range: [0, maxActivityValue * activityHigherScaleFactor],
                    tick0: 0,
                    dtick: (maxActivityValue * activityHigherScaleFactor) / 6,
                };

                let minTemperatureRange = minTemperatureValue;
                let maxTemperatureRange = maxTemperatureValue;

                if (minTemperatureValue >= 0) {
                    minTemperatureRange =
                        minTemperatureValue * temperatureLowerScaleFactor;
                } else {
                    minTemperatureRange =
                        minTemperatureValue * temperatureHigherScaleFactor;
                }

                if (maxTemperatureValue >= 0) {
                    maxTemperatureRange =
                        maxTemperatureValue * temperatureHigherScaleFactor;
                } else {
                    maxTemperatureRange =
                        maxTemperatureValue * temperatureLowerScaleFactor;
                }

                let temperatureYAxis = {
                    visible: false,
                    anchor: 'x',
                    overlaying: 'y',
                    fixedrange: true,
                    range: [minTemperatureRange, maxTemperatureRange],
                    tick0: minTemperatureRange,
                    dtick: (maxTemperatureRange - minTemperatureRange) / 6,
                };

                if (leftScale === 'activity') {
                    activityYAxis = {
                        ...activityYAxis,
                        ...visibleYAxis,
                    };
                } else {
                    temperatureYAxis = {
                        ...temperatureYAxis,
                        ...visibleYAxis,
                    };
                }

                const newChart = {
                    data: [
                        activityTrace,
                        temperatureTrace,
                        photopicTrace,
                        melanopicTrace,
                    ],
                    layout: {
                        height: 160,
                        width: sleepScoringChartWidth,
                        hovermode: 'x',
                        margin: { l: 100, t: 25, b: 25, r: 35 },
                        font: {
                            family: 'Georgia, serif',
                        },
                        showlegend: false,
                        xaxis: {
                            type: 'date',
                            tickformat: '%H:%M',
                            tickmode: 'auto',
                            fixedrange: true,
                            showgrid: true,
                            linecolor: 'black',
                            linewidth: 1,
                            mirror: true,
                            ticklen: 8,
                            range: [
                                chartsData[index][i].range[0].slice(0, 17) +
                                    '00',
                                chartsData[index][i].range[
                                    chartsData[index][i].range.length - 1
                                ].slice(0, 17) + '00',
                            ],
                        },
                        // activity axis
                        yaxis: activityYAxis,
                        // light axis
                        yaxis2: {
                            type: 'log',
                            visible: true,
                            fixedrange: true,
                            showgrid: false,
                            anchor: 'x',
                            overlaying: 'y',
                            side: 'right',
                            tickmode: 'auto',
                            gridcolor: '#cccccc',
                            gridwidth: 1,
                            zerolinecolor: '#cccccc',
                            range: [0, 5],
                            tickprefix: ' ',
                        },
                        // temperature axis
                        yaxis3: temperatureYAxis,
                        shapes,
                    },
                    config: {
                        displayModeBar: false,
                        staticPlot: false,
                        responsive: true,
                        edits: {
                            axisTitleText: false,
                            shapePosition: false,
                            titleText: false,
                        },
                        scrollZoom: false,
                        showAxisDragHandles: false,
                    },
                };

                newcharts.push(newChart);

                newLayouts.push(newChart.layout);
            }

            setLayouts(newLayouts);
            setCharts(newcharts);
        }
    }

    function createMixedDeviceCharts(leftScale) {
        let actigraphyDataDatesByBodyPart = [];

        for (let i = 0; i < chartsData.length; i++) {
            actigraphyDataDatesByBodyPart.push([]);

            for (let j = 0; j < chartsData[i].length; j++) {
                actigraphyDataDatesByBodyPart[i].push(chartsData[i][j]['date']);
            }
        }

        const wristIndex = bodyParts.findIndex(
            (bodyPart) => bodyPart === 'wrist'
        );

        const necklaceIndex = bodyParts.findIndex(
            (bodyPart) => bodyPart === 'necklace'
        );

        const newcharts = [];
        const newLayouts = [];
        const newDatasLists = { wrist: [], necklace: [] };

        const maxActivityValue = chartsData[wristIndex].reduce(
            (accumulator, currentValue) => {
                let tempMax = Math.max(...currentValue.activity);

                if (tempMax > accumulator) {
                    return tempMax;
                }

                return accumulator;
            },
            0
        );

        for (let i = 0; i < chartsData[wristIndex].length; i++) {
            const minTemperatureValue = Math.min(
                ...chartsData[wristIndex][i].temperature
            );

            const maxTemperatureValue = Math.max(
                ...chartsData[wristIndex][i].temperature
            );

            const newChartData = [
                createActivityTrace(
                    chartsData[wristIndex][i].log_times,
                    chartsData[wristIndex][i].activity,
                    sleepScoringColors.activity
                ),
                createTemperatureTrace(
                    chartsData[wristIndex][i].log_times,
                    chartsData[wristIndex][i].temperature,
                    sleepScoringColors.temperature
                ),
            ];

            let chartDateIndex = actigraphyDataDatesByBodyPart[
                necklaceIndex
            ].findIndex(
                (date) => date === actigraphyDataDatesByBodyPart[wristIndex][i]
            );

            let linecolor = 'black';
            let linewidth = 1;

            if (chartDateIndex !== -1) {
                let {
                    log_times,
                    lux_photopic,
                    lux_melanopic,
                    f1,
                    f2,
                    f3,
                    f4,
                    f5,
                    f6,
                    f7,
                    f8,
                    clear,
                    nir,
                } = connectLightValues(
                    chartsData[necklaceIndex][chartDateIndex],
                    chartsData[wristIndex][i]
                );

                newChartData.push(
                    createLightTrace(
                        log_times.smaller,
                        lux_photopic.smaller,
                        sleepScoringColors.photopic,
                        'photopic'
                    )
                );
                newChartData.push(
                    createLightTrace(
                        chartsData[necklaceIndex][chartDateIndex].log_times,
                        chartsData[necklaceIndex][chartDateIndex].lux_photopic,
                        sleepScoringColors.photopic2,
                        'photopic',
                        3
                    )
                );
                newChartData.push(
                    createLightTrace(
                        log_times.bigger,
                        lux_photopic.bigger,
                        sleepScoringColors.photopic,
                        'photopic'
                    )
                );

                newChartData.push(
                    createLightTrace(
                        log_times.smaller,
                        lux_melanopic.smaller,
                        sleepScoringColors.melanopic,
                        'melanopic'
                    )
                );
                newChartData.push(
                    createLightTrace(
                        chartsData[necklaceIndex][chartDateIndex].log_times,
                        chartsData[necklaceIndex][chartDateIndex].lux_melanopic,
                        sleepScoringColors.melanopic2,
                        'melanopic',
                        3
                    )
                );
                newChartData.push(
                    createLightTrace(
                        log_times.bigger,
                        lux_melanopic.bigger,
                        sleepScoringColors.melanopic,
                        'melanopic'
                    )
                );

                let newChartsDataNecklace = {
                    ...chartsData[necklaceIndex][chartDateIndex],
                    log_times: log_times.smaller.concat(
                        chartsData[necklaceIndex][chartDateIndex].log_times,
                        log_times.bigger
                    ),
                    lux_photopic: lux_photopic.smaller.concat(
                        chartsData[necklaceIndex][chartDateIndex].lux_photopic,
                        lux_photopic.bigger
                    ),
                    lux_melanopic: lux_melanopic.smaller.concat(
                        chartsData[necklaceIndex][chartDateIndex].lux_melanopic,
                        lux_melanopic.bigger
                    ),
                    f1: f1.smaller.concat(
                        chartsData[necklaceIndex][chartDateIndex].f1,
                        f1.bigger
                    ),
                    f2: f2.smaller.concat(
                        chartsData[necklaceIndex][chartDateIndex].f2,
                        f2.bigger
                    ),
                    f3: f3.smaller.concat(
                        chartsData[necklaceIndex][chartDateIndex].f3,
                        f3.bigger
                    ),
                    f4: f4.smaller.concat(
                        chartsData[necklaceIndex][chartDateIndex].f4,
                        f4.bigger
                    ),
                    f5: f5.smaller.concat(
                        chartsData[necklaceIndex][chartDateIndex].f5,
                        f5.bigger
                    ),
                    f6: f6.smaller.concat(
                        chartsData[necklaceIndex][chartDateIndex].f6,
                        f6.bigger
                    ),
                    f7: f7.smaller.concat(
                        chartsData[necklaceIndex][chartDateIndex].f7,
                        f7.bigger
                    ),
                    f8: f8.smaller.concat(
                        chartsData[necklaceIndex][chartDateIndex].f8,
                        f8.bigger
                    ),
                    clear: clear.smaller.concat(
                        chartsData[necklaceIndex][chartDateIndex].clear,
                        clear.bigger
                    ),
                    nir: nir.smaller.concat(
                        chartsData[necklaceIndex][chartDateIndex].nir,
                        nir.bigger
                    ),
                };

                newDatasLists.necklace.push(newChartsDataNecklace);

                linecolor = '#6440e6';
                linewidth = 3;
            } else {
                newChartData.push(
                    createLightTrace(
                        chartsData[wristIndex][i].log_times,
                        chartsData[wristIndex][i].lux_photopic,
                        sleepScoringColors.photopic,
                        'photopic'
                    )
                );
                newChartData.push(
                    createLightTrace(
                        chartsData[wristIndex][i].log_times,
                        chartsData[wristIndex][i].lux_melanopic,
                        sleepScoringColors.melanopic,
                        'melanopic'
                    )
                );

                newDatasLists.necklace.push({});
            }

            newDatasLists.wrist.push(chartsData[wristIndex][i]);

            let shapes = [];

            shapes = shapes.concat(
                createNoDataShapes(
                    chartsData[wristIndex][i].log_times,
                    chartsData[wristIndex][i].range,
                    sleepScoringColors.no_data
                )
            );
            shapes = shapes.concat(
                createEventShapes(
                    chartsData[wristIndex][i].log_times,
                    chartsData[wristIndex][i].event,
                    sleepScoringColors.event
                )
            );
            shapes = shapes.concat(
                createSleepDiaryShapes(
                    chartsData[wristIndex][i].sleep_diary_dates,
                    sleepScoringColors.sleep_diary
                )
            );
            shapes = shapes.concat(
                createOffwristShapes(
                    chartsData[wristIndex][i].log_times,
                    chartsData[wristIndex][i].state,
                    'wrist',
                    sleepScoringColors.offwrist
                )
            );
            shapes = shapes.concat(
                createSleepingShapes(
                    chartsData[wristIndex][i].log_times,
                    chartsData[wristIndex][i].state,
                    sleepScoringColors.sleeping
                )
            );
            shapes = shapes.concat(
                createRestingShapes(
                    chartsData[wristIndex][i].log_times,
                    chartsData[wristIndex][i].state,
                    sleepScoringColors.resting
                )
            );

            let formattedDate = '';
            let weekDay = '';

            try {
                formattedDate = localeDateFormatter(
                    chartsData[wristIndex][i]['date'].replace(' ', 'T'),
                    dateLocale
                );

                if (formattedDate === null) {
                    formattedDate = '';
                }
            } catch (e) {
                formattedDate = '';
            }

            try {
                weekDay = localeWeekDayFormatter(
                    chartsData[wristIndex][i]['date'].replace(' ', 'T'),
                    dateLocale
                );

                if (weekDay === null) {
                    weekDay = '';
                }
            } catch (e) {
                weekDay = '';
            }

            const visibleYAxis = {
                visible: true,
                showgrid: true,
                side: 'left',
                tickmode: 'linear',
                ticksuffix: ' ',
                gridcolor: '#cccccc',
                gridwidth: 1,
                zerolinecolor: '#cccccc',
                linecolor,
                linewidth,
                mirror: true,
                title: {
                    text: `<br />${formattedDate}<br />${weekDay}<br />`,
                    font: {
                        size: 18,
                    },
                },
            };

            let activityYAxis = {
                visible: false,
                fixedrange: true,
                range: [0, maxActivityValue * activityHigherScaleFactor],
                tick0: 0,
                dtick: (maxActivityValue * activityHigherScaleFactor) / 6,
            };

            let minTemperatureRange = minTemperatureValue;
            let maxTemperatureRange = maxTemperatureValue;

            if (minTemperatureValue >= 0) {
                minTemperatureRange =
                    minTemperatureValue * temperatureLowerScaleFactor;
            } else {
                minTemperatureRange =
                    minTemperatureValue * temperatureHigherScaleFactor;
            }

            if (maxTemperatureValue >= 0) {
                maxTemperatureRange =
                    maxTemperatureValue * temperatureHigherScaleFactor;
            } else {
                maxTemperatureRange =
                    maxTemperatureValue * temperatureLowerScaleFactor;
            }

            let temperatureYAxis = {
                visible: false,
                anchor: 'x',
                overlaying: 'y',
                fixedrange: true,
                range: [minTemperatureRange, maxTemperatureRange],
                tick0: minTemperatureRange,
                dtick: (maxTemperatureRange - minTemperatureRange) / 6,
            };

            if (leftScale === 'activity') {
                activityYAxis = {
                    ...activityYAxis,
                    ...visibleYAxis,
                };
            } else {
                temperatureYAxis = {
                    ...temperatureYAxis,
                    ...visibleYAxis,
                };
            }

            const newChart = {
                data: newChartData,
                layout: {
                    height: 160,
                    width: sleepScoringChartWidth,
                    hovermode: 'x',
                    margin: { l: 100, t: 25, b: 25, r: 35 },
                    font: {
                        family: 'Georgia, serif',
                    },
                    showlegend: false,
                    xaxis: {
                        type: 'date',
                        tickformat: '%H:%M',
                        tickmode: 'auto',
                        fixedrange: true,
                        showgrid: true,
                        linecolor,
                        linewidth,
                        mirror: true,
                        ticklen: 8,
                        range: [
                            chartsData[wristIndex][i].range[0].slice(0, 17) +
                                '00',
                            chartsData[wristIndex][i].range[
                                chartsData[wristIndex][i].range.length - 1
                            ].slice(0, 17) + '00',
                        ],
                    },
                    // activity axis
                    yaxis: activityYAxis,
                    // light axis
                    yaxis2: {
                        type: 'log',
                        visible: true,
                        fixedrange: true,
                        showgrid: false,
                        anchor: 'x',
                        overlaying: 'y',
                        side: 'right',
                        tickmode: 'auto',
                        gridcolor: '#cccccc',
                        gridwidth: 1,
                        zerolinecolor: '#cccccc',
                        range: [0, 5],
                        tickprefix: ' ',
                    },
                    // temperature axis
                    yaxis3: temperatureYAxis,
                    shapes: shapes,
                },
                config: {
                    displayModeBar: false,
                    staticPlot: false,
                    responsive: true,
                    edits: {
                        axisTitleText: false,
                        shapePosition: false,
                        titleText: false,
                    },
                    scrollZoom: false,
                    showAxisDragHandles: false,
                },
            };

            newcharts.push(newChart);

            newLayouts.push(newChart.layout);
        }

        setDataLists(newDatasLists);
        setLayouts(newLayouts);
        setCharts(newcharts);
    }

    async function handleCalculateStatesClick() {
        await calculateSleepStatesData(userId, 30, false, false);
    }

    async function handleRecalculateStatesClick() {
        await calculateSleepStatesData(
            userId,
            numberOfDays,
            true,
            isFullPeriodToggle
        );
    }

    function handleChangeLeftScaleClick(scale) {
        if (chartsLeftScale !== scale) {
            setChartsLeftScale(scale);

            setChartsLeftScaleOptions((prevState) => {
                const newState = [...prevState];

                return newState.map((option) => ({
                    ...option,
                    active: option.type === scale,
                }));
            });

            createCharts(chartsMode, scale);
        }
    }

    function handleFullPeriodChange() {
        setIsFullPeriodToggle(!isFullPeriodToggle);
    }

    return (
        <>
            <Col xs="12">
                <Card
                    title={textData.cardTitle}
                    isTable={true}
                    textSize="lg"
                    textCenter
                >
                    <Row className="pb-3 mx-3">
                        <Col
                            xs="12"
                            className="d-inline-flex justify-content-center align-items-center mt-3 flex-wrap"
                        >
                            {dataLabels.map((dataLabel) => (
                                <ChartLabel
                                    key={dataLabel.name}
                                    name={dataLabel.name}
                                    color={dataLabel.color}
                                    type={dataLabel.type}
                                />
                            ))}
                        </Col>
                    </Row>

                    {bodyParts.length > 1 && (
                        <Row className="pb-3 mx-3">
                            <Col
                                xs="12"
                                className="d-inline-flex justify-content-center align-items-center mt-3 flex-wrap"
                            >
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: textData.mixedChartsInfoText,
                                    }}
                                ></p>
                            </Col>
                        </Row>
                    )}

                    <Row className="mt-3 mx-3 d-flex justify-content-center">
                        <div className="sleepScoringToolbar">
                            <div className="sleepScoringToolbarMenu">
                                <div className="sleepScoringToolbarMenuControl">
                                    <p className="mr-2">
                                        {textData.dataVisualizationText}
                                    </p>
                                    <UncontrolledButtonDropdown
                                        nav
                                        inNavbar
                                        disabled={
                                            loadingCharts || loadingSleepStates
                                        }
                                    >
                                        <DropdownToggle
                                            caret
                                            color="primary"
                                            size="sm"
                                        >
                                            {
                                                chartsModesOptions.filter(
                                                    (mode) => mode.active
                                                )[0].text
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {chartsModesOptions.map((mode) => {
                                                return (
                                                    <DropdownItem
                                                        key={mode.text}
                                                        active={mode.active}
                                                        onClick={() =>
                                                            handleClickActigraphyData(
                                                                mode.type
                                                            )
                                                        }
                                                    >
                                                        {mode.text}
                                                    </DropdownItem>
                                                );
                                            })}
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>

                                <div className="sleepScoringToolbarMenuControl">
                                    <p className="mr-2">
                                        {textData.changeChartLeftScaleText}
                                    </p>
                                    <UncontrolledButtonDropdown
                                        nav
                                        inNavbar
                                        disabled={
                                            loadingCharts || loadingSleepStates
                                        }
                                    >
                                        <DropdownToggle
                                            caret
                                            color="primary"
                                            size="sm"
                                        >
                                            {
                                                chartsLeftScaleOptions.filter(
                                                    (mode) => mode.active
                                                )[0].text
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {chartsLeftScaleOptions.map(
                                                (mode) => {
                                                    return (
                                                        <DropdownItem
                                                            key={mode.text}
                                                            active={mode.active}
                                                            onClick={() =>
                                                                handleChangeLeftScaleClick(
                                                                    mode.type
                                                                )
                                                            }
                                                        >
                                                            {mode.text}
                                                        </DropdownItem>
                                                    );
                                                }
                                            )}
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            </div>

                            <div className="sleepScoringToolbarMenu">
                                <div className="sleepScoringToolbarMenuControl">
                                    <p>{textData.sleepingStatesText}</p>
                                    <p>
                                        {sleepingStatesStatus === null &&
                                            textData.noActigraphyDataText}
                                        {sleepingStatesStatus === 'loading' &&
                                            textData.calculatingStatesText}
                                        {sleepingStatesStatus ===
                                            'states_to_calculate' &&
                                            textData.thereAreStatesToCalculateText}
                                        {sleepingStatesStatus ===
                                            'up_to_date' &&
                                            textData.upToDateStatesText}
                                    </p>
                                </div>

                                <Button
                                    type="button"
                                    size="sm"
                                    color="primary"
                                    onClick={() => handleCalculateStatesClick()}
                                    disabled={
                                        loadingCharts ||
                                        loadingSleepStates ||
                                        !hasStatesToCalculate
                                    }
                                >
                                    {textData.calculateStateText}{' '}
                                    {(loadingCharts || loadingSleepStates) && (
                                        <Spinner size="sm" />
                                    )}
                                </Button>
                            </div>

                            <div className="sleepScoringToolbarMenu">
                                <div className="sleepScoringToolbarMenuControl">
                                    <p>{textData.numberOfDaysText}</p>
                                    <input
                                        type="number"
                                        value={numberOfDays}
                                        min={1}
                                        max={30}
                                        onChange={(e) =>
                                            setNumberOfDays(e.target.value)
                                        }
                                        disabled={
                                            loadingCharts ||
                                            loadingSleepStates ||
                                            isFullPeriodToggle
                                        }
                                    />
                                </div>

                                <div className="sleepScoringToolbarMenuControl">
                                    <p>{textData.entirePeriodText}</p>
                                    <input
                                        type="checkbox"
                                        checked={isFullPeriodToggle}
                                        onChange={handleFullPeriodChange}
                                        disabled={
                                            loadingCharts || loadingSleepStates
                                        }
                                    />
                                </div>

                                <Button
                                    type="button"
                                    size="sm"
                                    color="primary"
                                    onClick={() =>
                                        handleRecalculateStatesClick()
                                    }
                                    disabled={
                                        loadingCharts || loadingSleepStates
                                    }
                                >
                                    {textData.recalculateStatesText}{' '}
                                    {(loadingCharts || loadingSleepStates) && (
                                        <Spinner size="sm" />
                                    )}
                                </Button>
                            </div>
                        </div>
                    </Row>

                    <Row className="pb-3 mt-3 d-flex justify-content-center">
                        {loadingCharts ? (
                            <Spinner />
                        ) : (
                            <>
                                <Col
                                    style={{
                                        width: sleepScoringChartWidth,
                                    }}
                                    className="sleepScoringChartContainer"
                                >
                                    {charts.map((chart, index) => (
                                        <Col
                                            key={index}
                                            className={`sleepScoringChart ${
                                                Object.keys(channelsChart)
                                                    .length > 0
                                                    ? ''
                                                    : 'align-items-center'
                                            }`}
                                        >
                                            <SleepScoringChart
                                                chartData={chart.data}
                                                layout={layouts[index]}
                                                data={{
                                                    wrist:
                                                        dataLists?.wrist[
                                                            index
                                                        ] ?? {},
                                                    necklace:
                                                        dataLists?.necklace[
                                                            index
                                                        ] ?? {},
                                                }}
                                                index={index}
                                                chartsMode={chartsMode}
                                                setLayouts={setLayouts}
                                                setChannelsChart={
                                                    setChannelsChart
                                                }
                                                channelsChart={channelsChart}
                                                onCloseChannelsChart={
                                                    onCloseChannelsChart
                                                }
                                            />
                                        </Col>
                                    ))}
                                </Col>
                                {Object.keys(channelsChart).length > 0 && (
                                    <Col
                                        style={{
                                            width: width - (72 * width) / 100,
                                            position: 'relative',
                                        }}
                                    >
                                        {charts.map((_, index) => (
                                            <React.Fragment key={index}>
                                                {index !== 0 && (
                                                    <Col
                                                        style={{
                                                            height: `10rem`,
                                                            width: '100%',
                                                        }}
                                                    ></Col>
                                                )}
                                                {index === 0 && (
                                                    <MatriceChart
                                                        width={width}
                                                        data={
                                                            channelsChart.data2
                                                        }
                                                        onClose={
                                                            onCloseChannelsChart
                                                        }
                                                    />
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </Col>
                                )}
                            </>
                        )}
                    </Row>
                </Card>
            </Col>
        </>
    );
}
