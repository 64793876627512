import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Button, Card, CardBody, CardFooter, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import { ShepherdTour } from 'react-shepherd';
import lodash from 'lodash';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { stepsOptions, tourOptions } from './tour.config';
import { setLoading } from '../../../store/actions/loadingActions';
import { fetchDiaries } from '../../../services/api/diaries';
import history from '../../../services/history';
import { addUser } from '../../../services/api/users';
import { fetchCompany } from '../../../services/api/account';
import { fetchCompanies } from '../../../services/api/companies';
import { fetchFreeDevices } from '../../../services/api/devices';
import { validateForm } from '../../../helpers/formValidator';
import { FormFields } from './components/formFields';
import { DeviceFormFields } from './components/deviceFormFields';

AddUser.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function AddUser({ currentUser }) {
    const formRef = useRef(null);

    const [companies, setCompanies] = useState([]);
    const [showTrialsConfigurations, setShowTrialsConfigurations] =
        useState(false);
    const [showShareOptions, setShowShareOptions] = useState(false);
    const [roleIdOptions, setRoleIdOptions] = useState([]);
    const [devices, setDevices] = useState([]);
    const [serialNumbersOptions, setSerialNumbersOptions] = useState([]);
    const [devicesFields, setDevicesFields] = useState([]);

    const [diaryOptions, setDiaryOptions] = useState([]);
    const [langOptions, setLangOptions] = useState([]);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];

    const { schema, schemaWithTrials } =
        schemaConfig[lang][currentUser.permissions];

    const sleepDiarySubscription = currentUser.subscription.plans.filter(
        (subscriptionPlan) => subscriptionPlan.type === 'sleep_diary'
    );

    const lumusSubscription = currentUser.subscription.plans.filter(
        (subscriptionPlan) => subscriptionPlan.type === 'lumus'
    );

    function handleChangeCompany(value) {
        const company = companies.find((company) => company.id === value);

        if (company) {
            if (
                pageData.languageOptions[company?.descriptor?.defaultLanguage]
                    ?.label
            ) {
                formRef.current.setFieldValue('lang', {
                    label: pageData.languageOptions[
                        company?.descriptor?.defaultLanguage
                    ]?.label,
                    value: pageData.languageOptions[
                        company?.descriptor?.defaultLanguage
                    ]?.value,
                });
            } else {
                formRef.current.clearField('lang');
            }
        } else {
            formRef.current.clearField('lang');
        }
    }

    async function handleSubmit(data) {
        const useSchema = showTrialsConfigurations ? schemaWithTrials : schema;
        const { isValid, errors } = await validateForm(data, useSchema);

        if (isValid) {
            formRef.current.setErrors(errors);

            dispatch(setLoading(true));

            if (data?.diary_id !== undefined && data.diary_id === 0) {
                delete data['diary_id'];
            }

            data.role_id =
                currentUser.permissions === 'condor_admin'
                    ? data.role_id
                    : roleIdOptions[0].value;

            const response = await addUser(data);

            dispatch(setLoading(false));

            if (response) {
                toast.success(pageData.successText);
                history.push(history.location.state?.to || '/users');
            }
        } else {
            formRef.current.setErrors(errors);
        }
    }

    function handleNewUserRole(role_id) {
        if (role_id === 1) {
            setShowTrialsConfigurations(true);
            setShowShareOptions(false);
        } else {
            setShowTrialsConfigurations(false);
            setShowShareOptions(true);
        }
    }

    function handleRemoveClick(index) {
        let tempDevices = lodash.cloneDeep(devicesFields);
        tempDevices.splice(index, 1);

        setDevicesFields(tempDevices);
    }

    function handleAddClick() {
        let tempDevicesFields = lodash.cloneDeep(pageData.deviceFields);
        let deviceFieldsUUID = crypto.randomUUID();

        tempDevicesFields[0].props.options = serialNumbersOptions;
        tempDevicesFields[0].key = deviceFieldsUUID;
        tempDevicesFields[1].key = deviceFieldsUUID;
        tempDevicesFields[2].key = deviceFieldsUUID;

        setDevicesFields([...devicesFields, tempDevicesFields]);
    }

    useEffect(() => {
        formRef.current.reset();

        (async function () {
            if (currentUser.permissions === 'condor_admin') {
                dispatch(setLoading(true));

                const data = await fetchCompanies();

                setCompanies(data?.companies);

                pageData.fields.map((field) => {
                    switch (field.props.name) {
                        case 'role_id':
                            setRoleIdOptions(field.props.options);
                            break;
                    }
                });

                setLangOptions([
                    pageData.languageOptions.pt,
                    pageData.languageOptions.en,
                ]);

                dispatch(setLoading(false));
            } else if (
                currentUser.permissions === 'manager' ||
                currentUser.permissions === 'customer_admin'
            ) {
                if (history.location?.state) {
                    if (history.location.state?.canAddUsers === false) {
                        toast.warning(pageData.warningText);

                        history.push('/users');
                    } else {
                        setShowTrialsConfigurations(true);

                        pageData.fields.map((field) => {
                            switch (field.props.name) {
                                case 'role_id':
                                    setRoleIdOptions([field.props.options[0]]);
                                    formRef.current.setFieldValue(
                                        field.props.name,
                                        field.props.options[0]
                                    );
                                    break;
                            }
                        });

                        dispatch(setLoading(true));

                        const data = await fetchCompany();
                        const diariesData = await fetchDiaries();

                        setDiaryOptions([
                            {
                                value: 0,
                                label: pageData.defaultDiaryText,
                            },
                            ...diariesData?.diaries.map((diary) => {
                                return {
                                    value: diary.id,
                                    label: diary.name,
                                };
                            }),
                        ]);

                        const devicesData = await fetchFreeDevices();

                        if (devicesData) {
                            setDevices(devicesData?.devices);
                            setSerialNumbersOptions(
                                devicesData?.devices?.map((device) => {
                                    return {
                                        value: device.id,
                                        label: device.serial_no,
                                    };
                                })
                            );
                        }

                        dispatch(setLoading(false));

                        if (data) {
                            if (
                                pageData.languageOptions[
                                    data?.company?.descriptor?.defaultLanguage
                                ]?.label
                            ) {
                                formRef.current.setFieldValue('lang', {
                                    label: pageData.languageOptions[
                                        data?.company?.descriptor
                                            ?.defaultLanguage
                                    ]?.label,
                                    value: pageData.languageOptions[
                                        data?.company?.descriptor
                                            ?.defaultLanguage
                                    ]?.value,
                                });
                            }
                        }

                        formRef.current.setFieldValue('diary_id', {
                            label: pageData.defaultDiaryText,
                            value: 0,
                        });

                        setLangOptions([
                            pageData.languageOptions.pt,
                            pageData.languageOptions.en,
                            pageData.languageOptions.ge,
                        ]);
                    }
                } else {
                    history.push('/users');
                }
            }

            if (sleepDiarySubscription.length > 0) {
                formRef.current.setFieldValue('active', {
                    label: pageData.yesText,
                    value: true,
                });
            } else {
                formRef.current.setFieldValue('active', {
                    label: pageData.noText,
                    value: false,
                });
            }
        })();
    }, []);

    useEffect(() => {
        if (devicesFields.length > 0) {
            let tempDevicesFields = lodash.cloneDeep(devicesFields);

            tempDevicesFields = tempDevicesFields.map((deviceFields) => {
                deviceFields[0].props.label = pageData.serialNumberText;
                deviceFields[1].props.label = pageData.bodyPartText;
                deviceFields[1].props.options = pageData.bodyPartOptions;
                deviceFields[2].props.label = pageData.receivedAtText;

                return deviceFields;
            });

            setDevicesFields(tempDevicesFields);
        }

        if (currentUser.permissions === 'condor_admin') {
            setLangOptions([
                pageData.languageOptions.pt,
                pageData.languageOptions.en,
            ]);
        } else if (
            currentUser.permissions === 'manager' ||
            currentUser.permissions === 'customer_admin'
        ) {
            setLangOptions([
                pageData.languageOptions.pt,
                pageData.languageOptions.en,
                pageData.languageOptions.ge,
            ]);

            setDiaryOptions(
                diaryOptions.map((diary, index) => {
                    return index === 0
                        ? {
                              label: pageData.defaultDiaryText,
                              value: 0,
                          }
                        : {
                              value: diary.value,
                              label: diary.label,
                          };
                })
            );

            let diary_id = formRef.current.getFieldValue('diary_id');

            if (diary_id !== undefined) {
                let findDiary = diaryOptions.find(
                    (diary) => diary.value === diary_id
                );
                formRef.current.setFieldValue('diary_id', {
                    label:
                        diary_id === 0
                            ? pageData.defaultDiaryText
                            : findDiary?.label,
                    value: diary_id,
                });
            }
        }

        if (formRef.current.getFieldValue('lang')) {
            formRef.current.setFieldValue('lang', {
                label: pageData.languageOptions[
                    formRef.current.getFieldValue('lang')
                ]?.label,
                value: pageData.languageOptions[
                    formRef.current.getFieldValue('lang')
                ]?.value,
            });
        }

        if (sleepDiarySubscription.length > 0) {
            formRef.current.setFieldValue('active', {
                label: pageData.yesText,
                value: true,
            });
        } else {
            formRef.current.setFieldValue('active', {
                label: formRef.current.getFieldValue('active')
                    ? pageData.yesText
                    : pageData.noText,
                value: formRef.current.getFieldValue('active'),
            });
        }
    }, [lang]);

    return (
        <>
            <Card className="cardShadow">
                <CardBody>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <FormGroup row>
                            <ShepherdTour
                                steps={stepsOptions[lang].addPatient}
                                tourOptions={tourOptions}
                            >
                                <FormFields
                                    fields={pageData.fields}
                                    handleNewUserRole={handleNewUserRole}
                                    companyOptions={companies?.map(
                                        (company) => {
                                            return {
                                                value: company.id,
                                                label: company.name,
                                            };
                                        }
                                    )}
                                    diaryOptions={diaryOptions}
                                    showTrialsConfigurations={
                                        showTrialsConfigurations
                                    }
                                    roleIdOptions={roleIdOptions}
                                    currentUser={currentUser}
                                    handleChangeCompany={handleChangeCompany}
                                    langOptions={langOptions}
                                    showShareOptions={showShareOptions}
                                />
                            </ShepherdTour>
                        </FormGroup>
                        {currentUser.permissions !== 'condor_admin' &&
                            lumusSubscription.length > 0 &&
                            lumusSubscription[0].active && (
                                <DeviceFormFields
                                    pageData={pageData}
                                    devicesFields={devicesFields}
                                    handleAddClick={handleAddClick}
                                    handleRemoveClick={handleRemoveClick}
                                />
                            )}
                        <FormGroup row>
                            <div className="col-xs-12 col-md-12 text-left">
                                <Button color="primary mr-2" type="submit">
                                    {pageData.addText}
                                </Button>
                                <Link
                                    to={'/users'}
                                    className="btn btn-secondary"
                                >
                                    {pageData.cancelText}
                                </Link>
                            </div>
                        </FormGroup>
                    </Form>
                </CardBody>
                <CardFooter>
                    <p>
                        <b style={{ color: 'darkred' }}>*</b>{' '}
                        {pageData.requiredFieldText}
                    </p>
                </CardFooter>
            </Card>
        </>
    );
}
