import { parseISO, format } from 'date-fns';
import { ptBR, enUS } from 'date-fns/locale';

export function localeWeekDayFormatter(date, locale, lang = undefined) {
    try {
        return format(parseISO(date), 'eeee', {
            locale: lang ? (lang === 'pt' ? ptBR : enUS) : locale,
        });
    } catch (e) {
        return null;
    }
}

export function localeDateFormatter(date, locale) {
    try {
        return format(parseISO(date), 'P', {
            locale,
        });
    } catch (e) {
        return null;
    }
}

export function localeDateTimeFormatter(date, locale) {
    try {
        return format(parseISO(date), 'Pp', {
            locale,
        });
    } catch (e) {
        return null;
    }
}

export function currencyFormatter(amount, locale, currencyFormat) {
    let currency = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyFormat,
    }).format(amount / 100);

    return currency;
}

export function timeFormatter(strTime) {
    if (strTime[2] !== ':') {
        return '0' + strTime.slice(0, 4);
    }
    return strTime.slice(0, 5);
}
