import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa';
import {
    Col,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import PropTypes from 'prop-types';

import {
    Accordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody,
} from '../../../../components/accordion';
import { Table, TableHeaders, Td } from '../../../../components/table/table';

export function SystemLogs({ data, textData, handleUpdateLogType }) {
    const [open, setOpen] = useState([]);
    const [logTimes, setLogTimes] = useState([
        { text: textData.oneWeekText, active: false, days: 7 },
        { text: textData.oneMonthText, active: true, days: 30 },
        { text: textData.twoMonthsText, active: false, days: 60 },
        { text: textData.threeMonthsText, active: false, days: 90 },
    ]);

    const lang = useSelector((state) => state.lang.lang);

    async function handleClick(days) {
        await handleUpdateLogType('systemLogs', days);

        setLogTimes(
            logTimes.map((logTime) => {
                return {
                    ...logTime,
                    active: logTime.days === days ? true : false,
                };
            })
        );
    }

    useEffect(() => {
        setLogTimes([
            { text: textData.oneWeekText, active: false, days: 7 },
            { text: textData.oneMonthText, active: true, days: 30 },
            { text: textData.twoMonthsText, active: false, days: 60 },
            { text: textData.threeMonthsText, active: false, days: 90 },
        ]);
    }, [lang]);

    return (
        <>
            <Col xs="12">
                <Table>
                    <thead>
                        <tr>
                            <TableHeaders ths={textData.ths} />
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {textData.tds.map((td) => (
                                <Td key={td.th} title={td.th}>
                                    {data.tableData[td.property]}
                                </Td>
                            ))}
                        </tr>
                    </tbody>
                </Table>
            </Col>
            <Col xs="12" className="mt-3">
                <UncontrolledButtonDropdown nav inNavbar>
                    <DropdownToggle caret>
                        {textData.selectLogTimeText}
                    </DropdownToggle>
                    <DropdownMenu>
                        {logTimes.map((logTime) => {
                            return (
                                <DropdownItem
                                    key={logTime.text}
                                    active={logTime.active}
                                    onClick={() => handleClick(logTime.days)}
                                >
                                    {logTime.text}
                                </DropdownItem>
                            );
                        })}
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            </Col>
            <Col xs="12" className="mt-3">
                <Accordion open={open} toggle={setOpen}>
                    <AccordionItem>
                        <AccordionHeader targetId="7">
                            {textData.serverConnectionHistoryText}
                        </AccordionHeader>
                        <AccordionBody accordionId="7">
                            <Col xs="12" className="mt-2">
                                <Table>
                                    <thead>
                                        <tr>
                                            <TableHeaders
                                                ths={
                                                    textData.serverConnection
                                                        .ths
                                                }
                                            />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.serverConnectionHistoryData.map(
                                            (log, index) => (
                                                <tr
                                                    key={
                                                        log.lastServerConnection +
                                                        index
                                                    }
                                                >
                                                    {textData.serverConnection.tds.map(
                                                        (td) => (
                                                            <Td
                                                                key={
                                                                    td.th +
                                                                    index
                                                                }
                                                                title={td.th}
                                                            >
                                                                {
                                                                    log[
                                                                        td
                                                                            .property
                                                                    ]
                                                                }
                                                            </Td>
                                                        )
                                                    )}
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </AccordionBody>
                    </AccordionItem>
                    {/* <AccordionItem>
                        <AccordionHeader targetId="8">
                            {textData.eraseDeviceMemoryHistoryText}
                        </AccordionHeader>
                        <AccordionBody accordionId="8">
                            <Col xs="12" className="mt-2">
                                <Table>
                                    <thead>
                                        <tr>
                                            <TableHeaders
                                                ths={
                                                    textData.eraseDeviceMemory
                                                        .ths
                                                }
                                            />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.eraseDeviceMemoryHistoryData.map(
                                            (log, index) => (
                                                <tr key={log.logTime + index}>
                                                    <Td
                                                        title={
                                                            textData.commandTypeText
                                                        }
                                                    >
                                                        {log.type === 'request'
                                                            ? textData.requestCommandText
                                                            : textData.executionCommandText}
                                                    </Td>
                                                    <Td
                                                        title={
                                                            textData.dateTimeCommandSentText
                                                        }
                                                    >
                                                        {log.logTime}
                                                    </Td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </AccordionBody>
                    </AccordionItem> */}
                    <AccordionItem>
                        <AccordionHeader targetId="1">
                            {textData.fullChargeLogText}
                        </AccordionHeader>
                        <AccordionBody accordionId="1">
                            <Col xs="12" className="mt-2">
                                <Table>
                                    <thead>
                                        <tr>
                                            <TableHeaders
                                                ths={textData.fullCharge.ths}
                                            />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.fullChargeHistoryData.map(
                                            (log, index) => (
                                                <tr key={log.logTime + index}>
                                                    {textData.fullCharge.tds.map(
                                                        (td) => (
                                                            <Td
                                                                key={
                                                                    td.th +
                                                                    index
                                                                }
                                                                title={td.th}
                                                            >
                                                                {
                                                                    log[
                                                                        td
                                                                            .property
                                                                    ]
                                                                }
                                                            </Td>
                                                        )
                                                    )}
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="2">
                            {textData.batteryUsbStateLogText}
                        </AccordionHeader>
                        <AccordionBody accordionId="2">
                            <Col xs="12" className="mt-2">
                                <Table>
                                    <thead>
                                        <tr>
                                            <TableHeaders
                                                ths={
                                                    textData.batteryUsbState.ths
                                                }
                                            />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.batteryUsbStatusHistoryData.map(
                                            (log, index) => (
                                                <tr key={log.logTime + index}>
                                                    {textData.batteryUsbState.tds.map(
                                                        (td) => {
                                                            if (
                                                                td.property ===
                                                                'formattedLogType'
                                                            ) {
                                                                return (
                                                                    <Td
                                                                        key={
                                                                            td.th +
                                                                            index
                                                                        }
                                                                        title={
                                                                            td.th
                                                                        }
                                                                        className={
                                                                            log.logType ===
                                                                            96
                                                                                ? 'textGreen'
                                                                                : 'textRed'
                                                                        }
                                                                    >
                                                                        {
                                                                            log[
                                                                                td
                                                                                    .property
                                                                            ]
                                                                        }
                                                                    </Td>
                                                                );
                                                            } else {
                                                                return (
                                                                    <Td
                                                                        key={
                                                                            td.th +
                                                                            index
                                                                        }
                                                                        title={
                                                                            td.th
                                                                        }
                                                                    >
                                                                        {
                                                                            log[
                                                                                td
                                                                                    .property
                                                                            ]
                                                                        }
                                                                    </Td>
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="3">
                            {textData.powerDownLogText}
                        </AccordionHeader>
                        <AccordionBody accordionId="3">
                            <Col xs="12" className="mt-2">
                                <Table>
                                    <thead>
                                        <tr>
                                            <TableHeaders
                                                ths={textData.powerDown.ths}
                                            />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.powerDownHistoryData.map(
                                            (log, index) => (
                                                <tr key={log.logTime + index}>
                                                    {textData.powerDown.tds.map(
                                                        (td) => (
                                                            <Td
                                                                key={
                                                                    td.th +
                                                                    index
                                                                }
                                                                title={td.th}
                                                            >
                                                                {
                                                                    log[
                                                                        td
                                                                            .property
                                                                    ]
                                                                }
                                                            </Td>
                                                        )
                                                    )}
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="4">
                            {textData.bootLogText}
                        </AccordionHeader>
                        <AccordionBody accordionId="4">
                            <Col xs="12" className="mt-2">
                                <Table>
                                    <thead>
                                        <tr>
                                            <TableHeaders
                                                ths={textData.boot.ths}
                                            />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.bootReasonHistoryData.map(
                                            (log, index) => (
                                                <tr key={log.logTime + index}>
                                                    {textData.boot.tds.map(
                                                        (td) => (
                                                            <Td
                                                                key={
                                                                    td.th +
                                                                    index
                                                                }
                                                                title={td.th}
                                                            >
                                                                {
                                                                    log[
                                                                        td
                                                                            .property
                                                                    ]
                                                                }
                                                            </Td>
                                                        )
                                                    )}
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="5">
                            {textData.errorsLogText}
                        </AccordionHeader>
                        <AccordionBody accordionId="5">
                            <Col xs="12" className="mt-2">
                                <Table>
                                    <thead>
                                        <tr>
                                            <TableHeaders
                                                ths={textData.errors.ths}
                                            />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.errorsHistoryData.map(
                                            (log, index) => (
                                                <tr key={log.logTime + index}>
                                                    {textData.errors.tds.map(
                                                        (td, i) => {
                                                            switch (
                                                                td.property
                                                            ) {
                                                                case 'logTime':
                                                                    return (
                                                                        <Td
                                                                            key={
                                                                                td.property +
                                                                                index
                                                                            }
                                                                            title={
                                                                                td.th
                                                                            }
                                                                        >
                                                                            {
                                                                                log[
                                                                                    td
                                                                                        .property
                                                                                ]
                                                                            }
                                                                        </Td>
                                                                    );
                                                                default:
                                                                    return (
                                                                        <Td
                                                                            key={
                                                                                td.property +
                                                                                index
                                                                            }
                                                                            title={
                                                                                td.th
                                                                            }
                                                                        >
                                                                            {log[
                                                                                td
                                                                                    .property
                                                                            ] ? (
                                                                                <FaCheckCircle
                                                                                    style={{
                                                                                        color: 'green',
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <FaTimesCircle
                                                                                    style={{
                                                                                        color: 'red',
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Td>
                                                                    );
                                                            }
                                                        }
                                                    )}
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="6">
                            {textData.associationLogText}
                        </AccordionHeader>
                        <AccordionBody accordionId="6">
                            <Col xs="12" className="mt-2">
                                <Table>
                                    <thead>
                                        <tr>
                                            <TableHeaders
                                                ths={textData.association.ths}
                                            />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.associationsHistoryData.map(
                                            (association, index) => (
                                                <tr
                                                    key={
                                                        association.receivedAt +
                                                        index
                                                    }
                                                >
                                                    {textData.association.tds.map(
                                                        (td) => (
                                                            <Td
                                                                key={td.th}
                                                                title={td.th}
                                                            >
                                                                {
                                                                    association[
                                                                        td
                                                                            .property
                                                                    ]
                                                                }
                                                            </Td>
                                                        )
                                                    )}
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            </Col>
        </>
    );
}

SystemLogs.propTypes = {
    data: PropTypes.object.isRequired,
    textData: PropTypes.object.isRequired,
    handleUpdateLogType: PropTypes.func.isRequired,
};
