import api from '../../services/api';

export function fetchUsers() {
    const data = api
        .get('/users', { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchManagers() {
    const data = api
        .get('/managers', { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchSubordinates() {
    const data = api
        .get('/subordinates', { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function addUser(parms) {
    const data = api
        .post('/users', { ...parms }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function editUser(id, parms) {
    const data = api
        .put(`/users/${id}`, { ...parms }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function deleteUser(id) {
    const data = api
        .delete(`/users/${id}`, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function sendUserCredentials(id, parms) {
    const data = api
        .put(
            `/users/credentials/${id}`,
            { ...parms },
            { withCredentials: true }
        )
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchUserReport(
    id,
    date = null,
    min_date = null,
    max_date = null,
    days_in_week = null
) {
    const data = api
        .get(`/users/report/${id}`, {
            params: {
                date,
                min_date,
                max_date,
                days_in_week,
            },
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchUserPrintReport(
    id,
    date = null,
    min_date = null,
    max_date = null,
    days_in_week = null
) {
    const data = api
        .get(`/users/report/print/${id}`, {
            params: {
                date,
                min_date,
                max_date,
                days_in_week,
            },
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function importUser(parms) {
    let formData = new FormData();
    formData.append('file', parms.file);
    const data = api
        .post('/users/import', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchCurrentWeekReferenceData(
    id,
    date = null,
    min_date = null,
    max_date = null,
    days_in_week = null
) {
    return api.get(`/users/report/${id}/current_week_reference`, {
        params: {
            date,
            min_date,
            max_date,
            days_in_week,
        },
        withCredentials: true,
    });
}

export function fetchCalendarData(
    id,
    date = null,
    min_date = null,
    max_date = null,
    days_in_week = null
) {
    return api.get(`/users/report/${id}/calendar`, {
        params: {
            date,
            min_date,
            max_date,
            days_in_week,
        },
        withCredentials: true,
    });
}

export function fetchWeeklyEvolutionData(
    id,
    date = null,
    min_date = null,
    max_date = null,
    days_in_week = null
) {
    return api.get(`/users/report/${id}/weekly_evolution`, {
        params: {
            date,
            min_date,
            max_date,
            days_in_week,
        },
        withCredentials: true,
    });
}

export function fetchDailyEvolutionData(
    id,
    date = null,
    min_date = null,
    max_date = null,
    days_in_week = null
) {
    return api.get(`/users/report/${id}/daily_evolution`, {
        params: {
            date,
            min_date,
            max_date,
            days_in_week,
        },
        withCredentials: true,
    });
}

export function fetchAverageTableData(
    id,
    date = null,
    min_date = null,
    max_date = null,
    days_in_week = null
) {
    return api.get(`/users/report/${id}/average_table`, {
        params: {
            date,
            min_date,
            max_date,
            days_in_week,
        },
        withCredentials: true,
    });
}

export function fetchEditData(
    id,
    date = null,
    min_date = null,
    max_date = null,
    days_in_week = null
) {
    return api.get(`/users/report/${id}/edit_data`, {
        params: {
            date,
            min_date,
            max_date,
            days_in_week,
        },
        withCredentials: true,
    });
}

export function fetchActogramData(id) {
    return api.get(`/users/report/${id}/actogram`, {
        withCredentials: true,
    });
}

export function calculateSleepStates(
    id,
    recalculate = false,
    days = 30,
    fullPeriod = false
) {
    return api.get(`/users/report/${id}/calculate_sleep_states`, {
        params: {
            recalculate,
            days,
            full_period: fullPeriod,
        },
        withCredentials: true,
    });
}

export function fetchSleepScoringStatus(id) {
    return api.get(`/users/report/${id}/sleep_scoring_status`, {
        withCredentials: true,
    });
}

export function fetchSleepScoringData(id) {
    return api.get(`/users/report/${id}/sleep_scoring`, {
        withCredentials: true,
    });
}
