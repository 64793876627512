import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';

export default {
    pt: {
        customer_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome <b style="color: darkred">*</b>',
                        placeholder: 'Digite o nome do paciente...',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'ID Secundário',
                        placeholder: 'Digite o id secundário do paciente...',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail <b style="color: darkred">*</b>',
                        placeholder: 'Digite o e-Mail do paciente...',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'role_id',
                        label: 'Tipo de usuário <b style="color: darkred">*</b>',
                        options: [{ value: 1, label: 'Paciente' }],
                        className: 'role-id-sheperd',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Ativo <b style="color: darkred">*</b>',
                        options: [
                            { value: false, label: 'Não' },
                            { value: true, label: 'Sim' },
                        ],
                        className: 'active-sheperd',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialStart',
                        label: 'Início',
                        type: 'date',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialEnd',
                        label: 'Fim',
                        type: 'date',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'diary_id',
                        label: 'Diário',
                        options: [{}],
                        className: 'diary-sheperd',
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Select,
                    props: {
                        name: 'lang',
                        label: 'Idioma <b style="color: darkred">*</b>',
                        options: [
                            { value: 'pt', label: 'Português' },
                            { value: 'en', label: 'Inglês' },
                            { value: 'ge', label: 'Alemão' },
                        ],
                        className: 'lang-sheperd',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            deviceFields: [
                {
                    component: Select,
                    props: {
                        name: 'device_id',
                        label: 'Número de série',
                        options: [],
                    },
                    className: 'col-xs-12 col-md-2',
                },
                {
                    component: Select,
                    props: {
                        name: 'body_part',
                        label: 'Parte do corpo',
                        options: [
                            {
                                value: 'wrist',
                                label: 'Pulso',
                            },
                            {
                                value: 'necklace',
                                label: 'Colar',
                            },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'received_at',
                        type: 'date',
                        label: 'Data de recebimento',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            bodyPartOptions: [
                {
                    value: 'wrist',
                    label: 'Pulso',
                },
                {
                    value: 'necklace',
                    label: 'Colar',
                },
            ],
            devicesText: 'Dispositivos',
            serialNumberText: 'Numéro de série',
            receivedAtText: 'Data de recebimento',
            bodyPartText: 'Parte do corpo',
            addDeviceText: 'Adicionar dispositivo',
            deleteDeviceText: 'Excluir dispositivo',
            successText: 'Paciente adicionado com sucesso!',
            warningText: `Sua empresa atingiu o número máximo de pacientes ativos do plano!.
            Para conseguir adicionar mais pacientes, inative pacientes ou
            faça o upgrade do plano.`,
            addText: 'Adicionar',
            cancelText: 'Cancelar',
            requiredFieldText: 'Campos obrigatórios!',
            yesText: 'Sim',
            noText: 'Não',
            languageOptions: {
                pt: { label: 'Português', value: 'pt' },
                en: { label: 'Inglês', value: 'en' },
                ge: { label: 'Alemão', value: 'ge' },
            },
            defaultDiaryText: 'Diário padrão',
        },
        manager: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome <b style="color: darkred">*</b>',
                        placeholder: 'Digite o nome do paciente...',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'ID Secundário',
                        placeholder: 'Digite o id secundário do paciente...',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail <b style="color: darkred">*</b>',
                        placeholder: 'Digite o e-Mail do paciente...',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'role_id',
                        label: 'Tipo de usuário <b style="color: darkred">*</b>',
                        options: [{ value: 1, label: 'Paciente' }],
                        className: 'role-id-sheperd',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Ativo <b style="color: darkred">*</b>',
                        options: [
                            { value: false, label: 'Não' },
                            { value: true, label: 'Sim' },
                        ],
                        className: 'active-sheperd',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialStart',
                        label: 'Início',
                        type: 'date',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialEnd',
                        label: 'Fim',
                        type: 'date',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'diary_id',
                        label: 'Diário',
                        options: [{}],
                        className: 'diary-sheperd',
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Select,
                    props: {
                        name: 'lang',
                        label: 'Idioma <b style="color: darkred">*</b>',
                        options: [
                            { value: 'pt', label: 'Português' },
                            { value: 'en', label: 'Inglês' },
                            { value: 'ge', label: 'Alemão' },
                        ],
                        className: 'lang-sheperd',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            deviceFields: [
                {
                    component: Select,
                    props: {
                        name: 'device_id',
                        label: 'Número de série',
                        options: [],
                    },
                    className: 'col-xs-12 col-md-2',
                },
                {
                    component: Select,
                    props: {
                        name: 'body_part',
                        label: 'Parte do corpo',
                        options: [
                            {
                                value: 'wrist',
                                label: 'Pulso',
                            },
                            {
                                value: 'necklace',
                                label: 'Colar',
                            },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'received_at',
                        type: 'date',
                        label: 'Data de recebimento',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            bodyPartOptions: [
                {
                    value: 'wrist',
                    label: 'Pulso',
                },
                {
                    value: 'necklace',
                    label: 'Colar',
                },
            ],
            devicesText: 'Dispositivos',
            serialNumberText: 'Numéro de série',
            receivedAtText: 'Data de recebimento',
            bodyPartText: 'Parte do corpo',
            addDeviceText: 'Adicionar dispositivo',
            deleteDeviceText: 'Excluir dispositivo',
            successText: 'Usuário adicionado com sucesso!',
            warningText: `Sua empresa atingiu o número máximo de pacientes ativos do plano!.
            Para conseguir adicionar mais pacientes, inative pacientes ou
            faça o upgrade do plano.`,
            addText: 'Adicionar',
            cancelText: 'Cancelar',
            requiredFieldText: 'Campos obrigatórios!',
            yesText: 'Sim',
            noText: 'Não',
            languageOptions: {
                pt: { label: 'Português', value: 'pt' },
                en: { label: 'Inglês', value: 'en' },
                ge: { label: 'Alemão', value: 'ge' },
            },
            defaultDiaryText: 'Diário padrão',
        },
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome <b style="color: darkred">*</b>',
                        placeholder: 'Digite o nome do usuário...',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Select,
                    props: {
                        name: 'company_id',
                        label: 'Empresa <b style="color: darkred">*</b>',
                        options: [{}],
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'ID Secundário',
                        placeholder: 'Digite o id secundário do usuário...',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail <b style="color: darkred">*</b>',
                        placeholder: 'Digite o e-Mail do usuário...',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'role_id',
                        label: 'Tipo de usuário <b style="color: darkred">*</b>',
                        options: [
                            { value: 2, label: 'Gestor' },
                            { value: 3, label: 'Administrador Condor' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Ativo <b style="color: darkred">*</b>',
                        options: [
                            { value: false, label: 'Não' },
                            { value: true, label: 'Sim' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'lang',
                        label: 'Idioma <b style="color: darkred">*</b>',
                        options: [
                            { value: 'pt', label: 'Português' },
                            { value: 'en', label: 'Inglês' },
                            { value: 'ge', label: 'Alemão' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            successText: 'Usuário adicionado com sucesso!',
            addText: 'Adicionar',
            cancelText: 'Cancelar',
            requiredFieldText: 'Campos obrigatórios!',
            yesText: 'Sim',
            noText: 'Não',
            languageOptions: {
                pt: { label: 'Português', value: 'pt' },
                en: { label: 'Inglês', value: 'en' },
                ge: { label: 'Alemão', value: 'ge' },
            },
        },
    },
    en: {
        customer_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name <b style="color: darkred">*</b>',
                        placeholder: `Enter the patient's name...`,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'Secondary ID',
                        placeholder: `Enter the secondary patient id...`,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail <b style="color: darkred">*</b>',
                        placeholder: `Enter the patient's e-Mail...`,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'role_id',
                        label: 'User type <b style="color: darkred">*</b>',
                        options: [{ value: 1, label: 'Patient' }],
                        className: 'role-id-sheperd',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Active <b style="color: darkred">*</b>',
                        options: [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                        ],
                        className: 'active-sheperd',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialStart',
                        label: 'Start',
                        type: 'date',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialEnd',
                        label: 'End',
                        type: 'date',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'diary_id',
                        label: 'Diary',
                        options: [{}],
                        className: 'diary-sheperd',
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Select,
                    props: {
                        name: 'lang',
                        label: 'Language <b style="color: darkred">*</b>',
                        options: [
                            { value: 'pt', label: 'Portuguese' },
                            { value: 'en', label: 'English' },
                            { value: 'ge', label: 'German' },
                        ],
                        className: 'lang-sheperd',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            deviceFields: [
                {
                    component: Select,
                    props: {
                        name: 'device_id',
                        label: 'Serial number',
                        options: [],
                    },
                    className: 'col-xs-12 col-md-2',
                },
                {
                    component: Select,
                    props: {
                        name: 'body_part',
                        label: 'Body part',
                        options: [
                            {
                                value: 'wrist',
                                label: 'Wrist',
                            },
                            {
                                value: 'necklace',
                                label: 'Necklace',
                            },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'received_at',
                        type: 'date',
                        label: 'Received at',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            bodyPartOptions: [
                {
                    value: 'wrist',
                    label: 'Wrist',
                },
                {
                    value: 'necklace',
                    label: 'Necklace',
                },
            ],
            devicesText: 'Devices',
            serialNumberText: 'Serial number',
            receivedAtText: 'Received at',
            bodyPartText: 'Body part',
            addDeviceText: 'Add device',
            deleteDeviceText: 'Delete device',
            successText: 'Patient successfully added!',
            warningText: `Your company has reached the maximum number of active patients allowed in your plan!
            To be able to add more patients, inactivate patients or
            upgrade your plan!`,
            addText: 'Add',
            cancelText: 'Cancel',
            requiredFieldText: 'Required fields!',
            yesText: 'Yes',
            noText: 'No',
            languageOptions: {
                pt: { label: 'Portuguese', value: 'pt' },
                en: { label: 'English', value: 'en' },
                ge: { label: 'German', value: 'ge' },
            },
            defaultDiaryText: 'Default diary',
        },
        manager: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name <b style="color: darkred">*</b>',
                        placeholder: `Enter the patient's name...`,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'Secondary ID',
                        placeholder: `Enter the secondary patient id...`,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail <b style="color: darkred">*</b>',
                        placeholder: `Enter the patient's e-Mail...`,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'role_id',
                        label: 'User type <b style="color: darkred">*</b>',
                        options: [{ value: 1, label: 'Patient' }],
                        className: 'role-id-sheperd',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Active <b style="color: darkred">*</b>',
                        options: [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                        ],
                        className: 'active-sheperd',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialStart',
                        label: 'Start',
                        type: 'date',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialEnd',
                        label: 'End',
                        type: 'date',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'diary_id',
                        label: 'Diary',
                        options: [{}],
                        className: 'diary-sheperd',
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Select,
                    props: {
                        name: 'lang',
                        label: 'Language <b style="color: darkred">*</b>',
                        options: [
                            { value: 'pt', label: 'Portuguese' },
                            { value: 'en', label: 'English' },
                            { value: 'ge', label: 'German' },
                        ],
                        className: 'lang-sheperd',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            deviceFields: [
                {
                    component: Select,
                    props: {
                        name: 'device_id',
                        label: 'Serial number',
                        options: [],
                    },
                    className: 'col-xs-12 col-md-2',
                },
                {
                    component: Select,
                    props: {
                        name: 'body_part',
                        label: 'Body part',
                        options: [
                            {
                                value: 'wrist',
                                label: 'Wrist',
                            },
                            {
                                value: 'necklace',
                                label: 'Necklace',
                            },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'received_at',
                        type: 'date',
                        label: 'Received at',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            bodyPartOptions: [
                {
                    value: 'wrist',
                    label: 'Wrist',
                },
                {
                    value: 'necklace',
                    label: 'Necklace',
                },
            ],
            devicesText: 'Devices',
            serialNumberText: 'Serial number',
            receivedAtText: 'Received at',
            bodyPartText: 'Body part',
            addDeviceText: 'Add device',
            deleteDeviceText: 'Delete device',
            successText: 'User successfully added!',
            warningText: `Your company has reached the maximum number of active patients allowed in your plan!
                 To be able to add more patients, inactivate patients or
                 upgrade your plan!`,
            addText: 'Add',
            cancelText: 'Cancel',
            requiredFieldText: 'Required fields!',
            yesText: 'Yes',
            noText: 'No',
            languageOptions: {
                pt: { label: 'Portuguese', value: 'pt' },
                en: { label: 'English', value: 'en' },
                ge: { label: 'German', value: 'ge' },
            },
            defaultDiaryText: 'Default diary',
        },
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name <b style="color: darkred">*</b>',
                        placeholder: `Enter the username.`,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Select,
                    props: {
                        name: 'company_id',
                        label: 'Company <b style="color: darkred">*</b>',
                        options: [{}],
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'Secondary ID',
                        placeholder: `Enter the user's secondary ID...`,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail <b style="color: darkred">*</b>',
                        placeholder: `Enter the user's e-Mail...`,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'role_id',
                        label: 'User type <b style="color: darkred">*</b>',
                        options: [
                            { value: 2, label: 'Manager' },
                            { value: 3, label: 'Condor Admin' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Active <b style="color: darkred">*</b>',
                        options: [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'lang',
                        label: 'Language <b style="color: darkred">*</b>',
                        options: [
                            { value: 'pt', label: 'Portuguese' },
                            { value: 'en', label: 'English' },
                            { value: 'ge', label: 'German' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            successText: 'User successfully added!',
            addText: 'Add',
            cancelText: 'Cancel',
            requiredFieldText: 'Required fields!',
            yesText: 'Yes',
            noText: 'No',
            languageOptions: {
                pt: { label: 'Portuguese', value: 'pt' },
                en: { label: 'English', value: 'en' },
                ge: { label: 'German', value: 'ge' },
            },
        },
    },
};
