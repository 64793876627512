import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from './tableContainer.module.css';

import Table from '../../../components/table/table';
import { ThWithOrdering } from '../../../components/table/thWithOrdering';
import TableRows from './tableRows';

export function TableContainer({
    ths,
    tds,
    filteredUsers,
    actions,
    activeText,
    inactiveText,
    roleDescription,
    activeFilter,
    orderByProperty,
}) {
    return (
        <Row>
            <Col xs="12">
                <Table className={styles.usersTable}>
                    <thead>
                        <tr>
                            {ths.map((th) => {
                                if (
                                    ['id', 'name', 'secondary_id'].includes(
                                        th.property
                                    )
                                ) {
                                    return (
                                        <ThWithOrdering
                                            key={th.property}
                                            property={th.property}
                                            activeFilterProperty={
                                                activeFilter.property
                                            }
                                            activeOrder={activeFilter.order}
                                            orderByProperty={orderByProperty}
                                            activeClassName={styles.active}
                                            clickableIconClassName={
                                                styles.clickableIcon
                                            }
                                        >
                                            {th.name}
                                        </ThWithOrdering>
                                    );
                                }

                                return <th key={th.property}>{th.name}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <TableRows
                            users={filteredUsers}
                            tds={tds}
                            actions={actions}
                            activeText={activeText}
                            inactiveText={inactiveText}
                            roleDescription={roleDescription}
                        />
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}

TableContainer.propTypes = {
    ths: PropTypes.array.isRequired,
    tds: PropTypes.array.isRequired,
    filteredUsers: PropTypes.array.isRequired,
    actions: PropTypes.array,
    activeText: PropTypes.string.isRequired,
    inactiveText: PropTypes.string.isRequired,
    roleDescription: PropTypes.object,
    activeFilter: PropTypes.object.isRequired,
    orderByProperty: PropTypes.func.isRequired,
};
